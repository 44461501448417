export const getConsultationRatingIsLoading = state =>
  state.consultationRatings.isLoading;

export const getConsultationRating = state =>
  state.consultationRatings.consultationRating;

export const getConsultationRatingPage = state =>
  state.consultationRatings.page;

export const getConsultationRatingOrderBy = state =>
  state.consultationRatings.order_by;
