import makeModel from "../../utils/redux/requestModel";

const name = "users";
export const USERS_MODE_ALL = "all";
export const modes = [USERS_MODE_ALL];

export const {
  types: requestTypes,
  state,
  actions,
  reducer: requestReducer,
  selector: contactRequestSelector
} = makeModel(name, modes);
