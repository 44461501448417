import types from "./conclusionsTypes";

export default {
  searchRequest: (userId, page, search, mode, limit = 100) => ({
    type: types.SEARCH_REQUEST,
    request: { userId, page, search, mode, limit }
  }),
  searchSuccess: (payload, request = {}, rest) => ({
    type: types.SEARCH_SUCCESS,
    payload,
    request,
    rest
  }),
  searchFailure: (error, request) => ({
    type: types.SEARCH_FAILURE,
    error,
    request
  }),

  sendConclusionRequest: ({
    consultationId,
    text,
    isNew,
    problemTitle,
    problemStatus,
    recommendedConsultationDays
  }) => ({
    type: types.SEND_CONCLUSION_REQUEST,
    request: {
      consultationId,
      text,
      isNew,
      problemTitle,
      problemStatus,
      recommendedConsultationDays
    }
  }),
  sendConclusionSuccess: payload => ({
    type: types.SEND_CONCLUSION_SUCCESS,
    payload
  }),
  sendConclusionFailure: error => ({
    type: types.SEND_CONCLUSION_FAILURE,
    error
  }),

  getConclusion: id => ({
    type: types.GET_CONCLUSION,
    id
  }),
  getConclusionSuccess: payload => ({
    type: types.GET_CONCLUSION_SUCCESS,
    payload
  }),
  getConclusionFailure: () => ({
    type: types.GET_CONCLUSION_FAILURE
  })
};

export const getConcusionPDF = id => ({
  type: types.GET_CONCLUSION_PDF,
  payload: {
    id
  }
});
