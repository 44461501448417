import React from "react";
import PropTypes from "prop-types";

import { Dialog } from "ibolit-ui";

import { useShowModal } from "~/hooks/useModal";

import FeedbackComment from "../FeedbackComment/FeedbackComment";

import { SETTINGS } from "~/locators/privateProfile";

import styles from "./ProfileFeedback.scss";

import { CONSULTATIONS_RATING } from "~/modals/ConsultationsRatings";

const ProfileSettings = ({ onCloseClick }) => {
  const handleSorting = useShowModal(CONSULTATIONS_RATING, {});

  return (
    <Dialog
      testId="ProfileSettingsModal"
      header="Отзывы пациентов"
      headerTestId={"header_modal_window_feedback"}
      testIdTitle={SETTINGS.TITLE}
      closeTestId={SETTINGS.ICON_CLOSE}
      backTestId={SETTINGS.ICON_BACK}
      onSortingClick={handleSorting}
      onCloseClick={onCloseClick}
      boxClassName={styles.modalBox}
      bodyClassName={styles.body}
      headerColorVariant="tertiary"
    >
      <FeedbackComment />
    </Dialog>
  );
};

ProfileSettings.propTypes = {
  onCloseClick: PropTypes.func.isRequired
};

export default ProfileSettings;
