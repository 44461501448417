import { FILTERS } from "~/locators/contacts";

export const FILTER_NO_SORTING = null;
export const FILTER_BY_NAME = "alphabet";
export const FILTER_BY_DATE = "added";
export const INITIAL_FILTER = {
  order: FILTER_NO_SORTING,
  only_active: false
};

export const SORTING_VARIANTS = [
  {
    label: "Без сортировки",
    value: FILTER_NO_SORTING,
    testidProps: { input: FILTERS.NO_SORTING }
  },
  {
    label: "По алфавиту",
    value: FILTER_BY_NAME,
    testidProps: { input: FILTERS.BY_NAME }
  },
  {
    label: "По дате добавления",
    value: FILTER_BY_DATE,
    testidProps: { input: FILTERS.BY_DATE }
  }
];
