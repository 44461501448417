import React from "react";
import { useSelector } from "react-redux";
import UserItem from "~/components/UserItem";
import ShapeButton from "~/components/ShapeButton";
import { useSelectorModal } from "../components/SelectorModal";
import { USER_SELECTOR } from "~/locators/consultations";
import styles from "../components/NewConsultation.scss";
import SearchBlock from "~/components/SearchBlock";
import { useUsersList } from "~/hooks/useUsersList";
import ItemsList from "~/modals/NewConsultation/UserSelector/ItemsList";
import {
  contactRequestSelector,
  USERS_MODE_ALL
} from "~/store/users/usersModel";

const SELECTOR_INITIAL_FILTER = {
  order: null
};

export default ({ user, setUser, disabled }) => {
  const [SelectorModal, toggleSelector] = useSelectorModal();

  const { listRef, setSearch, incrementPage } = useUsersList(
    SELECTOR_INITIAL_FILTER
  );

  const {
    items,
    isFetching,
    initialFetched,
    canLoadMore,
    hasErrors,
    isEmpty
  } = useSelector(state => contactRequestSelector(state, USERS_MODE_ALL));

  const handleUserSelect = _user => {
    setUser(_user);
    toggleSelector(false);
  };

  return (
    <>
      {user ? (
        <UserItem
          testid={USER_SELECTOR.SELECTED_USER}
          user={user}
          variant="shape"
          {...(!disabled && { onClick: toggleSelector })}
          contentClass={styles.userRowContent}
        />
      ) : (
        <ShapeButton
          testid={USER_SELECTOR.PLACEHOLDER}
          textColorVariant="pale"
          onClick={toggleSelector}
          text="Выбрать пациента"
        />
      )}
      <SelectorModal>
        <SearchBlock
          onSearchChange={setSearch}
          placeholder="Поиск пациента по имени"
          testId={USER_SELECTOR.INPUT_SEARCH}
          className={styles.searchContainer}
        />
        <ItemsList
          ref={listRef}
          items={items}
          className={styles.listContainer}
          onItemClick={handleUserSelect}
          selected={user}
          isFetching={isFetching}
          initialFetched={initialFetched}
          canLoadMore={canLoadMore}
          hasErrors={hasErrors}
          isEmpty={isEmpty}
          incrementPage={incrementPage}
        />
      </SelectorModal>
    </>
  );
};
