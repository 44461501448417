import React, { useMemo } from "react";
import { Button, Dialog, Text, Icon } from "ibolit-ui";
import { useDispatch, useSelector } from "react-redux";
import styles from "./AddingFilesModal.scss";
import locators from "~/locators/addingFiles";
import LoadFileSvg from "~/assets/images/load-file.svg";
import { getConsultationFiles } from "~/store/consultations/consultationSelectors";
import consultationActions from "~/store/consultations/consultationsActions";
import uiActions from "~/store/ui/uiActions";
import { FILE_ALERT_MODAL } from "~/layouts/Consultations/containers/Consultation/components/FileManager/FileAlertModal/FileAlertModal";
import { ERROR_BOX_MODAL } from "~/components/ErrorBox/ErrorBox";
import { useShowModal } from "~/hooks/useModal";

export const ADDING_FILES = "ADDING_FILES";

export const MAX_FILE_SIZE = 1e8;

const AddingFilesModal = ({ consultationId }) => {
  const dispatch = useDispatch();
  const content = useSelector(getConsultationFiles);
  const isFilesToLoad = useMemo(() => {
    let defaultAnswer = false;

    if (Object.keys(content).length !== 0) {
      return Object.values(content).reduce((answer, file) => {
        if (!answer && !file.tempFileId && file.progress === undefined) {
          return true;
        }

        return answer;
      }, defaultAnswer);
    }

    return defaultAnswer;
  }, [content]);
  const handleFile = (e, file) => {
    dispatch(
      consultationActions.addFile({
        url: URL.createObjectURL(file),
        file,
        data: e.target.result
      })
    );
  };
  const handleChangeFile = event => {
    let index = 0;
    let filesNames = "";
    let bigFilesCount = 0;
    let filesCount = event.currentTarget.files.length;

    while (filesCount > index) {
      let reader = new FileReader();
      const file = event.currentTarget.files[index];

      if (file.size > MAX_FILE_SIZE) {
        filesNames += `${bigFilesCount >= 1 ? ", " : ""}${file.name}`;
        bigFilesCount++;
      } else {
        reader.onloadend = e => handleFile(e, file);
        reader.readAsDataURL(file);
      }
      index++;
    }

    if (bigFilesCount > 0) {
      dispatch(
        uiActions.showModal(FILE_ALERT_MODAL, { filesNames, bigFilesCount })
      );
    }
  };
  const handleRemoveFile = file =>
    dispatch(consultationActions.removeFile(file));
  const handleClose = () => dispatch(uiActions.hideModal(ADDING_FILES));
  const handleRepeatUploadFiles = () => {
    dispatch(consultationActions.uploadFiles({ consultationId }));
    dispatch(uiActions.hideModal(ERROR_BOX_MODAL));
  };
  const handleShowNetworkErrorModal = useShowModal(ERROR_BOX_MODAL, {
    onRepeatClick: handleRepeatUploadFiles
  });
  const handleUploadFiles = () => {
    if (!navigator.onLine) {
      handleShowNetworkErrorModal();
    }

    dispatch(consultationActions.uploadFiles({ consultationId }));
    handleClose();
  };

  return (
    <Dialog
      header="Добавьте файлы"
      onCloseClick={handleClose}
      testId={locators.MODAL}
      boxClassName={styles.box}
      bodyClassName={styles.dialog_body}
    >
      <div className={styles.body}>
        <Text colorVariant="tertiary" testid={locators.MODAL_TITLE}>
          Выберите нужные файлы перед отправкой
        </Text>
        {isFilesToLoad ? (
          <div className={styles.gallery}>
            {Object.entries(content).map(([key, file]) => {
              if (file.tempFileId || file.progress !== undefined) return;

              const isImage = file.file.type.match("image.*");
              const fileName =
                file.file.name.length > 10
                  ? `${file.file.name.slice(0, 4)}...${file.file.name.slice(
                      -3
                    )}`
                  : file.file.name;

              return (
                <div
                  className={styles.img_wrapper}
                  key={file.file.name}
                  data-testid={locators.FILE_ITEM}
                >
                  {isImage ? (
                    <img
                      src={file.url}
                      alt="file"
                      className={styles.img}
                      data-testid={locators.FILE_IMAGE}
                    />
                  ) : (
                    <div className={styles.file}>
                      <Icon
                        variant="loaded-file"
                        fill="var(--secondaryBlue)"
                        testid={locators.FILE_ICON}
                      />
                      <div data-testid={locators.FILE_NAME}>{fileName}</div>
                    </div>
                  )}
                  <div
                    onClick={() => handleRemoveFile(file)}
                    className={styles.remove}
                    data-testid={locators.FILE_REMOVE_BUTTON}
                  >
                    <Icon variant="bin" sizeValue="12px" fill="var(--white)" />
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <svg viewBox={LoadFileSvg.viewBox} className={styles.svg}>
            <use xlinkHref={`#${LoadFileSvg.id}`} />
          </svg>
        )}
        {!isFilesToLoad && (
          <Text colorVariant="tertiary" testid={locators.MODAL_MAX_FILE_DESC}>
            Максимальный объем файла 100мб.
          </Text>
        )}
      </div>
      <div className={styles.controls}>
        <div className={styles.input_wrapper}>
          <input
            id="file"
            name="file"
            type="file"
            multiple
            onChange={handleChangeFile}
            className={styles.input}
          />
          <Button
            variant="tertiary"
            className={styles.button}
            textClassName={styles.button_text}
            testid={locators.MODAL_SELECT_BUTTON}
          >
            <label htmlFor="file" className={styles.label}>
              Выбрать файлы
            </label>
          </Button>
        </div>
        <Button
          onClick={handleUploadFiles}
          disabled={!isFilesToLoad}
          colorVariant="doctor"
          fullWidth
          testid={locators.MODAL_UPLOAD_BUTTON}
        >
          Загрузить
        </Button>
      </div>
    </Dialog>
  );
};

export default AddingFilesModal;
