import React from "react";
import cn from "classnames";
import { Separator } from "ibolit-ui";
import Comment from "~/components/PublicProfile/components/Comment";
import { CONTACT } from "~/locators/contacts";
import MessageRow from "./MessageRow";
import Header from "./Header";
import styles from "./PublicProfile.scss";
import NewConsultationButtonContainer from "~/modals/NewConsultation/containers/NewConsultationButton";

const PublicProfile = ({ user, user: { id, comment }, variant }) => (
  <div className={cn(styles.container, styles[variant])}>
    <Header user={user} variant={variant} />

    <Comment
      id={id}
      comment={comment}
      className={styles.commentContainer}
      variant="full"
      testid={CONTACT.COMMENT}
    />

    <Separator className={styles.separator} />

    {variant !== "inChat" && <MessageRow {...user} />}

    <NewConsultationButtonContainer
      user={user}
      className={styles.rowWithImage}
      testid={CONTACT.CONSULTATION}
    />

    <Separator className={styles.separator} />
  </div>
);

export default PublicProfile;
