import React from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";

import { Dialog } from "ibolit-ui";
import Form from "./components/Form";
import { SETTINGS } from "~/locators/privateProfile";
import styles from "./InfoSettings.scss";

const InfoSettings = ({
  user,
  onCloseClick,
  loadingUserData,
  onFormSubmit
}) => {
  const { description, education, experience, category, specialties } = user;

  return (
    <Dialog
      testId="ProfileSettingsModal"
      header="О себе"
      headerTestId={"header_modal_window_about"}
      testIdTitle={`${SETTINGS.TITLE}_about`}
      closeTestId={SETTINGS.ICON_CLOSE}
      backTestId={SETTINGS.ICON_BACK}
      onCloseClick={onCloseClick}
      boxClassName={styles.modalBox}
      headerClassName={styles.modalHeader}
    >
      <Formik
        key={JSON.stringify(user)}
        initialValues={{
          description: description || "",
          education: education || "",
          experience: experience || "",
          category: category || "",
          specialties: specialties || ""
        }}
        validate={validate}
        onSubmit={onFormSubmit}
      >
        {props => <Form {...props} loadingUserData={loadingUserData} />}
      </Formik>
    </Dialog>
  );
};

function validate(values) {
  const errors = {};

  if (values.description?.length > 2000) {
    errors.description = "Максимальное количество символов - 2000";
  }

  if (values.education?.length > 2000) {
    errors.education = "Максимальное количество символов - 2000";
  }

  if (values.category?.length > 255) {
    errors.category = "Максимальное количество символов - 255";
  }

  if (values?.experience > 100) {
    errors.experience = "Максимальный стаж - 100 лет";
  }

  return errors;
}

InfoSettings.propTypes = {
  user: PropTypes.object.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  loadingUserData: PropTypes.bool.isRequired,
  onFormSubmit: PropTypes.func.isRequired
};

export default InfoSettings;
