import React from "react";
import PropTypes from "prop-types";
import { Dialog, Scrollbar } from "ibolit-ui";
import styles from "./ConnectionCheck.scss";
import { CONNECTION_CHECK } from "~/locators/privateProfile";

const ConnectionCheck = ({ onCloseClick, isModal }) => (
  <Dialog
    testId="ConnectionCheckModal"
    onCloseClick={onCloseClick}
    backTestId={CONNECTION_CHECK.ICON_BACK}
    closeTestId={CONNECTION_CHECK.ICON_CLOSE}
  >
    {isModal ? (
      <Scrollbar style={{ width: "350px", height: "680px" }}>
        <iframe
          title="health.ibolit.pro"
          src={`https://health.ibolit.pro`}
          className={styles.iframeContainer}
          scrolling="no"
          seamless
          allow="microphone; camera"
        />
      </Scrollbar>
    ) : (
      <Scrollbar autoHeight autoHeightMin={680} autoHeightMax={990}>
        <iframe
          title="health.ibolit.pro"
          src={`https://health.ibolit.pro`}
          className={styles.iframeContainer}
          scrolling="no"
          seamless
          allow="microphone; camera"
        />
      </Scrollbar>
    )}
  </Dialog>
);

ConnectionCheck.propTypes = {
  onCloseClick: PropTypes.func,
  isModal: PropTypes.bool
};

export default ConnectionCheck;
