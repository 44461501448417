import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { useShowModal } from "~/hooks/useModal";
import { CONFIRM_VIEW } from "~/layouts/Consultations/containers/Consultation/containers/Confirm";

import { CONCLUSION_VIEW } from "~/modals/Conclusion/ConclusionModal";
import { CANCEL_CONSULTATION } from "~/modals/CancelConsultation/CancelConsultation";

import { getConsultationInProgress } from "~/store/consultations/consultationSelectors";

import consultationsActions from "~/store/consultations/consultationsActions";
import callActions from "~/store/call/callActions";

import {
  ConsultationInfo,
  ConsultationStatus,
  ConsultationRating,
  ConsultationComment
} from "ibolit-components";

import {
  returnButtonsData,
  returnConsultationStatusText
} from "./contentCreators";

import styles from "../ConsultationPage.scss";

export const ConsultationInfoPage = ({
  consultationData,
  consultationStatusInfo,
  handleSetShowTab,
  isTariffTypeOffline
}) => {
  const dispatch = useDispatch();

  const handleShowConclusionModal = useShowModal(CONCLUSION_VIEW, {
    consultationId: consultationData.id,
    conclusion: consultationData.conclusion,
    problem: consultationData?.problem,
    testid: consultationData.id,
    recommendedConsultationDate:
      consultationData?.recommended_consultation?.date_time
  });

  const handleCancelConfirmation = useShowModal(CANCEL_CONSULTATION, {
    handleSubmitCancel: reason => () =>
      dispatch(
        consultationsActions.cancelConsultation({
          consultationId: consultationData.id,
          reason
        })
      )
  });

  const handleSubmitConfirmation = () =>
    dispatch(
      consultationsActions.confirmConsultation({
        consultationId: consultationData.id
      })
    );

  const handleStartCall = consultationId => () =>
    dispatch(callActions.callStart({ consultationId }));

  const handleDoctorReply = replyComment =>
    dispatch(
      consultationsActions.doctorReplyAction(
        consultationData.rating?.id,
        replyComment,
        consultationData.id
      )
    );

  const handleDeleteReply = replyId =>
    dispatch(
      consultationsActions.deleteDoctorReplyAction(replyId, consultationData.id)
    );

  const handleDeleteConsultationRating = consultationRatingId =>
    dispatch(
      consultationsActions.deletePatientRatingAction(
        consultationRatingId,
        consultationData.id
      )
    );

  const isInProgress = useSelector(state =>
    getConsultationInProgress(state, consultationData)
  );

  const hasProblem = consultationData.problem?.status !== "auto"; 

  const { text, label, duration } = returnConsultationStatusText({
    status: consultationData.status,
    isStarted: isInProgress,
    hasConclusion: !!consultationData.conclusion,
    hasProblem: hasProblem,
    isTariffTypeOffline,
    context: consultationData.context
  });

  const handleEndConsultation = useShowModal(CONFIRM_VIEW, {
    consultationId: consultationData.id,
    service_patient_id: consultationData.service_patient_id
  });

  return (
    <div className={styles.consultationContainer}>
      <ConsultationInfo
        text={consultationStatusInfo.text}
        icon={consultationStatusInfo.icon}
        label={consultationStatusInfo.label}
        textClassName={consultationStatusInfo.textClassName}
        header={consultationStatusInfo.header}
        iconFill="#00B5CE"
      />
      <ConsultationStatus
        label={label}
        duration={duration}
        text={text}
        buttonsData={returnButtonsData({
          status: consultationData.status,
          isInProgress,
          hasConclusion: !!consultationData.conclusion,
          hasProblem: hasProblem,
          hConfirmConsultation: handleSubmitConfirmation,
          hCancelConsultation: handleCancelConfirmation,
          hShowTab: handleSetShowTab,
          hEnterConsultation: handleStartCall(consultationData.id),
          hMakeConclusion: handleShowConclusionModal,
          hEndConsultation: handleEndConsultation,
          isTariffTypeOffline,
          context: consultationData.context
        })}
      />
      {consultationData?.comment ? (
        <ConsultationComment
          comment={consultationData.comment}
          title="Комментарий пациента"
        />
      ) : null}
      {consultationData?.rating ? (
        <ConsultationRating
          type="doctor"
          feedback={consultationData.rating}
          key={consultationData.rating.id}
          title={"Отзыв пациента"}
          withIcon
          handleReplyConsultationRatingCall={handleDoctorReply}
          handleDeleteReply={handleDeleteReply}
          handleDelete={handleDeleteConsultationRating}
        />
      ) : null}
    </div>
  );
};
