import React from "react";
import classNames from "classnames";
import { Textarea } from "ibolit-ui";
import useInput from "~/hooks/useInput";
import styles from "./TextareaInput.scss";

const TextareaInput = (
  { fullWidth, resize, autosize, rows, maxRows, testidProps, ...props },
  inputRef
) => {
  const [input, className] = useInput({ inputRef, ...props });
  const _className = classNames(styles.container, className);
  return (
    <Textarea
      inputBinds={input}
      fullWidth={fullWidth}
      resize={resize}
      className={_className}
      autosize={autosize}
      rows={rows}
      maxRows={maxRows}
      testidProps={testidProps}
    />
  );
};

export default React.forwardRef(TextareaInput);
