import {
  all,
  put,
  call,
  takeLatest,
  throttle,
  select
} from "redux-saga/effects";

import {
  GET_CONSULTATION_RATING,
  DELETE_CONSULTATION_RATING,
  REPLY_CONSULTATION_RATING,
  DELETE_REPLY
} from "./types";

import {
  getConsultationRatingSuccessAction,
  consultationRatingFailAction,
  patchReplyConsultationData,
  deleteConsultationRatingSuccessAction
} from "./actions";

import {
  fetchConsultationRatings,
  deleteConsultationRatings,
  replyConsultationRatings,
  deleteDoctorReplyConsultationRating
} from "~/api/consultationRatingsApi";

import { getConsultationRatingOrderBy } from "./selectors";

function* getConsultationRatingSaga({ payload: { page = 1, limit = 20 } }) {
  try {
    const orderBy = yield select(getConsultationRatingOrderBy);
    const { data } = yield call(fetchConsultationRatings, {
      page,
      limit,
      order_by: orderBy
    });

    yield put(getConsultationRatingSuccessAction(data));
  } catch (err) {
    yield put(consultationRatingFailAction(err));
  }
}

function* deleteConsultationRatingSaga({ payload: { id } }) {
  try {
    yield call(deleteConsultationRatings, id);

    yield put(deleteConsultationRatingSuccessAction(id));
  } catch (err) {
    yield put(consultationRatingFailAction(err));
  }
}

function* replyConsultationRatingSaga({ payload: { id, data } }) {
  try {
    const res = yield call(replyConsultationRatings, id, {
      comment: data
    });

    yield put(patchReplyConsultationData(res.data));
  } catch (err) {
    yield put(consultationRatingFailAction(err));
  }
}

function* deleteReplySaga({ payload: { id } }) {
  try {
    const { data } = yield call(deleteDoctorReplyConsultationRating, id);

    yield put(patchReplyConsultationData(data));
  } catch (err) {
    yield put(consultationRatingFailAction(err));
  }
}

export default function* consultationsSaga() {
  yield all([
    takeLatest(DELETE_CONSULTATION_RATING.CALL, deleteConsultationRatingSaga),
    takeLatest(REPLY_CONSULTATION_RATING.CALL, replyConsultationRatingSaga),
    takeLatest(DELETE_REPLY.CALL, deleteReplySaga),
    throttle(1000, GET_CONSULTATION_RATING.CALL, getConsultationRatingSaga)
  ]);
}
