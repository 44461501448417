import PropTypes from "prop-types";
import React from "react";
import SimpleList from "~/components/List/SimpleList";
import Loading from "~/components/Loading/Loading";

const getContent = ({
  mode,
  items,
  params,
  loading,
  renderEmpty,
  ...listProps
}) =>
  !items.length && renderEmpty ? (
    renderEmpty(mode)
  ) : (
    <SimpleList
      {...listProps}
      items={items}
      loading={loading}
      showSpinner={loading}
    />
  );

const ItemsList = ({
  className,
  style,
  isReady,
  renderNotReady,
  mode,
  items,
  loading,
  testid,
  ...props
}) => {
  const hasWrapper = style || className;
  const Wrapper = hasWrapper ? "div" : React.Fragment;
  return (
    <Wrapper
      {...(className && { className })}
      {...(style && { style })}
      {...(hasWrapper && { "data-testid": testid })}
    >
      {isReady
        ? getContent({
            ...props,
            mode,
            loading,
            items,
            ...(!hasWrapper && { testid })
          })
        : renderNotReady && renderNotReady(mode)}
      <Loading showLoading={!items.length && loading} />
    </Wrapper>
  );
};

export default ItemsList;

ItemsList.propTypes = {
  // Стилизация котейнера
  className: PropTypes.string,
  style: PropTypes.object,
  // признак, что произошла начальная закрузка и scroll-bar принял элементы
  isReady: PropTypes.bool,
  // признак, что идет закрузка данных с сервера
  loading: PropTypes.bool,
  // режим
  mode: PropTypes.string,
  // функция для отрисовки заглушки, если пришел пустой массив
  renderEmpty: PropTypes.func,
  // функция для отрисовки заглушки, до того как завершилась инициализация
  renderNotReady: PropTypes.func
};
