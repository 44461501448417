import types from "./webSocketTypes";

export default {
  setChatMessage: event => ({
    type: types.WS_CHAT_MESSAGE,
    event
  }),
  setChatDialog: event => ({
    type: types.WS_CHAT_ADD,
    event
  }),
  updateChat: event => ({
    type: types.WS_CHAT_UPDATE,
    event
  }),
  setToolbarUpdate: event => ({
    type: types.WS_TOOLBAR_UPDATE,
    event
  }),
  setMedCardMessage: event => ({
    type: types.WS_MED_CARD_MESSAGE,
    event
  }),
  slotStarted: event => ({
    type: types.WS_SLOT_STARTED,
    event
  }),
  slotEnded: event => ({
    type: types.WS_SLOT_ENDED,
    event
  }),
  slotPaid: event => ({
    type: types.WS_SLOT_PAID,
    event
  }),
  slotEnrolled: event => ({
    type: types.WS_SLOT_ENROLLED,
    event
  }),
  slotMoved: event => ({
    type: types.WS_SLOT_MOVED,
    event
  }),
  slotCanceled: event => ({
    type: types.WS_SLOT_CANCELED,
    event
  })
};
