import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Input,
  Label,
  InputPreventAutocomplete,
  Dialog,
} from "ibolit-ui";
import styles from "./ChangeCredentialForm.scss";
import FieldError from "~/components/FieldError";
import { getFieldError } from "~/components/FieldError/FieldError";
import Loading from "~/components/Loading/Loading";

const ChangeCredentialForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleSubmit,
  title,
  loading,
  firstInput,
  secondInput,
  onCloseClick,
  mask,
  firstInputTestId,
  secondInputTestId,
  submitTestId,
  testIdHeader,
  testIdIconClose,
  testIdIconBack,
  resetForm,
}) => {
  const firstName = firstInput.name;
  const firstInputValue = values[firstName];
  const firstInputError = errors[firstName];
  const firstInputTouched = touched[firstName];
  const [firstHasError, firstError] = getFieldError(
    firstInputError,
    firstInputTouched
  );

  const secondName = secondInput.name;
  const secondInputValue = values[secondName];
  const secondInputError = errors[secondName];
  const secondInputTouched = touched[secondName];
  const [secondHasError, secondError] = getFieldError(
    secondInputError,
    secondInputTouched
  );

  useEffect(
    () => () => {
      resetForm();
    },
    []
  );

  return (
    <Dialog
      testId="ChangeSettingsModal"
      header={title}
      headerTestId={testIdHeader}
      closeTestId={testIdIconClose}
      backTestId={testIdIconBack}
      onCloseClick={onCloseClick}
      boxClassName={styles.modalBox}
    >
      <form onSubmit={handleSubmit}>
        <Label
          title={firstInput.label}
          className={styles.field}
          testid="ChangeCredentialForm__first_input"
        >
          <Input
            focus
            nativeChange
            fullWidth
            value={mask(firstInputValue)}
            type={firstInput.type}
            name={firstName}
            onChange={handleChange}
            disabled={loading}
            testidProps={{ input: firstInputTestId }}
            maxlength={firstInput.maxLength}
          />
          <FieldError error={firstError} showError={firstHasError} />
        </Label>
        <Label
          title={secondInput.label}
          className={styles.field}
          testid="ChangeCredentialForm__second_input"
        >
          <InputPreventAutocomplete
            nativeChange
            fullWidth
            value={secondInputValue}
            type={secondInput.type}
            name={secondName}
            onChange={handleChange}
            disabled={loading}
            testidProps={{ input: secondInputTestId }}
            maxlength={secondInput.maxLength}
          />
          <FieldError error={secondError} showError={secondHasError} />
        </Label>
        <Button
          testId={submitTestId}
          disabled={loading}
          className={styles.button}
          fullWidth
        >
          Продолжить
        </Button>
      </form>
      <Loading showLoading={loading} />
    </Dialog>
  );
};

ChangeCredentialForm.propTypes = {
  values: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  errors: PropTypes.object,
  title: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  firstInput: PropTypes.object.isRequired,
  secondInput: PropTypes.object.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  mask: PropTypes.func,
  firstInputTestId: PropTypes.string,
  secondInputTestId: PropTypes.string,
  submitTestId: PropTypes.string,
  testIdHeader: PropTypes.string,
  testIdIconClose: PropTypes.string,
  testIdIconBack: PropTypes.string,
};

ChangeCredentialForm.defaultProps = {
  mask: (value) => value,
};

export default ChangeCredentialForm;
