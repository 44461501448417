import React, { useEffect, useState, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import messagesActions from "~/store/messages/messagesActions";
import Loading from "~/components/Loading/Loading";
import MessagesListComponent from "./components/MessagesList";
import { messagesSelector } from "~/store/messages/messagesSelector";

const MessagesList = ({ chatId, countNew, partner }) => {
  const scrollRef = useRef();
  const prevBottomOffset = useRef({});
  const [isReady, setIsReady] = useState(false);
  const { items, loading, canLoadMore, page } = useSelector(state =>
    messagesSelector(state, { chatId })
  );
  const { fetchRequest, readingNewMessageRequest } = useDispatchedAction(
    chatId,
    partner.id
  );

  useEffect(() => fetchRequest(1), [chatId]);

  const scrollToBottom = () => {
    scrollRef.current.scrollToBottom();
  };

  const handleReady = useCallback(() => {
    scrollToBottom();
    setIsReady(true);
  }, [items]);

  useEffect(() => {
    if (!isReady) {
      items && items.length && handleReady();
    } else {
      const last = items.slice(-1)[0] || {};
      /*
        new message received
       */
      if (last.author_id === partner.id) {
        // it is remote message,
        if (prevBottomOffset.current < 400) {
          scrollToBottom();
        }
      } else {
        // its own message
        scrollToBottom();
      }
    }
    countNew > 0 && readingNewMessageRequest();
  }, [items]);

  const handleScroll = values => {
    if (isReady) {
      if (page && canLoadMore) {
        values.scrollTop < 100 && !loading && fetchRequest(page + 1);
        // hook to prevent stick scroll to top when new items loaded
        values.scrollTop < 50 && (scrollRef.current.view.scrollTop = 50);
      }
      values.top === 1 && countNew > 0 && readingNewMessageRequest();
    }
    prevBottomOffset.current =
      values.scrollHeight - (values.scrollTop + values.clientHeight);
  };
  return (
    <>
      <MessagesListComponent
        key={chatId}
        chatId={chatId}
        items={items}
        partner={partner}
        isReady={isReady}
        onReady={handleReady}
        onScroll={handleScroll}
        scrollRef={scrollRef}
        canLoadMore={canLoadMore}
      />
      <Loading showLoading={loading && !page} />
    </>
  );
};

function useDispatchedAction(chatId, partnerId) {
  const dispatch = useDispatch();
  const fetchRequest = useCallback(
    page => {
      dispatch(messagesActions.searchRequest(chatId, partnerId, page));
    },
    [chatId]
  );
  const readingNewMessageRequest = useCallback(
    messageIds => {
      dispatch(
        messagesActions.readingNewMessageRequest(partnerId, chatId, messageIds)
      );
    },
    [partnerId, chatId]
  );
  return {
    fetchRequest,
    readingNewMessageRequest
  };
}

export default MessagesList;
