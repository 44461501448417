import PropTypes from "prop-types";
import React from "react";
import cn from "classnames";
import isEmpty from "lodash/isEmpty";
import { Text, Icon } from "ibolit-ui";
import ShapeButton from "~/components/ShapeButton";
import { COMMENT_VIEW } from "~/modals/Comment/CommentModal";
import styles from "./Comment.scss";
import { useShowModal } from "~/hooks/useModal";

const getContent = (comment, onClick, isConclusion, testid) => {
  const empty = isEmpty(comment);
  let text = comment;
  if (empty) {
    text = "Добавьте для себя комментарий или заметку о пациенте";
  }
  return (
    <ShapeButton
      variant="h5"
      textColorVariant={`${empty ? "pale" : "primary"}`}
      onClick={onClick}
      text={text}
      testid={`${testid}__button`}
    />
  );
};

export const Comment = ({ id, comment, className, variant, testid }) => {
  const showModal = useShowModal(COMMENT_VIEW, { id, comment, testid });

  const isButton = variant === "button";

  return (
    <div className={cn(styles.container, className)}>
      {!isButton && (
        <Text variant="h6" className={styles.label}>
          Комментарий
        </Text>
      )}
      {getContent(comment, showModal, testid)}
      {!isButton && (
        <Text
          variant="caption"
          colorVariant="tertiary"
          className={styles.textComment}
        >
          <Icon
            variant="crossed-eye"
            size="s"
            className={styles.textCommentIcon}
          />
          Виден только вам
        </Text>
      )}
    </div>
  );
};

Comment.defaultProps = {
  variant: "button"
};

Comment.propTypes = {
  className: PropTypes.string,
  comment: PropTypes.string,
  id: PropTypes.number.isRequired,
  variant: PropTypes.oneOf(["button", "full"]),
  testid: PropTypes.string
};

export default Comment;
