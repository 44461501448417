import React from "react";
import TextInput from "~/components/inputs/TextInput";
import { checkValidEmail, checkValidPhone } from "~/utils/validations";

const validator = ({ type }) => value => {
  let isValid;
  let message;
  try {
    const isValidPhone = checkValidPhone(value);
    const isValidEmail = checkValidEmail(value);
    switch (type) {
      case "phone":
        isValid = isValidPhone || [false, "Неправильный номер телефона"];
        break;
      case "email":
        isValid = isValidEmail || [
          false,
          "Неправильный адрес электронной почты"
        ];
        break;
      default:
        isValid = isValidPhone || isValidEmail;
        message = "Неправильный телефонный номер или email";
    }
  } catch (e) {
    message = e;
    isValid = false;
  }
  if (value.length > 40) {
    return [false, "Максимальная длина 40 символов", true];
  }
  return isValid || [false, message];
};

const LoginInput = ({ type, ...props }, inputRef) => {
  const loginProps = {
    validator: validator({ type })
  };

  if (type === "phone") {
    loginProps.mask = { pattern: "+9(999)999-9999" };
  }
  return <TextInput ref={inputRef} {...loginProps} {...props} />;
};

export default React.forwardRef(LoginInput);
