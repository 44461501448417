import { useDispatch } from "react-redux";
import { useCallback } from "react";
import uiActions from "~/store/ui/uiActions";

export const useCloseModal = view => {
  const dispatch = useDispatch();
  return useCallback(() => {
    dispatch(uiActions.hideModal(view));
  }, []);
};

export const useCloseAllModal = () => {
  const dispatch = useDispatch();
  return useCallback(() => {
    dispatch(uiActions.closeAllModal());
  }, []);
};

export const useShowModal = (view, modalProps = {}, deps) => {
  const dispatch = useDispatch();
  return useCallback(() => {
    dispatch(uiActions.showModal(view, modalProps));
  }, deps || Object.values(modalProps));
};

export const useReplaceModal = (oldView, newView, modalProps = {}, deps) => {
  const dispatch = useDispatch();
  return useCallback(() => {
    dispatch(uiActions.replaceModal(oldView, newView, modalProps));
  }, deps || Object.values(modalProps));
};
