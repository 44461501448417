import Types from "./authTypes";

const Actions = {
  userAuthorizeRequest: () => ({ type: Types.USER_AUTHORIZE_REQUEST }),
  userAuthorizeSuccess: payload => ({
    type: Types.USER_AUTHORIZE_SUCCESS,
    payload
  }),
  userAuthorizeFailure: (error, payload) => ({
    type: Types.USER_AUTHORIZE_FAILURE,
    error,
    payload
  }),

  checkLoginRequest: (login, loginType = "doctor") => ({
    type: Types.LOGIN_CHECK_REQUEST,
    request: { login, type: loginType }
  }),
  checkLoginSuccess: payload => ({ type: Types.LOGIN_CHECK_SUCCESS, payload }),
  checkLoginTimeout: () => ({ type: Types.LOGIN_CHECK_TIMEOUT }),
  checkLoginFailure: (error, payload) => ({
    type: Types.LOGIN_CHECK_FAILURE,
    error,
    payload
  }),

  checkPasswordRequest: (password, login) => ({
    type: Types.PASSWORD_CHECK_REQUEST,
    request: { password, login, type: "doctor" }
  }),
  checkPasswordSuccess: payload => ({
    type: Types.PASSWORD_CHECK_SUCCESS,
    payload
  }),
  checkPasswordFailure: (error, payload) => ({
    type: Types.PASSWORD_CHECK_FAILURE,
    error,
    payload
  }),
  checkPasswordTimeout: () => ({ type: Types.PASSWORD_CHECK_TIMEOUT }),

  passwordResetRequest: (password, login, code) => ({
    type: Types.PASSWORD_RESET_REQUEST,
    request: { password, login, code, type: "doctor" }
  }),
  passwordResetSuccess: payload => ({
    type: Types.PASSWORD_RESET_SUCCESS,
    payload
  }),
  passwordResetFailure: (error, payload) => ({
    type: Types.PASSWORD_RESET_FAILURE,
    error,
    payload
  }),

  sendCodeRequest: login => ({
    type: Types.CODE_SEND_REQUEST,
    request: { login, type: "doctor", code: "" }
  }),
  sendCodeSuccess: payload => ({ type: Types.CODE_SEND_SUCCESS, payload }),
  sendCodeFailure: error => ({ type: Types.CODE_SEND_FAILURE, error }),

  validateCodeRequest: (code, login) => ({
    type: Types.CODE_VALIDATE_REQUEST,
    request: { login, code, type: "doctor" }
  }),
  validateCodeSuccess: payload => ({
    type: Types.CODE_VALIDATE_SUCCESS,
    payload
  }),
  validateCodeFailure: error => ({ type: Types.CODE_VALIDATE_FAILURE, error }),

  userActivateRequest: (password, login, code) => ({
    type: Types.USER_ACTIVATE_REQUEST,
    request: { password, login, code, type: "doctor" }
  }),
  userActivateSuccess: payload => ({
    type: Types.USER_ACTIVATE_SUCCESS,
    payload
  }),
  userActivateFailure: (error, payload) => ({
    type: Types.USER_ACTIVATE_FAILURE,
    error,
    payload
  }),

  stepBack: () => ({ type: Types.STEP_BACK }),

  logout: () => ({ type: Types.LOGOUT }),
  goToStep: step => ({ type: Types.SET_STEP, step })
};

export default Actions;
