import { createTypes } from "~/utils/redux/createTypes";

export const GET_CONSULTATION_RATING = createTypes(
  "consultationRating/GET_CONSULTATION_RATING"
);
export const RESET_CONSULTATION_RATING = createTypes(
  "consultationRating/RESET_CONSULTATION_RATING"
);
export const DELETE_CONSULTATION_RATING = createTypes(
  "consultationRating/DELETE_CONSULTATION_RATING"
);
export const REPLY_CONSULTATION_RATING = createTypes(
  "consultationRating/REPLY_CONSULTATION_RATING"
);
export const DELETE_REPLY = createTypes("consultationRating/DELETE_REPLY");
export const PATCH_CONSULTATION_RATING = createTypes(
  "consultationRating/PATCH_CONSULTATION_RATING"
);

export const CONSULTATION_RATING_FAIL = "consultationRating/FAIL";

export const orders = {
  id_desc: "id_desc",
  id_asc: "id_asc",
  rate_asc: "rate_asc",
  rate_desc: "rate_desc"
};
