import React, { useRef, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, CheckInput } from "ibolit-ui";
import PasswordInput from "~/components/inputs/PasswordInput";
import Field from "~/components/Field/Field";
import auth from "~/locators/auth";
import MainMessage from "./MainMessage";
import { getAgreementText } from "./Activation.helpers";
import styles from "./Activation.scss";
import stylesForm from "../../Authorization.scss";
import authActions from "~/store/auth/authActions";
import {
  getAuthError,
  getAuthPassword,
  getCode,
  getLogin
} from "~/store/auth/authSelectors";

const Activation = () => {
  const dispatch = useDispatch();

  const login = useSelector(getLogin);
  const code = useSelector(getCode);
  const password = useSelector(getAuthPassword);
  const errors = useSelector(getAuthError);

  const inputRef = useRef(null);
  const handleSubmit = useCallback(
    event => {
      if (event.key === "Enter") return;
      dispatch(
        authActions.userActivateRequest(
          inputRef.current.value.trim(),
          login,
          code
        )
      );
    },
    [login, code]
  );

  const [canSubmit, setCanSubmit] = useState(false);
  const checkboxRef = useRef(null);

  const checkCanSubmit = useCallback(() => {
    try {
      setCanSubmit(inputRef.current.isValid && checkboxRef.current.checked);
    } catch (e) {
      setCanSubmit(false);
    }
  }, []);

  return (
    <div className={stylesForm.form}>
      <MainMessage login={login} />
      <Field
        testidProps={{
          input: auth.AUTH_ACTIVATION_PASSWORD_INPUT,
          label: auth.AUTH_ACTIVATION+"__label",
          error: auth.AUTH_ACTIVATION+"__error-password"
        }}
        Component={PasswordInput}
        initialValue={password}
        errors={errors}
        errorKey="password"
        minLength={8}
        ref={inputRef}
        autoFocus
        secured
        submitHandler={handleSubmit}
        label="Установите пароль для входа"
        validHandler={checkCanSubmit}
        testid="Activation__password_input"
      />
      <Field
        labelProps={{ className: styles.agreement }}
        Component={CheckInput}
        onChange={checkCanSubmit}
        inputClassName={styles.checkbox}
        noSpace
        ref={checkboxRef}
        testidProps={{
          input: auth.AUTH_ACTIVATION_AGREEMENT_CHECKBOX
        }}
      >
        {getAgreementText(styles.agreementText)}
      </Field>
      <Button
        testid={auth.AUTH_ACTIVATION_BUTTON_SUBMIT}
        disabled={!canSubmit}
        onClick={handleSubmit}
        fullWidth
        type="submit"
      >
        Продолжить
      </Button>
    </div>
  );
};

Activation.defaultProps = {
  password: ""
};

export default Activation;
