import React from "react";
import { Spinner } from "ibolit-ui";
import SupportText from "./components/SupportText";
import CodeConfirmForm from "./screens/CodeConfirmForm";
import Login from "./screens/Login";
import Password from "./screens/Password";
import Activation from "./screens/Activation";
import { Step } from "~/store/auth/authReducer";
import AUTH_LOCATORS from "~/locators/auth";

export const SCENES = {
  [Step.SUCCESS]: {
    key: Step.SUCCESS,
    Component: Spinner
  },
  [Step.LOGIN]: {
    key: Step.LOGIN,
    Component: Login,
    fullLogo: true,
    footerContent: <SupportText />
  },
  [Step.PASSWORD]: {
    key: Step.PASSWORD,
    Component: Password,
    canGoBack: true,
    isReset: false
  },
  [Step.RESET_PASSWORD]: {
    key: Step.RESET_PASSWORD,
    Component: Password,
    canGoBack: true,
    isReset: true
  },
  [Step.CODE]: {
    key: Step.CODE,
    Component: CodeConfirmForm,
    testIdMsg: AUTH_LOCATORS.AUTH_MESSAGE_NUMBER,
    text: {
      active: [
        "Для восстановления доступа необходимо ввести код из письма, отправленного на e-mail",
        "Для восстановления доступа необходимо ввести код из СМС, отправленного на номер"
      ],
      inactive: [
        "Для продолжения необходимо ввести код из письма, отправленного на e-mail",
        "Для продолжения необходимо ввести код из СМС, отправленного на номер"
      ]
    },
    canGoBack: true
  },
  [Step.ACTIVATION]: {
    key: Step.ACTIVATION,
    Component: Activation,
    canGoBack: true
  }
};
