const TOKEN_KEY = "ibolit-doctor-token";
const PUSH_TOKEN = "push-token";

export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const dropToken = () => localStorage.removeItem(TOKEN_KEY);
export const setToken = token => localStorage.setItem(TOKEN_KEY, token);

export const setPushToken = token => localStorage.setItem(PUSH_TOKEN, token);
export const getPushToken = () => localStorage.getItem(PUSH_TOKEN);
export const dropPushToken = () => localStorage.removeItem(PUSH_TOKEN);
