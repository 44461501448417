import React from "react";
import classNames from "classnames";
import { Text } from "ibolit-ui";
import { getDate } from "~/utils/dateUtils";

export const getDateOfBirth = ({ date_of_birth } = {}) => {
  try {
    return getDate(date_of_birth).format("DD.MM.YYYY");
  } catch (e) {
    return null;
  }
};

const DateOfBirth = ({ date, className, style }) => (
  <Text
    colorVariant="tertiary"
    className={className}
    style={style}
    variant="desc"
  >
    {date}
  </Text>
);

export default DateOfBirth;
