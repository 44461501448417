import React, { useRef, useState, useCallback, useEffect } from "react";
import { Button, Dialog } from "ibolit-ui";
import { useDispatch, useSelector } from "react-redux";
import { ReactMask, countryData } from "react-mask-icon";
import Field from "~/components/Field";
import LoginInput from "~/components/inputs/LoginInput/LoginInput";
import { INVITATION_MODAL, PATIENT_CHECK } from "~/locators/invitation";
import contactsActions from "~/store/contacts/contactsActions";
import { getContactsLogin } from "~/store/contacts/contactsSelector";
import Loading from "~/components/Loading/Loading";
import styles from "./InvitationModal.scss";

const CheckUserExists = ({ testId, loading, hideModal, error: errors }) => {
  const [canSubmit, setCanSubmit] = useState(false);
  const [value, setValue] = useState("");
  const dispatch = useDispatch();
  const login = useSelector(getContactsLogin);

  const requestSubmit = useCallback(
    newLogin => {
      dispatch(contactsActions.searchRequest(newLogin));
    },
    [login]
  );

  useEffect(() => {
    setCanSubmit(!!value);
  }, [value]);

  const inputRef = useRef(null);

  const handleSubmit = useCallback(() => {
    inputRef.current.blur();
    requestSubmit(value.trim());
  }, [value]);

  const handleChangePhoneMask = newValue => setValue(newValue);

  return (
    <Dialog
      testId={testId}
      closeTestId={INVITATION_MODAL.ICON_CLOSE}
      header="Добавление пациента"
      boxClassName={styles.modalBox}
      onCloseClick={hideModal}
    >
      <ReactMask
        Component={LoginInput}
        initialValue={login}
        errors={errors}
        ref={inputRef}
        value={value}
        label="Телефон или email"
        onChange={handleChangePhoneMask}
        dropdownData={countryData}
        dataTestIds={{
          mainInput: PATIENT_CHECK.PHONE_OR_EMAIL,
          container: PATIENT_CHECK.MASK_CONTAINER,
          select: PATIENT_CHECK.MASK_SELECT,
          selectTitle: PATIENT_CHECK.MASK_SELECT_TITLE,
          input: PATIENT_CHECK.MASK_INPUT,
          dropdown: PATIENT_CHECK.MASK_DROPDOWN,
          dropdownMenuElement: PATIENT_CHECK.dropdownMenuElement,
          dropdownElementImg: PATIENT_CHECK.dropdownElementImg,
          dropdownElementCountryName: PATIENT_CHECK.dropdownElementCountryName,
          dropdownElementLabel: PATIENT_CHECK.dropdownElementLabel,
          selectImg: PATIENT_CHECK.MASK_SELECT_IMG,
          containerError: PATIENT_CHECK.MASK_CONTAINER_ERROR
        }}
      />
      {errors?.login?.map(errMessage => (
        <div className={styles.error}>{errMessage}</div>
      ))}
      <div className={styles.field}>
        <Button
          testid={PATIENT_CHECK.CANCEL}
          className={styles.controlButton}
          variant="secondary"
          onClick={hideModal}
        >
          Отмена
        </Button>
        <Button
          testid={PATIENT_CHECK.CONTINUE}
          className={styles.controlButton}
          disabled={!canSubmit}
          onClick={handleSubmit}
        >
          Продолжить
        </Button>
      </div>
      <Loading showLoading={loading} />
    </Dialog>
  );
};

export default CheckUserExists;
