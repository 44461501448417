import { types } from "~/store/chats/chatModel";

export default {
  ...types,
  END_EXPRESS_CHAT_REQUEST: "chat/END_EXPRESS_CHAT_REQUEST",
  END_EXPRESS_CHAT_SUCCESS: "chat/END_EXPRESS_CHAT_SUCCESS",
  END_EXPRESS_CHAT_FAILURE: "chat/END_EXPRESS_CHAT_FAILURE",
  ITEM_UPDATE: "chats/ITEM_UPDATE",
  INFO_REQUEST: "chats/INFO_REQUEST",
  INFO_SUCCESS: "chats/INFO_SUCCESS",
  INFO_FAILURE: "chats/INFO_FAILURE",
  UP_CHAT: "chats/UP_CHAT"
};
