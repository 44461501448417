import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import usersActions from "~/store/users/usersActions";
import Loading from "~/components/Loading/Loading";
import PublicProfile from "./components/PublicProfile";

const PublicProfileContainer = ({
  user,
  userId,
  variant,
  checkNew,
  isRequest,
  getDetailInfo
}) => {
  useEffect(() => {
    userId && getDetailInfo(userId);
  }, [userId]);

  const isNew = user && user.is_new;

  useEffect(() => {
    isNew && checkNew(userId);
  }, [isNew]);

  return (
    <>
      {user && (
        <PublicProfile
          key={userId}
          userId={userId}
          variant={variant}
          user={user}
        />
      )}
      <Loading showLoading={!user && isRequest} />
    </>
  );
};

PublicProfileContainer.defaultProps = {
  variant: "default"
};

PublicProfileContainer.propTypes = {
  isRequest: PropTypes.bool,
  getDetailInfo: PropTypes.func,
  user: PropTypes.object,
  userId: PropTypes.number,
  variant: PropTypes.oneOf(["default", "inChat"])
};

const mapStateToProps = ({ users: { items, isFetching } }, { userId }) => ({
  user: items[userId],
  isRequest: isFetching
});

const mapDispatchToProps = dispatch => ({
  getDetailInfo: userId => dispatch(usersActions.userInfoRequest(userId)),
  checkNew: userId => dispatch(usersActions.userCheckNewRequest(userId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PublicProfileContainer);
