import CallRoomModal, {
  CALL_ROOM_VIEW
} from "~/components/CallRoom/components/CallRoomModal/CallRoomModal";
import CallRoomExitModal, {
  CALL_ROOM_EXIT_VIEW
} from "~/components/CallRoom/components/CallRoomExitModal/CallRoomExitModal";
import LoginErrorModal, {
  AUTH_LOGIN_ERROR_VIEW
} from "~/layouts/Authorization/containers/LoginErrorModal";
import ConnectionCheck, {
  CONNECTION_CHECK_VIEW
} from "~/components/HealthCheck/ConnectionCheckModal";
import ProfileSupportContainer, {
  PROFILE_SUPPORT_VIEW
} from "~/modals/ProfileSupport";
import NewConsultationModal, {
  NEW_CONSULTATION_VIEW
} from "~/modals/NewConsultation/containers/NewConsultationModal";
import ChangeSettings from "~/layouts/Profile/ProfileSettings/components/ProfleSettings/components/ChangeSettingsModal/ChangeSettingsModal";
import Filter, { FILTER_VIEW } from "~/modals/Filter/FilterModal";
import EndExpressChatModal, {
  END_EXPRESS_CHAT_VIEW
} from "~/modals/ExpressChat/EndExpressChatModal";
import ConclusionModal, {
  CONCLUSION_VIEW
} from "~/modals/Conclusion/ConclusionModal";
import CancelConsultationModal, {
  CANCEL_CONSULTATION
} from "~/modals/CancelConsultation/CancelConsultation";
import CommentModal, { COMMENT_VIEW } from "~/modals/Comment/CommentModal";
import InvitationModal, {
  INVITATION_VIEW
} from "~/layouts/Contacts/ContactAside/Invitation/InvitationModal";
import { PROFILE_CHANGE_SETTINGS_VIEW } from "~/consts/profile";
import ConfirmModal, {
  CONFIRM_VIEW
} from "~/layouts/Consultations/containers/Consultation/containers/Confirm";
import { CONSULTATION_STARTED } from "~/modals/ConsultationStarted/ConsultationStarted";
import { ConsultationStarted } from "~/modals/ConsultationStarted";
import ErrorModal, {
  CALL_ROOM_ERROR
} from "~/components/CallRoom/components/ErrorModal/ErrorModal";
import AddingFilesModal, {
  ADDING_FILES
} from "~/layouts/Consultations/containers/Consultation/components/FileManager/AddingFilesModal/AddingFilesModal";
import FileAlertModal, {
  FILE_ALERT_MODAL
} from "~/layouts/Consultations/containers/Consultation/components/FileManager/FileAlertModal/FileAlertModal";
import ErrorBox, { ERROR_BOX_MODAL } from "~/components/ErrorBox/ErrorBox";
import ApproveDeleteModal, {
  APPROVE_DELETE_MODAL
} from "~/layouts/Consultations/containers/Consultation/components/FileManager/ApproveDeleteModal/ApproveDeleteModal";
import ConsultationsRatingsModal, {
  CONSULTATIONS_RATING
} from "~/modals/ConsultationsRatings";
import StatusError, {
  NEW_CONSULTATION_ERROR_MODAL
} from "~/modals/NewConsultation/components/Statuses/StatusError";

export default {
  [CALL_ROOM_VIEW]: CallRoomModal,
  [CALL_ROOM_EXIT_VIEW]: CallRoomExitModal,
  [CALL_ROOM_ERROR]: ErrorModal,
  [ERROR_BOX_MODAL]: ErrorBox,
  [AUTH_LOGIN_ERROR_VIEW]: LoginErrorModal,
  [CONNECTION_CHECK_VIEW]: ConnectionCheck,
  [PROFILE_SUPPORT_VIEW]: ProfileSupportContainer,
  [PROFILE_CHANGE_SETTINGS_VIEW]: ChangeSettings,
  [NEW_CONSULTATION_VIEW]: NewConsultationModal,
  [FILTER_VIEW]: Filter,
  [END_EXPRESS_CHAT_VIEW]: EndExpressChatModal,
  [CONCLUSION_VIEW]: ConclusionModal,
  [CANCEL_CONSULTATION]: CancelConsultationModal,
  [COMMENT_VIEW]: CommentModal,
  [INVITATION_VIEW]: InvitationModal,
  [CONFIRM_VIEW]: ConfirmModal,
  [CONSULTATION_STARTED]: ConsultationStarted,
  [ADDING_FILES]: AddingFilesModal,
  [FILE_ALERT_MODAL]: FileAlertModal,
  [APPROVE_DELETE_MODAL]: ApproveDeleteModal,
  [NEW_CONSULTATION_ERROR_MODAL]: StatusError,
  [CONSULTATIONS_RATING]: ConsultationsRatingsModal
};
