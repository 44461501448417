import { put, all, takeLatest, call } from "redux-saga/effects";
import types from "~/store/slots/slotsTypes";
import { getSlots } from "~/api/slots";
import slotsActions from "~/store/slots/slotsActions";
import toastActions from "~/store/toast/toastActions";
import { ERROR_REQUEST_DEFAULT } from "~/consts/texts";

function* fetchSlotsSaga({ payload: { slotType, doctorId } }) {
  try {
    const { status, data } = yield call(getSlots, { doctorId, slotType });
    if (status !== 204) {
      yield put(slotsActions.fetchSlotsSuccess(data));
    }
  } catch (error) {
    yield put(slotsActions.fetchSlotsError());
    yield put(toastActions.showErrorToast(ERROR_REQUEST_DEFAULT));
  }
}

export default function* slots() {
  yield all([takeLatest(types.SLOTS_FETCH_USER_REQUEST, fetchSlotsSaga)]);
}
