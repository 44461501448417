import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Button, Input, Label, Dialog } from "ibolit-ui";
import actions from "~/store/profile/profileAction";
import { getUser } from "~/store/profile/profileSelectors";
import styles from "./ProfileIncome.scss";

function ProfileIncome({ onCloseClick }) {
  const dispatch = useDispatch();
  const doctor = useSelector(state => getUser(state));
  const [chatIncome, setChatIncome] = useState(
    doctor.percent_income_chat ? `${doctor.percent_income_chat}%` : ""
  );
  const [videoIncome, setVideoIncome] = useState(
    doctor.percent_income_online ? `${doctor.percent_income_online}%` : ""
  );

  function handleInputChange(type) {
    const digitsRegex = /^\d*$/;

    return value => {
      if (!digitsRegex.test(value)) return;

      if (type === "video") {
        setVideoIncome(value);
        return;
      }

      setChatIncome(value);
    };
  }

  function handleInputBlur(type) {
    return () => {
      if (type === "video") {
        setVideoIncome(prevIncome =>
          prevIncome.length === 0 ? "" : `${prevIncome}%`
        );
        return;
      }

      setChatIncome(prevIncome =>
        prevIncome.length === 0 ? "" : `${prevIncome}%`
      );
    };
  }

  function handleInputFocus(type) {
    return () => {
      if (type === "video") {
        setVideoIncome(prevIncome => prevIncome.slice(0, -1));
        return;
      }

      setChatIncome(prevIncome => prevIncome.slice(0, -1));
    };
  }

  function handleSubmit() {
    const chatIncomeValue = Number(chatIncome.replace("%", "")) || null;
    const videoIncomeValue = Number(videoIncome.replace("%", "")) || null;

    dispatch(
      actions.userDataUpdateRequest({
        ...doctor,
        percent_income_online: videoIncomeValue,
        percent_income_chat: chatIncomeValue
      })
    );
  }

  return (
    <Dialog
      testId="ProfileIncomeModal"
      boxClassName={styles.dialog}
      header="Расчет заработной платы"
      onCloseClick={onCloseClick}
      headerTestId={"header_modal_window_income"}
    >
      <p className={styles.description}>
        Чтобы получать автоматический расчет своей заработной платы, установите
        процент который вы получаете с консультаций. Результаты расчета
        отображаются на странице с чатами, в виджете расположенном наверху.
      </p>
      <Label className={styles.input} title="Ваш процент за видеоконсультации">
        <Input
          fullWidth
          inputBinds={{
            onBlur: handleInputBlur("video"),
            onFocus: handleInputFocus("video")
          }}
          value={videoIncome}
          maxlength={2}
          onChange={handleInputChange("video")}
        />
      </Label>
      <Label
        className={styles.input}
        title="Ваш процент за консультации в чате"
      >
        <Input
          fullWidth
          inputBinds={{
            onBlur: handleInputBlur("chat"),
            onFocus: handleInputFocus("chat")
          }}
          value={chatIncome}
          maxlength={2}
          onChange={handleInputChange("chat")}
        />
      </Label>
      <Button
        testid="ProfileIncome__button"
        variant="secondary"
        type="submit"
        fullWidth
        onClick={handleSubmit}
      >
        Сохранить
      </Button>
    </Dialog>
  );
}

ProfileIncome.propTypes = {
  onCloseClick: PropTypes.func
};

export default ProfileIncome;
