import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import React, { useMemo } from "react";
import cn from "classnames";
import RowWithImage from "~/components/RowWithImage";
import UserAvatar from "~/components/UserAvatar";
import { userSelector } from "~/store/users/usersSelectors";
import UserInfo from "./UserInfo";
import styles from "./UserItem.scss";

export const height = 80;

const UserItem = ({
  user: _user,
  onClick,
  height: itemHeight,
  infoLines,
  className,
  avatarSize,
  imageClass,
  noPadding,
  showOnlineStatus,
  showIsNewStatus,
  sizeVariant,
  info,
  testid,
  isUserFromStore,
  ...rest
}) => {
  const userId = typeof _user === "number" ? _user : _user && _user.id;
  // TODO Remove selector from the component
  const user = useSelector(state => userSelector(state, userId));

  const { full_name, active, is_new, is_deleted } = isUserFromStore
    ? user || {}
    : _user;

  const rowProps = useMemo(() => {
    typeof itemHeight === "number" && (itemHeight = `${itemHeight}px`);
    !itemHeight && sizeVariant !== "medium" && (itemHeight = "unset");

    return {
      ...rest,
      sizeVariant,
      style: { ...(itemHeight && { height: itemHeight }) },
      title: full_name,
      ...(!active && { titleProps: { colorVariant: "tertiary" } }),
      ...(onClick && { onClick: () => onClick(user) }),
      ...((user || _user) && {
        image: (
          <UserAvatar
            testid={testid ? `${testid}-avatar` : undefined}
            user={user || _user}
            size={avatarSize || sizeVariant}
            showOnlineStatus={showOnlineStatus}
          />
        )
      }),
      ...(is_deleted && {
        to: undefined,
        hasHoverEffect: false,
        onClick: undefined
      })
    };
  }, [user, rest, sizeVariant, _user]);

  return (
    <RowWithImage
      className={cn(styles.container, className, {
        [styles.noPadding]: noPadding,
        [styles.redDot]: showIsNewStatus && is_new
      })}
      imageClass={cn(styles.avatar, imageClass)}
      contentClass={styles.contentContainer}
      info={info || <UserInfo user={user} infoLines={infoLines} />}
      testid={testid}
      {...rowProps}
    />
  );
};

UserItem.propTypes = {
  className: PropTypes.string,
  avatarSize: PropTypes.string,
  isSelected: PropTypes.bool,
  noPadding: PropTypes.bool,
  onClick: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  infoLines: PropTypes.number,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  variant: RowWithImage.propTypes.variant,
  sizeVariant: PropTypes.oneOf(["medium", "small"]),
  showOnlineStatus: PropTypes.bool,
  showIsNewStatus: PropTypes.bool,
  info: PropTypes.node,
  isUserFromStore: PropTypes.bool
};

UserItem.defaultProps = {
  sizeVariant: "medium",
  isUserFromStore: true
};

export default UserItem;
