import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import FadeGroup from "~/components/FadeGroup";
import styles from "./Main.scss";

const getStyle = (variant, width, hidden) => ({
  flexBasis: width,
  [variant === "leftSide" ? "marginLeft" : "marginRight"]: hidden ? -width : 0
});

const Aside = ({ children, className, hidden, variant, width }) => {
  const [style, setStyle] = useState(getStyle(variant, width, hidden));
  useEffect(() => setStyle(getStyle(variant, width, hidden)), [
    variant,
    width,
    hidden
  ]);
  return (
    <aside
      className={classNames(styles.aside, styles[variant], className)}
      style={style}
    >
      {children}
    </aside>
  );
};
Aside.defaultProps = {
  variant: "leftSide",
  width: 400
};

Aside.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  hidden: PropTypes.bool,
  variant: PropTypes.oneOf(["leftSide", "rightSide"]),
  width: PropTypes.number
};

const Section = React.forwardRef(({ children, className, useFading }, ref) =>
  React.createElement(
    useFading ? FadeGroup : "section",
    {
      ref,
      className: classNames(styles.section, className),
      component: "section"
    },
    children
  )
);

Section.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  useFading: PropTypes.bool
};

Section.defaultProps = {
  useFading: true
};

export const Main = ({ children, className, mode }) => (
  <main className={classNames(styles.main, styles[mode], className)}>
    {children}
  </main>
);

Main.propTypes = {
  className: PropTypes.string,
  mode: PropTypes.oneOf(["medium", "wide", "small"])
};

export { Aside, Section };

export default Main;
