import React from "react";
import { Text } from "ibolit-ui";
import BlockHeader from "~/components/BlockHeader";
import ConclusionItem from "./ConclusionItem";
import styles from "./PatientInfo.scss";

const ConclusionHistory = ({ items }) => (
  <>
    <BlockHeader
      title={
        <Text variant="h3" colorVariant="tertiary">
          История приемов
        </Text>
      }
      titlePosition="left"
      className={styles.blockHeader}
    />
    {items.map(item => (
      <ConclusionItem key={item.id} {...item} />
    ))}
  </>
);

export default ConclusionHistory;
