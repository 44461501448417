const PUSH_NOTIFICATION_FILE_NAME = "firebase-messaging-sw.js";
const GTAG_ID = "G-G1M14GQQWQ";

const createGlobals = ({ api, ws, ms } = {}) => {
  return {
    __API__: JSON.stringify(api),
    __WS__: JSON.stringify(ws),
    __MS__: JSON.stringify(ms),
    __CLIENT_TOKEN__: JSON.stringify(
      "FHA3BIymWmBLEu76lYkE0Agp6WogNL9BnSLyXK4wGFfLIetYt9WkZsPWfiJt"
    ),
  };
};

module.exports = {
  PUSH_NOTIFICATION_FILE_NAME,
  createGlobals,
  GTAG_ID,
};
