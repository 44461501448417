import React from "react";
import { useDispatch } from "react-redux";
import actions, {
  downloadFileAction
} from "~/store/consultations/consultationsActions";

import { useShowModal } from "~/hooks/useModal";

import { ADDING_FILES } from "~/layouts/Consultations/containers/Consultation/components/FileManager/AddingFilesModal/AddingFilesModal";

import { ConsultationFiles } from "ibolit-components";

import styles from "../ConsultationPage.scss";

export const ConsultationFilesPage = ({ consultationData }) => {
  const dispatch = useDispatch();
  const onDownloadFile = (url, fileName) => e => {
    e.stopPropagation();
    dispatch(downloadFileAction({ fileUrl: url, fileName }));
  };

  const consultationId = consultationData.id;
  const attachedFiles = consultationData.attached_files || [];

  const handleShowAddingFiles = useShowModal(ADDING_FILES, { consultationId });

  const handleDeleteFile = fileId => e => {
    e.stopPropagation();
    dispatch(
      actions.deleteFile({
        fileId,
        consultationId
      })
    );
  };

  const handleDownloadAllFiles = files => () => {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      setTimeout(() => dispatch(downloadFileAction({ fileUrl: file.url, fileName: file.name })), i * 160);
    }
  }

  return (
    <div className={styles.filesContainer}>
      <ConsultationFiles
        mode="doctor"
        onDoctorClick={handleShowAddingFiles}
        patientFiles={attachedFiles.filter(
          file => file.creator_id === consultationData.patient
        )}
        doctorFiles={attachedFiles.filter(
          file => file.creator_id === consultationData.doctor
        )}
        doctorPlaceholder="Вы можете поделиться с пациентом файлами"
        patientPlaceholder="Пациент может поделиться с вами файлами"
        handleDownloadFile={onDownloadFile}
        handleDownloadAllFiles={handleDownloadAllFiles}
        consultationId={consultationId}
        handleDeleteFile={handleDeleteFile}
      />
    </div>
  );
};
