import React from "react";
import PropTypes from "prop-types";
import { Text } from "ibolit-ui";
import cn from "classnames";
import getTariffText from "~/utils/getTariffText";
import styles from "./TariffsList.scss";
import { TARIFF_SELECTOR } from "~/locators/consultations";

const TariffsList = ({ selected, tariffs, onItemClick }) => (
  <ul>
    {tariffs.map((tariff, index) => (
      <li key={index}>
        <button
          data-testid={TARIFF_SELECTOR.TARIFF_ITEM}
          className={cn(styles.tariffButton)}
          type="button"
          onClick={() => onItemClick(tariff)}
        >
          <Text
            variant="h5"
            className={cn(styles.tariffItem, {
              [styles.selected]: tariff === selected
            })}
          >
            {getTariffText(tariff)}
          </Text>
        </button>
      </li>
    ))}
  </ul>
);

TariffsList.propTypes = {
  selected: PropTypes.object,
  tariffs: PropTypes.array.isRequired,
  onItemClick: PropTypes.func.isRequired
};

export default TariffsList;
