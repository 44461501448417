import React from "react";
import { useSelector } from "react-redux";
import usersActions from "~/store/users/usersActions";
import profileAction from "~/store/profile/profileAction";
import {
  getAvailableTariffs,
  getDefaultTariff
} from "~/store/profile/profileSelectors";
import {
  getAddConsultationErrors,
  getAddConsultationFormLoading
} from "~/store/consultations/consultationSelectors";
import NewConsultationModal from "../components/NewConsultationModal";
import {
  getGroupedSlotsByDate,
  getNonWorkingDays
} from "~/store/slots/slotsSelectors";
import consultationsAction from "~/store/consultations/consultationsActions";
import uiActions from "~/store/ui/uiActions";
import { useAction } from "~/hooks/useAction";
import { USERS_MODE_ALL } from "~/store/users/usersModel";

export const NEW_CONSULTATION_VIEW = "newConsultation";

const NewConsultationModalContainer = props => {
  const mappedProps = useSelector(state => ({
    tariffs: getAvailableTariffs(state),
    tariff: getDefaultTariff(state),
    errors: getAddConsultationErrors(state),
    loading: getAddConsultationFormLoading(state),
    groupedSlots: getGroupedSlotsByDate(state),
    nonWorkingDays: getNonWorkingDays(state)
  }));

  const actions = {
    getTariffs: useAction(profileAction.tariffsRequest),
    onSubmitClick: useAction(consultationsAction.post),
    selectTariff: useAction(consultationsAction.selectTariff),
    hideModal: useAction(() => uiActions.hideModal(NEW_CONSULTATION_VIEW)),
    getUsers: useAction((page = 1, search = "") =>
      usersActions.requestSend({
        page,
        search,
        mode: USERS_MODE_ALL
      })
    )
  };
  return <NewConsultationModal {...props} {...mappedProps} {...actions} />;
};

export default NewConsultationModalContainer;
