import React from "react";
import { Link } from "react-router-dom";
import { Text } from "ibolit-ui";
import { RatingChatMessage } from "ibolit-components";
import Bubble from "../Bubble";
import File from "../File";
import SystemText from "../SystemText";
import Row from "../Row";
import Consultation from "../Consultation";
import Conclusion from "../Conclusion";
import ChatDate from "~/layouts/Chats/Chat/ChatMessagesList/components/ChatDate";
import Voice from "~/layouts/Chats/Chat/ChatMessagesList/components/Voice/Voice";
import { PatientProblem } from "ibolit-components";
import styles from "./Message.scss";

const UNKNOWN_MSG_TYPE = "Неизвестный тип сообщения";

const Message = ({ testId, message, partnerId }) => {
  if (message.type === "_date") {
    return <ChatDate text={message.text} />;
  }
  const position = getPosition(message, partnerId);
  const { created_at, client_message_key, error } = message;

  return (
    <Row
      testId={testId}
      position={position}
      created_at={created_at}
      isSending={client_message_key}
      isSendFailure={!!error}
    >
      {getRow(message, position)}
    </Row>
  );
};

function getPosition(message, partnerId) {
  const { author_id, type } = message;

  switch (type) {
    case "system":
    case "service":
    case "conclusion":
    case "patient_problem":
    case "_date":
    case "rating":
      return "center";

    default:
      return author_id !== partnerId ? "right" : "left";
  }
}

function getRow(message, position) {
  switch (message.type) {
    case "bot":
    case "text":
      return (
        <Bubble
          variant={position === "left" ? "primary" : "secondary"}
          text={message.body}
        />
      );

    case "file":
    case "photo":
      return <File {...message.file} />;

    case "voice":
      return (
        <Voice voiceSrc={message.file?.url} duration={message.file?.duration} />
      );

    case "system":
      return <SystemText>{message.body}</SystemText>;

    case "conclusion":
      return <Conclusion {...message} />;

    case "service": {
      const { consultation } = message;
      return <Consultation id={consultation} />;
    }

    case "patient_problem": {
      return <PatientProblem message={message} isDoctor />;
    }

    case "evaluation":
      return null;

    case "rating":
      return (
        <Link to={"/profile?section=feedback"} className={styles.link}>
          <RatingChatMessage {...message} />
        </Link>
      );

    default:
      return (
        <Text variant="desc" key={message.id} colorVariant="error">
          {UNKNOWN_MSG_TYPE}
        </Text>
      );
  }
}

export default Message;
