import isNaN from "lodash/isNaN";
import PropTypes from "prop-types";
import React, { useRef, useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./ConsultationsLayout.scss";

import MainLayout, { Aside, Section } from "~/components/layouts/Main/Main";
import EmptyStub from "~/components/EmptyStub";
import ConsultationsList from "./containers/ConsultationsList";
import actions from "~/store/consultations/consultationsActions";
import { getConsultationsItems } from "~/store/consultations/consultationSelectors";

import { ConsultationPage } from "./ConsultationPage";

const ConsultationsLayout = ({ match, history }) => {
  const consultationRef = useRef();
  let { consultationId } = match.params;
  consultationId !== undefined && (consultationId = Number(consultationId));
  consultationRef.current = consultationId;
  const dispatch = useDispatch();

  useEffect(() => {
    isNaN(consultationId) && history.replace("/consultations/");
    if (consultationId) {
      dispatch(actions.getInfo(consultationId));
    }
  }, [consultationId]);

  const consultationData = useSelector(getConsultationsItems)[consultationId];

  const selectConsultation = useCallback(consultation => {
    const selected =
      consultation.id === consultationRef.current ? null : consultation.id;
    history.push(`/consultations/${selected || ""}`);
  }, []);

  return (
    <MainLayout mode="wide" className={styles.height880}>
      <Aside className={styles.aside}>
        <ConsultationsList
          id={+consultationId}
          onItemClick={selectConsultation}
        />
      </Aside>
      <Section key={consultationId || "stub"} className={styles.section}>
        {!consultationId || !consultationData ? (
          <EmptyStub text={"Выберите консультацию для\nпросмотра деталей"} />
        ) : (
          <ConsultationPage consultationData={consultationData} />
        )}
      </Section>
    </MainLayout>
  );
};

ConsultationsLayout.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: PropTypes.object.isRequired
  })
};

export default ConsultationsLayout;
