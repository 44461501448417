import React from "react";
import { Icon } from "ibolit-ui";
import RowWithImage from "~/components/RowWithImage";
import styles from "./PublicProfile.scss";

export default ({ chat_id, testid }) => (
  <RowWithImage
    className={styles.rowWithImage}
    image={<Icon variant="chat" fill="var(--blue)" />}
    title="Написать сообщение"
    hasHoverEffect
    to={`/chats/${chat_id}`}
    testid={testid}
  />
);
