import Types from "~/store/users/usersTypes";
import authTypes from "~/store/auth/authTypes";
import {
  requestReducer,
  state as requestState
} from "~/store/users/usersModel";
import { composeReducers } from "~/utils/redux/compose";

const upsertUser = (state, { user }) => {
  const items = { [user.id]: user };
  if (state.items[user.id]) {
    return state.merge({ items }, { deep: true });
  }
  return state.merge(
    { items, all: { order: [user.id, ...state.all.order] } },
    { deep: true }
  );
};

const reducer = (state = requestState, action) => {
  switch (action.type) {
    case Types.USER_INFO_REQUEST:
      return state.merge({
        isFetching: true,
        error: null
      });

    case Types.USER_INFO_SUCCESS:
      return state.merge(
        {
          isFetching: false,
          error: null,
          isSearching: false,
          ...(action.payload || {})
        },
        { deep: true }
      );

    case Types.USER_INFO_FAILURE:
      return state.merge({
        isFetching: false,
        error: action.error,
        ...(action.payload || {})
      });

    case Types.UPSERT_USER:
      return upsertUser(state, action);

    case Types.USER_CHECK_NEW_SUCCESS:
      return state.setIn(["items", action.request.id, "is_new"], false);

    case Types.SET_SELECTED:
      return state.set("selected", action.userId);

    case authTypes.LOGOUT:
      return requestState;

    default:
      return state;
  }
};

export default composeReducers(reducer, requestReducer);
