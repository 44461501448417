import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Dialog } from "ibolit-ui";
import CommentForm from "./CommentForm";
import contactsActions from "~/store/contacts/contactsActions";
import { useCloseModal } from "~/hooks/useModal";
import { getContactsError } from "~/store/contacts/contactsSelector";
import styles from "./CommentModal.scss";

export const COMMENT_VIEW = "comment";

const CommentModal = ({ id, comment, testid }) => {
  const dispatch = useDispatch();

  useEffect(() => () => dispatch(contactsActions.clearState()), []);

  const error = useSelector(getContactsError);

  const requestSubmit = useCallback(
    text => {
      dispatch(contactsActions.privateCommentSendRequest(id, text));
    },
    [id]
  );

  const onCloseClick = useCloseModal(COMMENT_VIEW);

  return (
    <Dialog
      testId="CommentModal"
      boxClassName={styles.modalBox}
      header="Комментарий"
      onCloseClick={onCloseClick}
    >
      <CommentForm
        requestSubmit={requestSubmit}
        comment={comment}
        error={error}
        testid={testid}
      />
    </Dialog>
  );
};

CommentModal.propTypes = {
  id: PropTypes.string.isRequired,
  comment: PropTypes.string.isRequired,
  testid: PropTypes.string
};

export default CommentModal;
