import Immutable from "seamless-immutable";
import types from "~/store/call/callTypes";

import CALL_STATUS from "~/components/CallRoom/constants/callStatus";

const initialState = Immutable({
  callStatus: CALL_STATUS.IDLE,
  callId: null
});

export default (state = initialState, action) => {
  const { type, status } = action;

  switch (type) {
    case types.SET_CALL_STATUS: {
      return state.set("callStatus", CALL_STATUS[status]);
    }

    case types.SET_VOXIMPLANT_PARAMS: {
      return state.merge({ callId: action.callId });
    }

    case types.CALL_ERROR:
      return initialState;

    default:
      return state;
  }
};
