import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Button, Label, Dialog, Select, Text } from "ibolit-ui";
import styles from "./ProfileOnlineRecord.scss";
import { getUser } from "~/store/profile/profileSelectors";
import action from "~/store/profile/profileAction";

const zonesSelectValues = [
  { label: "Не использовать", value: null },
  { label: "15 минут", value: 15 },
  { label: "30 минут", value: 30 },
  { label: "1 час", value: 60 },
  { label: "3 часа", value: 180 },
  { label: "12 часов", value: 720 },
  { label: "1 день", value: 1440 },
  { label: "3 дня", value: 4320 },
  { label: "7 дней", value: 10080 }
];

function ProfileOnlineRecords({ onCloseClick }) {
  const user = useSelector(getUser);
  const [selectValue, setSelectValue] = useState(
    zonesSelectValues.find(zone => zone.value === user.dead_zone)
  );
  const dispatch = useDispatch();

  const handleSaveDeadZone = () => {
    dispatch(
      action.userDataUpdateRequest({ ...user, dead_zone: selectValue.value })
    );
  };

  const handleChangeDeadZone = item => {
    setSelectValue(item);
  };

  return (
    <Dialog
      testId="ProfileOnlineRecordModal"
      boxClassName={styles.dialog}
      header="Настройки онлайн-записи"
      headerTestId={"header_modal_window_online_record"}
      onCloseClick={onCloseClick}
      bodyClassName={styles.body}
      testIdTitle={`header_online_record`}
    >
      <Text
        className={styles.description}
        variant="h4"
        colorVariant="tertiary"
        weight="normal"
        testid="ProfileOnlineRecords__DESCRIPTION"
      >
        Вы можете ограничить время возможной онлайн-записи пациента к вам на
        слот консультации
      </Text>
      <Label
        className={styles.input}
        title="За сколько по времени ограничить запись"
        infoText={`Укажите, за сколько по времени до начала\nконсультации онлайн-запись будет невозможна`}
        testid="ProfileOnlineRecords__INPUT_LABEL"
      >
        <Select
          inputClassName={styles.inputClassName}
          items={zonesSelectValues}
          testId={"ProfileOnlineRecord_select"}
          onChange={handleChangeDeadZone}
          value={selectValue?.value}
        />
      </Label>
      <div className={styles.horizontalLine} />
      <Button
        testid="ProfileOnlineRecord__button"
        variant="secondary"
        type="submit"
        fullWidth
        onClick={handleSaveDeadZone}
      >
        Сохранить
      </Button>
    </Dialog>
  );
}

ProfileOnlineRecords.propTypes = {
  onCloseClick: PropTypes.func
};

export default ProfileOnlineRecords;
