import * as Sentry from "@sentry/browser";

const SENTRY_KEY =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development"
    ? "https://103b38ef62524f9c9aae0a9f7b23447a@sentry.ibolit.pro/3"
    : "https://103b38ef62524f9c9aae0a9f7b23447a@sentry.ibolit.pro/3";

// Sentry.init({
//   dsn: SENTRY_KEY
// });
