import React from "react";
import { ImageLoader, Text } from "ibolit-ui";
import sadFace from "~/assets/images/sad_face.png";
import styles from "./NotFound.scss";

const NotFound = () => (
  <div className={styles.notFound}>
    <ImageLoader src={sadFace} asBackground className={styles.image} />
    <Text variant="h3">Ошибка 404</Text>
    <Text variant="h5" tag="pre">
      {`Страница устарела, была удалена или\nне существовала`}
    </Text>
  </div>
);

export default NotFound;
