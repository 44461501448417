import { useEffect, useState, useRef } from "react";
import ResizeObserver from "resize-observer-polyfill";
import _ from "lodash";

const useResizeObserver = (
  { useWidth, useHeight, useRect } = { useWidth: true }
) => {
  const ref = useRef();
  const [width, changeWidth] = useState(1);
  const [height, changeHeight] = useState(1);
  const [rect, changeRect] = useState({ width, height });

  useEffect(() => {
    const element = ref.current;

    const resizeObserver = new ResizeObserver(entries => {
      if (!Array.isArray(entries)) {
        return;
      }

      if (!entries.length) {
        return;
      }

      const { contentRect } = entries[0];

      useWidth && contentRect.width !== width && changeWidth(contentRect.width);
      useHeight &&
        contentRect.height !== height &&
        changeHeight(contentRect.height);
      useHeight &&
        contentRect.height !== height &&
        changeHeight(contentRect.height);
      useRect && !_.isEqual(rect, contentRect) && changeRect(contentRect);
    });

    resizeObserver.observe(element);

    return () => resizeObserver.unobserve(element);
  }, []);

  return [ref, { width, height, rect }];
};

export default useResizeObserver;
