import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import cn from "classnames";
import { Label, Button, Dialog, Scrollbar } from "ibolit-ui";
import { NEW_CONSULTATION_MODAL } from "~/locators/consultations";
import UserSelector from "../UserSelector";
import TariffSelector from "./TariffSelector";
import DateSelector from "./DateSelector";
import { FieldErrors } from "~/components/Field/Field";
import SlotsList from "~/components/Slots/SlotsList";
import styles from "./NewConsultation.scss";
import { DATE_FORMAT, getTzDate } from "~/utils/dateUtils";
import { CURRENT_DATE } from "~/consts/values";
import EmptySlotsList from "./EmptySlotsLIst";
import { getTariffType } from "~/store/profile/profileSelectors";
import { NO_SLOTS_ON_SELECTED_DATE } from "~/consts/texts";
import Loading from "~/components/Loading/Loading";

const useTariff = (tariff, selectTariff) => {
  const [selectedTariff, setTariff] = useState(tariff);
  const handleSelectTariff = selected => {
    selectTariff(selected);
    setTariff(selected);
  };

  useEffect(() => {
    tariff && handleSelectTariff(tariff);
  }, [tariff]);

  return [selectedTariff, handleSelectTariff];
};

const NewConsultationModal = ({
  user,
  getUsers,
  getTariffs,
  onSubmitClick,
  tariff,
  tariffs,
  errors,
  loading,
  hideModal,
  selectTariff,
  groupedSlots,
  nonWorkingDays
}) => {
  const [selectedUser, setUser] = useState(user);
  const [selectedDate, setDate] = useState(CURRENT_DATE);
  const [selectedSlot, setSlot] = useState();
  const [selectedTariff, setTariff] = useTariff(tariff, selectTariff);

  const slots = groupedSlots[getTzDate(selectedDate).format(DATE_FORMAT)] || [];
  const canSubmit = !!(
    selectedUser &&
    selectedTariff &&
    selectedDate &&
    selectedSlot
  );
  const handleSubmit = () => {
    onSubmitClick({
      patientId: selectedUser.id,
      tariffId: selectedTariff.id,
      tariffType: getTariffType(selectedTariff.type),
      slotId: selectedSlot.id
    });
  };

  return (
    <Dialog
      testId="NewConsultationModal"
      header="Новая консультация"
      headerTestId={NEW_CONSULTATION_MODAL.HEADER}
      closeTestId={NEW_CONSULTATION_MODAL.ICON_CLOSE}
      boxClassName={styles.modalBox}
      onCloseClick={hideModal}
    >
      <Label title="Пациент">
        <UserSelector
          setUser={setUser}
          user={selectedUser}
          getUsers={getUsers}
        />
        <FieldErrors error={errors.user} />
      </Label>

      <Label className={styles.field} title="Вид услуги">
        <TariffSelector
          tariff={selectedTariff}
          tariffs={tariffs}
          getTariffs={getTariffs}
          setTariff={setTariff}
          error={errors.code}
        />
        <FieldErrors error={errors.code} />
      </Label>

      <Label className={styles.field} title="Дата и время">
        <DateSelector
          date={selectedDate}
          setDate={setDate}
          disabledDays={nonWorkingDays}
        />
        {!!selectedTariff &&
          (!!slots.length ? (
            <div className={cn(styles.slotsContainer)}>
              <Scrollbar
                style={{ width: "103%" }}
                autoHeight
                autoHeightMax={200}
              >
                <SlotsList
                  onItemClick={setSlot}
                  slots={slots}
                  selected={selectedSlot}
                />
              </Scrollbar>
            </div>
          ) : (
            <EmptySlotsList text={NO_SLOTS_ON_SELECTED_DATE} />
          ))}
        <FieldErrors error={errors.date} />
      </Label>
      <Button
        className={styles.controls}
        fullWidth
        onClick={handleSubmit}
        disabled={!canSubmit}
        testid={NEW_CONSULTATION_MODAL.BUTTON_SUBMIT}
      >
        Назначить консультацию
      </Button>
      <Loading showLoading={loading} />
    </Dialog>
  );
};

NewConsultationModal.propTypes = {
  onSubmitClick: PropTypes.func.isRequired,
  getUsers: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired
};

export default NewConsultationModal;
