import React, { useState } from "react";
import { useDispatch } from "react-redux";
import classnames from "classnames";

import { Text, Icon, Input, Separator } from "ibolit-ui";

import RowWithImage from "~/components/RowWithImage";
import UserAvatar from "~/components/UserAvatar";

import { StarRating } from "~/components/StarRating";

import { getDayOrDateText } from "~/utils/dateUtils";

import {
  deleteConsultationRatingCallAction,
  replyConsultationRatingCallAction,
  deleteReplyConsultationRatingCallAction
} from "~/store/consultationRatings/actions";

import styles from "./ConsultationRating.scss";

function ProfileTitle({ fullName, rating, createdAt }) {
  return (
    <div>
      <Text variant="h4" tag="h4" className={styles.patientName}>
        {fullName}
      </Text>
      {rating && <StarRating rating={rating} />}
      <div className={rating && styles.createdAt}>
        <Text
          variant="caption"
          colorVariant="pale"
          className={styles.patientName}
        >
          {getDayOrDateText(createdAt)}
        </Text>
      </div>
    </div>
  );
}

export const ConsultationRating = ({ feedback }) => {
  const dispatch = useDispatch();

  const [commentValue, setCommentValue] = useState("");
  const [showCommentField, setShowCommentField] = useState(false);

  const handleDelete = id => () => {
    dispatch(deleteConsultationRatingCallAction(id));
  };

  const handleDeleteReply = id => () => {
    dispatch(deleteReplyConsultationRatingCallAction(id));
  };

  const handleSetComment = e => {
    setCommentValue(e.target.value);
  };

  const handleSendMessage = id => () => {
    dispatch(replyConsultationRatingCallAction(id, commentValue));
    setCommentValue("");
  };

  const handleToggleCommentField = () => setShowCommentField(!showCommentField);

  const { reply } = feedback;

  return (
    <div className={styles.consultationRating}>
      <div className={styles.container}>
        <RowWithImage
          className={styles.rowWithImage}
          title={
            <ProfileTitle
              fullName={feedback.patient.full_name}
              rating={feedback.rating}
              createdAt={feedback.created_at}
            />
          }
          image={<UserAvatar size="small" user={feedback.patient} />}
          imageClass={styles.avatar}
        >
          <div className={styles.comment}>{feedback.comment}</div>
          <div className={styles.operations}>
            {!reply && (
              <Icon
                variant="outline-reply"
                className={classnames(styles.operations_icon, {
                  [styles["operations_icon-active"]]: showCommentField
                })}
                onClick={handleToggleCommentField}
              />
            )}
            <Icon
              variant="outline-bin"
              className={styles.operations_icon}
              onClick={handleDelete(feedback.id)}
            />
          </div>
        </RowWithImage>
        {reply && (
          <>
            <Separator className={styles.replySeparator} />
            <RowWithImage
              className={styles.rowWithImage}
              title={
                <ProfileTitle
                  fullName={feedback.doctor.full_name}
                  createdAt={reply.created_at}
                />
              }
              image={<UserAvatar size="small" user={feedback.doctor} />}
              imageClass={styles.avatar}
            >
              <div className={styles.comment}>{reply.comment}</div>
              <div className={styles.operations}>
                <Icon
                  variant="outline-bin"
                  className={styles.operations_icon}
                  onClick={handleDeleteReply(reply.id)}
                />
              </div>
            </RowWithImage>
          </>
        )}
      </div>
      {showCommentField && !reply && (
        <>
          <div className={styles.messages}>
            <Input
              nativeChange
              placeholder="Сообщение…"
              autocomplete="off"
              className={styles.messages__input}
              value={commentValue}
              onChange={handleSetComment}
              maxlength={1000}
              containerClass={styles.messages__input_container}
            />
            <Icon
              variant="send"
              className={styles.messages__icon}
              fill={"primary"}
              onClick={handleSendMessage(feedback.id)}
            />
          </div>
          <Separator />
        </>
      )}
    </div>
  );
};
