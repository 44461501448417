import CheckExists from "./screens/CheckUserExists";
import UserFound from "./screens/UserFound";
import UserAdd from "./screens/UserAdd";
import {
  CHECK_EXISTS,
  USER_ADD,
  USER_FOUND
} from "~/store/contacts/contactsReducer";

export const SCENES = {
  [CHECK_EXISTS]: {
    key: CHECK_EXISTS,
    Component: CheckExists
  },
  [USER_FOUND]: {
    key: USER_FOUND,
    Component: UserFound
  },
  [USER_ADD]: {
    key: USER_ADD,
    Component: UserAdd
  }
};
