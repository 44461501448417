import Types from "./clinicsTypes";

// TODO - remove if doesnt need(except update)
export default {
  searchRequest: (page, search, mode, params) => ({
    type: Types.SEARCH_REQUEST,
    request: { page, search, mode, params }
  }),
  searchSuccess: (payload, request = {}, rest) => ({
    type: Types.SEARCH_SUCCESS,
    payload,
    request,
    rest
  }),
  searchFailure: (error, request) => ({
    type: Types.SEARCH_FAILURE,
    error,
    request
  }),
  updateItems: items => ({
    type: Types.UPDATE_ITEMS,
    items
  })
};
