import get from "lodash/get";
import {
  all,
  put,
  takeLatest,
  takeEvery,
  call,
  throttle
} from "redux-saga/effects";
import { normalize, schema } from "normalizr";
import api from "~/api/conclusionsApi";
import types from "~/store/conclusions/conclusionsTypes";
import action from "~/store/conclusions/conclusionsActions";
import consultationsActions from "~/store/consultations/consultationsActions";
import { consultationSchema } from "~/store/consultations/consultationsNormalizer";
import commonActions from "~/store/commons/commonActions";
import toastActions from "~/store/toast/toastActions";
import {
  GET_CONCLUSION_ERROR,
  POST_CONCLUSION_ERROR
} from "~/consts/conclusions";

function* searchSaga({ request }) {
  try {
    const { mode, chatId, ...params } = request;
    const { data } = yield call(api.conclusionsSearch, params);
    const {
      result: order,
      entities: { conclusions: items, ...rest }
    } = normalize(data, [new schema.Entity("conclusions")]);
    yield put(action.searchSuccess({ items, order }, request));
    yield put(commonActions.updateItems(rest));
  } catch (e) {
    yield put(action.searchFailure(e.conclusion, request));
  }
}

function* sendConclusionSaga({ request }) {
  try {
    const { data } = yield call(api.sendConclusion, request);
    yield put(action.sendConclusionSuccess(data.conclusion));
    yield put(
      consultationsActions.setConclusionId(
        request.consultationId,
        data.conclusion.id
      )
    );
    const {
      entities: { consultations: items, ...rest }
    } = normalize(data, consultationSchema);
    yield put(consultationsActions.getInfoSuccess({ items }));
    yield put(commonActions.updateItems(rest));
  } catch (error) {
    yield put(action.sendConclusionFailure(get(error, "errors")));
    yield put(toastActions.showErrorToast(POST_CONCLUSION_ERROR));
  }
}

function* getConclusion({ id }) {
  try {
    const { data } = yield call(api.getConclusion, id);
    yield put(action.getConclusionSuccess(data));
  } catch (error) {
    yield put(action.getConclusionFailure());
    yield put(toastActions.showErrorToast(GET_CONCLUSION_ERROR));
  }
}

function* getConclusionPDFSaga({ payload: { id } }) {
  try {
    const { data } = yield api.getConclusionPDF(id);
    var link = document.createElement("a");
    link.href = window.URL.createObjectURL(data);
    link.download = "PdfName-" + new Date().getTime() + ".pdf";

    // append the link to the document body

    document.body.appendChild(link);

    link.click();
  } catch (err) {
    console.log(err);
  }
}

export default function* conclusionsSaga() {
  yield all([
    throttle(500, types.SEARCH_REQUEST, searchSaga),
    takeLatest(types.SEND_CONCLUSION_REQUEST, sendConclusionSaga),
    takeEvery(types.GET_CONCLUSION, getConclusion),
    takeLatest(types.GET_CONCLUSION_PDF, getConclusionPDFSaga)
  ]);
}
