import React, { useState } from "react";
import { Switch, Route } from "react-router-dom";
import MainLayout, { Aside, Section } from "~/components/layouts/Main/Main";
import EmptyStub from "~/components/EmptyStub";
import ChatAside from "./ChatAside";
import Chat from "./Chat";
import PatientInfo from "./PatientInfo";
import styles from "./ChatsLayout.scss";

const ChatsLayout = ({ match }) => {
  const [showRight, toggleRight] = useState(false);

  return (
    <MainLayout mode="wide">
      <Aside variant="leftSide">
        <ChatAside />
      </Aside>
      <Section className={styles.section}>
        <Switch>
          <Route exact path={match.url}>
            <Section>
              <EmptyStub text={"Выберите чат в левой\nколонке"} />
            </Section>
          </Route>
          <Route path={`${match.url}/:chatId`}>
            {({
              match: {
                params: { chatId }
              }
            }) => (
              <>
                <Section className={styles.section}>
                  <Chat
                    chatId={+chatId}
                    showUserInfo={toggleRight}
                    infoShown={!!showRight}
                  />
                </Section>
                <Aside variant="rightSide" hidden={!showRight} width={416}>
                  {showRight && (
                    <PatientInfo
                      chatId={chatId}
                      handleClose={() => toggleRight(false)}
                    />
                  )}
                </Aside>
              </>
            )}
          </Route>
        </Switch>
      </Section>
    </MainLayout>
  );
};

export default ChatsLayout;
