import Immutable from "seamless-immutable";
import moment from "moment-timezone";
import types from "~/store/slots/slotsTypes";
import {
  getTzDate,
  DATE_FORMAT,
  HOURS_MINUTES_FORMAT
} from "~/utils/dateUtils";

const initialState = Immutable({
  groupedSlotsByDate: {},
  nonWorkingDays: [],
  loading: false
});

const createAvailableWorkingDays = () => {
  const workingDays = [
    moment()
      .startOf("day")
      .format(DATE_FORMAT)
  ];

  for (let i = 1; i <= 14; i++) {
    workingDays.push(
      moment()
        .add(i, "days")
        .startOf("day")
        .format(DATE_FORMAT)
    );
  }

  return workingDays;
};

const findNonWorkingDays = (workingDays, groupedSlotsByDate) =>
  workingDays.reduce((nonWorkingDays, date) => {
    if (groupedSlotsByDate[date]) return nonWorkingDays;
    return [...nonWorkingDays, new Date(date)];
  }, []);

const groupSlotsByDay = slots => {
  const slotByTzDate = {};

  slots.forEach(slot => {
    const startAt = getTzDate(slot.start_at).format(HOURS_MINUTES_FORMAT);
    const tzDate = getTzDate(slot.start_at).format(DATE_FORMAT);
    const normalizedSlot = {
      ...slot,
      start_at: startAt
    };

    if (slotByTzDate[tzDate]) {
      slotByTzDate[tzDate].push(normalizedSlot);
    } else {
      slotByTzDate[tzDate] = [normalizedSlot];
    }
  });

  return slotByTzDate;
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SLOTS_FETCH_USER_REQUEST: {
      return state.merge({ loading: true });
    }

    case types.SLOTS_FETCH_USER_SUCCESS: {
      const groupedSlotsByDate = groupSlotsByDay(action.payload.items);
      const availableWorkingDays = createAvailableWorkingDays();
      const nonWorkingDays = findNonWorkingDays(
        availableWorkingDays,
        groupedSlotsByDate
      );

      return state.merge({
        loading: false,
        groupedSlotsByDate,
        nonWorkingDays
      });
    }

    case types.SLOTS_FETCH_USER_ERROR: {
      return state.merge({
        loading: false
      });
    }

    default:
      return state;
  }
};
