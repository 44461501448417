/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import cn from "classnames";
import { Input, Label, Text } from "ibolit-ui";
import useErrorMessage from "~/hooks/useErrorMessage";
import styles from "./Field.scss";

const FieldLabel = ({ label, subTitle, className, children, testid }) => (
  <Label
    testid={testid}
    title={label}
    subTitle={subTitle}
    className={className}
  >
    {children}
  </Label>
);

export const FieldErrors = ({ error, errorSeverity, testid }) => (
  <div
    className={cn(styles.errorContainer, { [styles.hidden]: !error })}
    style={{ height: error ? "unset" : 0 }}
  >
    {error &&
      _.castArray(error).map(message => (
        <Text
          testid={testid}
          tag="span"
          key={message}
          variant="caption"
          colorVariant={errorSeverity === "notice" ? "tertiary" : "error"}
        >
          {String(message)}
        </Text>
      ))}
  </div>
);

const Field = React.forwardRef(
  (
    {
      Component = Input,
      errors,
      label,
      labelProps,
      className,
      noSpace,
      children,
      inputClassName,
      name,
      errorKey,
      testidProps,
      ...componentProps
    },
    ref
  ) => {
    const error = (errors || {})[errorKey];
    const [errorMessage, errorSeverity, handleError] = useErrorMessage(error);
    const showErrorFrame =
      ((errorMessage === undefined && error) || errorMessage) &&
      errorSeverity !== "notice";

    return (
      <div
        className={cn(styles.row, className, {
          [styles.noSpace]: noSpace
        })}
      >
        <FieldLabel testid={testidProps.label} {...{ label, ...labelProps }}>
          <Component
            ref={ref}
            name={name || errorKey}
            {...componentProps}
            testidProps={testidProps}
            className={cn(inputClassName, { errorFrame: showErrorFrame })}
            errorHandler={handleError}
            error={error}
          />
          {children}
        </FieldLabel>
        <FieldErrors
          testid={testidProps.error}
          error={errorMessage === undefined ? error : errorMessage}
          errorSeverity={errorSeverity}
        />
      </div>
    );
  }
);

Field.defaultProps = {
  testidProps: {}
};

Field.propTypes = {
  Component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  children: PropTypes.node,
  className: PropTypes.string,
  errors: PropTypes.any,
  inputClassName: PropTypes.string,
  label: PropTypes.string,
  labelProps: PropTypes.object,
  errorKey: PropTypes.string,
  name: PropTypes.string,
  noSpace: PropTypes.bool,
  testidProps: PropTypes.object
};

export default Field;
