import types from "./slotsTypes";

export default {
  fetchSlots: (slotType, doctorId) => ({
    type: types.SLOTS_FETCH_USER_REQUEST,
    payload: { slotType, doctorId }
  }),
  fetchSlotsSuccess: items => ({
    type: types.SLOTS_FETCH_USER_SUCCESS,
    payload: { items }
  }),
  fetchSlotsError: () => ({ type: types.SLOTS_FETCH_USER_ERROR })
};
