import React, { useState } from "react";
import { useSelector } from "react-redux";

import { useShowModal } from "~/hooks/useModal";
import { CONFIRM_VIEW } from "~/layouts/Consultations/containers/Consultation/containers/Confirm";

import {
  enumTabsIndexes,
  tabsInfo,
  Header,
  Tabs,
  enumTabsNames,
  getFormatDateOfBirth,
  getFormattedStringDateWithTime
} from "ibolit-components";

import { getConsultationInProgress } from "~/store/consultations/consultationSelectors";

import { CONSULTATION_STATUS } from "~/consts/consultations";

import Loading from "~/components/Loading/Loading";

import { ConsultationInfoPage } from "./pages/ConsultationInfoPage";
import { ConsultationAnamnesisPage } from "./pages/ConsultationAnamnesisPage";
import { ConsultationConslusionPage } from "./pages/ConsultationConslusionPage";
import { ConsultationFilesPage } from "./pages/ConsultationFilesPage";

import { getUsers } from "~/store/users/usersSelectors";

import { getConsultationIsLoading } from "~/store/consultations/consultationSelectors";

import { BottomButton } from "./BottomButton";

import styles from "./ConsultationPage.scss";

export const ConsultationPage = ({ consultationData }) => {
  const [activeTab, setActiveTab] = useState(tabsInfo[enumTabsIndexes["INFO"]]);
  const patientData = useSelector(getUsers)[consultationData.patient];

  const isLoading = useSelector(getConsultationIsLoading);

  const handleSetShowTab = () =>
    setActiveTab(tabsInfo[enumTabsIndexes["ANAMNESIS"]]);

  const isInProgress = useSelector(state =>
    getConsultationInProgress(state, consultationData)
  );
  const isTariffTypeOffline = consultationData.tariff_type === "offline";
  const isAvailableEndConsultation =
    consultationData.status === CONSULTATION_STATUS.APPROVED &&
    isInProgress &&
    !isTariffTypeOffline;
  const onEndConsultation = useShowModal(CONFIRM_VIEW, {
    consultationId: consultationData.id,
    service_patient_id: consultationData.service_patient_id
  });

  if (!patientData) {
    return null;
  }

  return (
    <>
      <Loading showLoading={isLoading} />
      <Header
        tariffName={consultationData.tariff_name}
        isTariffTypeOffline={isTariffTypeOffline}
        role={"Пациент"}
        name={patientData.full_name}
        birthDate={getFormatDateOfBirth(patientData.date_of_birth)}
        duration={`${consultationData.tariff_duration} мин`}
        price={`${consultationData.tariff_price} ₽`}
        consultationDate={getFormattedStringDateWithTime(
          consultationData.start_at
        )}
        status={consultationData.status}
        humanImage={patientData.avatar}
        isInProgress={isInProgress}
      />
      <Tabs
        activeTabInfo={activeTab}
        setActiveTab={setActiveTab}
        counter={consultationData.attached_files?.length}
      />
      <div className={styles.pageContainer}>
        <RenderedPage
          activeTab={activeTab}
          consultationData={consultationData}
          handleSetShowTab={handleSetShowTab}
          onEndConsultation={onEndConsultation}
          isAvailableEndConsultation={isAvailableEndConsultation}
          isTariffTypeOffline={isTariffTypeOffline}
        />
        <BottomButton
          activeTab={activeTab.name}
          consultationData={consultationData}
          onEndConsultation={onEndConsultation}
          isTariffTypeOffline={isTariffTypeOffline}
        />
      </div>
    </>
  );
};

const RenderedPage = ({
  activeTab,
  consultationData,
  handleSetShowTab,
  onEndConsultation,
  isAvailableEndConsultation,
  isTariffTypeOffline
}) => {
  const consultationStatusInfo = renderConsultationInfo(consultationData);
  if (!consultationData) {
    return null;
  }

  switch (activeTab.name) {
    case enumTabsNames.INFO:
      return (
        <ConsultationInfoPage
          consultationData={consultationData}
          consultationStatusInfo={consultationStatusInfo}
          handleSetShowTab={handleSetShowTab}
          isTariffTypeOffline={isTariffTypeOffline}
        />
      );
    case enumTabsNames.ANAMNESIS:
      return (
        <ConsultationAnamnesisPage
          consultationStatusInfo={consultationStatusInfo}
          consultationProblemData={consultationData.problem}
          onEndConsultation={onEndConsultation}
          isAvailableEndConsultation={isAvailableEndConsultation}
        />
      );
    case enumTabsNames.FILES:
      return <ConsultationFilesPage consultationData={consultationData} />;
    case enumTabsNames.CONCLUSION:
      return (
        <ConsultationConslusionPage
          consultationStatusInfo={consultationStatusInfo}
          consultationData={consultationData}
          isTariffTypeOffline={isTariffTypeOffline}
        />
      );
    default:
      return null;
  }
};

const renderConsultationInfo = consultationData => {
  const consultationDataProblem = consultationData.problem || {};
  switch (true) {
    case consultationData.status === CONSULTATION_STATUS.COMPLETED &&
      !consultationData.conclusion:
      return {
        text: `Укажите в заключении, какой следующий шаг нужно сделать пациенту выбрав один из четырех статусов завершения приема.`,
        icon: "next-step",
        label: "Какой следующий шаг?"
      };
    case consultationDataProblem.status === "diagnostics_assigned":
      return {
        label: "Назначена диагностика",
        text:
          "Пациент получил от вас назначение на диагностику. В ближайшее время пациент должен вернуться с результатами на повторный прием.",
        icon: "diagnostics-assigned"
      };
    case consultationDataProblem.status === "appointed_offline_reception":
      return {
        label: "Назначен очный прием",
        text:
          "Пациент получил от вас рекомендации. В ближайшее время пациент должен вернуться на повторный, очный прием.",
        icon: "appointed-offline-reception"
      };
    case consultationDataProblem.status === "treatment_prescribed":
      return {
        label: "Рекомендовано лечение",
        text:
          "Пациент получил от вас рекомендации. В ближайшее время пациент должен вернуться на повторный прием и рассказать о своих наблюдениях о ходе лечения.",
        icon: "treatment-prescribed"
      };
    case consultationDataProblem.status === "treatment_completed":
      return {
        label: "Лечение завершено",
        text: "Вы завершили лечение пациента по указанной жалобе.",
        icon: "treatment-completed"
      };
    case consultationDataProblem.status === "auto":
      return {
        text:
          "Пациент пока не предоставил описание по жалобе. Возможно, он заполнит жалобу чуть позже или расскажет во время консультации.",
        textClassName: styles.grayText
      };
    case consultationDataProblem.status === "primary_appointment":
      return {
        text:
          "Пациент выбрал первичную консультацию. Предложите пациенту максимально необходимый список исследований и анализов. Это позволит снять тревогу и подготовить пациента к след.приему.",
        icon: "primary-appointment",
        label: "Первичная консультация"
      };
    case consultationDataProblem.status === "second_opinion":
      return {
        text:
          "Пациент обратился к вам за вторым мнением, возможно он испытывает тревогу или не доверяет текущему диагнозу. Такие консультации демонстрирует высокую вовлеченность пациента в проблему своего здоровья. Постарайтесь сформировать у пациента план дальнейших наблюдений и действий.",
        icon: "second-opinion",
        label: "Второе мнение"
      };
    case consultationDataProblem.status === "transcript_of_analyzes":
      return {
        text:
          "Пациент хочет получить расшифровку анализов или других исследований. Постарайтесь подробно рассказать чем грозят даже минимальные отклонения. Предложите дополнительные исследования, чтобы снять все риски.",
        icon: "transcript-of-analyzes",
        label: "Расшифровка анализов"
      };
    case consultationDataProblem.status === "repeated_appointment":
      return {
        text:
          "Большинство пациентов делают один повторный прием и бросают лечение как только уходят симптомы. Постарайтесь объяснить пациенту чем он рискует, и почему лечение можно завершить только после одобрения врача.",
        icon: "repeated-appointment",
        label: "Повторная консультация"
      };
    case consultationDataProblem.status === "closed":
      return {
        text: "Вы завершили лечение пациента по указанной жалобе.",
        label: "Лечение завершено",
        icon: "treatment-completed"
      };
    default:
      return {};
  }
};
