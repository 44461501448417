import React, { useMemo } from "react";
import cn from "classnames";
import { connect } from "react-redux";
import { Text, IconWithText, Badge } from "ibolit-ui";
import UserItem from "~/components/UserItem";
import styles from "./ConsultationsListItem.scss";
import {
  getConsultationIcon,
  getIsConsultationInProgress,
  getUserById,
  isConsultationExpired
} from "~/store/consultations/consultationSelectors";
import {
  getTariffTextByType,
  getTariffType
} from "~/store/profile/profileSelectors";
import { getTzDate } from "~/utils/dateUtils";

const ConsultationsListItem = ({
  className,
  style,
  item,
  item: { start_at, conclusion_id, status, is_new, type },
  mode,
  user,
  onClick,
  isSelected,
  testid
}) => {
  const isExpired = isConsultationExpired(status);
  const isProgress = getIsConsultationInProgress(status, start_at);
  const hasConclusion = !!conclusion_id;

  const handleClick = useMemo(() => {
    if (isSelected) return;
    return () => onClick(item);
  }, [isSelected, item, onClick]);

  const tariffType = getTariffType(type);

  return (
    <div
      onClick={handleClick}
      className={cn(styles.container, className, {
        [styles.clickable]: handleClick,
        [styles.selected]: isSelected,
        [styles.progress]: isProgress
      })}
      style={style}
      data-testid={testid}
    >
      <Text variant="h3" colorVariant="pressing" className={styles.time}>
        {getTzDate(start_at).format("HH:mm")}
      </Text>
      <div className={styles.contentContainer}>
        <div className={styles.head}>
          <IconWithText
            text={getTariffTextByType(type)}
            iconVariant={getConsultationIcon(tariffType)}
            type="secondary"
          />
          {is_new && <Badge variant="secondary">НОВАЯ</Badge>}
        </div>
        {user && user.id && (
          <UserItem
            key={user.id}
            user={user.id}
            className={styles.userItem}
            noPadding
            sizeVariant="small"
            showOnlineStatus
          />
        )}
        {mode === "closed" && (
          <div className={styles.footer}>
            <IconWithText
              iconVariant={isExpired ? "fail" : "success"}
              type="secondary"
              iconColor={isExpired ? "var(--danger)" : "var(--green)"}
              text={isExpired ? "Не состоялась" : "Состоялась"}
            />
            <IconWithText
              iconVariant="phonendoscope"
              type="secondary"
              iconColor={hasConclusion && "var(--green"}
              text={hasConclusion ? "Есть заключение" : "Нет заключения"}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default connect((state, props) => ({
  user: getUserById(state, props)
}))(ConsultationsListItem);
