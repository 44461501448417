export const CONSULTATION_STATUS = {
  PENDING: "pending",
  WAIT_PAYMENT: "wait_payment",
  WAIT_CLIENT_CONFIRMATION: "wait_client_confirmation",
  APPROVED: "approved",
  COMPLETED: "completed",
  CANCELED: "canceled",
  NOT_APPEARED: "not_appeared",
  EXPIRED: "expired",
  CONFIRMED: "confirmed"
};

export const MAP_CONSULTATION_STATUSES_TO_NAMES = {
  [CONSULTATION_STATUS.PENDING]: "Ожидает подтверждения",
  [CONSULTATION_STATUS.WAIT_PAYMENT]: "Ожидает оплаты",
  [CONSULTATION_STATUS.CONFIRMED]: "Подтверждено",
  [CONSULTATION_STATUS.APPROVED]: "Оплачено",
  [CONSULTATION_STATUS.COMPLETED]: "Завершена",
  [CONSULTATION_STATUS.CANCELED]: "Отменена",
  [CONSULTATION_STATUS.NOT_APPEARED]: "Пациент не явился",
  [CONSULTATION_STATUS.EXPIRED]: "Просрочена"
};

export const CONSULTATION_STATUSES = [
  CONSULTATION_STATUS.PENDING,
  CONSULTATION_STATUS.WAIT_PAYMENT,
  CONSULTATION_STATUS.WAIT_CLIENT_CONFIRMATION,
  CONSULTATION_STATUS.APPROVED,
  CONSULTATION_STATUS.COMPLETED,
  CONSULTATION_STATUS.CANCELED,
  CONSULTATION_STATUS.NOT_APPEARED,
  CONSULTATION_STATUS.EXPIRED
];
export const POST_CONSULTATION_FAILURE_TEXT =
  "Не удалось добавить консультацию";
export const POST_CONSULTATION_SUCCESS_TEXT = "Консультация успешно назначена";
export const CONSULTATION_INFO_REQUEST_ERROR_TEXT = "Консультация не найдена";

export const CONSULTATIONS_FILTER_STATUS_OPEN = "open";
export const CONSULTATIONS_FILTER_STATUS_CLOSED = "closed";

export const CONSULTATION_WAIT_CONFIRM_TITLE = "Консультация не подтверждена";
export const CONSULTATION_WAIT_CONFIRM_TEXT =
  "Консультация ожидает подтверждения администратором клиники";
export const CONSULTATION_WAIT_PAYMENT_TITLE = "Консультация не оплачена";
export const CONSULTATION_WAIT_PAYMENT_TEXT =
  "Пациент пока не оплатил консультацию";
export const CONSULTATION_APPROVED_OFFLINE_TITLE = "Консультация подтверждена";
export const CONSULTATION_APPROVED_OFFLINE_TEXT = "Ожидайте пациента на прием";
export const CONSULTATION_APPROVED_ONLINE_TITLE = "Начните консультацию";
export const CONSULTATION_APPROVED_ONLINE_TEXT =
  "Когда будете готовы, нажмите на кнопку ниже, чтобы начать звонок:";

export const CONSULTATION_OPEN_STATUS_TEXTS = {
  [CONSULTATION_STATUS.PENDING]: {
    offline: {
      title: CONSULTATION_WAIT_CONFIRM_TITLE,
      text: CONSULTATION_WAIT_CONFIRM_TEXT
    },
    online: {
      title: CONSULTATION_WAIT_CONFIRM_TITLE,
      text: CONSULTATION_WAIT_CONFIRM_TEXT
    }
  },
  [CONSULTATION_STATUS.WAIT_PAYMENT]: {
    offline: {
      title: CONSULTATION_WAIT_PAYMENT_TITLE,
      text: CONSULTATION_WAIT_PAYMENT_TEXT
    },
    online: {
      title: CONSULTATION_WAIT_PAYMENT_TITLE,
      text: CONSULTATION_WAIT_PAYMENT_TEXT
    }
  },
  [CONSULTATION_STATUS.WAIT_CLIENT_CONFIRMATION]: {
    offline: {
      title: CONSULTATION_WAIT_CONFIRM_TITLE,
      text: CONSULTATION_WAIT_CONFIRM_TEXT
    },
    online: {
      title: CONSULTATION_WAIT_CONFIRM_TITLE,
      text: CONSULTATION_WAIT_CONFIRM_TEXT
    }
  },
  [CONSULTATION_STATUS.APPROVED]: {
    offline: {
      title: CONSULTATION_APPROVED_OFFLINE_TITLE,
      text: CONSULTATION_APPROVED_OFFLINE_TEXT
    },
    online: {
      title: CONSULTATION_APPROVED_ONLINE_TITLE,
      text: CONSULTATION_APPROVED_ONLINE_TEXT
    }
  }
};
