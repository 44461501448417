import { takeLatest, all, put, throttle, call } from "redux-saga/effects";
import { normalize } from "normalizr";
import api from "~/api/usersApi";
import Types from "~/store/users/usersTypes";
import Actions from "~/store/users/usersActions";
import commonAction from "~/store/commons/commonActions";
import { userSchema } from "~/store/users/usersNormalizer";
import toastActions from "~/store/toast/toastActions";
import { ERROR_REQUEST_DEFAULT } from "~/consts/texts";

function* sendRequestSata({ payload }) {
  const {
    page,
    mode,
    limit,
    search = "",
    filter: { order, only_active }
  } = payload;
  const searchParams = search.length ? { search } : {};
  const filterParams = only_active ? { order, only_active: 1 } : { order };
  const params = { page, ...searchParams, ...filterParams };
  try {
    const { data } = yield call(api.usersSearchApi, params);
    const { result, entities } = normalize(data, [userSchema]);
    yield put(commonAction.updateItems(entities));
    yield put(Actions.requestSuccess({ page, limit, mode, order: result }));
  } catch (e) {
    yield put(Actions.requestFailed({ mode, errors: e }));
  }
}

function* getChatId({ id }) {
  try {
    const {
      data: { id: chat_id }
    } = yield api.userChatIdApi({ id });
    return chat_id;
  } catch (e) {
    return null;
  }
}

function* userInfoSaga({ request }) {
  try {
    const { data } = yield api.userInfoApi(request);
    const chat_id = yield getChatId(request);
    const {
      entities: { users: items, ...rest }
    } = normalize({ ...data, chat_id }, userSchema);
    yield put(Actions.userInfoSuccess({ items }, request));
    yield put(commonAction.updateItems(rest));
  } catch (e) {
    yield put(Actions.userInfoFailure(e.message, request));
  }
}

function* userCheckNewSaga({ request }) {
  try {
    const { data } = yield api.userCheckNewApi(request);
    data.status && (yield put(Actions.userCheckNewSuccess(data, request)));
  } catch (e) {
    yield put(Actions.userCheckNewFailure(e.message, request));
  }
}

function* confirmNewPatient({ id, is_primary }) {
  try {
    const { data } = yield call(api.chatSendConfirmNewPatient, {
      id,
      is_primary
    });
    yield put(Actions.updateItems({ [data.id]: data }));
    yield put(Actions.confirmNewPatientSuccess());
  } catch (e) {
    yield put(Actions.confirmNewPatientError());
    yield put(toastActions.showErrorToast(ERROR_REQUEST_DEFAULT));
  }
}

export default function*() {
  yield all([
    throttle(2000, Types.REQUEST_SEND, sendRequestSata),
    takeLatest(Types.USER_INFO_REQUEST, userInfoSaga),
    takeLatest(Types.USER_CHECK_NEW_REQUEST, userCheckNewSaga),
    takeLatest(Types.NEW_PATIENT_CONFIRM_REQUEST, confirmNewPatient)
  ]);
}
