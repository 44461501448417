import types from "./faqTypes";

export default {
  faqDataRequest: () => ({
    type: types.FAQ_DATA_REQUEST
  }),
  faqDataSuccess: data => ({
    type: types.FAQ_DATA_SUCCESS,
    data
  }),
  faqDataError: () => ({
    type: types.FAQ_DATA_ERROR
  })
};
