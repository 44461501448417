import types from "./consultationTypes";
import { DEFAULT_SEARCH_ITEMS_LIMIT } from "~/consts/values";

export const CONSULTATION_TYPES = {
  DELETE_REPLY: "DELETE_REPLY",
  DOWNLOAD_FILE: "DOWNLOAD_FILE",
  DELETE_FILE: "DELETE_FILE"
};

export default {
  post: ({ patientId, tariffId, tariffType, slotId }) => ({
    type: types.CONSULTATIONS_POST,
    payload: { patientId, tariffId, tariffType, slotId }
  }),
  postSuccess: data => ({
    type: types.CONSULTATIONS_POST_SUCCESS,
    payload: data
  }),
  postFailure: (errors = {}) => ({
    type: types.CONSULTATIONS_POST_FAILURE,
    errors
  }),
  selectTariff: tariff => ({
    type: types.CONSULTATIONS_SELECT_TARIFF,
    tariff
  }),
  getConsultations: ({ page, mode, limit = DEFAULT_SEARCH_ITEMS_LIMIT }) => ({
    type: types.CONSULTATIONS_GET,
    request: {
      page,
      mode,
      limit
    }
  }),
  getConsultationsSuccess: (payload, request = {}, rest) => ({
    type: types.CONSULTATIONS_GET_SUCCESS,
    payload,
    request,
    rest
  }),
  getConsultationsFailure: (error, request) => ({
    type: types.CONSULTATIONS_GET_FAILURE,
    error,
    request
  }),
  getInfo: id => ({
    type: types.CONSULTATIONS_INFO_GET,
    request: { id }
  }),
  getInfoSuccess: (payload, rest) => ({
    type: types.CONSULTATIONS_INFO_GET_SUCCESS,
    payload,
    rest
  }),
  getInfoFailure: error => ({
    type: types.CONSULTATIONS_INFO_GET_FAILURE,
    error
  }),
  updateItems: items => ({
    type: types.CONSULTATIONS_UPDATE_ITEMS,
    items
  }),
  setConclusionId: (id, conclusionId) => ({
    type: types.CONSULTATIONS_SET_CONCLUSION_ID,
    id,
    conclusionId
  }),
  endConsultation: (service_patient_id, consultationId) => ({
    type: types.CONSULTATION_END,
    consultationId,
    service_patient_id
  }),
  addFile: file => ({
    type: types.CONSULTATIONS_ADD_FILE,
    file
  }),
  removeFile: file => ({
    type: types.CONSULTATIONS_REMOVE_FILE,
    file
  }),
  uploadFiles: ({ files, consultationId }) => ({
    type: types.CONSULTATION_UPLOAD_FILES,
    files,
    consultationId
  }),
  uploadFile: ({ file, consultationId }) => ({
    type: types.CONSULTATION_UPLOAD_FILE,
    file,
    consultationId
  }),
  updateFile: payload => ({
    type: types.CONSULTATION_UPDATE_FILE,
    payload
  }),
  updateFileError: payload => ({
    type: types.CONSULTATION_UPLOAD_FILE_ERROR,
    payload
  }),
  uploadProgress: ({ fileName, progress }) => ({
    type: types.CONSULTATION_UPLOAD_PROGRESS,
    fileName,
    progress
  }),
  uploadSuccess: ({ fileName }) => ({
    type: types.CONSULTATION_UPLOAD_SUCCESS,
    fileName
  }),
  cancelUpload: ({ fileName }) => ({
    type: types.CONSULTATION_CANCEL_UPLOAD,
    fileName
  }),
  clearFiles: () => ({
    type: types.CONSULTATION_CLEAR_FILES
  }),
  deleteFile: ({ fileId, consultationId }) => ({
    type: types.CONSULTATION_DELETE_FILE,
    fileId,
    consultationId
  }),
  deleteFileSuccess: ({ fileId, consultationId }) => ({
    type: types.CONSULTATION_DELETE_FILE_SUCCESS,
    fileId,
    consultationId
  }),
  deleteFileError: () => ({
    type: types.CONSULTATION_DELETE_FILE_ERROR
  }),
  confirmConsultation: ({ consultationId }) => ({
    type: types.CONSULTATION_CONFIRM,
    consultationId
  }),
  confirmConsultationError: error => ({
    type: types.CONSULTATION_CANCEL_ERROR,
    error
  }),
  cancelConsultation: ({ consultationId, reason }) => ({
    type: types.CONSULTATION_CANCEL,
    consultationId,
    reason
  }),
  cancelConsultationError: error => ({
    type: types.CONSULTATION_CANCEL_ERROR,
    error
  }),
  resetIsLoadingAction: () => ({
    type: types.RESET_LOADING
  }),
  doctorReplyAction: (replyId, comment, consultationId) => ({
    type: types.REPLY_DOCTOR,
    comment,
    replyId,
    consultationId
  }),
  doctorReplySuccessAction: comment => ({
    type: types.REPLY_DOCTOR_SUCCESS
  }),
  doctorReplyErrorAction: error => ({
    type: types.REPLY_DOCTOR_ERROR,
    error
  }),
  deleteDoctorReplyAction: (replyId, consultationId) => ({
    type: types.DELETE_REPLY_DOCTOR,
    replyId,
    consultationId
  }),
  deleteDoctorReplyErrorAction: error => ({
    type: types.DELETE_REPLY_DOCTOR_ERROR,
    error
  }),
  deletePatientRatingAction: (ratingId, consultationId) => ({
    type: types.DELETE_CONSULTATION_RATING,
    ratingId,
    consultationId
  }),
  deletePatientRatingErrorAction: error => ({
    type: types.DELETE_CONSULTATION_RATING_ERROR,
    error
  }),
  sendFeedbackFailure: ({ error }) => ({
    type: types.CONSULTATION_SEND_FEEDBACK_FAILURE,
    error
  })
};

export const downloadFileAction = ({ fileUrl, fileName }) => ({
  type: CONSULTATION_TYPES.DOWNLOAD_FILE,
  fileUrl,
  fileName
});
