import Immutable from "seamless-immutable";
import Types from "./uiTypes";

const initialState = Immutable({
  modal: {
    views: []
  }
});

export default (state = initialState, action) => {
  switch (action.type) {
    case Types.SHOW_MODAL:
      return state.update(
        "modal",
        (modalObj, viewObj) =>
          modalObj.update("views", views =>
            views.filter(v => v.view !== viewObj.view).concat([viewObj])
          ),
        { ...action.payload }
      );

    case Types.HIDE_MODAL:
      return state.update(
        "modal",
        (modalObj, viewStr) =>
          modalObj.update("views", views =>
            views.filter(item => item.view !== viewStr)
          ),
        action.payload.view
      );

    case Types.CLOSE_ALL_MODAL:
      return initialState;

    default:
      return state;
  }
};
