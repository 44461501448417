import { connect } from "react-redux";
import { withFormik } from "formik";
import actions from "~/store/profile/profileAction";
import { getIsLoadingChangePassword } from "~/store/profile/profileSelectors";
import ChangeCredentialForm from "../components/ChangeCredentialForm";
import { MAX_PASSWORD_LENGTH, MIN_PASSWORD_LENGTH } from "~/consts/values";
import { PASSWORD_CHANGE } from "~/locators/privateProfile";

const validate = values => {
  const result = {};

  if (!values.password) {
    result.password = "Это поле обязательно для заполнения";
  } else if (values.password.length < MIN_PASSWORD_LENGTH) {
    result.password = `Пароль не должен содержать меньше ${MIN_PASSWORD_LENGTH} символов`;
  }

  if (!values.password_confirmation) {
    result.password_confirmation = "Это поле обязательно для заполнения";
  } else if (values.password !== values.password_confirmation) {
    result.password_confirmation = "Пароли не совпадают";
  }

  return result;
};

const mapPropsToValues = () => ({
  password: "",
  password_confirmation: ""
});

const handleSubmit = (values, bag) => {
  bag.props.onSubmit({ values, setErrors: bag.setErrors });
};

const FormikPasswordForm = withFormik({
  mapPropsToValues,
  validate,
  handleSubmit
})(ChangeCredentialForm);

const defaultProps = {
  title: "Изменение пароля",
  firstInput: {
    label: "Введите новый пароль",
    type: "password",
    name: "password",
    maxLength: MAX_PASSWORD_LENGTH
  },
  secondInput: {
    label: "Подтвердите пароль",
    type: "password",
    name: "password_confirmation",
    maxLength: MAX_PASSWORD_LENGTH
  }
};

const ChangePasswordFormContainer = connect(
  state => ({
    loading: getIsLoadingChangePassword(state),
    title: defaultProps.title,
    firstInput: defaultProps.firstInput,
    secondInput: defaultProps.secondInput,
    testIdHeader: PASSWORD_CHANGE.TITLE,
    testIdIconClose: PASSWORD_CHANGE.ICON_CLOSE,
    firstInputTestId: PASSWORD_CHANGE.INPUT_NEW_PASSWORD,
    secondInputTestId: PASSWORD_CHANGE.INPUT_PASSWORD_CONFIRM,
    submitTestId: PASSWORD_CHANGE.BUTTON_SUBMIT
  }),
  {
    onSubmit: actions.changePasswordRequest
  }
)(FormikPasswordForm);

export default ChangePasswordFormContainer;
