import React from "react";
import { useDispatch, useSelector } from "react-redux";
import contactsActions from "~/store/contacts/contactsActions";
import { SCENES } from "./Invitation.helpers";
import { useCloseModal } from "~/hooks/useModal";
import {
  getContactsError,
  getInvitationScene,
  getIsFetching
} from "~/store/contacts/contactsSelector";

export const INVITATION_VIEW = "Invitation";

export default function InvitationModal() {
  const error = useSelector(getContactsError);
  const dispatch = useDispatch();
  const isFetching = useSelector(getIsFetching);
  const scene = useSelector(getInvitationScene);
  const { Component } = SCENES[scene];

  const hideModal = useCloseModal(INVITATION_VIEW);

  const onHideModal = () => {
    dispatch(contactsActions.clearState());
    hideModal();
  };

  return (
    <Component
      testId="InvitationModal"
      error={error}
      loading={isFetching}
      hideModal={onHideModal}
    />
  );
}
