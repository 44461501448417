import React from "react";
import { Text } from "ibolit-ui";
import styles from "./SystemText.scss";

const SystemText = ({ children }) => (
  <div className={styles.systemText}>
    <Text variant="h4">
      {children.split("").map(char => (char === "\n" ? <br /> : char))}
    </Text>
  </div>
);

export default SystemText;
