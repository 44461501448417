import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { Redirect } from "react-router-dom";
import {
  getAuthStep,
  getIsAuthFetching,
  getIsUserAuthorized
} from "~/store/auth/authSelectors";
import MainLayout from "~/components/layouts/Main/Main";
import authActions from "~/store/auth/authActions";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { SCENES } from "./Authorization.helpers";
import styles from "./Authorization.scss";

const Authorization = () => {
  const dispatch = useDispatch();

  const step = useSelector(getAuthStep);
  const isAuthorized = useSelector(getIsUserAuthorized);
  const isFetching = useSelector(getIsAuthFetching);
  const goBack = useCallback(() => {
    dispatch(authActions.stepBack());
  }, []);

  const scene = SCENES[step];

  const {
    Component,
    footerContent,
    canGoBack,
    key,
    fullLogo,
    ...componentProps
  } = scene;

  if (isAuthorized) return <Redirect to="/" />;

  return (
    <MainLayout
      className={classNames(styles.mainContainer)}
      mode="small"
      showLoading={isFetching}
    >
      <Header
        fullLogo={fullLogo}
        canGoBack={canGoBack}
        backClickHandler={goBack}
      />
      <Component className={styles.form} {...componentProps} />
      <Footer>{footerContent}</Footer>
    </MainLayout>
  );
};

export default Authorization;
