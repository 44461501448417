import React, { useCallback, forwardRef } from "react";
import { Scrollbar } from "ibolit-ui";

const InfiniteList = (
  {
    style,
    className,
    loadMore,
    canLoadMore,
    offsetLoad = 50,
    minHeight,
    maxHeight,
    children,
    ...props
  },
  ref
) => {
  const scrollRef = ref || React.createRef();
  const handleInfiniteLoading = useCallback(
    ({ scrollHeight, scrollTop, clientHeight }) => {
      if (!canLoadMore) return;
      const current = scrollHeight - scrollTop - clientHeight;
      if (current < offsetLoad) {
        loadMore();
      }
    },
    [offsetLoad, loadMore, canLoadMore]
  );
  return (
    <Scrollbar
      className={className}
      style={style}
      onScrollFrame={handleInfiniteLoading}
      autoHeight={minHeight !== undefined || maxHeight !== undefined}
      autoHeightMin={minHeight}
      autoHeightMax={maxHeight}
      ref={scrollRef}
      {...props}
    >
      {children}
    </Scrollbar>
  );
};

export default forwardRef(InfiniteList);
