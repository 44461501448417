import axios from "./httpService";

const fetchConsultationRatings = params =>
  axios.get(`/v3/doctors/consultation-ratings`, { params });
const deleteConsultationRatings = id =>
  axios.delete(`/v3/doctors/consultation-ratings/${id}`);
const replyConsultationRatings = (id, data) =>
  axios.post(`v3/doctors/consultation-ratings/${id}/reply`, data);
const deleteDoctorReplyConsultationRating = id =>
  axios.delete(`v3/doctors/consultation-ratings/reply/${id}`);

export {
  fetchConsultationRatings,
  deleteConsultationRatings,
  replyConsultationRatings,
  deleteDoctorReplyConsultationRating
};
