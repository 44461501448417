import Immutable from "seamless-immutable";
import Types from "~/store/conclusions/conclusionsTypes";
import {
  searchSuccessReducer,
  searchRequestReducer,
  searchFailureReducer
} from "~/store/utils";

const initialState = Immutable({
  items: {},
  posting: false,
  loading: false
});

export default (state = initialState, action) => {
  switch (action.type) {
    case Types.SEARCH_REQUEST:
      return state.update(action.request.userId, (localState = initialState) =>
        searchRequestReducer(localState, action)
      );

    case Types.SEARCH_SUCCESS:
      return state.update(action.request.userId, localState =>
        searchSuccessReducer(localState, action)
      );

    case Types.SEARCH_FAILURE:
      return state.update(action.request.userId, localState =>
        searchFailureReducer(localState, action)
      );

    case Types.SEND_CONCLUSION_REQUEST:
      return state.merge({
        posting: true
      });

    case Types.SEND_CONCLUSION_SUCCESS: {
      const { payload } = action;
      return state.setIn(["items", payload.id], payload).set("posting", false);
    }

    case Types.SEND_CONCLUSION_FAILURE:
      return state.merge({
        posting: false
      });

    case Types.GET_CONCLUSION_SUCCESS: {
      const { payload } = action;
      return state.setIn(["items", payload.id], payload);
    }

    default:
      return state;
  }
};
