import Types from "./chatsTypes";
import { actions } from "./chatModel";

export default {
  ...actions,
  infoRequest: chatId => ({
    type: Types.INFO_REQUEST,
    request: { chatId }
  }),
  infoSuccess: (payload, request = {}, rest) => ({
    type: Types.INFO_SUCCESS,
    payload,
    request,
    rest
  }),
  infoFailure: (error, request) => ({
    type: Types.INFO_FAILURE,
    error,
    request
  }),
  endExpressChatRequest: (chatId, serviceId, conclusion) => ({
    type: Types.END_EXPRESS_CHAT_REQUEST,
    request: { chatId, serviceId, conclusion }
  }),
  endExpressChatSuccess: (payload, request, rest) => ({
    type: Types.END_EXPRESS_CHAT_SUCCESS,
    payload,
    request,
    rest
  }),
  endExpressChatFailure: (error, request) => ({
    type: Types.END_EXPRESS_CHAT_FAILURE,
    error,
    request
  }),
  upChat: id => ({
    type: Types.UP_CHAT,
    id
  })
};
