import React from "react";
import { Box, Button, Text } from "ibolit-ui";
import styles from "./ErrorModal.scss";
import { ERROR_MODAL } from "~/locators/modal";
import ErrorSvg from "~/components/CallRoom/assets/Error";

export const CALL_ROOM_ERROR = "CALL_ROOM_ERROR";

const ErrorModal = ({ onCloseClick }) => (
  <Box className={styles.status_box} testId={ERROR_MODAL.BOX}>
    <ErrorSvg className={styles.status_img} />
    <Text
      tag="h3"
      variant="h3"
      maxLength={60}
      className={styles.status_header}
      testid={ERROR_MODAL.TITLE}
    >
      {"Хьюстон, у нас проблемы"}
    </Text>
    <Text
      tag="div"
      variant="desc"
      maxLength={60}
      className={styles.status_desc}
    >
      {
        "Не удалось подключится к видеоконсультации. Пожалуйста, попробуйте зайти еще раз!"
      }
    </Text>
    <Button
      fullWidth
      colorVariant="doctor"
      onClick={onCloseClick}
      testid={ERROR_MODAL.BUTTON}
    >
      Попробовать ещё раз
    </Button>
  </Box>
);

export default ErrorModal;
