import PropTypes from "prop-types";
import React from "react";
import Scrollbar from "~/components/Scrollbar";
import useInfiniteLoad from "~/components/List/useInfiniteLoad";
import Loading from "~/components/Loading/Loading";

const keyExtractor = (item, index) => {
  if (!item) return `loading_${index}`;
  return item.key || item.id || `item_${index}_no_key`;
};

const SimpleListContainer = ({
  items,
  loading,
  renderItem,
  loadMore,
  canLoadMore,
  onItemClick,
  selectedItemId,
  listRef,
  className,
  style,
  showSpinner,
  showHasTopOverflow,
  showHasBottomOverflow,
  onToggleScroll,
  onReady,
  minHeight,
  maxHeight
}) => {
  const scrollRef = listRef || React.createRef();
  const [onScrollFrame] = useInfiniteLoad({
    scrollRef,
    loading,
    loadMore,
    canLoadMore
  });
  return (
    <Scrollbar
      className={className}
      style={style}
      showHasTopOverflow={showHasTopOverflow}
      showHasBottomOverflow={showHasBottomOverflow}
      onToggleScroll={onToggleScroll}
      onScrollFrame={onScrollFrame}
      onReady={onReady}
      autoHeight={minHeight !== undefined || maxHeight !== undefined}
      autoHeightMin={minHeight}
      autoHeightMax={maxHeight}
      ref={scrollRef}
    >
      {items.map((item, index) =>
        renderItem({
          item,
          key: keyExtractor(item, index),
          onClick: onItemClick,
          isSelected: `${selectedItemId}` === `${item.id}`
        })
      )}
      {showSpinner && <Loading variant="row" height={80} />}
    </Scrollbar>
  );
};

SimpleListContainer.propTypes = {
  className: PropTypes.string,
  items: PropTypes.array.isRequired,
  loadMore: PropTypes.func,
  canLoadMore: PropTypes.bool,
  loading: PropTypes.bool,
  renderItem: PropTypes.func.isRequired,
  showHasTopOverflow: PropTypes.bool,
  showHasBottomOverflow: PropTypes.bool,
  style: PropTypes.object,
  onItemClick: PropTypes.func,
  selectedItemId: PropTypes.number
};

SimpleListContainer.defaultProps = {
  loadMore: () => {},
  style: {},
  showHasTopOverflow: true,
  showHasBottomOverflow: false
};

export default SimpleListContainer;
