import Immutable from "seamless-immutable";
import Types from "~/store/contacts/contactsTypes";

export const CHECK_EXISTS = "CHECK_EXISTS";
export const USER_FOUND = "USER_FOUND";
export const USER_ADD = "USER_ADD;";

const initialState = Immutable({
  isFetching: false,
  data: null,
  error: null,
  newContact: null,
  login: null,
  scene: CHECK_EXISTS
});

export default (state = initialState, action) => {
  switch (action.type) {
    // ******************************** start request ******************************** //
    case Types.SEARCH_REQUEST:
    case Types.ADD_NEW_REQUEST:
    case Types.ADD_BY_ID_REQUEST:
      return state.merge({
        isFetching: true,
        error: null,
        newContact: null
      });
    case Types.PRIVATE_COMMENT_SEND_REQUEST:
      return state.merge({
        isFetching: true,
        error: null
      });

    // ******************************** successful request ******************************** //
    case Types.SEARCH_SUCCESS:
    case Types.ADD_NEW_SUCCESS:
    case Types.ADD_BY_ID_SUCCESS:
      return state.merge({
        isFetching: false,
        error: null,
        newContact: action.payload
      });
    case Types.PRIVATE_COMMENT_SEND_SUCCESS:
      return state.merge(
        {
          isFetching: false,
          error: null,
          ...(action.payload || {})
        },
        { deep: true }
      );

    // ******************************** failed request ******************************** //
    case Types.SEARCH_FAILURE:
      return state.merge({
        isFetching: false,
        error: action.error,
        ...(action.request || {})
      });
    case Types.ADD_NEW_FAILURE:
    case Types.ADD_BY_ID_FAILURE:
    case Types.PRIVATE_COMMENT_SEND_FAILURE:
      return state.merge({
        isFetching: false,
        error: action.error,
        ...(action.payload || {})
      });

    // ******************************** other reducers ********************************//
    case Types.LOGOUT:
    case Types.CLEAR_STATE:
      return initialState;

    case Types.SET_INVITATION_SCENE:
      return state.merge({ scene: action.scene });

    default:
      return state;
  }
};
