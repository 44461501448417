import React from "react";
import PropTypes from "prop-types";
import { Text } from "ibolit-ui";
import cn from "classnames";
import styles from "./SlotItem.scss";

const SlotItem = ({ text, onClick, active = false, testid }) => (
  <button
    data-testid={testid}
    className={cn(styles.button, { [styles.active]: active })}
    type="button"
    onClick={onClick}
  >
    <Text variant="h5" className={cn({ [styles.text_active]: active })}>
      {text}
    </Text>
  </button>
);

SlotItem.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool
};

export default SlotItem;
