import React from "react";
import moment from "moment-timezone";
import TextInput from "~/components/inputs/TextInput";

const validator = ({ min, max, format }) => value => {
  let isValid;
  let message;
  const date = moment(value, format, true);
  try {
    switch (true) {
      case !date.isValid():
        throw "Дата не корректна";
      case min && date.isBefore(min):
        throw `Дата должна быть не ранее ${moment(min).format("ll")}`;
      case max && date.isAfter(max):
        throw `Дата должна быть не позднее ${moment(max).format("ll")}`;
      default:
        isValid = true;
    }
  } catch (e) {
    message = e;
    isValid = false;
  }
  return isValid || [false, message];
};

const DateInput = (
  { type, required = true, maxDate, minDate, ...props },
  inputRef
) => {
  const format = "DD.MM.YYYY";
  const inputProps = {
    validator: validator({
      type,
      required,
      max: maxDate,
      min: minDate,
      format
    }),
    mask: { pattern: "99.99.9999" }
  };

  maxDate && (inputProps.max = maxDate);
  minDate && (inputProps.min = minDate);

  return <TextInput ref={inputRef} {...inputProps} {...props} />;
};

export default React.forwardRef(DateInput);
