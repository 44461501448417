export const CONTACTS_LIST = {
  HEADER: "contacts__contactsList__header",
  INPUT_SEARCH: "contacts__contactsList__input-search",
  BUTTON_FILTER: "contacts__contactsList__button-filter",
  CONTAINER: "contacts__contactsList__container",
  USER_ITEM: "contacts__contactList__user-item",
  BUTTON_INVITE: "contacts__contactsList__button-invite",
  BUTTON_ROW_INVITE: "contacts__contactsList__button-row-invite"
};

export const CONTACT = {
  HEADER_DESCRIPTION: "contacts__contact__header-description",
  AVATAR: "contacts__contact__avatar",
  NAME: "contacts__contact__name",
  CREATED: "contacts__contact__created",
  SEND_MESSAGE: "contacts__contact__send-message",
  CONSULTATION: "contacts__contact__consultation",
  BUTTON_TARIFFS: "contacts__contact__button-tariffs",
  DESCRIPTION: "contacts__contact__description-wrapper",
  COMMENT: "contacts__contact__comment"
};

export const FILTERS = {
  ICON_CLOSE: "patients__filtersModal__iconClose",
  ICON_BACK: "patients__filtersModal__iconBack",
  HEADER: "patients__filtersModal__header",
  NO_SORTING: "patients__filtersModal__sort-no-sorting",
  BY_NAME: "patients__filtersModal__sort-by-name",
  BY_DATE: "patients__filtersModal__sort-by-date",
  HIDE_INACTIVE: "patients__filtersModal__hide-inactive",
  RESET: "patients__filtersModal__button-reset",
  SAVE: "patients__filtersModal__button-save"
};
