import React from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { getIsUserAuthorized } from "~/store/auth/authSelectors";
import IBolitAnimationLoader from "~/components/UI/IBolitAnimationLoader";
import NavigationBar from "~/layouts/App/components/NavigationBar";
import LightBoxProvider from "~/layouts/App/components/LightBoxProvider";
import HeaderWrapper from "./components/HeaderWrapper";
import InMaintain from "./components/InMaintain";
import styles from "./App.scss";

const App = ({ isAuthorized, pageName, children, isReady, inMaintain }) => (
  <>
    {inMaintain ? (
      <InMaintain />
    ) : (
      <LightBoxProvider>
        {isReady && (
          <div className={classNames(styles.base)}>
            <HeaderWrapper hidden={!isAuthorized || pageName === "notfound"}>
              <NavigationBar />
            </HeaderWrapper>
            <div className={styles.mainWrapper}>{children}</div>
          </div>
        )}
        {!isReady && <IBolitAnimationLoader fill="var(--blue)" />}
      </LightBoxProvider>
    )}
  </>
);

App.propTypes = {};

const appStateSelector = ({ auth, appStarted, router }) => {
  const path = router.location.pathname || "chats";
  const [pageName] = path.split("/");
  return {
    isAuthorized: getIsUserAuthorized({ auth }),
    pageName,
    isReady: appStarted.appReady,
    inMaintain: appStarted.getIn(["errors", "time", "status"]) === 500
  };
};

export default connect(appStateSelector)(App);
