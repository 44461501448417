import React from "react";
import styles from "./ChatDate.scss";

function ChatDate({ text }) {
  return (
    <div data-observer="target" className={styles.bubble}>
      {text}
    </div>
  );
}

export default ChatDate;
