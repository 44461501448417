import React from "react";
import classNames from "classnames";
import styles from "./HeaderWrapper.scss";

const HeaderWrapper = ({ className, style, hidden, children }) =>
  !hidden && (
    <header className={classNames(styles.container, className)} style={style}>
      {children}
    </header>
  );

export default HeaderWrapper;
