import React, { memo } from "react";
import MessagesList from "~/layouts/Chats/Chat/ChatMessagesList";
import ChatHeader from "./ChatHeader";
import styles from "./Chat.scss";
import ExpressChatShape from "./ExpressChat/ExpressChatShape";
import { passMoreThanHours } from "~/utils/dateUtils";
import { LAST_ASK_HOURS_DURATION } from "~/consts/chats";
import ChatQuestion from "~/layouts/Chats/Chat/ChatQuestion";
import ChatInput from "~/layouts/Chats/Chat/ChatInput";

const Chat = ({ chat, user, currentUserId, infoShown, showUserInfo }) => {
  const { id: chatId, tariff, count_new } = chat;
  const notAvailable = !user || user.is_deleted;
  const { can_close, service_patient_id, name } = tariff || {};
  const shouldAsk =
    user.is_primary &&
    (user.is_primary_at == null ||
      passMoreThanHours(user.is_primary_at, LAST_ASK_HOURS_DURATION));

  return (
    <div className={styles.container}>
      <ChatHeader
        partner={user}
        infoShown={infoShown}
        showUserInfo={showUserInfo}
      />
      {can_close && (
        <ExpressChatShape
          chatId={chatId}
          name={name}
          serviceId={service_patient_id}
        />
      )}
      <MessagesList
        chatId={chatId}
        chat={chat}
        partner={user}
        readOnly={notAvailable}
        countNew={count_new}
      />
      {!notAvailable && (
        <>
          {shouldAsk ? (
            <ChatQuestion id={user.id} />
          ) : (
            <ChatInput
              currentUserId={currentUserId}
              partner={user}
              chatId={chatId}
            />
          )}
        </>
      )}
    </div>
  );
};

export default memo(Chat);
