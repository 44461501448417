export default {
  SEARCH_REQUEST: "messages/SEARCH_REQUEST",
  SEARCH_SUCCESS: "messages/SEARCH_SUCCESS",
  SEARCH_FAILURE: "messages/SEARCH_FAILURE",
  SEND_REQUEST: "messages/SEND_REQUEST",
  SEND_SUCCESS: "messages/SEND_SUCCESS",
  SEND_FAILURE: "messages/SEND_FAILURE",
  ADD_OWN_ITEM: "messages/ADD_OWN_ITEM",
  UPDATE_CHATS_MESSAGES: "messages/UPDATE_CHATS_MESSAGES",
  READING_NEW_MESSAGE_REQUEST: "messages/READING_NEW_MESSAGE_REQUEST",
  READING_NEW_MESSAGE_SUCCESS: "messages/READING_NEW_MESSAGE_SUCCESS",
  READING_NEW_MESSAGE_FAILURE: "messages/READING_NEW_MESSAGE_FAILURE"
};
