import { takeLatest, call, put, all } from "redux-saga/effects";
import types from "~/store/feedback/feedbackTypes";
import actions from "~/store/feedback/feedbackAction";
import toastAction from "~/store/toast/toastActions";
import uiActions from "~/store/ui/uiActions";
import { sendFeedbackApi } from "~/api/feedbackApi";
import { ERROR_REQUEST_DEFAULT } from "~/consts/texts";

function* sendSaga({ values }) {
  try {
    yield call(sendFeedbackApi, values);

    yield put(actions.sendSuccess());
    yield put(actions.setDefaultState());
    yield put(uiActions.closeAllModal());
    yield put(toastAction.showSuccessToast("Сообщение успешно отправлено"));
  } catch (error) {
    yield put(actions.sendFailure(error));
    yield put(toastAction.showErrorToast(ERROR_REQUEST_DEFAULT));
  }
}

export default function* feedbackSaga() {
  yield all([yield takeLatest(types.FEEDBACK_SEND_REQUEST, sendSaga)]);
}
