import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Text, Icon } from "ibolit-ui";
import BlockHeader from "~/components/BlockHeader";
import { ScrollbarAutoSized } from "~/components/Scrollbar";
import usersActions from "~/store/users/usersActions";
import ConclusionHistory from "./ConclusionHistory";
import Loading from "~/components/Loading/Loading";
import PublicProfileContainer from "~/components/PublicProfile";
import conclusionsActions from "~/store/conclusions/conclusionsActions";
import useInfiniteLoad from "~/components/List/useInfiniteLoad";
import styles from "./PatientInfo.scss";

const PatientInfo = ({
  conclusions,
  page,
  canLoadMore,
  isConclusionsRequest,
  userId,
  handleClose,
  userInfoRequest,
  conclusionsRequest
}) => {
  const scrollRef = useRef();

  useEffect(() => {
    if (userId) {
      userInfoRequest(userId);
      conclusionsRequest(userId, 1);
    }
  }, [userId]);

  const [needLoad, setNeedLoad] = useState();

  useEffect(() => {
    if (needLoad && !isConclusionsRequest) {
      conclusionsRequest(userId, page + 1);
    }
  }, [needLoad]);

  useEffect(() => {
    if (!isConclusionsRequest) {
      setNeedLoad(false);
    }
  }, [isConclusionsRequest]);

  const [onScrollFrame] = useInfiniteLoad({
    scrollRef,
    loading: isConclusionsRequest,
    loadMore: () => conclusionsRequest(userId, page + 1),
    canLoadMore
  });

  return (
    <>
      <BlockHeader
        testid="patient_info"
        title={
          <Text variant="h3" colorVariant="tertiary">
            Информация о пациенте
          </Text>
        }
        titlePosition="left"
        renderRight={() => <Icon variant="close" onClick={handleClose} />}
        className={styles.mainHeader}
      />
      {userId && (
        <ScrollbarAutoSized
          ref={scrollRef}
          showHasTopOverflow
          onScrollFrame={onScrollFrame}
        >
          <PublicProfileContainer
            key={userId}
            userId={userId}
            variant="inChat"
          />
          {conclusions && conclusions.length > 0 && (
            <ConclusionHistory items={conclusions} />
          )}
          {isConclusionsRequest && <Loading variant="row" height={80} />}
        </ScrollbarAutoSized>
      )}
    </>
  );
};

const mapStateToProps = (
  { chats: { items: chats }, conclusions },
  { chatId }
) => {
  const userId = chats.getIn([chatId, "user"]);
  const { isFetching, page, canLoadMore, items = {}, order = [] } =
    conclusions[userId] || {};
  return {
    page,
    userId,
    canLoadMore,
    isConclusionsRequest: isFetching,
    conclusions: order.map(cId => items[cId])
  };
};

export default connect(mapStateToProps, {
  userInfoRequest: usersActions.userInfoRequest,
  conclusionsRequest: conclusionsActions.searchRequest
})(PatientInfo);
