import React from "react";

import { ConsultationInfo, ConsultationAnamnesis } from "ibolit-components";

import styles from "../ConsultationPage.scss";

export const ConsultationAnamnesisPage = ({
  consultationProblemData,
  consultationStatusInfo,
  onEndConsultation,
  isAvailableEndConsultation
}) => {
  return (
    <div className={styles.consultationContainer}>
      <ConsultationInfo {...consultationStatusInfo} iconFill="#00B5CE" />
      <ConsultationAnamnesis
        problemTitle={consultationProblemData.title}
        daysCount={consultationProblemData.days}
        discomfortLevel={consultationProblemData.discomfort_level}
        type="doctor"
        onEndConsultation={onEndConsultation}
        isAvailableEndConsultation={isAvailableEndConsultation}
      />
    </div>
  );
};
