import React from "react";
import { Switch, Route } from "react-router-dom";
import MainLayout, { Aside, Section } from "~/components/layouts/Main/Main";
import EmptyStub from "~/components/EmptyStub";
import PublicProfileContainer from "~/components/PublicProfile/PublicProfileContainer";
import ContactAside from "~/layouts/Contacts/ContactAside/ContactAside";

const ContactsContainer = ({ match }) => (
  <MainLayout mode="wide">
    <Aside>
      <ContactAside />
    </Aside>
    <Section>
      <Switch>
        <Route exact path={match.url}>
          <EmptyStub key="stub" text={"Выберите пациента в левой\nколонке"} />
        </Route>
        <Route path={`${match.url}/:contactId`}>
          {({
            match: {
              params: { contactId }
            }
          }) => <PublicProfileContainer userId={+contactId} />}
        </Route>
      </Switch>
    </Section>
  </MainLayout>
);

export default ContactsContainer;
