import { createSelector } from "reselect";
import get from "lodash/get";
import {
  CODE_CONFIRM_EMAIL_MSG,
  CODE_CONFIRM_EMAIL_TITLE,
  CODE_CONFIRM_SMS_MSG,
  CODE_CONFIRM_SMS_TITLE,
  OFFLINE_TARIFF_TEXT,
  ONLINE_TARIFF_TEXT,
  PROFILE_CHANGE_SETTINGS_VIEW
} from "~/consts/profile";
import { getIsEmail as emailCheker } from "~/utils/validations";

export const getUser = state => state.profile.user;
export const getIsLoadingChangePassword = state =>
  state.profile.loadingChangePassword;
export const getIsLoadingChangeLogin = state =>
  state.profile.loadingChangeLogin;
export const getIsLoadingConfirm = state => state.profile.loadingConfirm;

export const getPhoneOrEmail = state => {
  const settingsLogin = state.profile.settingsLogin.login;
  const userPhone = state.profile.user.phone;

  const isEmail = emailCheker(settingsLogin);

  if (isEmail) {
    return userPhone;
  } else {
    return settingsLogin;
  }
};

export const getSettingsLogin = state => state.profile.settingsLogin.login;

export const getUserPhone = state => state.profile.user.phone;

export const getSettingsCodeId = state => state.profile.settingsLogin.codeId;
export const getSettingsLoginPassword = state =>
  state.profile.settingsLogin.password;
export const getSettingsBackScene = state => state.profile.settingsBackScene;
export const getSettingsConfirmTimeout = state =>
  state.profile.settingsConfirmTimeout;
export const getSettingsConfirmCode = state =>
  state.profile.settingsConfirmCode;
const getTariffs = state => state.profile.tariffs;
export const getProfileSettingsScene = state => {
  const views = get(state, "ui.modal.views", []);
  const view = views.find(
    item => item.view === PROFILE_CHANGE_SETTINGS_VIEW
  ) || {
    props: { scene: null }
  };
  return view.props.scene;
};

export const getTariffsLoading = state => state.profile.loadingTariffs;

export const getConsultationRatingCount = state =>
  state.profile.user?.consultation_ratings_count;

export const getAvailableTariffs = createSelector(getTariffs, tariffs => ({
  online: tariffs.av || [],
  offline: tariffs.offline || []
}));

export const getDefaultTariff = createSelector(getAvailableTariffs, tariffs =>
  [...tariffs.online, ...tariffs.offline].find(tariff => tariff.is_default)
);

export const getTariffType = type => (type === "offline" ? type : "online");

// @param type {string} - tariff's type 'offline' | other
export const getTariffTextByType = type =>
  getTariffType(type) === "offline" ? OFFLINE_TARIFF_TEXT : ONLINE_TARIFF_TEXT;

const getIsEmailType = target => target === "email";

export const getConfirmTarget = state => {
  const phone = get(state, ["profile", "user", "phone"], false);
  if (phone) {
    return "phone";
  }

  const email = get(state, ["profile", "user", "email"], false);
  if (email) {
    return "email";
  }

  return "none";
};

export const getConfirmChangeLogin = createSelector(
  getUser,
  getConfirmTarget,
  getSettingsLogin,
  (user, target, login) => {
    if (!user || target === "none") {
      return login;
    }

    return user[target];
  }
);

export const getUpdatedLogin = createSelector(getSettingsLogin, login =>
  emailCheker(login) ? { email: login } : { phone: login }
);

export const getConfirmMsg = createSelector(
  getConfirmTarget,
  getSettingsBackScene,
  (target, backScene) => {
    const str = getIsEmailType(target)
      ? CODE_CONFIRM_EMAIL_MSG
      : CODE_CONFIRM_SMS_MSG;
    const replacer = backScene === "email" ? "e-mail" : "номера";
    return str.replace("%s", replacer);
  }
);

export const getConfirmTitle = createSelector(getSettingsLogin, login =>
  emailCheker(login) ? CODE_CONFIRM_EMAIL_TITLE : CODE_CONFIRM_SMS_TITLE
);

export const getConfirmLabelMsg = createSelector(
  getConfirmTarget,
  target => `Введите код из ${getIsEmailType(target) ? "письма" : "СМС"}`
);
