import { all, put, takeEvery, takeLatest } from "redux-saga/effects";
import api from "~/api/toolbarApi";
import Types from "~/store/toolbar/toolbarTypes";
import Actions from "~/store/toolbar/toolbarActions";
import normalizeToolbar from "~/store/toolbar/toolbarNormalizer";
import webSocketTypes from "~/store/webSocket/webSocketTypes";

function* getToolbarStateSaga() {
  try {
    const {
      data: { data }
    } = yield api.getToolbarStateApi();
    yield put(Actions.setToolbar(normalizeToolbar(data)));
  } catch (e) {
    yield put({ type: `@@err/GET_TOOLBAR_STATE`, e });
  }
}

export function* processWsToolbarSaga({ event }) {
  try {
    yield put(Actions.updateToolbar(normalizeToolbar(event)));
  } catch (e) {
    yield put({
      type: `@@err/PROCESS_WS_TOOLBAR`,
      e
    });
  }
}

export default function*() {
  yield all([
    takeLatest(Types.GET_TOOLBAR, getToolbarStateSaga),
    takeEvery(webSocketTypes.WS_TOOLBAR_UPDATE, processWsToolbarSaga)
  ]);
}
