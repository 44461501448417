// https://www.freeformatter.com/mime-types-list.html
const AVAILABLE_MIME_EXTENSION_TYPES = {
  jpeg: "image/jpeg",
  png: "image/png",
  gif: "image/gif",
  zip: "application/zip",
  pdf: "application/pdf",
  doc: "application/msword",
  xls: "application/excel",
  rtf: "application/rtf",
  txt: "text/plain",
  mp4: "video/mp4",
  avi: "video/avi",
  mov: ".mov",
  wmv: ".wmv",
  "3gp": ".3gp",
  docx: ".docx",
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  webm: "video/webm"
};

export default Object.keys(AVAILABLE_MIME_EXTENSION_TYPES)
  .map(key => AVAILABLE_MIME_EXTENSION_TYPES[key])
  .join(",");
