import React, { useCallback } from "react";
import { Button, Dialog } from "ibolit-ui";
import { useDispatch, useSelector } from "react-redux";
import UserItem from "~/components/UserItem";
import { INVITATION_MODAL, PATIENT_FOUND } from "~/locators/invitation";
import { getNewContact } from "~/store/contacts/contactsSelector";
import contactsActions from "~/store/contacts/contactsActions";
import Loading from "~/components/Loading/Loading";
import { CHECK_EXISTS } from "~/store/contacts/contactsReducer";
import styles from "./InvitationModal.scss";

const UserFound = ({ testId, loading, onCloseClick }) => {
  const dispatch = useDispatch();
  const newContact = useSelector(getNewContact);
  const onSubmitClick = () => {
    dispatch(contactsActions.addByIdRequest(newContact.id));
  };
  const onCancelClick = useCallback(() => {
    dispatch(contactsActions.setInvitationScene(CHECK_EXISTS));
  }, []);

  return (
    <Dialog
      testId={testId}
      header="Найден пациент"
      boxClassName={styles.modalBox}
      onCloseClick={onCloseClick}
      onBackClick={onCancelClick}
      closeTestId={INVITATION_MODAL.ICON_CLOSE}
      backTestId={INVITATION_MODAL.ICON_BACK}
    >
      {newContact ? (
        <UserItem
          testid={PATIENT_FOUND.USER_CARD}
          user={newContact}
          variant="shape"
          isUserFromStore={false}
        />
      ) : (
        <div style={{ display: "flex", flex: 1 }} />
      )}
      <div className={styles.field}>
        <Button
          className={styles.controlButton}
          testid={PATIENT_FOUND.CANCEL}
          variant="secondary"
          onClick={onCancelClick}
        >
          Отмена
        </Button>
        <Button
          className={styles.controlButton}
          testid={PATIENT_FOUND.ADD}
          onClick={onSubmitClick}
        >
          Добавить
        </Button>
      </div>
      <Loading showLoading={loading} />
    </Dialog>
  );
};

export default UserFound;
