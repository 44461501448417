import apiInstance from "~/api/httpService";

export const CallLogEvents = {
  // Our MCS + Vox instance login
  // СДК инициализировалось, все хорошо.
  initializedVox: "initializedVox",
  // Смогли подключиться к балансеру/серверу VoxImplant
  connectedToVox: "connectedToVox",
  // Cмог залогиниться в аккаунт вокса
  loggedIn: "loggedIn",
  // Отвалилось в процессе авторизации - ошибка в нашем флоу юзернейм/onetimetoken/логинить по нему в вокс
  joinFailure: "joinFailure",
  // Call events based on Vox Event listeners.
  // Cоединение установилось, начался процесс установление связи
  callConnected: "callConnected",
  // Звонок разъединился, без ошибок.
  callDisconnected: "callDisconnected",
  // Звонок разъединился. Тут в информации эвента должно быть рассказано почему reason + errorCode
  callFailed: "callFailed",
  // В звонок добавилось локальное видео пользователя (ни на что влияет по идее)
  callLocalVideoAdded: "callLocalVideoAdded",
  // Из звонка убралось локальное видео пользователя (ни на что влияет по идее)
  callLocalVideoRemoved: "callLocalVideoRemoved",
  // В звонок добавился endpoint - это а-ля сущность "собеседник" в контексе voximplant.
  // На этом endpoint и происходят эвенты remotevideoadded/removed
  callEndpointAdded: "callEndpointAdded",
  // Из звонка убрался endpoint -> "собеседник" ливнул из конференции.
  callEndpointRemoved: "callEndpointRemoved",
  // В звонок добавилось видео от собеседника (он его включил/было включено)
  callRemoteVideoAdded: "callRemoteVideoAdded",
  // Из звонка убралось видео от собеседника (он его выключил)
  callRemoteVideoRemoved: "callRemoteVideoRemoved",
  // Other stuff
  // Участник терял интернет, он восстановился
  // В вебе автоматически реконнектимся, в мобилках руками.
  callTryReconnect: "callTryReconnect",
  // Участник нажал кнопку "покинуть звонок" в приложении.
  callLeave: "callLeave",
  // Web Only.
  // Так как в вебсдк iceCompleted/iceTimeout deprecated, лезем как воксы напрямую в PC
  // И смотрим примерно похожие эвенты оттуда.
  // Все хорошо - когда все закончилось на
  // ConnectionState === 'connected'
  // IceGatheringState ==== 'completed'
  // IceConnectionStateChange ==== 'connected'
  callPCConnectionStateChange: "callPCConnectionStateChange",
  callPCIceGatheringStateChange: "callPCIceGatheringStateChange",
  callPCIceConnectionStateChange: "callPCIceConnectionStateChange",
  // Mobile Only
  callIceCompleted: "callIceCompleted", // ICE прошел хорошо, сейчас начнутся отправляться потоки с видео
  callIceTimeout: "callIceTimeout", // ICE не прошел, видео не будет, алярм (ВПН/Роутеры ломают флоу)
};

// type LogCallEventApiParams = {
//   name: string;
//   call_id: number;
//   user_id?: number;
//   metadata?: string | Record<string, any>;
//   microtime: number;
// };

const logCallEventApi = (params) => apiInstance.post("/v3/events/call", params);

// type LogCallEventParams = {
//   event: string;
//   eventData?: string | Record<string, any>;
// };

export const createCallEventLogger =
  (consultationId, userId) =>
  ({ event, eventData }) => {
    const payload = {
      name: event,
      call_id: consultationId,
      user_id: userId,
      metadata: eventData,
      microtime: new Date().getTime(),
    };
    logCallEventApi(payload)
      .then(() => console.log("Successfully logged"))
      .catch(() => console.log("Error in sending log"));
  };

export const addPeerConnectionStateLogging = (peerConnection, logger) => {
  peerConnection.addEventListener("connectionstatechange", () => {
    logger({
      event: CallLogEvents.callPCConnectionStateChange,
      eventData: peerConnection.connectionState,
    });
  });
  peerConnection.addEventListener("icegatheringstatechange", () => {
    logger({
      event: CallLogEvents.callPCIceGatheringStateChange,
      eventData: peerConnection.iceGatheringState,
    });
  });
  peerConnection.addEventListener("iceconnectionstatechange", () => {
    logger({
      event: CallLogEvents.callPCIceConnectionStateChange,
      eventData: peerConnection.iceConnectionState,
    });
  });
};
