import React from "react";
import { toast } from "react-toastify";
import { fork, take, takeLatest } from "redux-saga/effects";
import _ from "lodash";
import { Toast } from "ibolit-ui";
import contactsTypes from "~/store/contacts/contactsTypes";
import profileTypes from "~/store/profile/profileTypes";
import chatsTypes from "~/store/chats/chatsTypes";
import usersTypes from "~/store/users/usersTypes";
import toastTypes from "~/store/toast/toastTypes";
import { ERROR_REQUEST_DEFAULT } from "~/consts/texts";

const [ERROR, SUCCESS] = ["error", "success"];

export function showToast(_message, variant = SUCCESS) {
  const [message, options = {}] = _.castArray(_message);
  if (message && typeof message === "string" && message.length) {
    toast[variant](
      <Toast
        variant={variant}
        text={message}
        dataTestId={`toast-${variant}`}
      />,
      {
        containerId: "toast",
        ...options
      }
    );
  }
}

function showCommentMessage({ request: { comment } }) {
  showToast(
    `Комментарий успешно ${_.isEmpty(comment) ? "удален" : "сохранен"}`,
    SUCCESS
  );
}

function* watcher() {
  const success = {
    [usersTypes.USER_CONCLUSIONS_LIST_SUCCESS]:
      "Вы успшено создали заявку на консультацию"
  };

  const errors = {
    [contactsTypes.PRIVATE_COMMENT_SEND_FAILURE]:
      "Не удалось сохранить коментарий",
    [chatsTypes.INFO_FAILURE]: "Чат не найден",
    [usersTypes.USER_INFO_FAILURE]: "Пациент не найден",
    [contactsTypes.SEARCH_FAILURE]: null,
    400: "Ошибка 400 (Неверный запрос)",
    401: "Ошибка 401 (Не авторизован)",
    402: "Ошибка 402 (Необходима оплата)",
    403: "Ошибка 403 (Запрещено)",
    404: "Ошибка 404 (Не найдено)",
    405: "Ошибка 405 (Метод не поддерживается)",
    408: "Ошибка 408 (Истекло время ожидания)",
    413: "Ошибка 413 (Размер запроса слишком велик)",
    414: "Ошибка 414 (Запрашиваемый URI слишком длинный)",
    422: "Ошибка 422 (Ошибка валидации)",
    429: "Ошибка 429 (Слишком много запросов)",
    500: "Ошибка 500 (Ошибка сервера)",
    503: "Ошибка 503 (Сервис недоступен)"
  };
  while (true) {
    const { type, error, ...eventRest } = yield take("*");
    if (/@@|AUTHORIZATION|TIME_FAILURE/i.test(type)) {
      // skip internal errors (@@API_ERROR)
    } else if (/_success/i.test(type)) {
      showToast(success[type] || eventRest.message, SUCCESS);
    } else if (error) {
      // console.info(`error eventRest #`, type, error, eventRest);
      if (
        errors[type] === undefined &&
        error.status &&
        typeof error.status === "number"
      ) {
        showToast(errors[error.status], ERROR);
      } else {
        showToast(errors[type] || _.get(error, "message"), ERROR);
      }
    }
  }
}

function onSuccessSettingsSave() {
  showToast("Настройки успешно сохранены");
}

function onErrorSettingsSave() {
  showToast(ERROR_REQUEST_DEFAULT, ERROR);
}

const showSuccessToast = ({ msg }) => {
  showToast(msg);
};

const showErrorToast = ({ msg }) => {
  showToast(msg, ERROR);
};

export default [
  fork(watcher),
  takeLatest(contactsTypes.PRIVATE_COMMENT_SEND_SUCCESS, showCommentMessage),
  takeLatest(toastTypes.TOAST_SHOW_SUCCESS, showSuccessToast),
  takeLatest(toastTypes.TOAST_SHOW_ERROR, showErrorToast),
  takeLatest(
    [
      profileTypes.PROFILE_USER_DATA_UPDATE_SUCCESS,
      profileTypes.PROFILE_PASSWORD_CHANGE_SUCCESS,
      profileTypes.PROFILE_SETTINGS_CONFIRM_SUCCESS
    ],
    onSuccessSettingsSave
  ),
  takeLatest(
    [
      profileTypes.PROFILE_USER_DATA_UPDATE_ERROR,
      profileTypes.PROFILE_PASSWORD_CHANGE_ERROR,
      profileTypes.PROFILE_LOGIN_CHANGE_ERROR,
      profileTypes.PROFILE_SETTINGS_CODE_SEND_ERROR,
      profileTypes.PROFILE_SETTINGS_CONFIRM_ERROR,
      chatsTypes.END_EXPRESS_CHAT_FAILURE
    ],
    onErrorSettingsSave
  )
];
