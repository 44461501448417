import React from "react";
import { Button, Text } from "ibolit-ui";
import styles from "./ChatQuestion.scss";
import actions from "~/store/users/usersActions";
import { useAction } from "~/hooks/useAction";

const ChatQuestion = ({ id }) => {
  const confirmClick = useAction(actions.confirmNewPatient);
  return (
    <div className={styles.container}>
      <div className={styles.titles}>
        <Text variant="h4">Пациент был у вас на приеме ?</Text>
        <Text variant="desc">
          Поставить диагноз или назначить лечение можно пациентам, которые были
          на очном приеме
        </Text>
      </div>
      <div className={styles.controls}>
        <Button
          className={styles.controlsButton}
          onClick={() => confirmClick(id, false)}
          variant="tertiary"
        >
          Да
        </Button>
        <Button
          className={styles.controlsButton}
          onClick={() => confirmClick(id, true)}
          variant="tertiary"
        >
          Нет
        </Button>
      </div>
    </div>
  );
};

export default ChatQuestion;
