import Types from "./usersTypes";
import { actions } from "~/store/users/usersModel";

export default {
  ...actions,

  userConclusionsListRequest: (id, page = 1, limit = 20) => ({
    type: Types.USER_CONCLUSIONS_LIST_REQUEST,
    request: { id, page, limit }
  }),
  userConclusionsListSuccess: (payload, request, rest) => ({
    type: Types.USER_CONCLUSIONS_LIST_SUCCESS,
    payload,
    request,
    rest
  }),
  userConclusionsListFailure: (error, request) => ({
    type: Types.USER_CONCLUSIONS_LIST_FAILURE,
    error,
    request
  }),

  userInfoRequest: id => ({
    type: Types.USER_INFO_REQUEST,
    request: { id }
  }),
  userInfoSuccess: (payload, request, rest) => ({
    type: Types.USER_INFO_SUCCESS,
    payload,
    request,
    rest
  }),
  userInfoFailure: (error, request) => ({
    type: Types.USER_INFO_FAILURE,
    error,
    request
  }),

  userCheckNewRequest: id => ({
    type: Types.USER_CHECK_NEW_REQUEST,
    request: { id }
  }),
  userCheckNewSuccess: (payload, request, rest) => ({
    type: Types.USER_CHECK_NEW_SUCCESS,
    payload,
    request,
    rest
  }),
  userCheckNewFailure: (error, request) => ({
    type: Types.USER_CHECK_NEW_FAILURE,
    error,
    request
  }),

  upsertUser: user => ({
    type: Types.UPSERT_USER,
    user
  }),

  selectUser: userId => ({
    type: Types.SET_SELECTED,
    userId
  }),
  confirmNewPatient: (id, is_primary) => ({
    type: Types.NEW_PATIENT_CONFIRM_REQUEST,
    id,
    is_primary
  }),
  confirmNewPatientSuccess: () => ({
    type: Types.NEW_PATIENT_CONFIRM_SUCCESS
  }),
  confirmNewPatientError: () => ({
    type: Types.NEW_PATIENT_CONFIRM_ERROR
  })
};
