import Immutable from "seamless-immutable";
import Types from "~/store/messages/messagesTypes";
import {
  searchSuccessReducer,
  searchRequestReducer,
  searchFailureReducer
} from "~/store/utils";

const initialState = Immutable({});
const getInitialState = () => Immutable({});
const getChatInitialState = () => Immutable({ order: [], items: {} });

export const addOwnMessageReducer = (state, { chatId, ...message }) => {
  return state.update(chatId, (oldChat = getChatInitialState()) => {
    const { client_message_key: id } = message;
    return oldChat
      .update("order", oldOrder => [id, ...oldOrder])
      .update("items", oldItems => oldItems.merge({ [id]: message }));
  });
};

const updateOrder = (oldOrder, client_message_key, id) => {
  if (oldOrder.includes(client_message_key)) {
    const targetIndex = oldOrder.indexOf(client_message_key);
    return [
      ...oldOrder.slice(0, targetIndex),
      id,
      ...oldOrder.slice(targetIndex + 1)
    ];
  }
  if (oldOrder.includes(id)) {
    return oldOrder;
  }

  return [id, ...oldOrder];
};

export const updateMessageReducer = (
  chatState,
  { client_message_key, ...message }
) => {
  const { id } = message;
  if (client_message_key === id) {
    return chatState.update("items", oldItems =>
      oldItems.merge({ [id]: message })
    );
  }
  return chatState
    .update("order", oldOrder => updateOrder(oldOrder, client_message_key, id))
    .update("items", oldItems =>
      oldItems.merge({ [id]: message }).without(client_message_key)
    );
};

export const updateMessagesReducer = (state = getInitialState(), { payload }) =>
  Object.values(payload).reduce(
    (accState, { chatId, ...message }) =>
      accState.update(chatId, (oldChat = getChatInitialState()) =>
        updateMessageReducer(oldChat, message)
      ),
    state
  );

export default (state = initialState, action) => {
  switch (action.type) {
    case Types.SEARCH_REQUEST:
      return state.update(action.request.chatId, (localState = initialState) =>
        searchRequestReducer(localState, action)
      );

    case Types.SEARCH_SUCCESS:
      return state.update(action.request.chatId, localState =>
        searchSuccessReducer(localState, action)
      );

    case Types.SEARCH_FAILURE:
      return state.update(action.request.chatId, localState =>
        searchFailureReducer(localState, action)
      );

    case Types.ADD_OWN_ITEM:
      return addOwnMessageReducer(state, action.message);

    case Types.UPDATE_CHATS_MESSAGES:
      return updateMessagesReducer(state, action);

    default:
      return state;
  }
};
