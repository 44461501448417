import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { Icon, Text } from "ibolit-ui";
import styles from "./ProblemStatus.scss";
import Analytics from "~/utils/analytics";

export const DOCTOR_PROBLEM_STATUSES = [
  {
    icon: "diagnostics-assigned",
    title: "Назначена диагностика",
    value: "diagnostics_assigned"
  },
  {
    icon: "appointed-offline-reception",
    title: "Назначен очный прием",
    value: "appointed_offline_reception"
  },
  {
    icon: "treatment-prescribed",
    title: "Рекомендовано лечение",
    value: "treatment_prescribed"
  },
  {
    icon: "treatment-completed",
    title: "Лечение завершено",
    value: "treatment_completed"
  }
];
export const PATIENT_PROBLEM_STATUSES = [
  {
    icon: "primary-appointment",
    title: "Первичная консультация",
    value: "primary_appointment"
  },
  {
    icon: "transcript-of-analyzes",
    title: "Расшифровка анализов",
    value: "transcript_of_analyzes"
  },
  {
    icon: "repeated-appointment",
    title: "Повторная консультация",
    value: "repeated_appointment"
  },
  {
    icon: "second-opinion",
    title: "Второе мнение",
    value: "second_opinion"
  }
];
export const BACKEND_PROBLEM_STATUSES = [
  {
    icon: "treatment-completed",
    title: "Жалоба закрыта",
    value: "closed"
  }
];

export const LEGACY_PROBLEM_STATUSES = [
  {
    icon: "preliminary-diagnosis-made",
    title: "Предварительный диагноз",
    value: "preliminary_diagnosis_made"
  }
];

export const COMPLETE_PROBLEM_LIST = [
  ...DOCTOR_PROBLEM_STATUSES,
  ...PATIENT_PROBLEM_STATUSES,
  ...BACKEND_PROBLEM_STATUSES,
  ...LEGACY_PROBLEM_STATUSES
];

export function ProblemStatus({
  problemStatus,
  setProblemStatus,
  consultationId,
  problemId
}) {
  function selectProblem(problem) {
    return () => {
      if (setProblemStatus) {
        setProblemStatus(problem);
        Analytics.trackEvent("Chose Treatment Status", {
          status: problem,
          consultationId,
          problemId
        });
      }
    };
  }

  return (
    <>
      <Text variant="h4" className={styles.label} testid="ProblemStatus__label">
        Статус лечения пациента
      </Text>
      <div className={styles.problemStatuses}>
        {DOCTOR_PROBLEM_STATUSES.map(item => (
          <div
            data-testid="problem-box"
            key={item.value}
            onClick={selectProblem(item.value)}
            className={cn(styles.statusBox, {
              [styles.active]: item.value === problemStatus
            })}
          >
            <Icon
              testid="problem-box-icon"
              sizeValue="40px"
              variant={item.icon}
              fill={
                item.value === problemStatus ? "var(--white)" : "var(--blue)"
              }
            />
            <div className={styles.statusTitle}>{item.title}</div>
          </div>
        ))}
      </div>
    </>
  );
}

ProblemStatus.propTypes = {
  problemStatus: PropTypes.string,
  setProblemStatus: PropTypes.func,
  consultationId: PropTypes.number,
  problemId: PropTypes.number
};
