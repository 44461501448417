import React from "react";
import PropTypes from "prop-types";
import { Text, Separator } from "ibolit-ui";
import RowWithImage from "~/components/RowWithImage";
import UserAvatar from "~/components/UserAvatar";
import styles from "./ProfileHeader.scss";

function ProfileTitle({ fullName }) {
  return (
    <Text variant="h2" tag="h2">
      {fullName}
    </Text>
  );
}

ProfileTitle.propTypes = {
  fullName: PropTypes.string
};

function ProfileHeader({ user }) {
  return (
    <>
      <RowWithImage
        title={<ProfileTitle fullName={user.full_name} />}
        image={<UserAvatar size="large" user={user} testid="PROFILE_HEADER__Avatar"/>}
        info={[...user.specialties].filter(Boolean).join(", ")}
        testid="ProfileTitle"
      />
      <Separator className={styles.separator} />
    </>
  );
}

ProfileHeader.propTypes = {
  user: PropTypes.shape({
    active: PropTypes.bool,
    avatar: PropTypes.string,
    category: PropTypes.string,
    clinic_name: PropTypes.string,
    created_at: PropTypes.string,
    date_of_birth: PropTypes.string,
    description: PropTypes.string,
    education: PropTypes.string,
    email: PropTypes.string,
    experience: PropTypes.number,
    fingerprint: PropTypes.bool,
    full_name: PropTypes.string,
    gender: PropTypes.string,
    id: PropTypes.number,
    is_any_message_exist: PropTypes.bool,
    is_chat_free: PropTypes.bool,
    is_deleted: PropTypes.bool,
    is_new: PropTypes.bool,
    last_entered: PropTypes.string,
    next_activity_at: PropTypes.string,
    online: PropTypes.bool,
    percent_income_chat: PropTypes.number,
    percent_income_online: PropTypes.number,
    phone: PropTypes.string,
    primary_acceptance: PropTypes.bool,
    self_appointment: PropTypes.bool,
    send_email: PropTypes.bool,
    send_sms: PropTypes.bool,
    should_show_stats: PropTypes.bool,
    specialties: PropTypes.arrayOf(PropTypes.string),
    token: PropTypes.string,
    type: PropTypes.string,
    updated_at: PropTypes.string
  })
};

export default ProfileHeader;
