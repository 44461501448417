import { connect } from "react-redux";
import { withFormik } from "formik";
import actions from "~/store/profile/profileAction";
import {
  getConfirmLabelMsg,
  getConfirmMsg,
  getConfirmTitle,
  getIsLoadingConfirm,
  getSettingsConfirmCode,
  getSettingsConfirmTimeout,
  getPhoneOrEmail
} from "~/store/profile/profileSelectors";
import ConfirmForm from "../components/ConfirmForm";
import formatCode from "~/utils/formatCode";

const CODE_LENGTH = 4;

const validate = values => {
  const errors = {};

  if (!values.code) {
    errors.code = "Это поле обязательно для заполнения";
  } else if (values.code.length < CODE_LENGTH) {
    errors.code = "Код не должен быть меньше 4 символов";
  }

  return errors;
};

const mapPropsToValues = ({ code = "" }) => ({
  code
});

const handleSubmit = ({ code }, bag) => {
  bag.props.onSubmit({ code, setErrors: bag.setErrors });
};

const FormikForm = withFormik({
  mapPropsToValues,
  validate,
  handleSubmit
})(ConfirmForm);

const CodeInputPanelContainer = connect(
  state => ({
    code: getSettingsConfirmCode(state),
    msgText: getConfirmMsg(state),
    msgHeader: getPhoneOrEmail(state),
    msgLabel: getConfirmLabelMsg(state),
    loading: getIsLoadingConfirm(state),
    timeout: getSettingsConfirmTimeout(state),
    title: getConfirmTitle(state),
    mask: formatCode
  }),
  {
    onBackClick: actions.goBack,
    handleResend: actions.sendCodeRequest,
    onSubmit: actions.confirmRequest
  }
)(FormikForm);

export default CodeInputPanelContainer;
