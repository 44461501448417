import React, { memo } from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { Link } from "react-router-dom";
import BaseLogo from "~/assets/images/logo.svg";
import Extra from "~/assets/images/logo_doctor_extra.svg";
import styles from "./Logo.scss";

const Logo = ({ variant, size, href, onClick, className, fill, testid }) => {
  const color = "var(--blue)";
  const Wrapper = href ? Link : "div";
  return (
    <Wrapper
      {...(href && { to: href })}
      className={cn(
        styles.container,
        {
          [styles.clickable]: !!(href || onClick)
        },
        className
      )}
      data-testid={testid}
      onClick={onClick}
    >
      <svg
        viewBox={BaseLogo.viewBox}
        fill={fill || color}
        className={cn(styles[variant], styles[size])}
      >
        <use xlinkHref={`#${BaseLogo.id}`} />
      </svg>
      {variant === "full" && (
        <svg
          viewBox={Extra.viewBox}
          fill={fill || color}
          className={cn(styles.extraContainer)}
        >
          <use xlinkHref={`#${Extra.id}`} />
        </svg>
      )}
    </Wrapper>
  );
};

Logo.defaultProps = {
  variant: "default",
  size: "m"
};

Logo.propTypes = {
  /** Вариант лого */
  variant: PropTypes.oneOf(["default", "full"]),
  /** Устанавливает размер иконки */
  size: PropTypes.oneOf(["m", "l"]),
  /** Устанавливает ссылку для лого */
  href: PropTypes.string,
  /** Устанавливает обработчик клика */
  onClick: PropTypes.func,
  /** Передает класс для контейнера */
  className: PropTypes.string,
  /** Заливает иконку цветом */
  fill: PropTypes.string
};

export default memo(Logo);
