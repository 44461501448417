import { requestTypes } from "~/store/users/usersModel";

export default {
  ...requestTypes,

  USER_INFO_REQUEST: "users/USER_INFO_REQUEST",
  USER_INFO_SUCCESS: "users/USER_INFO_SUCCESS",
  USER_INFO_FAILURE: "users/USER_INFO_FAILURE",

  USER_CHECK_NEW_REQUEST: "users/USER_CHECK_NEW_REQUEST",
  USER_CHECK_NEW_SUCCESS: "users/USER_CHECK_NEW_SUCCESS",
  USER_CHECK_NEW_FAILURE: "users/USER_CHECK_NEW_FAILURE",

  USER_CONCLUSIONS_LIST_REQUEST: "users/USER_CONCLUSIONS_LIST_REQUEST",
  USER_CONCLUSIONS_LIST_SUCCESS: "users/USER_CONCLUSIONS_LIST_SUCCESS",
  USER_CONCLUSIONS_LIST_FAILURE: "users/USER_CONCLUSIONS_LIST_FAILURE",

  UPSERT_USER: "users/UPSERT_USER",
  UPDATE_ITEMS: "users/UPDATE_ITEMS",
  SET_SELECTED: "users/SET_SELECTED",

  NEW_PATIENT_CONFIRM_REQUEST: "users/NEW_PATIENT_CONFIRM_REQUEST",
  NEW_PATIENT_CONFIRM_SUCCESS: "users/NEW_PATIENT_CONFIRM_SUCCESS",
  NEW_PATIENT_CONFIRM_ERROR: "users/NEW_PATIENT_CONFIRM_ERROR"
};
