export const PRIVATE_PROFILE = {
  TITLE: "privateProfile__title",
  ICON_CLOSE: "privateProfile__icon-close",
  AVATAR: "privateProfile__avatar",
  NICKNAME: "privateProfile__nickname",
  INCOME: "privateProfile__income",
  HEALTH: "privateProfiles__health-check",
  SETTINGS: "privateProfiles__settings",
  SUPPORT: "privateProfiles__support"
};

export const SETTINGS = {
  TITLE: "privateProfile__settings__title",
  ICON_BACK: "privateProfile__settings__icon-back",
  ICON_CLOSE: "privateProfile__settings__icon-close",
  AVATAR: "privateProfile__settings__avatar",
  INPUT_FULL_NAME: "privateProfile__settings__input-full-name",
  INPUT_EXPERIENCE: "privateProfile__settings__input-experience",
  INPUT_CATEGORY: "privateProfile__settings__input-categoty",
  INPUT_EDUCATION: "privateProfile__settings__input-education",
  INPUT_DESCRIPTION: "privateProfile__settings__input-description",
  INPUT_DATE_OF_BIRTH: "privateProfile__settings__input-date-of-birth",
  INPUT_EMAIL: "privateProfile__settings__input-email",
  INPUT_PHONE: "privateProfile__settings__input-phone",
  INPUT_ABOUT: "privateProfile__settings__input-about",
  BUTTON_CANCEL: "privateProfile__settings__button-cancel",
  BUTTON_SAVE: "privateProfile__settings__button-save",
  BUTTON_LOGOUT: "privateProfile__settings__button-logout",
  BUTTON_CHANGE_PASSWORD: "privateProfile__settings__button-change-password"
};

export const PASSWORD_CHANGE = {
  TITLE: "privateProfile__passwordChange__title",
  ICON_CLOSE: "privateProfile__passwordChange__icon-close",
  INPUT_NEW_PASSWORD: "privateProfile__passwordChange__input-new-password",
  INPUT_PASSWORD_CONFIRM:
    "privateProfile__passwordChange__input-password-confirm",
  BUTTON_SUBMIT: "privateProfile__passwordChange__button-submit"
};

export const HELP = {
  TITLE: "privateProfile__help__title",
  ICON_BACK: "privateProfile__help__icon-back",
  ICON_CLOSE: "privateProfile__help__icon-close",
  FAQ: "privateProfile__help__faq",
  FAQ_NOT_FOUND: "privateProfile__help__faq-not-found",
  CONTACT_SUPPORT_BUTTON: "privateProfile__help__button-contact-support"
};

export const CONNECTION_CHECK = {
  ICON_CLOSE: "privateProfile_connectionCheck_close",
  ICON_BACK: "privateProfile_connectionCheck_back"
};

export const SUPPORT = {
  TITLE: "privateProfile__support__title",
  ICON_BACK: "privateProfile__support__icon-back",
  ICON_CLOSE: "privateProfile__support__icon-close",
  TEXTAREA: "privateProfile__support__textarea",
  TEXTAREA_ERROR: "privateProfile__support__error-textarea",
  SEND_BUTTON: "privateProfile__support__button-send",
  ERROR: "privateProfile__support__error"
};
