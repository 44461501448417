import React, { useCallback, useState } from "react";
import * as PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Dialog } from "ibolit-ui";
import moment from "moment";
import {
  ConclusionForm,
  recommendedDatesInDays
} from "~/modals/Conclusion/ConclusionForm/ConclusionForm";
import Loading from "~/components/Loading/Loading";
import { useCloseModal } from "~/hooks/useModal";
import conclusionsActions from "~/store/conclusions/conclusionsActions";
import styles from "./ConclusionModal.scss";
import uiActions from "~/store/ui/uiActions";
import { getConclusionPosting } from "~/store/conclusions/conclusionSelectors";
import Analytics from "~/utils/analytics";

export const CONCLUSION_VIEW = "conclusion";

export const getDaysCountBeforeConsultation = (
  date,
  currentDate = moment()
) => {
  const recommendedDate = moment(date);

  const diff = Math.ceil(recommendedDate.diff(currentDate, "days", true));

  return diff > 0 ? diff : 0;
};

export const initNextConsultation = recommendedConsultationDate => {
  if (!recommendedConsultationDate) return recommendedDatesInDays[0];

  const diff = getDaysCountBeforeConsultation(recommendedConsultationDate);

  return diff > 0 ? recommendedDatesInDays[diff] : recommendedDatesInDays[0];
};

function ConclusionModal({
  consultationId,
  problem,
  conclusion,
  recommendedConsultationDate
}) {
  const posting = useSelector(state => getConclusionPosting(state));
  const dispatch = useDispatch();
  const onCloseClick = useCloseModal(CONCLUSION_VIEW);
  const [conclusionText, setConclusion] = useState(conclusion?.text || "");
  const [problemTitle, setProblemTitle] = useState(problem?.title || "");
  const [problemStatus, setProblemStatus] = useState(problem?.status);
  const [nextConsultation, setNextConsultation] = useState(
    initNextConsultation(recommendedConsultationDate)
  );

  const requestSubmit = useCallback(
    text => {
      if (problemTitle !== "") {
        dispatch(
          conclusionsActions.sendConclusionRequest({
            consultationId,
            text,
            isNew: !conclusion?.id,
            problemTitle,
            problemStatus,
            recommendedConsultationDays: nextConsultation.value
              ? Number(nextConsultation.value)
              : null
          })
        );
      }
      dispatch(uiActions.hideModal(CONCLUSION_VIEW));
      const eventName = conclusion?.id
        ? "Edited Conclusion"
        : "Created Conclusion";
      Analytics.trackEvent(eventName, {
        consultationId
      });
    },
    [consultationId, problemStatus, problemTitle, nextConsultation, conclusion]
  );

  const isFormChanged =
    conclusionText !== (conclusion?.text ?? "") ||
    problemTitle !== (problem?.title ?? "") ||
    problemStatus !== problem?.status ||
    (!recommendedConsultationDate && !!nextConsultation.value) ||
    (recommendedConsultationDate &&
      nextConsultation.value !== null &&
      nextConsultation.value !==
        String(getDaysCountBeforeConsultation(recommendedConsultationDate)));

  return (
    <Dialog
      boxClassName={styles.box}
      headerClassName={styles.header}
      bodyClassName={styles.body}
      header="Консультация завершена"
      onCloseClick={onCloseClick}
      testId="ConclusionModal"
    >
      <ConclusionForm
        isFormChanged={isFormChanged}
        isNewConclusion={!conclusion?.id}
        requestSubmit={requestSubmit}
        problemTitle={problemTitle}
        setProblemTitle={setProblemTitle}
        conclusionText={conclusionText}
        setConclusion={setConclusion}
        problemStatus={problemStatus}
        setProblemStatus={setProblemStatus}
        nextConsultation={nextConsultation}
        setNextConsultation={setNextConsultation}
        consultationId={consultationId}
        problemId={problem?.id}
      />
      <Loading showLoading={posting} />
    </Dialog>
  );
}

ConclusionModal.propTypes = {
  conclusion: PropTypes.shape({
    text: PropTypes.string
  }),
  consultationId: PropTypes.number,
  problem: PropTypes.shape({
    title: PropTypes.string,
    status: PropTypes.string
  }),
  recommendedConsultationDate: PropTypes.string
};

export default ConclusionModal;
