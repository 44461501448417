import React, { useMemo } from "react";
import { connect } from "react-redux";
import navLocators from "~/locators/navbar";
import Header from "./components/Header";
import uiActions from "~/store/ui/uiActions";
import { getUser } from "~/store/profile/profileSelectors";
import { getToolbar } from "~/store/toolbar/toolbarSelectors";
import styles from "~/layouts/App/components/NavigationBar/components/Header/Header.scss";
import UserAvatar from "~/components/UserAvatar";

const NavigationBar = ({
  user,
  toolbar: { consultations, patients, chatMessages, loading }
}) => {
  const routesList = useMemo(
    () => [
      {
        id: 1,
        text: "Сообщения",
        to: "/chats",
        icon: "chat",
        badge: chatMessages,
        testid: navLocators.MESSAGES
      },
      {
        id: 2,
        text: "Приемы",
        to: "/consultations",
        icon: "chat-video",
        badge: consultations,
        testid: navLocators.CONSULTATIONS
      },
      {
        id: 3,
        text: "Пациенты",
        to: "/contacts",
        icon: "users",
        badge: patients,
        testid: navLocators.PATIENTS
      },
      {
        id: 4,
        to: "/profile",
        testid: navLocators.PROFILE,
        component: (
          <div
            data-testid={navLocators.PROFILE}
            className={styles.avatarContainer}
          >
            <UserAvatar user={user} />
          </div>
        )
      }
    ],
    [user, chatMessages, consultations, patients]
  );

  if (!user) return null;

  return <Header user={user} routesList={routesList} loading={loading} />;
};

export default connect(
  state => ({
    user: getUser(state),
    toolbar: getToolbar(state)
  }),
  {
    showModal: uiActions.showModal
  }
)(NavigationBar);
