import React from "react";
import { Text } from "ibolit-ui";

const DateItem = ({ className, children }) => (
  <Text variant="h6" className={className}>
    {children}
  </Text>
);

export default DateItem;
