import React, { useRef, useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "ibolit-ui";
import AUTH_LOCATORS from "~/locators/auth";
import PasswordInput from "~/components/inputs/PasswordInput";
import Field from "~/components/Field/Field";
import {
  getAuthError,
  getAuthPassword,
  getCode,
  getLogin
} from "~/store/auth/authSelectors";
import actions from "~/store/auth/authActions";
import { Step } from "~/store/auth/authReducer";
import styles from "../../Authorization.scss";
import Analytics from "~/utils/analytics";

const testidFieldProps = {
  input: AUTH_LOCATORS.AUTH_LOGIN_INPUT_PASSWORD,
  eye: AUTH_LOCATORS.AUTH_LOGIN_SHOW_PASSWORD,
  crossedEye: AUTH_LOCATORS.AUTH_LOGIN_HIDE_PASSWORD,
  label: AUTH_LOCATORS.AUTH_LOGIN_LABEL_INPUT_PASSWORD,
  error: AUTH_LOCATORS.AUTH_LOGIN_INPUT_PASSWORD_ERROR_MESSAGE
};

const Password = ({ isReset }) => {
  useEffect(() => {
    if (!isReset) {
      Analytics.trackEvent("Password Screen");
    }
  }, []);

  const dispatch = useDispatch();

  const login = useSelector(getLogin);
  const errors = useSelector(getAuthError);
  const code = useSelector(getCode);
  const password = useSelector(getAuthPassword);

  const [canSubmit, setCanSubmit] = useState(false);
  const inputRef = useRef(null);
  const onSubmit = useCallback(() => {
    if (isReset) {
      dispatch(
        actions.passwordResetRequest(inputRef.current.value.trim(), login, code)
      );
    } else {
      dispatch(
        actions.checkPasswordRequest(inputRef.current.value.trim(), login, code)
      );
    }
  }, [login, code]);

  const onRestoreClick = useCallback(() => {
    dispatch(actions.goToStep(Step.CODE));
  }, [login]);

  return (
    <div className={styles.form}>
      <Field
        Component={PasswordInput}
        initialValue={password}
        errors={errors}
        errorKey="password"
        ref={inputRef}
        autoFocus
        secured
        submitHandler={onSubmit}
        minLength={isReset ? 8 : 6}
        label={isReset ? "Задайте новый пароль для входа" : "Введите пароль"}
        validHandler={setCanSubmit}
        testidProps={testidFieldProps}
      />
      <Button
        disabled={!canSubmit}
        onClick={onSubmit}
        fullWidth
        type="submit"
        testid={AUTH_LOCATORS.AUTH_LOGIN_BUTTON_SUBMIT_PASSWORD}
      >
        Продолжить
      </Button>
      {!isReset && (
        <Button
          variant="tertiary"
          fullWidth
          onClick={onRestoreClick}
          testid={AUTH_LOCATORS.AUTH_LOGIN_BUTTON_FORGET_PASSWORD}
        >
          Забыли пароль?
        </Button>
      )}
    </div>
  );
};

export default Password;
