import React from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import BlockHeader from "~/components/BlockHeader";
import ContactList from "./ContactList";
import { CONTACTS_LIST } from "~/locators/contacts";
import {
  contactRequestSelector,
  USERS_MODE_ALL
} from "~/store/users/usersModel";
import SearchBlock from "~/components/SearchBlock";
import styles from "./ContactAside.scss";
import FilterButton from "~/modals/Filter/FilterButton";
import InviteUserButton from "./Invitation";
import Tooltip from "~/components/Tooltip";
import { useUsersList } from "~/hooks/useUsersList";
import { INITIAL_FILTER } from "~/store/users/const";

const ContactAside = () => {
  const { listRef, filter, setFilter, setSearch, incrementPage } = useUsersList(
    INITIAL_FILTER
  );

  const {
    items,
    isFetching,
    initialFetched,
    canLoadMore,
    hasErrors,
    isEmpty
  } = useSelector(state => contactRequestSelector(state, USERS_MODE_ALL));

  return (
    <div className={classNames(styles.container)}>
      <BlockHeader title="Пациенты" testid={CONTACTS_LIST.HEADER} />
      <div
        className={classNames(styles.block, { [styles.block.hidden]: isEmpty })}
      >
        <SearchBlock
          testId={CONTACTS_LIST.INPUT_SEARCH}
          placeholder="Поиск по пациентам"
          onSearchChange={setSearch}
          className={styles.search}
        />
        <FilterButton
          filter={filter}
          setFilter={setFilter}
          className={styles.filterButton}
          testid={CONTACTS_LIST.BUTTON_FILTER}
        />
      </div>
      <div className={styles.inviteButton}>
        <InviteUserButton />
      </div>
      <Tooltip
        place="bottom"
        show={initialFetched && isEmpty}
        testid={CONTACTS_LIST.TOOLTIP}
        timeout={1000}
      >
        Добавьте своих пациентов
      </Tooltip>
      <ContactList
        ref={listRef}
        items={items}
        isFetching={isFetching}
        initialFetched={initialFetched}
        canLoadMore={canLoadMore}
        filter={filter}
        hasErrors={hasErrors}
        isEmpty={isEmpty}
        incrementPage={incrementPage}
      />
    </div>
  );
};

export default ContactAside;
