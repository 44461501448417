import cn from "classnames";
import React from "react";
import PropTypes from "prop-types";
import { Input, Text, Label, Button, Textarea } from "ibolit-ui";
import { SETTINGS } from "~/locators/privateProfile";
import styles from "./Form.scss";

const Form = ({
  handleSubmit,
  errors,
  values,
  dirty,
  isValid,
  loadingUserData,
  setFieldValue
}) => {
  const onFieldChange = fieldName => event => {
    const value = event.target.value;

    if (value === '') {
      setFieldValue(fieldName, '');
      return;
    }

    if(fieldName === 'experience' && isNaN(value)){
        return;
    }

    setFieldValue(fieldName, value);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Label title="Опыт работы" className={styles.field}>
        <Input
          nativeChange
          fullWidth
          value={values.experience}
          onChange={onFieldChange("experience")}
          name="experience"
          disabled={loadingUserData}
          autocomplete="off"
          maxlength={2}
          testidProps={{
            input: SETTINGS.INPUT_EXPERIENCE
          }}
        />
        {dirty && errors.experience && (
          <Text variant="desc" colorVariant="error">
            {errors.experience}
          </Text>
        )}
      </Label>
      <Label title="Категория" className={styles.field}>
        <Input
          nativeChange
          fullWidth
          value={values.category}
          type="text"
          onChange={onFieldChange("category")}
          name="category"
          disabled={loadingUserData}
          autocomplete="off"
          testidProps={{
            input: SETTINGS.INPUT_CATEGORY
          }}
        />
        {dirty && errors.category && (
          <Text variant="desc" colorVariant="error">
            {errors.category}
          </Text>
        )}
      </Label>
      <Label title="Образование" className={styles.field}>
        <Input
          nativeChange
          fullWidth
          value={values.education}
          type="text"
          onChange={onFieldChange("education")}
          name="category"
          disabled={loadingUserData}
          autocomplete="off"
          testidProps={{
            input: SETTINGS.INPUT_EDUCATION
          }}
        />
        {dirty && errors.education && (
          <Text variant="desc" colorVariant="error">
            {errors.education}
          </Text>
        )}
      </Label>
      <Label title="О себе" className={styles.field}>
        <Textarea
          nativeChange
          fullWidth
          value={values.description}
          type="text"
          onChange={onFieldChange("description")}
          name="description"
          disabled={loadingUserData}
          autocomplete="off"
          testidProps={{
            input: SETTINGS.INPUT_DESCRIPTION
          }}
        />
        {dirty && errors.description && (
          <Text variant="desc" colorVariant="error">
            {errors.description}
          </Text>
        )}
      </Label>
      <div
        className={cn(styles.buttonsList, {
          [styles.buttonsListShow]: dirty && isValid
        })}
      >
        <Button
          testid={SETTINGS.BUTTON_SAVE}
          className={styles.formButton}
          disabled={loadingUserData}
        >
          Сохранить
        </Button>
      </div>
    </form>
  );
};

Form.propTypes = {
  loadingUserData: PropTypes.bool.isRequired
};

export default Form;
