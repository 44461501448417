export const CHATS_LIST = {
  HEADER: "chats__chatsList__header",
  INPUT_SEARCH: "chats__chatsList__input-search",
  TABS: "chats__chatsList__tabs",
  CONTAINER: "chats__chatsList__container",
  SEARCH_NO_RESULTS: "chats__chatsList__search-no-results"
};

export const CHAT = {
  MESSAGES_CONTAINER: "chats__chat__container-messages"
};

export const CHAT_FOOTER = {
  MENU_BUTTON: "chats__chatFooter__menu-button",
  TEXTAREA: "chats__chatFooter__textarea",
  BUTTON_SEND_MESSAGE: "chats__chatFooter__button-send-message",
  BUTTON_CONSULTATION: "chats__chatFooter__button-menu-consultation",
  BUTTON_SEND_FILE: "chats__chatFooter__button-menu-send-file"
};

export const CHAT_HEADER = {
  PARTNER: "chats__chatHeader__partner",
  PARTNER_ONLINE_STATUS: "chats__chatHeader__partner-online-status",
  STATUS: "chats__chatHeader__chat-status",
  BUTTON_PAYMENT: "chats__chatHeader__payment-button"
};
