import React, { useCallback, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Button, Text, IconWithText, Dialog } from "ibolit-ui";
import { useDispatch, useSelector } from "react-redux";
import Loading from "~/components/Loading/Loading";
import styles from "./EndExpressChatModal.scss";
import { getIsCloseChatLoading } from "~/store/chats/chatsSelectors";
import { useCloseModal } from "~/hooks/useModal";
import chatActions from "~/store/chats/chatsActions";
import Field from "~/components/Field";
import TextareaInput from "~/components/inputs/TextareaInput/TextareaInput";
import { CONCLUSION_BUTTON_TEXT } from "~/consts/conclusions";
import Analytics from "~/utils/analytics";

export const END_EXPRESS_CHAT_VIEW = "endExpressChat";

const ConclusionForm = ({
  requestSubmit,
  initialValue,
  submitText,
  error,
  testid
}) => {
  const inputRef = useRef(null);
  const [canSubmit, setCanSubmit] = useState(false);

  const handleSubmit = () => {
    inputRef.current.blur();
    requestSubmit(inputRef.current.value.trim());
  };

  return (
    <>
      <Field
        className={styles.field}
        initialValue={initialValue}
        inputClassName={styles.textarea}
        Component={TextareaInput}
        error={error}
        fullWidth
        placeholder={CONCLUSION_BUTTON_TEXT}
        ref={inputRef}
        required
        autoFocus
        noSpace
        autosize={false}
        rows={3}
        changeHandler={value => setCanSubmit(value.length > 0)}
        maxRows={10}
        testid={`${testid}__textarea-conclusion`}
      />
      <Button
        testid={`${testid}__button-submit`}
        disabled={!canSubmit}
        onClick={handleSubmit}
        fullWidth
      >
        {submitText}
      </Button>
    </>
  );
};

ConclusionForm.defaultProps = {
  submitText: "Сохранить"
};

ConclusionForm.propTypes = {
  requestSubmit: PropTypes.func.isRequired,
  submitText: PropTypes.string,
  initialValue: PropTypes.string,
  error: PropTypes.string
};

const EndExpressChatModal = ({ serviceId, chatId }) => {
  const loading = useSelector(getIsCloseChatLoading);
  const dispatch = useDispatch();
  const onCloseClick = useCloseModal(END_EXPRESS_CHAT_VIEW);
  const onButtonClick = useCallback(
    text => {
      dispatch(chatActions.endExpressChatRequest(chatId, serviceId, text));
      Analytics.trackEvent("Created Conclusion", {
        chatId
      });
    },
    [chatId, serviceId]
  );

  return (
    <Dialog
      boxClassName={styles.boxModal}
      testId="EndExpressChatModal"
      header="Заключение"
      onCloseClick={onCloseClick}
    >
      <ConclusionForm requestSubmit={onButtonClick} />
      <Loading showLoading={loading} />
    </Dialog>
  );
};

EndExpressChatModal.propTypes = {
  serviceId: PropTypes.string.isRequired,
  chatId: PropTypes.string.isRequired
};

export default EndExpressChatModal;
