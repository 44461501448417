export default {
  AUTH_LOGIN_DESCRIPTION_INPUT_USER: "auth__login__label-input-user",
  AUTH_LOGIN_INPUT_USER: "auth__login__input-user",
  AUTH_LOGIN_INPUT_USER_ERROR_MESSAGE: "auth__login__input-user-error-message",
  AUTH_LOGIN_LINK_SUPPORT: "auth__login__link-support",
  AUTH_LOGIN_LABEL_INPUT_USER: "auth__login__label-input-user",
  AUTH_LOGIN_LABEL_INPUT_PASSWORD: "auth__login__label-input-password",
  AUTH_LOGIN_INPUT_PASSWORD: "auth__login__input-password",
  AUTH_LOGIN_SHOW_PASSWORD: "auth__login__show-password",
  AUTH_LOGIN_HIDE_PASSWORD: "auth__login__hide-password",
  AUTH_LOGIN_INPUT_PASSWORD_ERROR_MESSAGE:
    "auth__login__input-password-error-message",
  AUTH_LOGIN_BUTTON_SUBMIT_PASSWORD: "auth__login__button-submit-password",
  AUTH_LOGIN_BUTTON_FORGET_PASSWORD: "auth__login__button-forget-password",
  AUTH_LOGIN_BUTTON_SUBMIT_USER: "auth__login__button-submit-user",
  // Login Error
  AUTH_LOGIN_LOGIN_ERROR_MODAL_TITLE: "auth__loginError__modal-title",
  AUTH_LOGIN_LOGIN_ERROR_MODAL_TEXT: "auth__loginError__error-modal-text",
  AUTH_LOGIN_LOGIN_ERROR_BACK_BUTTON: "auth__loginError__back-button",
  AUTH_LOGIN_LOGIN_ERROR_PARTNERS_BUTTON: "auth__loginError__partners-button",
  // Activation
  AUTH_ACTIVATION: "auth__activation",
  AUTH_ACTIVATION_DOCTOR: "auth__activation__doctor-wrapper",
  AUTH_ACTIVATION_INVITATION_TEXT: "auth__activation__invitation-text",
  AUTH_ACTIVATION_PASSWORD_INPUT: "auth__activation__password-input",
  AUTH_ACTIVATION_AGREEMENT_CHECKBOX: "auth__activation__agreement-checkbox",
  AUTH_ACTIVATION_BUTTON_SUBMIT: "auth__activation__submit-button",

  AUTH_MESSAGE_NUMBER: "auth_message_number"
};
