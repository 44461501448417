import axios from "./httpService";

const MCS_URL = __MS__ || "https://mcs-stage2.ibolit.pro:4098"; // Meta Call Server URL is retrieved from the old MS build parameter TODO: refactor this and inform backend

// export type GetUsernameParams = {
//   user_id?: number;
// };

// If user_id param is provided
// Response will return formatted username (123@ibolit.pgerasomiov.voximplant.com)
// If user_id param is NOT provided
// Will return just an unformatted username which is used as roomId
//  export type GetUsernameResponse = {
//    username: string;
//  };
const getUsername = params =>
  axios.get(`${MCS_URL}/api/v2/username`, { params });

// export type GetOneTimeTokenParams = {
//   oneTimeKey: string;
// };
// export type GetOneTimeTokenResponse = {
//   token: string;
// };
const getOneTimeToken = params =>
  axios.get(`${MCS_URL}/api/v2/get-token`, { params });

export default {
  callJoin: params => axios.get(`${MCS_URL}/api/v1/join`, { params }),
  callLeave: params => axios.get(`${MCS_URL}/api/v1/leave`, { params }),
  getUsername,
  getOneTimeToken
};
