import React, { useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import { connect } from "react-redux";
import actions from "~/store/profile/profileAction";
import uiActions from "~/store/ui/uiActions";
import auth from "~/store/auth/authActions";
import ProfileSettings from "./components/ProfleSettings";
import { SERVER_DATE_FORMAT } from "~/utils/dateUtils";
import {
  getProfileSettingsScene,
  getUser
} from "~/store/profile/profileSelectors";
import { PROFILE_CHANGE_SETTINGS_VIEW } from "~/consts/profile";

const ProfileSettingsContainer = ({
  user,
  showModal,
  userAvatarRequest: userAvatarRequestAction,
  userDataUpdateRequest: userDataUpdateRequestAction,
  resetStateActions: resetStateActionsAction,
  loadingUserData,
  loadingAvatar,
  successUpdateUserData,
  logout,
  onCloseClick
}) => {
  useEffect(() => resetStateActionsAction, []);

  const onAvatarChange = file => {
    if (file) {
      userAvatarRequestAction(file);
    }
  };

  const onFormSubmit = values => {
    let date_of_birth = null;

    if (values.date_of_birth !== "") {
      date_of_birth = moment(values.date_of_birth, "DD.MM.YYYY").format(
        SERVER_DATE_FORMAT
      );
    }

    userDataUpdateRequestAction({ ...user, ...values, date_of_birth });
  };

  const onChangeSettings = useCallback(scene => {
    showModal(PROFILE_CHANGE_SETTINGS_VIEW, { scene });
  }, []);

  return (
    <ProfileSettings
      onAvatarChange={onAvatarChange}
      user={user}
      onCloseClick={onCloseClick}
      loadingAvatar={loadingAvatar}
      onFormSubmit={onFormSubmit}
      loadingUserData={loadingUserData}
      successUpdateUserData={successUpdateUserData}
      onLogout={logout}
      onChangeSettings={onChangeSettings}
    />
  );
};

ProfileSettingsContainer.propTypes = {
  onClose: PropTypes.func,
  user: PropTypes.object.isRequired,
  userAvatarRequest: PropTypes.func.isRequired,
  userDataUpdateRequest: PropTypes.func.isRequired,
  loadingAvatar: PropTypes.bool.isRequired,
  loadingUserData: PropTypes.bool.isRequired,
  successUpdateUserData: PropTypes.bool,
  resetStateActions: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  user: getUser(state),
  loadingAvatar: state.profile.loadingAvatar,
  loadingUserData: state.profile.loadingUserData,
  successUpdateUserData: state.profile.successUpdateUserData,
  scene: getProfileSettingsScene(state)
});

export const PROFILE_SETTINGS_VIEW = "profileSettings";

export default connect(mapStateToProps, {
  userAvatarRequest: actions.userAvatarRequest,
  userDataUpdateRequest: actions.userDataUpdateRequest,
  resetStateActions: actions.resetStateActions,
  logout: auth.logout,
  showModal: uiActions.showModal
})(ProfileSettingsContainer);
