import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Dialog, Button } from "ibolit-ui";
import Field from "~/components/Field";
import { useCloseModal } from "~/hooks/useModal";
import styles from "./CancelConsultation.scss";
import { getConsultationError } from "~/store/consultations/consultationSelectors";

export const CANCEL_CONSULTATION = "cancel_consultation";

const enumInners = {
  youSure: "youSure",
  needCause: "needCause",
};

function CancelConsultationModal({ handleSubmitCancel }) {
  const [state, setState] = useState(enumInners.youSure);
  const [commentValue, setCommentValue] = useState("");
  const [canSubmit, setCanSubmit] = useState(true);

  const errors = useSelector(getConsultationError);
  const onCloseClick = useCloseModal(CANCEL_CONSULTATION);

  const handleAgreed = () => setState(enumInners.needCause);

  const handleChangeComment = (value) => {
    setCommentValue(value);
  };

  const handleCancel = () => {
    if (canSubmit) {
      handleSubmitCancel(commentValue)();
    }
  };

  const renderDialogInner = () => {
    if (state === enumInners.youSure) {
      return (
        <>
          <div
            className={styles.description}
            data-testId="CancelConsultationModal__desc"
          >
            Пациент получит уведомление об отмене консультации
          </div>
          <div
            className={styles.buttons}
            data-testId="CancelConsultationModal__yes"
          >
            <button className={styles.agreeButton} onClick={handleAgreed}>
              Да
            </button>
            <button
              onClick={onCloseClick}
              className={styles.disagreeButton}
              data-testId="CancelConsultationModal__no"
            >
              Нет
            </button>
          </div>
        </>
      );
    }

    if (state === enumInners.needCause) {
      return (
        <>
          <div className={styles.description}>
            Напишите причину отмены консультации для администратора клиники
          </div>
          <Field
            placeholder="Оставьте комментарий"
            className={styles.commentInputContainer}
            onChange={handleChangeComment}
            value={commentValue}
            errorKey={"reason"}
            inputClassName={styles.commentInput}
            validHandler={setCanSubmit}
            submitHandler={handleCancel}
            errors={errors}
          />

          <Button
            className={styles.submitButton}
            onClick={handleCancel}
            fullWidth
          >
            Отправить
          </Button>
        </>
      );
    }
  };

  return (
    <Dialog
      boxClassName={
        state === enumInners.needCause ? styles.commentBox : styles.box
      }
      headerClassName={styles.header}
      bodyClassName={styles.body}
      header={
        state === enumInners.needCause
          ? "Комментарий"
          : "Отменить консультацию?"
      }
      onCloseClick={state === enumInners.needCause ? onCloseClick : null}
      testId="CancelConsultationModal"
    >
      {renderDialogInner()}
    </Dialog>
  );
}

CancelConsultationModal.propTypes = {};

export default CancelConsultationModal;
