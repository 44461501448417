import PropTypes from "prop-types";
import React from "react";
import { Text } from "ibolit-ui";
import { getDateOfBirth } from "~/components/DateOfBirth";

const UserInfo = ({ user, className, colorVariant, variant }) => (
  <Text className={className} colorVariant={colorVariant} variant={variant}>
    {getDateOfBirth(user)}
  </Text>
);

UserInfo.defaultProps = {
  colorVariant: "tertiary",
  variant: "descTitle"
};

UserInfo.propTypes = {
  className: PropTypes.string,
  colorVariant: PropTypes.string,
  user: PropTypes.object,
  variant: PropTypes.string
};

export default UserInfo;
