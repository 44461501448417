import PropTypes from "prop-types";
import React from "react";
import { Icon, Button, IconWithText } from "ibolit-ui";
import cn from "classnames";
import RowWithImage from "~/components/RowWithImage";
import styles from "./NewConsultation.scss";

const NewConsultationButton = ({
  user,
  testid,
  className,
  variant,
  text,
  onClick,
  ...props
}) => {
  if (variant === "menuitem") {
    return (
      <IconWithText
        className={className}
        onClick={onClick}
        testid={testid}
        iconVariant="file"
        text="Записать на прием"
        hasHoverEffect
      />
    );
  }

  if (variant === "row") {
    return (
      <RowWithImage
        className={className}
        image={<Icon variant="camera" fill="var(--blue)" />}
        title="Записать на прием"
        onClick={onClick}
        hasHoverEffect
        testid={testid}
      />
    );
  }

  return (
    <Button
      onClick={onClick}
      {...props}
      className={cn(styles.button, className)}
      testid={testid}
      icon="camera"
      iconColor="var(--white)"
    >
      {text || `Назначить консультацию`}
    </Button>
  );
};

NewConsultationButton.defaultProps = {
  variant: "row"
};

NewConsultationButton.propTypes = {
  variant: PropTypes.oneOf(["button", "row", "menuitem"]),
  className: PropTypes.string,
  user: PropTypes.object,
  onClick: PropTypes.func.isRequired,
  testid: PropTypes.string
};

export default React.memo(NewConsultationButton);
