import { takeLatest, all, put } from "redux-saga/effects";
import Types from "~/store/ui/uiTypes";
import actions from "~/store/ui/uiActions";

function* modalReplace({ payload }) {
  const { viewOld, viewNew, props } = payload;

  yield put(actions.hideModal(viewOld));
  yield put(actions.showModal(viewNew, props));
}

export default function* uiSaga() {
  yield all([takeLatest(Types.REPLACE_MODAL, modalReplace)]);
}
