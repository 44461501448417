import React from "react";

const ErrorSvg = ({ className }) => (
  <svg
    width="279"
    height="183"
    viewBox="0 0 279 183"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath="url(#clip-ufo)">
      <g opacity="0.8">
        <path
          opacity="0.8"
          d="M143.486 81.6307C170.352 81.6307 192.131 64.7586 192.131 43.9458C192.131 23.133 170.352 6.26086 143.486 6.26086C116.619 6.26086 94.8401 23.133 94.8401 43.9458C94.8401 64.7586 116.619 81.6307 143.486 81.6307Z"
          fill="url(#paint0_linear)"
        />
      </g>
      <path
        d="M143.486 80.7938C169.756 80.7938 191.052 64.2964 191.052 43.9458C191.052 23.5953 169.756 7.0979 143.486 7.0979C117.216 7.0979 95.9194 23.5953 95.9194 43.9458C95.9194 64.2964 117.216 80.7938 143.486 80.7938Z"
        fill="#00B5CE"
      />
      <path
        opacity="0.1"
        d="M103.609 48.0411C103.609 27.6907 124.902 11.1932 151.171 11.1932C159.327 11.1483 167.402 12.815 174.873 16.0856C166.513 10.4392 155.576 7.00818 143.605 7.00818C117.336 7.00818 96.0391 23.5057 96.0391 43.8561C96.0391 57.5205 105.641 69.4477 119.907 75.8116C109.919 69.0558 103.609 59.1214 103.609 48.0411Z"
        fill="white"
      />
      <path
        opacity="0.2"
        d="M143.486 58.7561L196.217 150.089L248.948 241.418H143.486H38.0237L90.7547 150.089L143.486 58.7561Z"
        fill="#00B5CE"
      />
      <g opacity="0.3">
        <path
          opacity="0.3"
          d="M155.998 188.066L160.093 175.913C161.501 171.68 161.513 167.108 160.125 162.869C158.738 158.629 156.025 154.948 152.387 152.367L152.42 152.347C150.81 151.213 149.043 150.318 147.176 149.69L147.119 149.67L147.056 149.65C145.244 149.037 143.354 148.682 141.443 148.594C140.767 148.559 140.09 148.559 139.414 148.594C138.241 148.655 137.075 148.817 135.93 149.076C135.597 149.149 135.265 149.232 134.963 149.321C134.661 149.411 134.322 149.507 134.006 149.61C133.375 149.82 132.754 150.055 132.146 150.324C131.841 150.457 131.539 150.597 131.243 150.746C130.349 151.188 129.489 151.694 128.669 152.261C128.393 152.45 128.124 152.646 127.858 152.845C124.575 155.342 122.118 158.767 120.804 162.677L114.367 181.825C113.354 184.828 113.049 188.025 113.477 191.165C113.543 191.646 113.626 192.125 113.722 192.6C113.819 193.075 113.938 193.543 114.055 194.008C114.144 194.317 114.237 194.626 114.34 194.931C114.493 195.386 114.672 195.841 114.842 196.286C114.965 196.582 115.094 196.878 115.23 197.17C115.499 197.751 115.798 198.319 116.121 198.874C116.443 199.428 116.785 199.97 117.17 200.495C117.449 200.887 117.745 201.272 118.05 201.644C118.462 202.142 118.894 202.64 119.352 203.082C121.336 205.082 123.71 206.653 126.327 207.696L126.44 207.742C126.581 207.493 126.736 207.252 126.905 207.021C127.902 205.656 129.635 204.696 131.286 205.055C131.406 205.082 131.525 205.118 131.645 205.155C132.482 205.45 133.306 205.978 134.113 206.237C134.406 206.369 134.725 206.436 135.047 206.432C135.369 206.428 135.686 206.353 135.976 206.214C137.59 205.354 136.992 202.64 138.377 201.451C138.442 201.396 138.51 201.345 138.58 201.298C138.56 201.274 138.543 201.249 138.527 201.222C139.596 200.558 141.078 201 142.31 201.428C143.639 201.896 145.372 202.212 146.296 201.126C146.92 200.382 146.874 199.249 147.468 198.469C148.159 197.572 149.461 197.472 150.587 197.632L150.643 197.648C152.802 198.538 155.161 197.06 154.995 194.732C154.799 191.626 155.775 188.7 155.998 188.066Z"
          fill="url(#paint1_linear)"
        />
      </g>
      <path
        d="M132.056 173.312C133.026 171.983 134.69 171.053 136.284 171.415C137.879 171.777 139.39 173.295 140.808 172.534C142.748 171.498 141.373 167.688 144.521 167.406C145.178 167.363 145.835 167.477 146.438 167.738C147.767 168.28 149.846 168.891 150.869 167.672C151.47 166.954 151.427 165.862 152.001 165.118C152.666 164.254 153.931 164.144 155.011 164.31L155.064 164.327C157.146 165.187 159.425 163.759 159.262 161.514C159.053 158.525 160.003 155.708 160.209 155.097L164.154 143.369C165.833 138.341 165.453 132.854 163.097 128.105C160.741 123.357 156.602 119.735 151.583 118.03C146.556 116.353 141.069 116.735 136.323 119.091C131.576 121.448 127.955 125.587 126.25 130.605L120.029 149.048C118.412 153.899 118.709 159.184 120.859 163.824C123.008 168.463 126.848 172.106 131.594 174.009C131.735 173.768 131.889 173.535 132.056 173.312Z"
        fill="white"
      />
      <path
        opacity="0.2"
        d="M139.41 130.923C140.604 130.923 141.572 129.955 141.572 128.761C141.572 127.567 140.604 126.599 139.41 126.599C138.216 126.599 137.248 127.567 137.248 128.761C137.248 129.955 138.216 130.923 139.41 130.923Z"
        fill="#212121"
      />
      <path
        opacity="0.2"
        d="M151.706 135.059C152.9 135.059 153.868 134.091 153.868 132.896C153.868 131.702 152.9 130.734 151.706 130.734C150.512 130.734 149.543 131.702 149.543 132.896C149.543 134.091 150.512 135.059 151.706 135.059Z"
        fill="#212121"
      />
      <path
        d="M144.857 139.737C145.428 138.04 144.974 136.355 143.842 135.974C142.71 135.594 141.33 136.66 140.759 138.357C140.188 140.054 140.642 141.738 141.774 142.119C142.906 142.5 144.286 141.433 144.857 139.737Z"
        fill="#E0E0E0"
      />
      <path
        d="M183.519 165.168C185.309 165.168 186.761 163.717 186.761 161.926C186.761 160.136 185.309 158.685 183.519 158.685C181.728 158.685 180.277 160.136 180.277 161.926C180.277 163.717 181.728 165.168 183.519 165.168Z"
        fill="white"
      />
      <path
        d="M193.789 158.449C194.684 158.449 195.41 157.723 195.41 156.828C195.41 155.933 194.684 155.207 193.789 155.207C192.894 155.207 192.168 155.933 192.168 156.828C192.168 157.723 192.894 158.449 193.789 158.449Z"
        fill="white"
      />
      <g opacity="0.5">
        <g opacity="0.3">
          <path
            opacity="0.3"
            d="M160.315 57.5338L164.411 45.3807C165.819 41.1484 165.83 36.5756 164.443 32.3364C163.056 28.0972 160.343 24.4159 156.705 21.8351L156.738 21.8151C155.127 20.6811 153.361 19.7861 151.494 19.158L151.437 19.1381L151.374 19.1181C149.562 18.5051 147.672 18.1496 145.761 18.0619C145.085 18.027 144.408 18.027 143.732 18.0619C142.559 18.1233 141.393 18.2844 140.247 18.5435C139.915 18.6166 139.583 18.6996 139.281 18.7893C138.979 18.879 138.64 18.9753 138.324 19.0783C137.693 19.2875 137.072 19.5234 136.464 19.7924C136.159 19.9253 135.856 20.0648 135.561 20.2142C134.667 20.6556 133.807 21.1618 132.987 21.7288C132.711 21.9181 132.442 22.1141 132.176 22.3134C128.893 24.8101 126.436 28.2351 125.122 32.1448L118.685 51.2928C117.672 54.2958 117.367 57.4924 117.795 60.6327C117.861 61.1143 117.944 61.5926 118.04 62.0675C118.137 62.5425 118.256 63.0108 118.372 63.4758C118.462 63.7847 118.555 64.0936 118.658 64.3992C118.811 64.8542 118.99 65.3093 119.16 65.7543C119.283 66.0499 119.412 66.3455 119.548 66.6378C119.817 67.2191 120.116 67.787 120.438 68.3417C120.761 68.8964 121.103 69.4378 121.488 69.9626C121.767 70.3545 122.063 70.7398 122.368 71.1118C122.78 71.61 123.212 72.0916 123.67 72.55C125.654 74.5501 128.028 76.1205 130.645 77.1634L130.758 77.2099C130.899 76.9606 131.054 76.72 131.223 76.4892C132.22 75.1241 133.953 74.1642 135.604 74.5229C135.724 74.5495 135.843 74.586 135.963 74.6225C136.8 74.9181 137.623 75.4463 138.431 75.7053C138.724 75.8372 139.043 75.9035 139.365 75.8995C139.687 75.8955 140.004 75.8213 140.294 75.6821C141.908 74.8218 141.31 72.1082 142.695 70.9191C142.76 70.8643 142.827 70.8133 142.898 70.7664C142.878 70.7423 142.861 70.7167 142.845 70.69C143.914 70.0257 145.396 70.4674 146.628 70.8959C147.956 71.3642 149.69 71.6797 150.614 70.5936C151.238 69.8496 151.192 68.717 151.786 67.9365C152.477 67.0397 153.779 66.9401 154.905 67.0995H154.961C157.12 67.9896 159.478 66.5116 159.312 64.1833C159.116 61.0944 160.093 58.1682 160.315 57.5338Z"
            fill="url(#paint2_linear)"
          />
        </g>
        <path
          opacity="0.5"
          d="M131.598 75.4662C132.568 74.1376 134.232 73.2076 135.827 73.5696C137.421 73.9317 138.932 75.4496 140.35 74.689C142.29 73.6527 140.915 69.843 144.064 69.5607C144.72 69.5173 145.377 69.6312 145.98 69.8928C147.309 70.4342 149.388 71.0454 150.411 69.8264C151.012 69.109 150.969 68.0162 151.544 67.2722C152.208 66.4086 153.473 66.299 154.553 66.4651L154.606 66.4817C156.688 67.342 158.967 65.9137 158.804 63.6685C158.595 60.6792 159.545 57.8626 159.751 57.2515L163.697 45.5235C165.375 40.4958 164.995 35.0082 162.639 30.26C160.283 25.5118 156.144 21.8894 151.125 20.1843C146.098 18.5081 140.611 18.8899 135.865 21.2461C131.118 23.6023 127.497 27.7415 125.793 32.7592L119.572 51.2031C117.955 56.0539 118.251 61.3391 120.401 65.9784C122.551 70.6178 126.391 74.2611 131.137 76.1637C131.277 75.9223 131.431 75.6894 131.598 75.4662Z"
          fill="white"
        />
        <path
          opacity="0.2"
          d="M138.952 33.0782C140.146 33.0782 141.114 32.1101 141.114 30.9159C141.114 29.7217 140.146 28.7537 138.952 28.7537C137.758 28.7537 136.79 29.7217 136.79 30.9159C136.79 32.1101 137.758 33.0782 138.952 33.0782Z"
          fill="#212121"
        />
        <path
          opacity="0.2"
          d="M151.248 37.2133C152.442 37.2133 153.41 36.2452 153.41 35.051C153.41 33.8569 152.442 32.8888 151.248 32.8888C150.054 32.8888 149.086 33.8569 149.086 35.051C149.086 36.2452 150.054 37.2133 151.248 37.2133Z"
          fill="#212121"
        />
      </g>
      <path
        opacity="0.1"
        d="M189.162 33.025C189.779 35.4116 190.091 37.867 190.089 40.3322C190.089 60.6826 168.795 77.1801 142.526 77.1801C121.026 77.1668 102.861 66.1197 96.9856 50.9607C101.35 67.8236 120.568 80.5215 143.622 80.5215C169.891 80.5215 191.188 64.0239 191.188 43.6735C191.181 40.0289 190.494 36.4175 189.162 33.025Z"
        fill="#212121"
      />
      <g opacity="0.8">
        <path
          opacity="0.8"
          d="M143.486 85.7228C197.816 85.7228 241.86 74.2115 241.86 60.0116C241.86 45.8117 197.816 34.3004 143.486 34.3004C89.1551 34.3004 45.1116 45.8117 45.1116 60.0116C45.1116 74.2115 89.1551 85.7228 143.486 85.7228Z"
          fill="url(#paint3_linear)"
        />
      </g>
      <path
        d="M143.486 35.1506C90.9473 35.1506 48.3533 46.2807 48.3533 60.0148H238.618C238.618 46.2807 196.024 35.1506 143.486 35.1506Z"
        fill="white"
      />
      <path
        d="M143.486 84.8758C196.024 84.8758 238.618 73.7457 238.618 60.0149H48.3533C48.3533 73.7457 90.9473 84.8758 143.486 84.8758Z"
        fill="white"
      />
      <path
        opacity="0.05"
        d="M143.486 84.8758C196.024 84.8758 238.618 73.7457 238.618 60.0149H48.3533C48.3533 73.7457 90.9473 84.8758 143.486 84.8758Z"
        fill="#212121"
      />
      <path
        opacity="0.05"
        d="M183.17 53.5248H103.801C100.642 53.5248 98.0818 56.0855 98.0818 59.2443V60.7788C98.0818 63.9376 100.642 66.4983 103.801 66.4983H183.17C186.329 66.4983 188.89 63.9376 188.89 60.7788V59.2443C188.89 56.0855 186.329 53.5248 183.17 53.5248Z"
        fill="#212121"
      />
      <path
        opacity="0.5"
        d="M44.4707 19.2012C44.1963 19.0814 43.9753 18.8651 43.8498 18.5933C43.7243 18.3215 43.703 18.013 43.7898 17.7265C43.8037 17.6822 43.8126 17.6365 43.8164 17.5903C43.8248 17.4589 43.7906 17.3282 43.7188 17.2178C43.647 17.1073 43.5414 17.0231 43.4178 16.9775C43.2942 16.932 43.1592 16.9276 43.0329 16.965C42.9067 17.0024 42.7958 17.0796 42.717 17.1851C42.6904 17.2223 42.6681 17.2624 42.6505 17.3047C42.5314 17.5795 42.3151 17.8009 42.0431 17.9265C41.7711 18.0521 41.4623 18.0731 41.1758 17.9855C41.1315 17.972 41.0858 17.9631 41.0396 17.959C40.9082 17.9505 40.7775 17.9847 40.6671 18.0565C40.5567 18.1283 40.4724 18.2339 40.4269 18.3575C40.3813 18.4811 40.3769 18.6161 40.4143 18.7424C40.4517 18.8687 40.5289 18.9795 40.6344 19.0584C40.6711 19.0867 40.7113 19.1101 40.754 19.1281C41.0284 19.2479 41.2493 19.4642 41.3748 19.736C41.5003 20.0078 41.5217 20.3163 41.4349 20.6028C41.4213 20.646 41.4124 20.6906 41.4083 20.7357C41.399 20.8674 41.4327 20.9986 41.5042 21.1097C41.5757 21.2207 41.6813 21.3055 41.8051 21.3515C41.9289 21.3975 42.0642 21.4022 42.1909 21.3648C42.3176 21.3275 42.4287 21.2501 42.5077 21.1442C42.535 21.1063 42.5573 21.065 42.5741 21.0213C42.6962 20.7495 42.9137 20.5318 43.1854 20.4094C43.4572 20.287 43.7643 20.2684 44.0488 20.357C44.0932 20.3706 44.1388 20.3795 44.185 20.3836C44.3165 20.3921 44.4471 20.3579 44.5576 20.2861C44.668 20.2143 44.7522 20.1087 44.7978 19.9851C44.8433 19.8615 44.8478 19.7265 44.8103 19.6002C44.7729 19.4739 44.6957 19.3631 44.5902 19.2842C44.5543 19.2513 44.5141 19.2233 44.4707 19.2012Z"
        fill="#4D8AF0"
      />
      <path
        opacity="0.5"
        d="M213.531 120.837C213.26 120.714 213.043 120.497 212.92 120.225C212.798 119.953 212.779 119.647 212.867 119.362C212.881 119.318 212.89 119.272 212.893 119.226C212.902 119.094 212.868 118.964 212.796 118.853C212.724 118.743 212.619 118.659 212.495 118.613C212.371 118.568 212.236 118.563 212.11 118.601C211.984 118.638 211.873 118.715 211.794 118.821C211.767 118.858 211.745 118.898 211.728 118.94C211.609 119.215 211.392 119.437 211.12 119.562C210.848 119.688 210.539 119.709 210.253 119.621C210.209 119.608 210.163 119.599 210.117 119.595C209.985 119.586 209.855 119.62 209.744 119.692C209.634 119.764 209.55 119.87 209.504 119.993C209.458 120.117 209.454 120.252 209.491 120.378C209.529 120.504 209.606 120.615 209.712 120.694C209.748 120.722 209.788 120.746 209.831 120.764C210.103 120.886 210.32 121.104 210.442 121.376C210.564 121.647 210.583 121.954 210.495 122.238C210.482 122.282 210.473 122.326 210.469 122.371C210.46 122.503 210.493 122.634 210.565 122.745C210.636 122.856 210.742 122.941 210.866 122.987C210.989 123.033 211.125 123.038 211.251 123C211.378 122.963 211.489 122.886 211.568 122.78C211.596 122.742 211.618 122.701 211.635 122.657C211.757 122.385 211.974 122.167 212.246 122.045C212.518 121.923 212.825 121.904 213.109 121.993C213.154 122.006 213.199 122.015 213.246 122.019C213.377 122.028 213.508 121.993 213.618 121.922C213.729 121.85 213.813 121.744 213.858 121.621C213.904 121.497 213.908 121.362 213.871 121.236C213.833 121.11 213.756 120.999 213.651 120.92C213.615 120.887 213.575 120.859 213.531 120.837Z"
        fill="#4D8AF0"
      />
      <path
        opacity="0.5"
        d="M26.8672 168.001C26.5927 167.881 26.3718 167.665 26.2463 167.393C26.1208 167.121 26.0994 166.813 26.1863 166.526C26.2002 166.482 26.2091 166.436 26.2128 166.39C26.2213 166.259 26.1871 166.128 26.1153 166.018C26.0435 165.907 25.9379 165.823 25.8143 165.777C25.6907 165.732 25.5557 165.728 25.4294 165.765C25.3031 165.802 25.1923 165.879 25.1134 165.985C25.0868 166.022 25.0646 166.062 25.047 166.105C24.9278 166.379 24.7116 166.601 24.4396 166.726C24.1676 166.852 23.8588 166.873 23.5723 166.785C23.528 166.772 23.4823 166.763 23.4361 166.759C23.3047 166.75 23.174 166.785 23.0636 166.856C22.9532 166.928 22.8689 167.034 22.8234 167.157C22.7778 167.281 22.7734 167.416 22.8108 167.542C22.8482 167.669 22.9254 167.779 23.0309 167.858C23.0676 167.887 23.1078 167.91 23.1505 167.928C23.4249 168.048 23.6458 168.264 23.7713 168.536C23.8968 168.808 23.9182 169.116 23.8314 169.403C23.8177 169.446 23.8088 169.491 23.8048 169.536C23.7955 169.667 23.8291 169.799 23.9007 169.91C23.9722 170.021 24.0778 170.105 24.2016 170.151C24.3253 170.197 24.4607 170.202 24.5874 170.165C24.714 170.127 24.8252 170.05 24.9042 169.944C24.9315 169.906 24.9538 169.865 24.9706 169.821C25.0927 169.549 25.3102 169.332 25.5819 169.209C25.8537 169.087 26.1608 169.068 26.4453 169.157C26.4897 169.171 26.5353 169.179 26.5815 169.184C26.713 169.192 26.8436 169.158 26.954 169.086C27.0645 169.014 27.1487 168.909 27.1943 168.785C27.2398 168.661 27.2442 168.526 27.2068 168.4C27.1694 168.274 27.0922 168.163 26.9867 168.084C26.9508 168.051 26.9106 168.023 26.8672 168.001Z"
        fill="#4D8AF0"
      />
      <path
        opacity="0.5"
        d="M8.59909 58.0619C8.32771 57.9392 8.11045 57.7217 7.98814 57.4501C7.86582 57.1786 7.84685 56.8717 7.9348 56.5872C7.94874 56.5429 7.95766 56.4973 7.96137 56.451C7.96985 56.3196 7.93563 56.1889 7.86382 56.0785C7.79201 55.9681 7.68644 55.8838 7.56285 55.8382C7.43926 55.7927 7.30426 55.7883 7.17797 55.8257C7.05167 55.8631 6.94084 55.9403 6.86198 56.0458C6.83538 56.083 6.8131 56.1231 6.79555 56.1654C6.67638 56.4403 6.46014 56.6616 6.18813 56.7872C5.91611 56.9128 5.60736 56.9338 5.32084 56.8463C5.2765 56.8327 5.23085 56.8238 5.18466 56.8197C5.05321 56.8112 4.92255 56.8454 4.81213 56.9172C4.70171 56.9891 4.61744 57.0946 4.57189 57.2182C4.52634 57.3418 4.52193 57.4768 4.55934 57.6031C4.59674 57.7294 4.67394 57.8402 4.77944 57.9191C4.81613 57.9474 4.85632 57.9708 4.89901 57.9888C5.17343 58.1086 5.39435 58.3249 5.51986 58.5967C5.64536 58.8686 5.66673 59.177 5.57991 59.4635C5.56628 59.5067 5.55737 59.5513 5.55334 59.5964C5.54405 59.7281 5.57768 59.8593 5.6492 59.9704C5.72073 60.0814 5.82629 60.1663 5.95009 60.2123C6.07388 60.2583 6.20925 60.2629 6.33592 60.2255C6.46258 60.1882 6.57373 60.1108 6.65273 60.0049C6.68002 59.967 6.70235 59.9257 6.71916 59.882C6.84127 59.6102 7.05874 59.3925 7.33047 59.2701C7.6022 59.1477 7.90935 59.1291 8.19387 59.2178C8.23821 59.2313 8.28385 59.2403 8.33005 59.2443C8.4615 59.2528 8.59216 59.2186 8.70258 59.1468C8.813 59.075 8.89726 58.9694 8.94281 58.8458C8.98837 58.7222 8.99277 58.5872 8.95537 58.4609C8.91797 58.3346 8.84077 58.2238 8.73527 58.1449C8.69425 58.1106 8.64835 58.0827 8.59909 58.0619Z"
        fill="#4D8AF0"
      />
      <path
        opacity="0.5"
        d="M210.21 2.26186C209.939 2.13918 209.721 1.92163 209.599 1.65009C209.477 1.37855 209.458 1.07168 209.546 0.787145C209.56 0.742894 209.568 0.697211 209.572 0.650965C209.581 0.51952 209.546 0.388854 209.475 0.278434C209.403 0.168015 209.297 0.0837517 209.174 0.0381989C209.05 -0.00735394 208.915 -0.0117578 208.789 0.0256427C208.662 0.0630432 208.552 0.140246 208.473 0.24575C208.446 0.282966 208.424 0.32308 208.406 0.365322C208.284 0.637181 208.067 0.854887 207.795 0.977287C207.523 1.09969 207.216 1.1183 206.932 1.02961C206.887 1.01602 206.842 1.00711 206.795 1.00304C206.652 0.973002 206.503 0.995321 206.375 1.06594C206.247 1.13655 206.148 1.25078 206.097 1.38786C206.046 1.52495 206.046 1.6758 206.097 1.81302C206.147 1.95023 206.246 2.06471 206.374 2.13564C206.41 2.16395 206.451 2.18739 206.493 2.20539C206.765 2.32807 206.982 2.54562 207.104 2.81716C207.226 3.08871 207.245 3.39557 207.157 3.68011C207.144 3.72331 207.135 3.76785 207.131 3.81297C207.117 3.94973 207.149 4.08724 207.223 4.20326C207.297 4.31929 207.408 4.40705 207.537 4.45236C207.667 4.49768 207.809 4.4979 207.938 4.453C208.068 4.40809 208.179 4.32068 208.254 4.2049C208.281 4.16693 208.303 4.12563 208.32 4.082C208.442 3.81014 208.66 3.59244 208.931 3.47004C209.203 3.34764 209.51 3.32902 209.795 3.41772C209.839 3.4313 209.885 3.44021 209.931 3.44429C210.062 3.45276 210.193 3.41855 210.303 3.34673C210.414 3.27492 210.498 3.16936 210.544 3.04577C210.589 2.92218 210.594 2.78718 210.556 2.66088C210.519 2.53458 210.442 2.42375 210.336 2.34489C210.298 2.31142 210.256 2.28346 210.21 2.26186Z"
        fill="#4D8AF0"
      />
      <g opacity="0.5">
        <path
          opacity="0.5"
          d="M37.5321 85.6431H36.5356V91.2895H37.5321V85.6431Z"
          fill="#47E6B1"
        />
        <path
          opacity="0.5"
          d="M39.8572 88.9644V87.968H34.2108V88.9644H39.8572Z"
          fill="#47E6B1"
        />
      </g>
      <g opacity="0.5">
        <path
          opacity="0.5"
          d="M276.675 59.0717H275.678V64.7181H276.675V59.0717Z"
          fill="#47E6B1"
        />
        <path
          opacity="0.5"
          d="M279 62.393V61.3966H273.354V62.393H279Z"
          fill="#47E6B1"
        />
      </g>
      <g opacity="0.5">
        <path
          opacity="0.5"
          d="M243.461 151.74H242.464V157.386H243.461V151.74Z"
          fill="#47E6B1"
        />
        <path
          opacity="0.5"
          d="M245.786 155.061V154.064H240.139V155.061H245.786Z"
          fill="#47E6B1"
        />
      </g>
      <path
        opacity="0.5"
        d="M240.139 29.1787C241.24 29.1787 242.132 28.2865 242.132 27.1859C242.132 26.0852 241.24 25.193 240.139 25.193C239.039 25.193 238.146 26.0852 238.146 27.1859C238.146 28.2865 239.039 29.1787 240.139 29.1787Z"
        fill="#F55F44"
      />
      <path
        opacity="0.5"
        d="M1.99286 6.26086C3.09348 6.26086 3.98571 5.36863 3.98571 4.268C3.98571 3.16738 3.09348 2.27515 1.99286 2.27515C0.892233 2.27515 0 3.16738 0 4.268C0 5.36863 0.892233 6.26086 1.99286 6.26086Z"
        fill="#F55F44"
      />
      <path
        opacity="0.5"
        d="M61.7785 120.85C62.8791 120.85 63.7714 119.958 63.7714 118.857C63.7714 117.757 62.8791 116.865 61.7785 116.865C60.6779 116.865 59.7856 117.757 59.7856 118.857C59.7856 119.958 60.6779 120.85 61.7785 120.85Z"
        fill="#F55F44"
      />
      <path
        opacity="0.05"
        d="M77.7213 53.6178C83.9582 53.6178 89.0142 52.5768 89.0142 51.2928C89.0142 50.0087 83.9582 48.9678 77.7213 48.9678C71.4845 48.9678 66.4285 50.0087 66.4285 51.2928C66.4285 52.5768 71.4845 53.6178 77.7213 53.6178Z"
        fill="#212121"
      />
      <path
        opacity="0.05"
        d="M77.7213 71.2214C83.9582 71.2214 89.0142 70.1805 89.0142 68.8964C89.0142 67.6124 83.9582 66.5714 77.7213 66.5714C71.4845 66.5714 66.4285 67.6124 66.4285 68.8964C66.4285 70.1805 71.4845 71.2214 77.7213 71.2214Z"
        fill="#212121"
      />
      <path
        opacity="0.05"
        d="M206.261 53.6178C212.498 53.6178 217.553 52.5768 217.553 51.2928C217.553 50.0087 212.498 48.9678 206.261 48.9678C200.024 48.9678 194.968 50.0087 194.968 51.2928C194.968 52.5768 200.024 53.6178 206.261 53.6178Z"
        fill="#212121"
      />
      <path
        opacity="0.05"
        d="M206.261 71.2214C212.498 71.2214 217.553 70.1805 217.553 68.8964C217.553 67.6124 212.498 66.5714 206.261 66.5714C200.024 66.5714 194.968 67.6124 194.968 68.8964C194.968 70.1805 200.024 71.2214 206.261 71.2214Z"
        fill="#212121"
      />
      <path
        opacity="0.05"
        d="M143.486 43.6535C149.723 43.6535 154.779 42.6126 154.779 41.3285C154.779 40.0445 149.723 39.0035 143.486 39.0035C137.249 39.0035 132.193 40.0445 132.193 41.3285C132.193 42.6126 137.249 43.6535 143.486 43.6535Z"
        fill="#212121"
      />
      <path
        opacity="0.05"
        d="M151.789 19.4071C158.026 19.4071 163.082 18.3661 163.082 17.0821C163.082 15.798 158.026 14.7571 151.789 14.7571C145.552 14.7571 140.496 15.798 140.496 17.0821C140.496 18.3661 145.552 19.4071 151.789 19.4071Z"
        fill="#212121"
      />
      <path
        opacity="0.05"
        d="M130.2 29.3714C136.437 29.3714 141.493 28.3305 141.493 27.0464C141.493 25.7624 136.437 24.7214 130.2 24.7214C123.963 24.7214 118.907 25.7624 118.907 27.0464C118.907 28.3305 123.963 29.3714 130.2 29.3714Z"
        fill="#212121"
      />
      <path
        opacity="0.05"
        d="M171.718 33.6893C177.955 33.6893 183.011 32.6484 183.011 31.3643C183.011 30.0802 177.955 29.0393 171.718 29.0393C165.481 29.0393 160.425 30.0802 160.425 31.3643C160.425 32.6484 165.481 33.6893 171.718 33.6893Z"
        fill="#212121"
      />
      <path
        opacity="0.05"
        d="M143.486 78.1964C149.723 78.1964 154.779 77.1554 154.779 75.8714C154.779 74.5873 149.723 73.5464 143.486 73.5464C137.249 73.5464 132.193 74.5873 132.193 75.8714C132.193 77.1554 137.249 78.1964 143.486 78.1964Z"
        fill="#212121"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="143.486"
        y1="81.634"
        x2="143.486"
        y2="6.26086"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#808080" stopOpacity="0.25" />
        <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
        <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="-15643.4"
        y1="114342"
        x2="-12132.2"
        y2="103918"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#808080" stopOpacity="0.25" />
        <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
        <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1="-13604.5"
        y1="91316.2"
        x2="-10093.3"
        y2="80892.2"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#808080" stopOpacity="0.25" />
        <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
        <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
      </linearGradient>
      <linearGradient
        id="paint3_linear"
        x1="85040.3"
        y1="13305.9"
        x2="85040.3"
        y2="5344.69"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#808080" stopOpacity="0.25" />
        <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
        <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
      </linearGradient>
      <clipPath id="clip-ufo">
        <rect width="279" height="183" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default ErrorSvg;
