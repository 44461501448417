import React, { useRef, useCallback, useEffect } from "react";
import { CheckInput } from "ibolit-ui";
import PropTypes from "prop-types";
import cn from "classnames";
import _ from "lodash";

const RadioGroup = React.forwardRef(
  (
    { className, style, options, name = "radio", onChange, initialValue },
    ref
  ) => {
    const inputRef = ref || useRef();

    useEffect(() => {
      inputRef.current = { value: initialValue };
    }, []);

    const handleChange = useCallback(
      value => () => {
        inputRef.current.value = value;
        onChange && onChange(inputRef.current.value);
      },
      []
    );

    return (
      <div className={cn(className)} style={style}>
        {options.map(({ label, value, testidProps }) => (
          <CheckInput
            type="radio"
            key={`l${label}-v${value}`}
            onChange={handleChange(value)}
            name={name}
            checked={_.isEqual(initialValue, value)}
            text={label}
            testidProps={testidProps}
          />
        ))}
      </div>
    );
  }
);

RadioGroup.propTypes = {
  /** Массив данных радиокнопок */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      /** Лейбл кнопки */
      label: PropTypes.node,
      /** Значение которое будет установлено при нажатии */
      value: PropTypes.any
    })
  ).isRequired,
  /** Начальное значение, может быть value одного из options или индексом options */
  initialValue: PropTypes.any,
  onChange: PropTypes.func
};

export default RadioGroup;
