import React from "react";
import PropTypes from "prop-types";
import { Text } from "ibolit-ui";
import cn from "classnames";
import styles from "./TariffsListHeader.scss";

const TariffsListHeader = ({ title }) => (
  <div className={cn(styles.title)}>
    <Text tag="span" variant="h6" className={cn(styles.text)}>
      {title}
    </Text>
  </div>
);

TariffsListHeader.propTypes = {
  title: PropTypes.string.isRequired
};

export default TariffsListHeader;
