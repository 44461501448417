import axios from "./httpService";

export default {
  authorizeUserApi: () => axios.get("/v3/auth-user"),
  loginCheckApi: data => axios.post("/v3/check-login", data),
  passwordCheckApi: data => axios.post("/v3/check-password", data),
  codeSendApi: data => axios.post("/v3/send-code", data),
  codeValidateApi: data => axios.post("/v3/check-code", data),
  userActivateApi: data => axios.post("/v3/activate-user", data),
  passwordResetApi: data => axios.post("/v3/reset-password", data)
};
