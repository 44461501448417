import { schema } from "normalizr";
import { clinicSchema } from "../clinics/clinicsNomalizer";

const processStrategy = ({ doctor_new, ...value }) => {
  if (doctor_new !== undefined) {
    value.is_new = doctor_new;
  }
  return value;
};

export const userSchema = new schema.Entity(
  "users",
  { clinic: clinicSchema },
  { processStrategy }
);
