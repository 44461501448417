import React, { useState, useEffect } from "react";
import cn from "classnames";
import { Text } from "ibolit-ui";
import styles from "./Tooltip.scss";

const Tooltip = ({
  place,
  children,
  type = "dark",
  show,
  timeout = 100,
  className,
  style = {}
}) => {
  const [shown, setShown] = useState(false);
  useEffect(() => {
    setShown(show);
  }, [show]);
  return (
    <div
      className={cn(
        styles.container,
        styles[`place-${place}`],
        styles[`type-${type}`],
        { [styles.show]: shown },
        className
      )}
      style={{ ...style, ...(show && { transitionDelay: `${timeout}ms` }) }}
    >
      <Text variant="caption" colorVariant="secondary">
        {children}
      </Text>
    </div>
  );
};

export default Tooltip;
