import firebase from "firebase/app";
import { takeLatest, call, put, all } from "redux-saga/effects";
import types from "~/store/pushNotifications/pushNotificationTypes";
import actions from "~/store/pushNotifications/pushNotificationActions";
import notificationFileName from "~/firebase-messaging-sw";
import api from "~/api/pushNotification";
import { setPushToken } from "~/utils/localStorageHelper";

// From the firebase settings of the project
// https://console.firebase.google.com/u/1/project/ibolit-doctor/settings/general/web:NWZjNzUwM2ItZmJlZC00YzQ1LTllYjYtOTc1Y2JmZTJiZTUw
const firebaseConfig = {
  apiKey: "AIzaSyCuos0g206lylz4p7fnR8T-OeJg0PlLS2U",
  authDomain: "ibolit-doctor.firebaseapp.com",
  databaseURL: "https://ibolit-doctor.firebaseio.com",
  projectId: "ibolit-doctor",
  storageBucket: "ibolit-doctor.appspot.com",
  messagingSenderId: "388435634074",
  appId: "1:388435634074:web:813dd8b22e6a995a66bc23",
  measurementId: "G-EET181NCBG"
};

// From the firebase settings of the project
// https://console.firebase.google.com/u/1/project/ibolit-doctor/settings/cloudmessaging/ios:pro.ibolit.iBolitDoctor
const vapidKey =
  "BGAZhRO6gU8u8sPy5DUptSi0YzTCWlFyaxnMWris_OxWZE5b9n0UKjgZpKkAuSQK2Ccw09oG4rmClOVxrHQX6zg";

let messagingInstance = null;
let serviceWorkerRegistration = null;

export const getMessaging = () => {
  if (messagingInstance !== null) {
    return messagingInstance;
  }

  const app = firebase.initializeApp(firebaseConfig);
  messagingInstance = firebase.messaging(app);

  return messagingInstance;
};

const registration = async () => {
  if (!("serviceWorker" in navigator))
    throw new Error("Service Workers are not supported");
  serviceWorkerRegistration = await navigator.serviceWorker.register(
    notificationFileName
  );
  const permission = await Notification.requestPermission();
  if (permission !== "granted") {
    throw new Error("Разрешение на отображение уведомлений не получено");
  }
  const messaging = getMessaging();
  const token = await messaging.getToken({
    vapidKey,
    serviceWorkerRegistration
  });

  return token;
};

function* registrationSaga() {
  try {
    const token = yield registration();
    if (!token) {
      throw new Error("PUSH-токен не получен");
    }
    yield call(api.sendTokenToServer, token);
    setPushToken(token);
    yield put(actions.registrationSuccess());
  } catch (err) {
    yield put(actions.error(err));
  }
}

function pushNotification() {
  try {
    const messaging = getMessaging();

    // onMessage is called when the notification arrives in the foreground
    messaging.onMessage(event => {
      // The service worker is responsible for showing the notifications. send a message to the
      // service worker, so it could show a notification even when it arrives in the foreground
      serviceWorkerRegistration.active.postMessage({
        notification: event.notification,
        notificationData: event.data
      });
    });
  } catch (err) {
    console.warn(err);
  }
}

export default function* slots() {
  yield all([
    takeLatest(types.PUSH_NOTIFICATION_REGISTRATION, registrationSaga),
    takeLatest(types.PUSH_NOTIFICATION_REGISTRATION_SUCCESS, pushNotification)
  ]);
}
