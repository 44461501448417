import React, { useMemo } from "react";
import cn from "classnames";
import { Badge, Text } from "ibolit-ui";
import UserAvatar from "~/components/UserAvatar";
import RowWithImage from "~/components/RowWithImage";
import { CONTACT } from "~/locators/contacts";
import styles from "./PublicProfile.scss";
import UserInfo from "~/components/UserItem/UserInfo";
import { DATE_FORMAT_DMY, getTzDate } from "~/utils/dateUtils";

const Header = ({ user, variant }) => {
  const { full_name, created_at, date_of_birth } = user;
  const active = variant === "inChat" ? true : user.active;
  const rowProps = useMemo(
    () => ({
      image: <UserAvatar testid={CONTACT.AVATAR} size="large" user={user} />,
      title: (
        <Text
          variant="h2"
          tag="h2"
          colorVariant={active ? "primary" : "tertiary"}
          className={styles.title}
          testid={CONTACT.NAME}
        >
          {full_name}
        </Text>
      ),
      info: date_of_birth && date_of_birth.length > 0 && (
        <UserInfo user={user} variant="desc" />
      ),
      testid: CONTACT.HEADER_DESCRIPTION,
      ...(!active && {
        titleRight: <Badge variant="tertiary">неактивен</Badge>
      })
    }),
    [user]
  );

  return (
    <RowWithImage
      className={cn(styles.header, styles.block)}
      contentClass={styles.headerContentClass}
      infoClass={styles.headerInfo}
      {...rowProps}
    >
      {created_at && (
        <Text variant="desc" colorVariant="tertiary" testid={CONTACT.CREATED}>
          {`Профиль создан: ${getTzDate(user.created_at).format(
            DATE_FORMAT_DMY
          )}`}
        </Text>
      )}
    </RowWithImage>
  );
};

export default Header;
