export default {
  LOGIN_CHECK_REQUEST: "auth/LOGIN_CHECK_REQUEST",
  LOGIN_CHECK_SUCCESS: "auth/LOGIN_CHECK_SUCCESS",
  LOGIN_CHECK_FAILURE: "auth/LOGIN_CHECK_FAILURE",
  LOGIN_CHECK_TIMEOUT: "auth/LOGIN_CHECK_TIMEOUT",
  PASSWORD_CHECK_REQUEST: "auth/PASSWORD_CHECK_REQUEST",
  PASSWORD_CHECK_SUCCESS: "auth/PASSWORD_CHECK_SUCCESS",
  PASSWORD_CHECK_FAILURE: "auth/PASSWORD_CHECK_FAILURE",
  PASSWORD_CHECK_TIMEOUT: "auth/LOGIN_CHECK_TIMEOUT",
  CODE_SEND_REQUEST: "auth/CODE_SEND_REQUEST",
  CODE_SEND_SUCCESS: "auth/CODE_SEND_SUCCESS",
  CODE_SEND_FAILURE: "auth/CODE_SEND_FAILURE",
  CODE_VALIDATE_REQUEST: "auth/CODE_VALIDATE_REQUEST",
  CODE_VALIDATE_SUCCESS: "auth/CODE_VALIDATE_SUCCESS",
  CODE_VALIDATE_FAILURE: "auth/CODE_VALIDATE_FAILURE",
  USER_ACTIVATE_REQUEST: "auth/USER_ACTIVATE_REQUEST",
  USER_ACTIVATE_SUCCESS: "auth/USER_ACTIVATE_SUCCESS",
  USER_ACTIVATE_FAILURE: "auth/USER_ACTIVATE_FAILURE",
  PASSWORD_RESET_REQUEST: "auth/PASSWORD_RESET_REQUEST",
  PASSWORD_RESET_SUCCESS: "auth/PASSWORD_RESET_SUCCESS",
  PASSWORD_RESET_FAILURE: "auth/PASSWORD_RESET_FAILURE",
  USER_AUTHORIZE_REQUEST: "auth/USER_AUTHORIZE_REQUEST",
  USER_AUTHORIZE_SUCCESS: "auth/USER_AUTHORIZE_SUCCESS",
  USER_AUTHORIZE_FAILURE: "auth/USER_AUTHORIZE_FAILURE",
  CLINICS_FETCH_REQUEST: "auth/CLINICS_FETCH_REQUEST",
  CLINICS_FETCH_SUCCESS: "auth/CLINICS_FETCH_SUCCESS",
  CLINICS_FETCH_FAILURE: "auth/CLINICS_FETCH_FAILURE",
  STEP_BACK: "auth/STEP_BACK",
  SET_STEP: "auth/SET_STEP",
  LOGOUT: "auth/LOGOUT"
};
