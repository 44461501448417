import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import uiActions from "~/store/ui/uiActions";
import LoginError from "../components/Modals/LoginError";

export const AUTH_LOGIN_ERROR_VIEW = "authLoginError";

export default function LoginErrorModal() {
  const dispatch = useDispatch();
  const onBackClick = useCallback(() => {
    dispatch(uiActions.hideModal(AUTH_LOGIN_ERROR_VIEW));
  }, []);
  return <LoginError onBackClick={onBackClick} />;
}
