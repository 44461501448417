import Immutable from "seamless-immutable";
import types from "~/store/profile/profileTypes";
import { DELETE_CONSULTATION_RATING } from "~/store/consultationRatings/types";

const DEFAULT_TIMEOUT = 60;

const statisticsInitial = {
  items: {},
  ids: [],
  page: 1,
  hasNextPage: true,
  loading: false,
  success: null
};

const initialState = Immutable({
  loadingAvatar: false,
  loadingUserData: false,
  successUpdateUserData: null,
  user: null,
  statistics: statisticsInitial,
  tariffs: {},
  loadingTariffs: true,

  settingsScene: null,
  settingsBackScene: null,

  loadingChangePassword: false,
  loadingChangeLogin: false,
  loadingConfirm: false,
  settingsLogin: {},
  settingsConfirmCode: "",
  settingsConfirmTimeout: DEFAULT_TIMEOUT,

  doctorsStats: null
});

const profileStatisticsSuccessReducer = (state, action) => {
  const { items, order, page } = action.data;
  const ids = [...new Set([...state.statistics.ids, ...order])];
  return state.merge(
    {
      statistics: {
        loading: false,
        success: true,
        items,
        ids,
        page,
        hasNextPage: order.length === 20
      }
    },
    { deep: true }
  );
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.PROFILE_USER_DATA_LOAD_REQUEST: {
      return state.merge({
        loadingUserData: true
      });
    }

    case types.PROFILE_USER_DATA_LOAD_SUCCESS: {
      return state.merge(
        {
          loadingUserData: false,
          user: action.data
        },
        { deep: !!action.deep }
      );
    }

    case types.PROFILE_USER_DATA_UPDATE_SUCCESS: {
      return state.merge({
        successUpdateUserData: true,
        loadingUserData: false,
        loadingAvatar: false,
        user: action.data
      });
    }

    case types.PROFILE_USER_AVATAR_REQUEST: {
      return state.merge({
        successUpdateUserData: null,
        loadingAvatar: true
      });
    }

    case types.PROFILE_USER_DATA_UPDATE_REQUEST: {
      return state.merge({
        successUpdateUserData: null,
        loadingUserData: true
      });
    }

    case types.PROFILE_USER_DATA_UPDATE_ERROR: {
      return state.merge({
        loadingUserData: false
      });
    }

    case types.PROFILE_PASSWORD_CHANGE_REQUEST: {
      return state.merge({
        loadingChangePassword: true
      });
    }
    case types.PROFILE_PASSWORD_CHANGE_SUCCESS: {
      return state.merge({
        loadingChangePassword: false
      });
    }
    case types.PROFILE_PASSWORD_CHANGE_ERROR: {
      return state.merge({
        loadingChangePassword: false
      });
    }
    case types.PROFILE_PASSWORD_CHANGE_VALIDATION_ERROR: {
      return state.merge({
        loadingChangePassword: false
      });
    }

    case types.PROFILE_LOGIN_CHANGE_REQUEST: {
      return state.merge({
        loadingChangeLogin: true
      });
    }
    case types.PROFILE_LOGIN_CHANGE_SUCCESS: {
      return state.merge({
        loadingChangeLogin: false,
        settingsLogin: action.values,
        settingsBackScene: action.scene,
        settingsConfirmTimeout: action.timeout
      });
    }
    case types.PROFILE_LOGIN_CHANGE_ERROR: {
      return state.merge({
        loadingChangeLogin: false
      });
    }
    case types.PROFILE_LOGIN_CHANGE_VALIDATION_ERROR: {
      return state.merge({
        loadingChangeLogin: false
      });
    }

    case types.PROFILE_LOGIN_RESET_SETTINGS: {
      return state.merge({
        settingsLogin: {},
        settingsConfirmCode: "",
        settingsBackScene: null
      });
    }

    case types.PROFILE_SETTINGS_CONFIRM_REQUEST: {
      return state.merge({
        loadingConfirm: true,
        settingsConfirmCode: action.code
      });
    }
    case types.PROFILE_SETTINGS_CONFIRM_SUCCESS: {
      return state.merge({
        loadingConfirm: false,
        user: { ...state.user, ...action.value }
      });
    }
    case types.PROFILE_SETTINGS_CONFIRM_VALIDATION_ERROR: {
      return state.merge({
        loadingConfirm: false
      });
    }
    case types.PROFILE_SETTINGS_CONFIRM_ERROR: {
      return state.merge({
        loadingConfirm: false
      });
    }

    case types.PROFILE_SETTINGS_CODE_SEND: {
      return state.merge({
        loadingConfirm: true
      });
    }

    case types.PROFILE_SETTINGS_CODE_SEND_SUCCESS: {
      return state.merge({
        settingsConfirmTimeout: action.timeout,
        loadingConfirm: false
      });
    }
    case types.PROFILE_SETTINGS_CODE_SEND_ERROR: {
      return state.merge({
        loadingConfirm: false
      });
    }

    case types.PROFILE_STATISTICS_REQUEST: {
      return state.merge(
        { statistics: { loading: true, success: null } },
        { deep: true }
      );
    }

    case types.PROFILE_STATISTICS_SUCCESS: {
      return profileStatisticsSuccessReducer(state, action);
    }

    case types.PROFILE_STATISTICS_CLEAR: {
      return state.merge(statisticsInitial);
    }

    case types.PROFILE_USER_DATA_DEFAULT_STATE_SET: {
      return state.merge({
        ...initialState
      });
    }

    case types.PROFILE_SETTINGS_CHANGE_SCENE: {
      return state.merge({
        settingsScene: action.settingsScene
      });
    }

    case types.PROFILE_TARIFFS_REQUEST: {
      return state.merge({
        loadingTariffs: true
      });
    }

    case types.PROFILE_TARIFFS_SUCCESS: {
      return state.merge({
        tariffs: action.payload,
        loadingTariffs: false
      });
    }

    case types.DOCTORS_STATS_SUCCESS: {
      return state.merge({
        doctorsStats: action.payload
      });
    }

    case DELETE_CONSULTATION_RATING.SUCCESS:
      return state.merge({
        user: {
          ...state.user,
          consultation_ratings_count: state.user.consultation_ratings_count - 1
        }
      });

    default:
      return state;
  }
};
