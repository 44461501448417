import { createRef, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import actions from "~/store/users/usersActions";
import { USERS_MODE_ALL } from "~/store/users/usersModel";

const CONTACTS_SEARCH_ITEMS_LIMIT = 20;

export function useUsersList(initialFilter) {
  const listRef = createRef();
  const [filter, setFilter] = useState(initialFilter);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();

  const fetchItems = useCallback((...args) => {
    dispatch(actions.requestSend(...args));
  });

  const incrementPage = useCallback(() => {
    setPage(page + 1);
  }, [page]);

  useEffect(() => {
    fetchItems({
      filter,
      page,
      mode: USERS_MODE_ALL,
      limit: CONTACTS_SEARCH_ITEMS_LIMIT,
      search
    });
  }, [filter, page, search]);

  useEffect(() => {
    setPage(1);
    listRef.current && listRef.current.scrollTop();
  }, [filter, search]);

  return {
    listRef,
    filter,
    setFilter,
    setSearch,
    incrementPage
  };
}
