import memoize from "lodash/memoize";
import { createSelector } from "reselect";
import { getDayOrDateText } from "~/utils/dateUtils";

const getPartState = state => state.messages || {};
const getChatId = (_, { chatId }) => chatId;

const getMessagesById = createSelector(
  getPartState,
  getChatId,
  (messages, id) => messages.getIn([id], { items: {}, order: [] })
);

const memoizedGetItemsByOrder = memoize((order = [], items = {}) => {
  let listDate = "";
  return [...order]
    .map(messageId => items[messageId])
    .filter(Boolean)
    .reverse()
    .reduce((acc, curr, index) => {
      const textDate = getDayOrDateText(curr.created_at);
      if (listDate !== textDate && index !== items.length - 1) {
        acc.push({
          type: "_date",
          id: textDate,
          text: textDate
        });
      }
      listDate = textDate;
      acc.push(curr);
      return acc;
    }, []);
});

export const messagesSelector = createSelector(getMessagesById, messages => {
  const {
    items,
    canLoadMore,
    page,
    order,
    isFetching,
    initialFetched
  } = messages;
  return {
    items: memoizedGetItemsByOrder(order, items),
    isSuccess: initialFetched,
    loading: isFetching,
    canLoadMore,
    page
  };
});
