/**
 * ROOT SAGA
 * Attempts to restart provided saga branches automatically on error
 */
import { all, call } from "redux-saga/effects";
import toastifySaga from "./toast/toastifySaga";
import notificationSaga from "./notificationSaga";
import commonSaga from "./commons/commonSaga";
import appStartedSaga from "./appStarted/appStartedSaga";
import profileSaga from "./profile/profileSaga";
import feedbackSaga from "./feedback/feedbackSaga";
import faqSaga from "./faq/faqSaga";
import authSaga from "./auth/authSaga";
import usersSaga from "./users/usersSaga";
import chatsSaga from "./chats/chatsSaga";
import contactsSaga from "./contacts/contactsSaga";
import clinicsSaga from "./clinics/clinicsSaga";
import webSocketSaga from "./webSocket/webSocketSaga";
import messagesSaga from "./messages/messagesSaga";
import toolbarSaga from "./toolbar/toolbarSaga";
import slotsSaga from "./slots/slotsSaga";
import consultationRatingsSaga from "./consultationRatings/saga";
import consultationsSaga from "./consultations/consultationsSaga";
import conclusionsSaga from "./conclusions/conclusionsSaga";
import callSaga from "./call/callSaga";
import pushNotificationSaga from "./pushNotifications/pushNotificationSaga";
import servicesSaga from "./services/servicesSaga";
import nativeSocketSaga from "~/store/nativeSocket/nativeSocketSaga";
import uiSaga from "./ui/uiSaga";

export default function* rootSaga() {
  yield all([
    ...commonSaga,
    ...toastifySaga,
    ...notificationSaga,
    call(authSaga),
    call(chatsSaga),
    call(clinicsSaga),
    call(contactsSaga),
    call(usersSaga),
    call(appStartedSaga),
    call(profileSaga),
    call(feedbackSaga),
    call(faqSaga),
    call(messagesSaga),
    call(toolbarSaga),
    call(slotsSaga),
    call(consultationsSaga),
    call(conclusionsSaga),
    call(pushNotificationSaga),
    call(servicesSaga),
    call(uiSaga),
    ...webSocketSaga,
    ...nativeSocketSaga,
    call(callSaga),
    call(consultationRatingsSaga)
  ]);
}
