import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import uiActions from "~/store/ui/uiActions";
import ConnectionCheck from "~/components/HealthCheck/ConnectionCheck";

export const CONNECTION_CHECK_VIEW = "connectionCheck";

export default function ConnectionCheckContainer(props) {
  const dispatch = useDispatch();
  const onCloseClick = useCallback(
    () => dispatch(uiActions.hideModal(CONNECTION_CHECK_VIEW)),
    []
  );

  return <ConnectionCheck {...props} onCloseClick={onCloseClick} isModal />;
}
