import React, { useRef, useState, useEffect } from "react";
import { Button, Dialog, Select } from "ibolit-ui";
import moment from "moment-timezone";
import { useDispatch, useSelector } from "react-redux";
import Field from "~/components/Field";
import TextInput from "~/components/inputs/TextInput/TextInput";
import DateInput from "~/components/inputs/DateInput";
import PrivateCommentField from "~/modals/Comment/PrivateCommentField";
import { SERVER_DATE_FORMAT } from "~/utils/dateUtils";
import { checkValidFullName } from "~/utils/validations";
import { INVITATION_MODAL, PATIENT_ADD } from "~/locators/invitation";
import contactActions from "~/store/contacts/contactsActions";
import { getContactsLogin } from "~/store/contacts/contactsSelector";
import Loading from "~/components/Loading/Loading";
import styles from "./InvitationModal.scss";
import { CHECK_EXISTS } from "~/store/contacts/contactsReducer";
import SNILSInput from "~/components/inputs/SNILSInput";

const fullNameValidator = value => {
  if (value.length > 40) return [false, "Не более 40 символов"];
  if (checkValidFullName(value)) return true;
  return [false, 'Только буквы, цифры, пробел, символы "_" или "-"'];
};

const UserAdd = ({ testId, loading, hideModal, error: errors }) => {
  const dispatch = useDispatch();
  const login = useSelector(getContactsLogin);
  const [canSubmit, setCanSubmit] = useState(false);
  const [gender, setGender] = useState(null);
  const fullNameRef = useRef(null);
  const dateOfBirthRef = useRef(null);
  const snilsRef = useRef(null);
  const commentRef = useRef(null);

  useEffect(() => {
    checkCanSubmit();
  }, [gender]);

  const checkCanSubmit = () => {
    try {
      setCanSubmit(fullNameRef.current.isValid && snilsRef.current.isValid);
    } catch (e) {
      setCanSubmit(false);
    }
  };

  const handleSubmit = () => {
    const full_name = fullNameRef.current.value;
    const snils = snilsRef.current.value;
    const dateOfBirth = dateOfBirthRef.current.value;
    const comment = commentRef.current.value;

    dispatch(
      contactActions.addNewRequest({
        full_name,
        snils: snils.replaceAll("-", ""),
        gender,
        date_of_birth:
          dateOfBirth === ""
            ? null
            : moment(dateOfBirth, "DD.MM.YYYY").format(SERVER_DATE_FORMAT),
        comment,
        [/@/.test(login) ? "email" : "phone"]: login
      })
    );
  };

  const handleCancel = () =>
    dispatch(contactActions.setInvitationScene(CHECK_EXISTS));

  const handleGenderChange = item => {
    setGender(item.value);
  };

  return (
    <Dialog
      testId={testId}
      header="Добавление пациента"
      boxClassName={styles.modalBox}
      onCloseClick={hideModal}
      onBackClick={handleCancel}
      closeTestId={INVITATION_MODAL.ICON_CLOSE}
      backTestId={INVITATION_MODAL.ICON_BACK}
    >
      <Field
        Component={TextInput}
        initialValue=""
        errors={errors}
        errorKey="full_name"
        ref={fullNameRef}
        autoFocus
        className={styles.field}
        validator={fullNameValidator}
        validHandler={checkCanSubmit}
        placeholder="Заполните ФИО пациента"
        label="ФИО"
        noSpace
        testidProps={{
          input: PATIENT_ADD.FIO,
          label: PATIENT_ADD.FIO_LABEL
        }}
      />
      <Field
        Component={SNILSInput}
        initialValue=""
        errors={errors}
        className={styles.field}
        errorKey="snils"
        autoFocus
        ref={snilsRef}
        noSpace
        validHandler={checkCanSubmit}
        placeholder="СНИЛС"
        label="СНИЛС"
        testidProps={{
          input: PATIENT_ADD.SNILS,
          label: PATIENT_ADD.SNILS_LABEL
        }}
      />
      <Field
        Component={Select}
        className={styles.field}
        inputClassName={styles.gender}
        placeholder="Выберите пол"
        items={[
          { value: "male", label: "Мужской" },
          { value: "female", label: "Женский" }
        ]}
        onChange={handleGenderChange}
        errors={errors}
        errorKey="gender"
        label="Пол"
        noSpace
        testId={{
          input: PATIENT_ADD.GENDER,
          label: PATIENT_ADD.GENDER_LABEL
        }}
      />
      <Field
        Component={DateInput}
        initialValue=""
        errors={errors}
        className={styles.field}
        errorKey="date_of_birth"
        ref={dateOfBirthRef}
        maxDate={new Date()}
        validHandler={checkCanSubmit}
        placeholder="ДД.ММ.ГГГГ"
        label="Дата рождения"
        noSpace
        testidProps={{
          input: PATIENT_ADD.DATE_OF_BIRTH,
          label: PATIENT_ADD.DATE_OF_BIRTH_LABEL
        }}
      />
      <PrivateCommentField
        className={styles.field}
        initialValue=""
        errors={errors}
        errorKey="comment"
        ref={commentRef}
        required={false}
        resize="none"
        label="Комментарий"
        noSpace
      />
      <Button
        className={styles.field}
        fullWidth
        disabled={!canSubmit}
        onClick={handleSubmit}
        type="submit"
        testid={PATIENT_ADD.SEND_INVITATION}
      >
        Отправить приглашение
      </Button>
      <Loading showLoading={loading} />
    </Dialog>
  );
};

export default UserAdd;
