import React from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import cn from "classnames";
import getNumbersFromString from "~/utils/getNumbersFromString";

import styles, { DURATION } from "./FadeGroup.scss";

const duration = getNumbersFromString(DURATION)[0];

const FadeGroup = ({ component, children, className, style = {}, height }) => {
  const _style = { ...style, ...(height !== undefined && { height }) };
  if (_style.height !== undefined) {
    _style.transition = `height ${duration}ms ease-in-out`;
  }
  return (
    <>
      <TransitionGroup
        className={cn(styles.container, className)}
        style={_style}
        component={component}
      >
        {React.Children.map(children, item => (
          <CSSTransition
            timeout={duration}
            unmountOnExit
            classNames={{ ...styles }}
          >
            {item}
          </CSSTransition>
        ))}
      </TransitionGroup>
    </>
  );
};

export default FadeGroup;
