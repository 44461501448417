import React, { useMemo } from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import { Formik } from "formik";
import { Button, Dialog, Label, Separator } from "ibolit-ui";
import { getFormatDateOfBirth } from "~/utils/dateUtils";
import isDefaultAvatar from "~/utils/isDefaultAvatar";
import Form from "./components/Form";
import FileReader from "~/components/FileReader";
import { formScenes } from "./components/FormSwitcher/types";
import { SETTINGS } from "~/locators/privateProfile";
import UploadAvatar from "../UploadAvatar";
import styles from "./ProfileSettings.scss";
import ButtonInput from "~/components/ButtonInput";
import formatPhone from "~/utils/formatPhone";
import { MIN_NAME_LENGTH } from "~/consts/values";

const FILE_EXTENSIONS = ["png", "jpg", "jpeg"];
const SEPARATOR_STYLES = {
  marginTop: 15,
  marginBottom: 15
};

const minDate = moment()
  .endOf("day")
  .subtract(150, "year");

const maxDate = moment().endOf("day");

const ProfileSettings = ({
  user,
  onCloseClick,
  onAvatarChange,
  loadingAvatar,
  loadingUserData,
  onLogout,
  onFormSubmit,
  onChangeSettings
}) => {
  const { full_name, date_of_birth, description } = user;

  const onShowSceneForm = selectedScene => () => {
    onChangeSettings(selectedScene);
  };

  const onLogoutClick = () => {
    onCloseClick();
    onLogout();
  };

  const dateOfBirthNormalized = useMemo(
    () => getFormatDateOfBirth(date_of_birth),
    [date_of_birth]
  );

  return (
    <Dialog
      testId="ProfileSettingsModal"
      header="Настройки"
      headerTestId={"header_modal_window_settings"}
      testIdTitle={SETTINGS.TITLE}
      closeTestId={SETTINGS.ICON_CLOSE}
      backTestId={SETTINGS.ICON_BACK}
      onCloseClick={onCloseClick}
      boxClassName={styles.modalBox}
    >
      <div className={styles.uploadAvatar}>
        <FileReader
          onChange={onAvatarChange}
          fileExtensions={FILE_EXTENSIONS}
          render={({ readedFile = {}, onClick, error }) => {
            const avatar = isDefaultAvatar(user.avatar)
              ? undefined
              : user.avatar;
            const src = readedFile.result || avatar;

            return (
              <UploadAvatar
                onClick={onClick}
                full_name={user.full_name}
                userId={user.id}
                srcAvatar={src}
                loading={loadingAvatar}
                error={error}
                dataTestid={"profile-settings-upload-avatar"}
              />
            );
          }}
        />
      </div>
      <Formik
        key={JSON.stringify(user)}
        initialValues={{
          full_name: full_name || "",
          date_of_birth: dateOfBirthNormalized,
          description: description || ""
        }}
        validate={validate}
        onSubmit={onFormSubmit}
      >
        {props => (
          <Form
            {...props}
            loadingUserData={loadingUserData}
            minDate={minDate}
            maxDate={maxDate}
          />
        )}
      </Formik>
      <Label title="Почта" className={styles.field} testid="ProfileSettings__email">
        <ButtonInput
          onClick={onShowSceneForm(formScenes.email)}
          testId={SETTINGS.INPUT_EMAIL}
        >
          {user.email}
        </ButtonInput>
      </Label>
      <Label title="Телефон" className={styles.field} testid="ProfileSettings__phone">
        <ButtonInput
          onClick={onShowSceneForm(formScenes.phone)}
          testId={SETTINGS.INPUT_PHONE}
        >
          {user.phone ? user.phone : ""}
        </ButtonInput>
      </Label>
      <Button
        className={styles.button}
        variant="tertiary"
        fullWidth
        type="button"
        onClick={onShowSceneForm(formScenes.password)}
        testid={SETTINGS.BUTTON_CHANGE_PASSWORD}
      >
        Изменить пароль
      </Button>
      <Separator style={SEPARATOR_STYLES} />
      <Button
        testid={SETTINGS.BUTTON_LOGOUT}
        variant="secondary"
        type="submit"
        fullWidth
        onClick={onLogoutClick}
      >
        Выйти из учётной записи
      </Button>
    </Dialog>
  );
};

function validate(values) {
  const errors = {};

  if (!values.full_name) {
    errors.full_name = "Это поле обязательно для заполнения";
  } else if (values.full_name.length < MIN_NAME_LENGTH) {
    errors.full_name = `Имя должно иметь не менее ${MIN_NAME_LENGTH} символов`;
  }

  if (values.date_of_birth !== "") {
    const date = moment(values.date_of_birth, "DD.MM.YYYY", true);

    if (!date.isValid() || date < minDate || date > maxDate) {
      errors.date_of_birth = "Некорректная дата";
    }
  }

  return errors;
}

ProfileSettings.propTypes = {
  user: PropTypes.object.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  onAvatarChange: PropTypes.func.isRequired,
  loadingUserData: PropTypes.bool.isRequired,
  loadingAvatar: PropTypes.bool.isRequired,
  successUpdateUserData: PropTypes.bool,
  onLogout: PropTypes.func.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  scene: PropTypes.oneOf([
    null,
    formScenes.confirm,
    formScenes.password,
    formScenes.phone,
    formScenes.email
  ]),
  onChangeSettings: PropTypes.func.isRequired
};

export default ProfileSettings;
