import React from "react";
import { Box, Button, Text } from "ibolit-ui";
import styles from "./ErrorBox.scss";
import { ERROR_MODAL } from "~/locators/modal";
import ErrorSvg from "~/components/CallRoom/assets/Error";

export const ERROR_BOX_MODAL = "ERROR_BOX_MODAL";

const ErrorBox = ({ onRepeatClick }) => (
  <Box className={styles.container}>
    <div>
      <ErrorSvg className={styles.img} />

      <Text variant="h3" testid={ERROR_MODAL.TITLE} className={styles.title}>
        Хьюстон, у нас проблемы
      </Text>
      <Text variant="desc" colorVariant="tertiary" className={styles.desc}>
        Повторите попытку позже
      </Text>
      <Button
        onClick={onRepeatClick}
        fullWidth
        colorVariant="doctor"
        data-testid={ERROR_MODAL.BUTTON}
      >
        Попробовать еще раз
      </Button>
    </div>
  </Box>
);

export default ErrorBox;
