import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import actions from "~/store/profile/profileAction";
import InfoSettings from "./components/InfoSettings";
import { getUser } from "~/store/profile/profileSelectors";

const ProfileInfoContainer = ({
  user,
  userDataUpdateRequest: userDataUpdateRequestAction,
  resetStateActions: resetStateActionsAction,
  loadingUserData,
  onCloseClick
}) => {
  useEffect(() => resetStateActionsAction, []);

  const onFormSubmit = values => {
    userDataUpdateRequestAction({ ...user, ...values });
  };

  return (
    <InfoSettings
      user={user}
      onCloseClick={onCloseClick}
      onFormSubmit={onFormSubmit}
      loadingUserData={loadingUserData}
    />
  );
};

ProfileInfoContainer.propTypes = {
  onCloseClick: PropTypes.func,
  user: PropTypes.object.isRequired,
  userDataUpdateRequest: PropTypes.func.isRequired,
  loadingUserData: PropTypes.bool.isRequired,
  resetStateActions: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  user: getUser(state),
  loadingUserData: state.profile.loadingUserData
});

export const PROFILE_SETTINGS_VIEW = "profileSettings";

export default connect(mapStateToProps, {
  userDataUpdateRequest: actions.userDataUpdateRequest,
  resetStateActions: actions.resetStateActions
})(ProfileInfoContainer);
