import { normalize } from "normalizr";
import { put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { replace } from "connected-react-router";
import profileActions from "~/store/profile/profileAction";
import contactsTypes from "~/store/contacts/contactsTypes";
import usersActions from "~/store/users/usersActions";
import clinicsActions from "~/store/clinics/clinicsActions";
import authTypes from "~/store/auth/authTypes";
import messagesActions from "~/store/messages/messagesActions";
import consultationsActions from "~/store/consultations/consultationsActions";
import chatsActions from "~/store/chats/chatsActions";
import webSocketTypes from "~/store/webSocket/webSocketTypes";
import messagesTypes from "~/store/messages/messagesTypes";
import { chatSchema } from "~/store/chats/chatsNormalizer";
import usersTypes from "~/store/users/usersTypes";
import consultationsTypes from "~/store/consultations/consultationTypes";
import { isNotFoundRequest } from "~/utils/notFound";
import chatsTypes from "~/store/chats/chatsTypes";
import toolbarActions from "~/store/toolbar/toolbarActions";
import pushNotificationAction from "~/store/pushNotifications/pushNotificationActions";
import commonTypes from "~/store/commons/commonTypes";
import commonActions from "~/store/commons/commonActions";
import logger from "~/utils/Logger";

export function* onSuccessAuth() {
  yield put(profileActions.userDataLoadRequest());
  yield put(toolbarActions.getToolbar());
  yield put(pushNotificationAction.registration());
}

function* upsertUser({ payload }) {
  yield put(usersActions.upsertUser(payload));
}

function* processSocketMessage({ event }) {
  const { id: chatId } = event;
  const {
    entities: {
      messages,
      chats: {
        [chatId]: {
          messages: [last_message],
          ...chat
        },
        ...chats
      },
      ...rest
    }
  } = normalize(event, chatSchema);
  if (messages[last_message].type !== "system") {
    chat.last_message = messages[last_message];
  }

  rest.chats = { ...chats, [chatId]: chat };

  yield put(messagesActions.updateItems(messages));
  yield put(commonActions.updateItems(rest));
}

// for now - force set "count_new" to 0
function* processReadingNewMessages({ request }) {
  const chats = Object.entries(request).reduce((acc, [chatId]) => {
    acc[chatId] = { count_new: 0 };
    return acc;
  }, {});
  yield put(chatsActions.updateItems(chats));
}

function* checkRequestNotFound(href, { error }) {
  if (isNotFoundRequest(error)) {
    yield put(replace(href));
  }
}

function* processCheckNewUserSaga() {
  try {
    const { patients } = yield select(({ toolbar }) => toolbar);
    if (patients > 0) {
      yield put(toolbarActions.updateToolbar({ patients: patients - 1 }));
    }
  } catch (e) {
    yield put({ type: `@@err/PROCESS_CHECK_NEW_USER_SAGA`, e });
  }
}

const updateActionsMap = {
  users: usersActions.updateItems,
  chats: chatsActions.updateItems,
  clinics: clinicsActions.updateItems,
  messages: messagesActions.updateItems,
  consultations: consultationsActions.updateItems
};

export function* updateItems({ items }) {
  for (let key of Object.keys(items)) {
    const action = updateActionsMap[key];
    if (action === undefined) {
      logger.warn(`updateItemsError: Need action for ${key}`);
      continue;
    }
    yield put(action(items[key]));
  }
}

export default [
  takeLatest(authTypes.USER_AUTHORIZE_SUCCESS, onSuccessAuth),
  takeLatest(contactsTypes.PRIVATE_COMMENT_SEND_SUCCESS, upsertUser),
  takeLatest(contactsTypes.ADD_NEW_SUCCESS, upsertUser),
  takeLatest(contactsTypes.ADD_BY_ID_SUCCESS, upsertUser),
  takeLatest(
    messagesTypes.READING_NEW_MESSAGE_SUCCESS,
    processReadingNewMessages
  ),
  takeLatest(usersTypes.USER_CHECK_NEW_SUCCESS, processCheckNewUserSaga),
  takeLatest(usersTypes.USER_INFO_FAILURE, checkRequestNotFound, "/contacts"),
  takeLatest(
    consultationsTypes.CONSULTATIONS_INFO_GET_FAILURE,
    checkRequestNotFound,
    "/consultations"
  ),
  takeEvery(commonTypes.UPDATE_ITEMS, updateItems),
  takeLatest(chatsTypes.INFO_FAILURE, checkRequestNotFound, "/chats"),
  takeEvery(webSocketTypes.WS_CHAT_MESSAGE, processSocketMessage)
];
