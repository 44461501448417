import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  getConsultationRating,
  getConsultationRatingIsLoading,
  getConsultationRatingPage
} from "~/store/consultationRatings/selectors";

import {
  getConsultationRatingCallAction,
  resetConsultationRatingAction
} from "~/store/consultationRatings/actions";

import { getConsultationRatingCount } from "~/store/profile/profileSelectors";

import Loading from "~/components/Loading/Loading";

import InfiniteList from "~/components/InfiniteList";

import { ConsultationRating } from "../ConsultationRating";

const FeedbackComment = () => {
  const consultationRatings = useSelector(getConsultationRating);
  const isLoading = useSelector(getConsultationRatingIsLoading);
  const consultationRatingLength = useSelector(getConsultationRatingCount);
  const page = useSelector(getConsultationRatingPage);

  const listRef = useRef(null);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getConsultationRatingCallAction({}));

    return () => dispatch(resetConsultationRatingAction());
  }, []);

  const incrementPage = () =>
    !isLoading &&
    dispatch(
      getConsultationRatingCallAction({
        page
      })
    );

  useEffect(() => {
    if (listRef.current && consultationRatings.length === 20) {
      listRef.current.scrollToTop();
    }
  }, [consultationRatings.length]);

  const renderTrackVertical = ({ style, ...props }) => {
    const customStyle = {
      position: 'absolute',
      width: '2px',
      right: '2px',
      bottom: '2px',
      top: '2px',
      borderRadius: '3px'
    };
    return (
      <div {...props} style={{ ...style, ...customStyle }} />
    );
  }

  const renderThumbVertical = ({ style, ...props }) => {
    const customStyle = {
      backgroundColor: 'var(--blue)',
    };
    return (
      <div {...props} style={{ ...style, ...customStyle }}/>
    );
  }

  return (
    <InfiniteList
      canLoadMore={consultationRatings.length < consultationRatingLength}
      loadMore={incrementPage}
      ref={listRef}
      renderThumbVertical={renderThumbVertical}
      renderTrackVertical={renderTrackVertical}
    >
      {consultationRatings.map(feedback => (
        <ConsultationRating feedback={feedback} key={feedback.id} />
      ))}
      {isLoading && <Loading variant="row" height={80} />}
    </InfiniteList>
  );
};

export default FeedbackComment;
