import axios from "./httpService";

export default {
  searchApi: data => axios.post("/v3/user/patient/exist", data),
  addNewApi: data => axios.post("/v3/user/patient/invite", data),
  addByIdApi: ({ id, ...data }) =>
    axios.post(`/v3/user/patient/invite/${id}`, data),
  privateCommentSendApi: ({ id, ...data }) =>
    axios.put(`/v3/user/patient/invite/${id}`, data)
};
