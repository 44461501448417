export const CONSULTATIONS_LIST = {
  HEADER: "consultations__consultationsList__header",
  NEW_CONSULTATION: "consultations__consultationsList__button-new-consultation",
  TABS: "consultations__consultationsList__tabs",
  CONTAINER: "consultations__consultationsList__container",
  ITEM: "consultations__consultationsList__consultation_item",
  TOOLTIP: "consultations__consultationsList__tooltip"
};

export const HEADER = {
  WRAPPER_TITLE: "consultations__consultation__wrapper-title",
  TIME: "consultations__consultation__time",
  STATUS: "consultations__consultation__status"
};

export const CONSULTATION = {
  STATUS: "consultations__consultation__status",
  BUTTON_PAY: "consultations__consultation__button-pay",
  DOCTOR: "consultations__consultation__doctor",
  SERVICE_TYPE: "consultations__consultation__service-type",
  PRICE: "consultations__consultation__price",
  HEALTH_CHECK: "consultations__consultation__button-health-check",
  RESCHEDULE: "consultations__consultation__button-reschedule"
};

export const NEW_CONSULTATION_MODAL = {
  HEADER: "consultations__newConsultation__modal-header",
  SERVICE_TYPE: "consultations__newConsultation__select-service-type",
  BUTTON_SUBMIT: "consultations__newConsultation__button-submit",
  ICON_CLOSE: "consultations__newConsultation__iconClose"
};

export const USER_SELECTOR = {
  PLACEHOLDER: "userSelector__placeholder",
  SELECTED_USER: "userSelector__selected-user",
  SEARCH_EMPTY: "userSelector__search-empty",
  INPUT_SEARCH: "userSelector__input-search",
  SEARCH_RESULTS_CONTAINER: "userSelector__search-results-container",
  USER_ITEM: "userSelector__user-item"
};

export const TARIFF_SELECTOR = {
  PLACEHOLDER: "tariffSelector__placeholder",
  TARIFF_ITEM: "tariffSelector__tariff-item"
};

export const DATE_SELECTOR = {
  SELECTED_DATE: "dateSelector__selected-date"
};

export const SLOTS = {
  CONTAINER: "slotsList__container",
  SLOT: "slotsList__slot-item"
};
