import React, { useCallback, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Textarea from "react-textarea-autosize";
import { Dropdown, Toast } from "ibolit-ui";
import IconButton from "~/components/IconButton";
import AVAILABLE_MIME_TYPES from "~/layouts/Chats/utils/availableMimeTypes";
import { CHAT_FOOTER } from "~/locators/chats";
import ChatAttachList from "../ChatAttachList";
import styles from "./ChatInput.scss";
import { MAX_SIZE_MB } from "~/consts/chats";
import { dateToServer, SERVER_DATETIME_FORMAT } from "~/utils/dateUtils";
import messagesActions from "~/store/messages/messagesActions";

import { imageExtensionsRegExp } from "~/utils/regExps";

const ChatInput = ({ currentUserId, partner, chatId }) => {
  const forceUpdate = useState()[1];
  const inputRef = useRef();
  const messageRef = useRef();
  const textareaRef = useRef();

  const checkAndSendMessage = () => {
    const message = messageRef.current.value.trim();
    messageRef.current.value = message;
    if (message !== "") {
      messageRef.current.value = "";
      onSubmit({ type: "text", body: message });
    }
    forceUpdate({});
  };

  const onMessagesSubmit = event => {
    if (event.keyCode === 13 && !event.shiftKey) {
      event.preventDefault();

      checkAndSendMessage();
    }
  };

  const onButtonClick = event => {
    event.preventDefault();

    checkAndSendMessage();
  };

  const onSendFileClick = event => {
    event.preventDefault();

    inputRef.current.click();
  };

  const onInputFileChange = event => {
    const fileList = event.target.files;

    if (fileList.length > 0 && fileList[0].size >= 1024 * 1024 * MAX_SIZE_MB) {
      toast(
        <Toast
          text={`Выбранный файл слишком большого размера,\nмаксимальный размер ${MAX_SIZE_MB}мб`}
          variant="error"
        />,
        { containerId: "toast", autoClose: 10000 }
      );

      inputRef.current.value = "";
    } else if (fileList.length > 0) {
      const type = imageExtensionsRegExp.test(fileList[0].name)
        ? "photo"
        : "file";

      onSubmit({ type, file: fileList[0] });
    }
  };
  const dispatch = useDispatch();
  const onSubmit = useCallback(
    messageData => {
      const key = new Date().getTime();
      const created_at = dateToServer(new Date()).format(
        SERVER_DATETIME_FORMAT
      );
      const message = {
        ...messageData,
        to: partner.id,
        author_id: currentUserId,
        client_message_key: key,
        created_at,
        id: key,
        is_new: false
      };
      dispatch(
        messagesActions.sendRequest(chatId, partner.id, message, messageData)
      );
    },
    [partner.id, currentUserId]
  );

  return (
    <div className={styles.footer}>
      <div className={styles.left}>
        <Dropdown
          content={
            <ChatAttachList user={partner} onSendFileClick={onSendFileClick} />
          }
        >
          {show => (
            <IconButton
              testid={CHAT_FOOTER.MENU_BUTTON}
              iconVariant="attach"
              fill={show ? "var(--blue)" : undefined}
              hasState
            />
          )}
        </Dropdown>
      </div>
      <Textarea
        onKeyDown={onMessagesSubmit}
        inputRef={messageRef}
        ref={textareaRef}
        className={styles.textarea}
        placeholder="Напишите сообщение…"
        maxLength={1000}
        maxRows={12}
        data-testid={CHAT_FOOTER.TEXTAREA}
      />
      <div className={styles.right}>
        <IconButton
          onClick={onButtonClick}
          iconVariant="send"
          hasState
          testid={CHAT_FOOTER.BUTTON_SEND_MESSAGE}
        />
      </div>
      <input
        accept={AVAILABLE_MIME_TYPES}
        onChange={onInputFileChange}
        ref={inputRef}
        name="file"
        type="file"
        style={{ display: "none" }}
      />
    </div>
  );
};

export default ChatInput;
