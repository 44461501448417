import Types from "./contactsTypes";

const Actions = {
  clearState: () => ({ type: Types.CLEAR_STATE }),
  searchRequest: login => ({ type: Types.SEARCH_REQUEST, request: { login } }),
  searchSuccess: (payload, request = {}) => ({
    type: Types.SEARCH_SUCCESS,
    payload,
    request
  }),
  searchFailure: (error, request) => ({
    type: Types.SEARCH_FAILURE,
    error,
    request
  }),

  addNewRequest: data => ({ type: Types.ADD_NEW_REQUEST, request: data }),
  addNewSuccess: (payload, request) => ({
    type: Types.ADD_NEW_SUCCESS,
    payload,
    request
  }),
  addNewFailure: (error, request) => ({
    type: Types.ADD_NEW_FAILURE,
    error,
    request
  }),

  addByIdRequest: (id, comment) => ({
    type: Types.ADD_BY_ID_REQUEST,
    request: { id, comment }
  }),
  addByIdSuccess: (payload, request) => ({
    type: Types.ADD_BY_ID_SUCCESS,
    payload,
    request
  }),
  addByIdFailure: (error, request) => ({
    type: Types.ADD_BY_ID_FAILURE,
    error,
    request
  }),

  privateCommentSendRequest: (id, comment) => ({
    type: Types.PRIVATE_COMMENT_SEND_REQUEST,
    request: { id, comment }
  }),
  privateCommentSendSuccess: (payload, request) => ({
    type: Types.PRIVATE_COMMENT_SEND_SUCCESS,
    payload,
    request
  }),
  privateCommentSendFailure: (error, request) => ({
    type: Types.PRIVATE_COMMENT_SEND_FAILURE,
    error,
    request
  }),

  setInvitationScene: scene => ({
    type: Types.SET_INVITATION_SCENE,
    scene
  })
};

export default Actions;
