import React, { useRef, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "ibolit-ui";
import CountDownTextButton from "~/components/UI/CountDownTextButton";
import RP_LOCATORS from "~/locators/resetPassword";
import Field from "~/components/Field/Field";
import CodeInput from "~/components/inputs/CodeInput";
import CodeMessage from "../../../../components/CodeMessage";

import styles from "./CodeConfirmForm.scss";
import formStyles from "../../Authorization.scss";
import { getIsEmail } from "~/utils/validations";
import authActions from "~/store/auth/authActions";
import {
  getAuthError,
  getCode,
  getLogin,
  getStatus,
  getTimeout
} from "~/store/auth/authSelectors";

import formatPhone from "~/utils/formatPhone";

const testidFieldProps = {
  label: RP_LOCATORS.LABEL_INPUT_CODE,
  input: RP_LOCATORS.INPUT_KEY,
  error: RP_LOCATORS.INPUT_CODE_ERROR_MESSAGE
};

const getLabelProps = login => ({
  label: `Введите код из ${getIsEmail(login) ? "письма" : "СМС"}`,
  className: styles.label,
  testid: 'CodeConfirmForm__label'
});

const getText = (text, status, login) =>
  text[status] && text[status][getIsEmail(login) ? 0 : 1];

const CodeConfirmForm = ({ text, testIdMsg }) => {
  const dispatch = useDispatch();
  const login = useSelector(getLogin);
  const code = useSelector(getCode);
  const status = useSelector(getStatus);
  const timeout = useSelector(getTimeout);
  const errors = useSelector(getAuthError);

  const [canSubmit, setCanSubmit] = useState(false);

  const inputRef = useRef(null);

  const handleSubmit = useCallback(() => {
    dispatch(
      authActions.validateCodeRequest(inputRef.current.value.trim(), login)
    );
  }, [login]);

  const handleResend = useCallback(() => {
    dispatch(authActions.sendCodeRequest(login));
  }, [login]);

  const isEmail = getIsEmail(login);

  return (
    <div className={formStyles.form}>
      <CodeMessage
        testId={testIdMsg}
        header={isEmail ? login : formatPhone(login.replace(/^\+?8/, "+7"))}
        text={getText(text, status, login)}
      />
      <Field
        Component={CodeInput}
        pattern={"\\d{4}"}
        mask="9999"
        initialValue={code}
        errors={errors}
        errorKey="code"
        ref={inputRef}
        autoFocus
        labelProps={getLabelProps(login)}
        validHandler={setCanSubmit}
        submitHandler={handleSubmit}
        className={styles.inputFiled}
        testidProps={testidFieldProps}
      />
      <Button
        disabled={!canSubmit}
        onClick={handleSubmit}
        fullWidth
        type="submit"
        testid={RP_LOCATORS.BUTTON_SUBMIT_KEY}
      >
        Продолжить
      </Button>
      <CountDownTextButton
        enabled
        defaultTimeout={timeout}
        text="Запросить код еще раз"
        onClick={handleResend}
        testid={RP_LOCATORS.BUTTON_REQUEST_AGAIN}
      />
    </div>
  );
};

export default CodeConfirmForm;
