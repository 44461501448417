import React from "react";
import cn from "classnames";
import isEqual from "lodash/isEqual";
import { Icon } from "ibolit-ui";
import { FILTER_VIEW } from "./FilterModal";
import { useShowModal } from "~/hooks/useModal";
import { INITIAL_FILTER } from "~/store/users/const";

const FilterButton = ({ filter, setFilter, className, testid }) => {
  const showModal = useShowModal(FILTER_VIEW, { filter, setFilter });
  const hasFilter = !isEqual(filter, INITIAL_FILTER);

  return (
    <div className={cn(className)}>
      <Icon
        variant="filters"
        hasState
        {...(hasFilter && { fill: "var(--blue)" })}
        badge={hasFilter}
        onClick={showModal}
        testid={testid}
      />
    </div>
  );
};

export default FilterButton;
