import React from "react";
import { Button, Text } from "ibolit-ui";
import MessageModal from "~/components/MessageModal";
import authLocators from "~/locators/auth";

const LoginError = ({ onBackClick }) => (
  <MessageModal
    title="Неверное имя пользователя"
    message={
      <Text
        testid={authLocators.AUTH_LOGIN_LOGIN_ERROR_MODAL_TEXT}
        variant="desc"
      >
        Убедитесь в том, что имя пользователя написано без ошибок и повторите
        попытку
      </Text>
    }
  >
    <Button
      testid={authLocators.AUTH_LOGIN_LOGIN_ERROR_BACK_BUTTON}
      variant="secondary"
      onClick={onBackClick}
    >
      Назад
    </Button>
  </MessageModal>
);

export default LoginError;
