import axios from "./httpService";

export default {
  fetchConsultations: params =>
    axios.get(`/v3/user/consultations/doctor`, { params }),
  getConsultation: id => axios.get(`/v3/user/consultation/${id}`),
  createConsultation: params =>
    axios.post("/v3/user/consultation/doctor", params),
  uploadFile: ({ payload, onProgress, cancelToken }) => {
    return axios.post(`/v3/upload-file`, payload, {
      onUploadProgress(progressEvent) {
        onProgress(progressEvent);
      },
      cancelToken,
      timeout: 0
    });
  },
  linkUploadFile: ({ consultationId, tempFileId }) =>
    axios.post(`/v3/user/consultation/${consultationId}/file`, {
      temp_file_id: tempFileId
    }),
  deleteFile: ({ fileId }) =>
    axios.delete(`/v3/user/consultation/file/${fileId}`),
  confirmConsultation: ({ consultationId }) =>
    axios.post(`/v3/user/consultation/${consultationId}/doctor/confirm`),
  cancelConsultation: ({ consultationId, reason }) =>
    axios.post(`/v3/user/consultation/${consultationId}/doctor/cancel`, {
      reason
    }),
  downloadFile: url =>
    axios.get(url, {
      responseType: "blob"
    })
};
