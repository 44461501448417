import authTypes from "~/store/auth/authTypes";
import Types from "~/store/chats/chatsTypes";
import { state as baseState, reducer as requestReducer } from "./chatModel";
import { composeReducers } from "~/utils/redux/compose";

const initialState = baseState.merge({
  isCloseChatLoading: false
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.UP_CHAT: {
      const firstChatId = state.getIn(["all", "order", "0"]);
      const targetChatId = action.id;
      return firstChatId !== targetChatId
        ? state.updateIn(
            ["all", "order"],
            (order, target) => [
              target,
              ...order.filter(item => item !== target)
            ],
            targetChatId
          )
        : state;
    }

    case Types.INFO_SUCCESS:
      return state.merge(
        {
          items: action.payload.items
        },
        { deep: true }
      );

    case Types.INFO_FAILURE:
      return state.merge({
        error: action.error
      });

    case Types.END_EXPRESS_CHAT_REQUEST: {
      return state.merge({
        isCloseChatLoading: true
      });
    }

    case Types.END_EXPRESS_CHAT_SUCCESS: {
      return state.merge({
        isCloseChatLoading: false
      });
    }

    case Types.END_EXPRESS_CHAT_FAILURE: {
      return state.merge({
        isCloseChatLoading: false
      });
    }

    case authTypes.LOGOUT:
      return initialState;

    default:
      return state;
  }
};

export default composeReducers(reducer, requestReducer);
