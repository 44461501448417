import React from "react";
import { Icon, Button } from "ibolit-ui";
import { INVITATION_VIEW } from "./InvitationModal";
import { CONTACTS_LIST } from "~/locators/contacts";
import { useShowModal } from "~/hooks/useModal";
import styles from "./Invitation.scss";

const InviteUserButton = ({ className }) => {
  const showModal = useShowModal(INVITATION_VIEW, {});

  return (
    <Button
      onClick={showModal}
      className={className}
      testid={CONTACTS_LIST.BUTTON_INVITE}
      fullWidth
    >
      <Icon variant="user-add" fill="var(--white)" className={styles.icon} />
      Добавить пациента
    </Button>
  );
};

export default InviteUserButton;
