import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { Icon, Text } from "ibolit-ui";
import cn from "classnames";
import { getConsultationMsgFormattedRange } from "~/utils/dateUtils";
import {
  getConsultationIcon,
  isConsultationApproved,
  isConsultationExpired
} from "~/store/consultations/consultationSelectors";
import { getTariffTextByType } from "~/store/profile/profileSelectors";
import styles from "./Consultation.scss";
import {
  CONSULTATION_STATUS,
  MAP_CONSULTATION_STATUSES_TO_NAMES
} from "~/consts/consultations";

const Consultation = ({
  id,
  tariff_price,
  tariff_name,
  type,
  start_at,
  end_at,
  status,
  conclusion,
  isConclusionMessage
}) => {
  const icon = getConsultationIcon(type);
  const iconText = getTariffTextByType(type);
  const textStatus =
    tariff_price === 0 && status === CONSULTATION_STATUS.APPROVED
      ? MAP_CONSULTATION_STATUSES_TO_NAMES[CONSULTATION_STATUS.CONFIRMED]
      : MAP_CONSULTATION_STATUSES_TO_NAMES[status];

  const expired = isConsultationExpired(status);
  const expiredClass = expired ? styles.expired : "";
  // isConclusionMessage separates between messageType===service and messageType===conclusion
  // to not display conclusion in every block.
  const showConclusionBlock = isConclusionMessage && conclusion?.text;

  return (
    <NavLink to={`/consultations/${id}`} className={styles.consultationChat}>
      <div className={styles.bubble}>
        <div className={styles.consultationChat_row}>
          <span>
            <Icon variant={icon} fill="var(--blue)" size="s" />
            <Text
              tag="span"
              variant="caption"
              className={cn(expiredClass, styles.consultationChat_type)}
              testid="Consultation__consultationChat_iconText"
            >
              {iconText}
            </Text>
            <Text
              tag="span"
              variant="caption"
              colorVariant="tertiary"
              className={cn(expiredClass, styles.consultationChat_date)}
              testid="Consultation__consultationChat_date"
            >
              {getConsultationMsgFormattedRange(start_at, end_at)}
            </Text>
          </span>
          <Text
            tag="span"
            variant="desc"
            className={cn({
              [styles.consultationChat_status__approved]: isConsultationApproved(
                status
              ),
              [styles.consultationChat_status__canceled]: expired
            })}
            testid="Consultation__textStatus"
          >
            {textStatus}
          </Text>
        </div>
        <Text variant="h4" className={expiredClass} testid="Consultation__tariff_name">
          {tariff_name}
        </Text>
      </div>
      {showConclusionBlock && <Conclusion body={conclusion.text} />}
    </NavLink>
  );
};

const Conclusion = ({ body }) => (
  <div className={styles.consultationChat_conclusion}>
    <Text variant="h4" testid="Conclusion__title">Заключение</Text>
    <Text variant="desc" tag="pre" testid="Conclusion__body">
      {body}
    </Text>
  </div>
);

Consultation.propTypes = {
  id: PropTypes.number,
  type: PropTypes.string,
  start_at: PropTypes.string,
  end_at: PropTypes.string,
  status: PropTypes.string
};

export default Consultation;
