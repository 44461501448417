import { put, all, takeLatest } from "redux-saga/effects";
import chatAction from "~/store/chats/chatsActions";
import consultationAction from "~/store/consultations/consultationsActions";
import types from "./servicesTypes";

function* paidSaga({ event }) {
  try {
    const { type, external_id } = event;
    if (type === "chat") {
      yield put(chatAction.infoRequest(external_id));
    }
    if (type === "slot") {
      yield put(consultationAction.getInfo(external_id));
    }
  } catch (error) {
    console.warn(
      "sockets:chat.paid: При обновлении по сокетам возникли проблемы"
    );
  }
}

export default function* services() {
  yield all([takeLatest(types.SERVICE_PAID, paidSaga)]);
}
