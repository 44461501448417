import React, { useEffect } from "react";
import cn from "classnames";
import PropTypes from "prop-types";
import { Redirect, Route, Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import ModalsRoot from "~/modals/ModalsRoot";
import modalsMap from "~/modals/modalsMap";
import appStartedActon from "~/store/appStarted/appStartedAction";
import PrivateRoute from "~/components/PrivateRoute/PrivateRoute";
import AppContainer from "~/layouts/App/App";
import { history } from "~/store/store";
import Authorization from "~/layouts/Authorization/Authorization";
import NotFound from "~/layouts/NotFound/NotFound";
import Chats from "~/layouts/Chats";
import Contacts from "~/layouts/Contacts";
import Consultations from "~/layouts/Consultations";
import Profile from "~/layouts/Profile";

import "./ym";
import "./sentryConfig";
import "./styles/colors.scss";
import "./styles/_reset.scss";
import "./styles/basic.scss";
import "./assets/fonts/Roboto/Roboto.scss";
import "react-toastify/dist/ReactToastify.css";

import toastStyles from "./styles/toastStyles.scss";

const App = ({ setAppStarted: setAppStartedAction }) => {
  useEffect(() => {
    setAppStartedAction();
  }, []);

  return (
    <ConnectedRouter history={history}>
      <AppContainer>
        <Switch>
          <Route exact path="/auth" component={Authorization} componentProps={{ error: "Auth__error" }} />
          <Route exact path="/notfound" component={NotFound} />
          <PrivateRoute
            path="/consultations/:consultationId?"
            component={Consultations}
          />
          <PrivateRoute path="/chats" component={Chats} />
          <PrivateRoute path="/contacts" component={Contacts} />
          <PrivateRoute path="/profile" component={Profile} />
          <PrivateRoute
            exact
            path="/"
            component={() => <Redirect to="chats" />}
          />
          <Redirect to="/notfound" />
        </Switch>
      </AppContainer>
      <ModalsRoot modalsMap={modalsMap} idDOMRoot="modal-root" />
      <ToastContainer
        position={toast.POSITION.TOP_RIGHT}
        className={cn("iBolitToast", toastStyles.container)}
        toastClassName={toastStyles.toast}
        progressClassName={toastStyles.progress}
        bodyClassName={toastStyles.body}
        enableMultiContainer
        containerId="notification"
        closeButton={false}
        closeOnClick={false}
        autoClose={3000}
      />
      <ToastContainer
        position={toast.POSITION.TOP_CENTER}
        className={cn("iBolitToast", toastStyles.container)}
        toastClassName={toastStyles.toast}
        progressClassName={toastStyles.progress}
        bodyClassName={toastStyles.body}
        enableMultiContainer
        containerId="toast"
        pauseOnFocusLoss={false}
        closeOnClick={false}
        closeButton={false}
        autoClose={3000}
      />
    </ConnectedRouter>
  );
};

App.propTypes = {
  setAppStarted: PropTypes.func.isRequired
};

export default connect(null, { ...appStartedActon })(App);
