import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Dialog, Separator } from "ibolit-ui";

import { getConsultationRatingCallAction } from "~/store/consultationRatings/actions";
import { orders } from "~/store/consultationRatings/types";

import { useCloseModal } from "~/hooks/useModal";

import styles from "./ConsultationsRatings.scss";
import { getConsultationRatingOrderBy } from "~/store/consultationRatings/selectors";

export const CONSULTATIONS_RATING = "ConsultationsRatings";

export default function ConsultationsRatingsModal() {
  const dispatch = useDispatch();

  const closeModal = useCloseModal(CONSULTATIONS_RATING);

  const currentOrderBy = useSelector(getConsultationRatingOrderBy);

  const handleClick = order_by => () => {
    if (currentOrderBy !== order_by) {
      dispatch(getConsultationRatingCallAction({ order_by }));
    }
    closeModal();
  };

  return (
    <Dialog
      headerClassName={styles.dialog__header}
      boxClassName={styles.dialog}
      bodyClassName={styles.dialog__body}
    >
      {sortOptionParams.map(optionParam => (
        <Fragment key={optionParam.orderBy}>
          <div
            onClick={handleClick(optionParam.orderBy)}
            className={styles.dialog__variant}
          >
            {optionParam.label}
          </div>
          <Separator />
        </Fragment>
      ))}
    </Dialog>
  );
}

const sortOptionParams = [
  {
    orderBy: orders.id_desc,
    label: "Сначала новые"
  },
  {
    orderBy: orders.id_asc,
    label: "Сначала старые"
  },
  {
    orderBy: orders.rate_asc,
    label: "Сначала плохие"
  },
  {
    orderBy: orders.rate_desc,
    label: "Сначала хорошие"
  }
];
