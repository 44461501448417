import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import { Icon, Text, Tooltip } from "ibolit-ui";
import Logo from "~/components/UI/Logo";
import UserAvatar from "~/components/UserAvatar";
import locators from "~/locators/navbar";
import Nav from "../Nav";
import styles from "./Header.scss";
import useOutsideClickHandler from "~/hooks/useOutsideClickHandler";
import Analytics from "~/utils/analytics";

const moneyFormatter = new Intl.NumberFormat("ru-RU");
const currentMonth = moment(new Date())
  .locale("ru")
  .format("MMMM");

const Header = ({ loading, routesList }) => {
  const [isShowStats, setIsShowStats] = useState(false);
  const doctorStats = useSelector(state => state.profile.doctorsStats);
  const overlayRef = useRef();
  const tooltipRef = useRef();

  const handleShowStats = () => {
    if (isShowStats === false) {
      Analytics.trackEvent("Click Board");
    }
    setIsShowStats(prevState => !prevState);
  };

  useOutsideClickHandler(overlayRef, () => setIsShowStats(false));

  return (
    <div className={styles.container} ref={overlayRef}>
      <Logo
        href="/"
        className={styles.logo}
        testid={locators.LOGO}
        loading={loading}
      />
      <div id="stats-tooltip" className={styles.tooltipDom} ref={tooltipRef}>
        {doctorStats && (
          <Tooltip
            position="bottom"
            clickable
            opened={isShowStats}
            portalClassName={styles.tooltipPortal}
            triangleClassName={styles.tooltipPortalTriangle}
            domRoot={tooltipRef.current}
            header={
              <div onClick={handleShowStats} className={styles.tooltipButton}>
                <Icon
                  fill="var(--blue)"
                  variant="chart"
                  sizeValue={16}
                  className={styles.tooltipIcon}
                />
                Статистика
              </div>
            }
          >
            <div className={styles.tooltip}>
              <div>
                <Text variant="h4">Ваш результат</Text>
                <Text
                  variant="desc"
                  colorVariant="tertiary"
                  className={styles.tooltipDesc}
                >
                  Результат за {currentMonth}
                </Text>
                <div className={styles.tooltipRow}>
                  <Text colorVariant="tertiary">Пациенты:&nbsp;&nbsp;</Text>{" "}
                  <Text variant="h6">{doctorStats.patients_per_month}</Text>
                </div>
                <div className={styles.tooltipRow}>
                  <Text colorVariant="tertiary">Консультации:&nbsp;&nbsp;</Text>{" "}
                  <Text variant="h6">
                    {doctorStats.consultations_per_month}
                  </Text>
                </div>
                <div className={styles.tooltipRow}>
                  <Text colorVariant="tertiary">Доход:&nbsp;&nbsp;</Text>{" "}
                  <Text variant="h6">
                    {moneyFormatter.format(doctorStats.profit_per_month)}&nbsp;₽
                  </Text>
                </div>
              </div>
              <div>
                <Text variant="h4">Лучший результат iBolit</Text>
                <Text
                  variant="desc"
                  colorVariant="tertiary"
                  className={styles.tooltipDesc}
                >
                  Результат за {currentMonth}
                </Text>
                <div className={styles.tooltipRow}>
                  <Text colorVariant="tertiary">Пациенты:&nbsp;&nbsp;</Text>
                  <Text variant="h6">{doctorStats.max_patients_per_month}</Text>
                </div>
                <div className={styles.tooltipRow}>
                  <Text colorVariant="tertiary">Консультации:&nbsp;&nbsp;</Text>{" "}
                  <Text variant="h6">
                    {doctorStats.max_consultations_per_month}
                  </Text>
                </div>
                <div className={styles.tooltipRow}>
                  <Text colorVariant="tertiary">Доход:&nbsp;&nbsp;</Text>
                  <Text variant="h6">
                    {moneyFormatter.format(doctorStats.max_profit_per_month)}
                    &nbsp;₽
                  </Text>
                </div>
              </div>
            </div>
            {doctorStats.income_per_month !== null && (
              <div className={styles.incomeBlock}>
                <Text className={styles.incomeHeader} colorVariant="tertiary">
                  Моя заработная плата:
                </Text>
                <div className={styles.income}>
                  {moneyFormatter.format(doctorStats.income_per_month)} ₽
                </div>
              </div>
            )}
          </Tooltip>
        )}
      </div>
      <Nav list={routesList} className={styles.navContainer} />
    </div>
  );
};

Header.propTypes = {
  ...UserAvatar.propTypes,
  routesList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      text: PropTypes.string,
      to: PropTypes.string.isRequired,
      icon: Icon.propTypes.variant
    })
  )
};

export default Header;
