import React from "react";
import PropTypes from "prop-types";
import { IconWithText } from "ibolit-ui";
import { NavLink } from "react-router-dom";
import styles from "./Nav.scss";

const Nav = ({ list, className }) => (
  <nav className={className}>
    <ul className={styles.ul}>
      {list.map(
        ({
          id,
          to,
          text,
          icon,
          badge,
          exact = false,
          testid,
          component: Component
        }) => (
          <li className={styles.li} key={id}>
            <NavLink
              exact={exact}
              to={to}
              className={styles.a}
              activeClassName={styles.aActive}
              data-testid={testid}
            >
              {Component || (
                <IconWithText iconVariant={icon} text={text} badge={badge} />
              )}
              {/*<IconWithText iconVariant={icon} text={text} badge={badge} />*/}
            </NavLink>
          </li>
        )
      )}
    </ul>
  </nav>
);

Nav.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      icon: PropTypes.string.isRequired
    }).isRequired
  ).isRequired
};

export default Nav;
