import React, { useState, useCallback } from "react";
import { Text, TextTruncate } from "ibolit-ui";
import cn from "classnames";
import styles from "./Expandable.scss";

let style = {};
const Expandable = ({
  text,
  line,
  className,
  textElement,
  collapsedText,
  togglerClassName,
  expandedText
}) => {
  const [expanded, toggleExpanded] = useState(undefined);

  const refSetter = useCallback(node => {
    if (node) {
      const {
        paddingLeft,
        paddingRight,
        paddingBottom
      } = window.getComputedStyle(node);
      style = { paddingLeft, paddingRight, paddingBottom };
    }
  }, []);

  return (
    <div className={cn(styles.container, className)} ref={refSetter}>
      <TextTruncate
        line={expanded ? false : line}
        textElement={textElement}
        truncateText="…"
        text={text}
        containerClassName={styles.textContainer}
        textTruncateChild={
          collapsedText && (
            <Text
              tag="span"
              variant="h6"
              colorVariant="pressing"
              className={cn(styles.toggleButton, togglerClassName)}
              onClick={() => toggleExpanded(!expanded)}
              style={style}
            >
              {collapsedText}
            </Text>
          )
        }
      />
      {expanded === true && expandedText && (
        <Text
          tag="span"
          variant="h6"
          colorVariant="pressing"
          className={cn(styles.toggleButton, styles.expanded, togglerClassName)}
          onClick={() => toggleExpanded(!expanded)}
        >
          {expandedText}
        </Text>
      )}
    </div>
  );
};

Expandable.defaultProps = {
  textElement: <Text variant="desc" />
};

export default Expandable;
