export default {
  SEARCH_REQUEST: "contacts/SEARCH_REQUEST",
  SEARCH_SUCCESS: "contacts/SEARCH_SUCCESS",
  SEARCH_FAILURE: "contacts/SEARCH_FAILURE",

  ADD_NEW_REQUEST: "contacts/ADD_NEW_REQUEST",
  ADD_NEW_SUCCESS: "contacts/ADD_NEW_SUCCESS",
  ADD_NEW_FAILURE: "contacts/ADD_NEW_FAILURE",

  ADD_BY_ID_REQUEST: "contacts/ADD_BY_ID_REQUEST",
  ADD_BY_ID_SUCCESS: "contacts/ADD_BY_ID_SUCCESS",
  ADD_BY_ID_FAILURE: "contacts/ADD_BY_ID_FAILURE",

  PRIVATE_COMMENT_SEND_REQUEST: "contacts/PRIVATE_COMMENT_SEND_REQUEST",
  PRIVATE_COMMENT_SEND_SUCCESS: "contacts/PRIVATE_COMMENT_SEND_SUCCESS",
  PRIVATE_COMMENT_SEND_FAILURE: "contacts/PRIVATE_COMMENT_SEND_FAILURE",

  CLEAR_STATE: "contacts/CLEAR_STATE",
  SET_INVITATION_SCENE: "contacts/SET_INVITATION_SCENE"
};
