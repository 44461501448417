import React from "react";
import { Icon, Text } from "ibolit-ui";
import Field from "~/components/Field";
import TextareaInput from "~/components/inputs/TextareaInput/TextareaInput";

import styles from "./PrivateCommentField.scss";

const PrivateCommentField = (props, inputRef) => (
  <Field
    Component={TextareaInput}
    ref={inputRef}
    resize="none"
    fullWidth
    placeholder="Добавьте для себя комментарий или заметку о пациенте"
    autosize
    inputClassName={props.className}
    rows={3}
    maxRows={10}
    {...props}
  >
    <Text
      variant="caption"
      colorVariant="tertiary"
      className={styles.textComment}
    >
      <Icon variant="crossed-eye" size="s" className={styles.textCommentIcon} />
      {`Комментарий будет виден только вам`}
    </Text>
  </Field>
);

export default React.forwardRef(PrivateCommentField);
