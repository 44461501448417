import {
  all,
  put,
  throttle,
  actionChannel,
  fork,
  delay,
  take,
  flush,
  spawn,
  cancel
} from "redux-saga/effects";
import _ from "lodash";
import { normalize } from "normalizr";
import api from "~/api/messagesApi";
import Types from "~/store/messages/messagesTypes";
import Actions from "~/store/messages/messagesActions";
import commonActions from "~/store/commons/commonActions";
import { messageSchema } from "~/store/messages/messagesNormalizer";

function* searchSaga({ request }) {
  try {
    const { mode, partner, ...params } = request;
    const { data } = yield api.messagesSearchApi(params);
    const {
      result: order,
      entities: { messages: items, ...rest }
    } = normalize(data, [messageSchema]);
    yield put(Actions.searchSuccess({ items, order }, request));
    yield put(commonActions.updateItems(rest));
  } catch (e) {
    yield put(Actions.searchFailure(e.message, request));
  }
}

function readAsURL(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}

function* preloadFile(message, chatId, File) {
  const result = yield readAsURL(File);
  const file = {
    ...message.file,
    preview_url: message.type === "photo" ? result : undefined,
    url: message.type === "photo" ? result : undefined
  };
  yield put(Actions.addOwnMessage({ ...message, file, chatId }));
  return result;
}

function* sendMessageSaga({ request }) {
  const { message, chatId } = request;
  const isMedia = message.type === "photo" || message.type === "file";
  let formData;
  let task;

  if (isMedia) {
    const { file, type, to } = message;
    const { size, name } = file;

    formData = new FormData();
    formData.append("to", to);
    formData.append("type", type);
    formData.append("file", file);
    formData.append("client_message_key", message.client_message_key);
    const extension = name
      .split(".")
      .pop()
      .toLowerCase();
    message.file = {
      extension,
      name,
      size
    };

    task = yield fork(preloadFile, message, chatId, file);
  } else {
    yield put(Actions.addOwnMessage({ ...message, chatId }));
  }
  try {
    const timeout = isMedia ? 60000 : undefined;
    const { data: processedMessage } = yield api.messageSendApi(
      formData || message,
      { timeout }
    );
    yield put(
      Actions.updateItems({
        [processedMessage.id]: { ...processedMessage, chatId }
      })
    );
  } catch (e) {
    yield put(
      Actions.updateItems({
        [message.id]: {
          ...message,
          chatId,
          error: e.message
        }
      })
    );
    if (e.response && e.response.status === 403) {
      yield put(
        Actions.sendFailure(
          { message: "Доступ к чату ограничен", status: 403 },
          request
        )
      );
    }
  }
  yield cancel(task);
}

function* readMessages(toRead) {
  try {
    yield all(
      Object.values(toRead).map(({ chat_id }) =>
        api.messageReadApi({ chat_id })
      )
    );
    yield put(Actions.readingNewMessageSuccess({}, toRead));
  } catch (e) {
    yield put(Actions.readingNewMessageFailure(e.message, toRead));
  }
}

function* watchMessageReading() {
  const chan = yield actionChannel(Types.READING_NEW_MESSAGE_REQUEST);
  while (true) {
    const { request } = yield take(chan);

    yield delay(500);
    const actions = yield flush(chan);
    // eslint-disable-next-line no-loop-func
    const toRead = actions.reduce(
      (acc, { request: { chat_id, receiver_id, message_ids } }) => {
        if (!acc[chat_id] && message_ids)
          acc[chat_id] = { chat_id, receiver_id, message_ids: [] };

        message_ids &&
          acc[chat_id].message_ids.concat(_.castArray(message_ids));

        return acc;
      },
      { [request.chat_id]: request }
    );

    yield spawn(readMessages, toRead);
  }
}

export default function*() {
  yield all([
    throttle(500, Types.SEARCH_REQUEST, searchSaga),
    throttle(500, Types.SEND_REQUEST, sendMessageSaga),
    fork(watchMessageReading)
  ]);
}
