import { all, put, takeLatest } from "redux-saga/effects";
import api from "~/api/contactsApi";
import Types from "~/store/contacts/contactsTypes";
import Actions from "~/store/contacts/contactsActions";
import toastActions from "~/store/toast/toastActions";
import uiActions from "~/store/ui/uiActions";
import {
  ADD_PATIENT_MSG_FAILURE,
  ADD_PATIENT_MSG_SUCCESS
} from "~/consts/contracts";
import { COMMENT_VIEW } from "~/modals/Comment/CommentModal";
import { INVITATION_VIEW } from "~/layouts/Contacts/ContactAside/Invitation/InvitationModal";
import { USER_ADD, USER_FOUND } from "~/store/contacts/contactsReducer";

function* searchSaga({ request }) {
  try {
    const { data } = yield api.searchApi(request);
    yield put(Actions.setInvitationScene(USER_FOUND));
    yield put(Actions.searchSuccess(data, request));
  } catch (e) {
    // TODO: think about handling timeouts in one place.
    if (e.code === "ECONNABORTED") {
      yield put(
        toastActions.showErrorToast("Произошла ошибка, попробуйте позже")
      );
    }
    // There won't be a response filed on error on abort,
    // so we dispatch an action with checks to remove the spinner.
    yield put(
      Actions.searchFailure(
        {
          status: e.response && e.response.status,
          ...((e.response && e.response.data && e.response.data.errors) || {})
        },
        request
      )
    );
    if (e.response && e.response.status === 404) {
      yield put(Actions.setInvitationScene(USER_ADD));
    }
  }
}

function* addNewSaga({ request }) {
  try {
    const { data } = yield api.addNewApi(request);
    yield put(Actions.addNewSuccess(data, request));
    yield put(Actions.clearState());
    yield put(uiActions.hideModal(INVITATION_VIEW));
    yield put(toastActions.showSuccessToast(ADD_PATIENT_MSG_SUCCESS));
  } catch (e) {
    yield put(Actions.addNewFailure(e.message, request));
    yield put(Actions.clearState());
    yield put(uiActions.hideModal(INVITATION_VIEW));

    if (e.response.data?.errors && e.response.data.errors.snils) {
      yield put(toastActions.showErrorToast(e.response.data.errors.snils[0]));
    } else {
      yield put(toastActions.showErrorToast(ADD_PATIENT_MSG_FAILURE));
    }
  }
}

function* addByIdSaga({ request }) {
  try {
    const { data } = yield api.addByIdApi(request);
    yield put(Actions.addByIdSuccess(data, request));
    yield put(Actions.clearState());
    yield put(uiActions.hideModal(INVITATION_VIEW));
    yield put(toastActions.showSuccessToast(ADD_PATIENT_MSG_SUCCESS));
  } catch (e) {
    yield put(Actions.addByIdFailure(e.message, request));
    yield put(Actions.clearState());
    yield put(uiActions.hideModal(INVITATION_VIEW));
    yield put(toastActions.showErrorToast(ADD_PATIENT_MSG_FAILURE));
  }
}

function* privateCommentSendSaga({ request }) {
  try {
    const { data } = yield api.privateCommentSendApi(request);
    yield put(Actions.privateCommentSendSuccess(data, request));
    yield put(uiActions.hideModal(COMMENT_VIEW));
  } catch (e) {
    yield put(Actions.privateCommentSendFailure(e.message, request));
  }
}

export default function* contactsSaga() {
  yield all([
    takeLatest(Types.SEARCH_REQUEST, searchSaga),
    takeLatest(Types.ADD_NEW_REQUEST, addNewSaga),
    takeLatest(Types.ADD_BY_ID_REQUEST, addByIdSaga),
    takeLatest(Types.PRIVATE_COMMENT_SEND_REQUEST, privateCommentSendSaga)
  ]);
}
