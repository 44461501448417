import { takeLatest, put, all } from "redux-saga/effects";
import { normalize } from "normalizr";
import api from "~/api/clinicsApi";
import Types from "~/store/clinics/clinicsTypes";
import Actions from "~/store/clinics/clinicsActions";
import commonActions from "~/store/commons/commonActions";
import { clinicSchema } from "~/store/clinics/clinicsNomalizer";

function* clinicListSaga({ request }) {
  try {
    const { data } = yield api.clinicListApi(request);
    const {
      result: order,
      entities: { clinics: items, ...rest }
    } = normalize(data, [clinicSchema]);
    yield put(Actions.searchSuccess({ items, order }, request));
    yield put(commonActions.updateItems(rest));
  } catch (e) {
    yield put(Actions.searchFailure(e.message, request));
  }
}

export default function*() {
  yield all([takeLatest(Types.SEARCH_REQUEST, clinicListSaga)]);
}
