import axios from "axios";
import { eventChannel, END } from "redux-saga";
import {
  all,
  put,
  call,
  throttle,
  takeLatest,
  select,
  take,
  fork,
  cancel,
  takeEvery
} from "redux-saga/effects";
import { normalize } from "normalizr";
import get from "lodash/get";
import types from "~/store/consultations/consultationTypes";
import actions, {
  CONSULTATION_TYPES
} from "~/store/consultations/consultationsActions";
import toastActions from "~/store/toast/toastActions";
import { consultationSchema } from "~/store/consultations/consultationsNormalizer";
import api from "~/api/consultationsApi";
import {
  replyConsultationRatings,
  deleteDoctorReplyConsultationRating,
  deleteConsultationRatings
} from "~/api/consultationRatingsApi";
import {
  CONSULTATION_INFO_REQUEST_ERROR_TEXT,
  CONSULTATION_STATUSES,
  CONSULTATIONS_FILTER_STATUS_OPEN,
  POST_CONSULTATION_SUCCESS_TEXT
} from "~/consts/consultations";
import { ERROR_REQUEST_DEFAULT } from "~/consts/texts";
import { STATUS } from "~/consts/status";
import { parseResponseError } from "~/utils/parse";
import { getCurrentUserId } from "~/store/auth/authSelectors";
import { getTariffType } from "~/store/profile/profileSelectors";
import slotsActions from "~/store/slots/slotsActions";
import conclusionsActions from "~/store/conclusions/conclusionsActions";
import wsTypes from "~/store/webSocket/webSocketTypes";
import {
  getConsultationFiles,
  getConsultationsOpenPage
} from "~/store/consultations/consultationSelectors";
import uiActions from "~/store/ui/uiActions";
import { NEW_CONSULTATION_VIEW } from "~/modals/NewConsultation/containers/NewConsultationModal";
import commonActions from "~/store/commons/commonActions";
import productApi from "~/api/productApi";
import { CONSULTATION_STARTED } from "~/modals/ConsultationStarted/ConsultationStarted";
import { currentRouteSelector } from "~/store/routerSelectors";
import { CANCEL_CONSULTATION } from "~/modals/CancelConsultation/CancelConsultation";
import { NEW_CONSULTATION_ERROR_MODAL } from "~/modals/NewConsultation/components/Statuses/StatusError";
import { POST_CONSULTATION_FAILURE_TEXT } from "~/consts/consultations";

export const MAX_CONCURRENT_FILES = 5;

const getErrorData = error => get(error, "response", { data: {} });

const getSort = status => (status === "open" ? "asc" : "desc");
const getStatus = status => {
  switch (status) {
    case "open":
      return CONSULTATION_STATUSES.slice(0, 4);
    case "closed":
      return CONSULTATION_STATUSES.slice(4);
    default:
      return CONSULTATION_STATUSES;
  }
};
function* fetchSaga({ request }) {
  try {
    const { mode, limit, page } = request;
    const { data } = yield call(api.fetchConsultations, {
      sort: getSort(mode),
      status: getStatus(mode),
      limit,
      page
    });
    const {
      result: order,
      entities: { consultations: items, ...rest }
    } = normalize(data, [consultationSchema]);
    yield put(actions.getConsultationsSuccess({ items, order }, request));
    yield put(commonActions.updateItems(rest));
  } catch (err) {
    yield put(actions.getConsultationsFailure(err.consultations, request));
    yield put(toastActions.showErrorToast(ERROR_REQUEST_DEFAULT));
  }
}

function* getConsultationSaga({ request }) {
  try {
    const { data } = yield call(api.getConsultation, request.id);
    const conclusionId = data.conclusion_id;
    if (conclusionId) {
      yield put(conclusionsActions.getConclusion(conclusionId));
    }
    const {
      entities: { consultations: items, ...rest }
    } = normalize(data, consultationSchema);
    yield put(actions.getInfoSuccess({ items }));
    yield put(commonActions.updateItems(rest));
  } catch (error) {
    yield put(actions.getInfoFailure(error.message));
    yield put(
      toastActions.showErrorToast(CONSULTATION_INFO_REQUEST_ERROR_TEXT)
    );
  }
}

function* postConsultationSaga({
  payload: { patientId, tariffId, tariffType, slotId }
}) {
  try {
    const { data } = yield call(api.createConsultation, {
      patient_id: patientId,
      tariff_id: tariffId,
      slot_id: slotId,
      type: tariffType
    });
    yield put(actions.postSuccess({ data }));
    yield put(uiActions.hideModal(NEW_CONSULTATION_VIEW));
    yield put(toastActions.showSuccessToast(POST_CONSULTATION_SUCCESS_TEXT));
    yield put(
      actions.getConsultations({
        page: 1,
        mode: CONSULTATIONS_FILTER_STATUS_OPEN
      })
    );
  } catch (error) {
    const { status, data } = getErrorData(error);
    if (status === STATUS.ERROR_VALIDATION) {
      yield put(actions.postFailure(parseResponseError(data.errors)));
    } else {
      yield put(actions.postFailure());
      yield put(
        uiActions.replaceModal(
          NEW_CONSULTATION_VIEW,
          NEW_CONSULTATION_ERROR_MODAL
        )
      );
      yield put(toastActions.showErrorToast(POST_CONSULTATION_FAILURE_TEXT));
    }
  }
}

function* selectTariffSaga({ tariff }) {
  const doctorId = yield select(getCurrentUserId);
  const type = getTariffType(tariff.type);
  yield put(slotsActions.fetchSlots(type, doctorId));
}

function* wsSlotsSaga({ type, event }) {
  // tariff_type: av | chat | offline
  if (type === wsTypes.WS_SLOT_STARTED && event.tariff_type === "av") {
    const currentRoute = yield select(currentRouteSelector);

    if (currentRoute !== `/consultations/${event.id}`) {
      yield put(uiActions.showModal(CONSULTATION_STARTED, event));
    }
  }

  if (
    [
      wsTypes.WS_SLOT_MOVED,
      wsTypes.WS_SLOT_ENROLLED,
      wsTypes.WS_SLOT_CANCELED,
      wsTypes.WS_SLOT_ENDED
    ].includes(type)
  ) {
    const page = yield select(getConsultationsOpenPage) || 1;
    yield put(
      actions.getConsultations({ page, mode: CONSULTATIONS_FILTER_STATUS_OPEN })
    );
  }

  const { entities: items } = normalize(event, consultationSchema);

  if (typeof items === "object") {
    yield put(commonActions.updateItems(items));
  }
}

function* endConsultation({ service_patient_id, consultationId }) {
  try {
    yield call(productApi.endProduct, { service_patient_id });
    yield put(actions.getInfo(consultationId));
  } catch (e) {
    yield put(toastActions.showErrorToast(ERROR_REQUEST_DEFAULT));
  }
}

function* linkUploadFile({ consultationId, tempFileId, file }) {
  try {
    const data = yield call(api.linkUploadFile, { consultationId, tempFileId });

    yield put(
      actions.updateFile({
        ...data,
        tempFileId,
        isLinked: true,
        file,
        consultationId
      })
    );
  } catch (err) {
    yield put(toastActions.showErrorToast(ERROR_REQUEST_DEFAULT));
    yield put(actions.updateFile({ error: err.message }));
  }
}

function createUploader({ payload, fileName, cancelSource }) {
  let emit;
  const channel = eventChannel(emitter => {
    emit = emitter;
    return () => {};
  });

  const uploadPromise = api.uploadFile({
    payload,
    cancelToken: cancelSource.token,
    onProgress: event => {
      if (event.total === event.loaded) {
        emit(END);
      }

      const progress = event.loaded / event.total;

      emit({
        progress,
        fileName
      });
    }
  });

  return [uploadPromise, channel];
}

function* watchOnProgress(channel) {
  while (true) {
    const data = yield take(channel);

    yield put(
      actions.uploadProgress({
        fileName: data.fileName,
        progress: data.progress
      })
    );
  }
}

function* linkingFile({ uploadPromise, consultationId, file }) {
  try {
    const result = yield call(() => uploadPromise);

    yield put(actions.uploadSuccess({ fileName: file.file.name }));
    yield linkUploadFile({
      consultationId,
      tempFileId: result.data.temp_file_id,
      file
    });
  } catch (err) {
    yield put(
      actions.updateFileError({
        fileName: file.file.name,
        error: err.message,
        progress: null
      })
    );
  }
}

function* uploadSource({ file, consultationId }) {
  let formData = new FormData();
  formData.append("file", file.file);
  const cancelSource = axios.CancelToken.source();
  const fileName = file.file.name;
  const [uploadPromise, channel] = createUploader({
    payload: formData,
    fileName,
    cancelSource
  });

  const watchingTask = yield fork(watchOnProgress, channel);
  const linkingFileTask = yield fork(linkingFile, {
    uploadPromise,
    consultationId,
    file
  });

  yield take(
    action =>
      action.type === types.CONSULTATION_CANCEL_UPLOAD &&
      action.fileName === fileName
  );
  channel.close();
  yield call(cancelSource.cancel);
  yield cancel(watchingTask);
  yield cancel(linkingFileTask);
}

function* uploadFiles({ consultationId }) {
  const files = yield select(getConsultationFiles);
  yield put({ type: types.CONSULTATION_START_UPLOAD_FILES });

  const filesCopy = Object.entries(files).reduce((acc, [fileName, file]) => {
    if (!file.tempFileId) {
      acc[fileName] = {
        ...file
      };
    }

    return acc;
  }, {});

  let filesFlag = 0;

  while (Object.keys(filesCopy).length > 0) {
    if (filesFlag < MAX_CONCURRENT_FILES) {
      let [fileName, file] = Object.entries(filesCopy)[0];

      filesFlag++;

      if (consultationId) {
        yield fork(uploadSource, { file, consultationId });
      }

      delete filesCopy[fileName];
    } else {
      yield take([
        types.CONSULTATION_UPDATE_FILE,
        types.CONSULTATION_CANCEL_UPLOAD,
        types.CONSULTATION_UPLOAD_FILE_ERROR
      ]);
      filesFlag--;
    }
  }
}

function* uploadFile({ file, consultationId }) {
  yield uploadSource({ file, consultationId });
}

function* deleteFile({ consultationId, fileId }) {
  try {
    const { status } = yield call(api.deleteFile, {
      fileId
    });

    if (status === 200) {
      yield put(actions.deleteFileSuccess({ consultationId, fileId }));
    }
  } catch (err) {
    yield put(actions.deleteFileError());
  }
}

function* confirmConsultationSaga({ consultationId }) {
  try {
    yield call(api.confirmConsultation, {
      consultationId
    });

    yield put(actions.getInfo(consultationId));
  } catch (err) {
    yield put(actions.confirmConsultationError(err.response?.data.errors));
  }
}

function* cancelConsultationSaga({ consultationId, reason }) {
  try {
    yield call(api.cancelConsultation, {
      consultationId,
      reason
    });

    yield put(actions.getInfo(consultationId));
    yield put(uiActions.hideModal(CANCEL_CONSULTATION));
  } catch (err) {
    yield put(actions.cancelConsultationError(err.response?.data.errors));
  }
}

function* replyDoctorSaga({ comment, replyId, consultationId }) {
  try {
    yield call(replyConsultationRatings, replyId, {
      comment
    });

    yield put(actions.getInfo(consultationId));
  } catch (err) {
    yield put(actions.doctorReplyErrorAction(err.response?.data.errors));
  } finally {
    yield put(actions.resetIsLoadingAction());
  }
}

function* deleteReplyDoctorSaga({ replyId, consultationId }) {
  try {
    yield call(deleteDoctorReplyConsultationRating, replyId);

    yield put(actions.getInfo(consultationId));
  } catch (err) {
    yield put(actions.doctorReplyErrorAction(err.response?.data.errors));
  } finally {
    yield put(actions.resetIsLoadingAction());
  }
}

function* deleteConsultationRatingSaga({ ratingId, consultationId }) {
  try {
    yield call(deleteConsultationRatings, ratingId);

    yield put(actions.getInfo(consultationId));
  } catch (err) {
    yield put(actions.doctorReplyErrorAction(err.response?.data.errors));
  } finally {
    yield put(actions.resetIsLoadingAction());
  }
}

function* downloadFileSaga({ fileUrl, fileName }) {
  try {
    const { data } = yield call(api.downloadFile, fileUrl);
    var link = document.createElement("a");
    link.href = window.URL.createObjectURL(data);
    link.download = fileName;

    document.body.appendChild(link);

    link.click();
  } catch (err) {
    console.log(err);
    yield put(actions.sendFeedbackFailure(err));
    yield put(toastActions.showErrorToast(ERROR_REQUEST_DEFAULT));
  }
}
export default function* consultationsSaga() {
  yield all([
    throttle(500, types.CONSULTATIONS_GET, fetchSaga),
    takeLatest(types.CONSULTATIONS_INFO_GET, getConsultationSaga),
    takeLatest(types.CONSULTATIONS_POST, postConsultationSaga),
    takeLatest(types.CONSULTATIONS_SELECT_TARIFF, selectTariffSaga),
    takeLatest(types.CONSULTATION_END, endConsultation),
    takeLatest(types.CONSULTATION_UPLOAD_FILES, uploadFiles),
    takeLatest(types.CONSULTATION_UPLOAD_FILE, uploadFile),
    takeLatest(types.CONSULTATION_DELETE_FILE, deleteFile),
    takeLatest(types.CONSULTATION_CONFIRM, confirmConsultationSaga),
    takeLatest(types.CONSULTATION_CANCEL, cancelConsultationSaga),
    takeLatest(types.REPLY_DOCTOR, replyDoctorSaga),
    takeLatest(types.DELETE_REPLY_DOCTOR, deleteReplyDoctorSaga),
    takeLatest(types.DELETE_CONSULTATION_RATING, deleteConsultationRatingSaga),
    takeEvery(CONSULTATION_TYPES.DOWNLOAD_FILE, downloadFileSaga),
    takeLatest(
      [
        wsTypes.WS_SLOT_PAID,
        wsTypes.WS_SLOT_STARTED,
        wsTypes.WS_SLOT_ENDED,
        wsTypes.WS_SLOT_CANCELED,
        wsTypes.WS_SLOT_ENROLLED,
        wsTypes.WS_SLOT_MOVED
      ],
      wsSlotsSaga
    )
  ]);
}
