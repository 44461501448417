import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Text } from "ibolit-ui";
import cn from "classnames";

import styles from "./BlockHeader.scss";
import useResizeObserver from "~/hooks/useResizeObserver";

const BlockHeader = React.forwardRef(
  (
    { className, title, titlePosition, renderLeft, renderRight, testid },
    ref
  ) => {
    const [leftRef, { width: leftWidth }] = useResizeObserver({
      useWidth: true
    });
    const [rightRef, { width: rightWidth }] = useResizeObserver({
      useWidth: true
    });

    const titleStyle = useMemo(() => {
      const offset = Math.max(leftWidth, rightWidth);
      return {
        paddingLeft: titlePosition === "center" ? offset : leftWidth,
        paddingRight: titlePosition === "center" ? offset : rightWidth
      };
    }, [leftWidth, rightWidth]);

    return (
      <header ref={ref} className={cn(styles.container, className)}>
        <aside ref={leftRef} className={styles.leftPanel}>
          {renderLeft && renderLeft()}
        </aside>
        <div className={cn(styles.titleRow, styles[titlePosition])}>
          <Text testid={testid} variant="h3" style={titleStyle}>
            {title}
          </Text>
        </div>
        <aside ref={rightRef} className={styles.rightPanel}>
          {renderRight && renderRight()}
        </aside>
      </header>
    );
  }
);

BlockHeader.defaultProps = {
  titlePosition: "center"
};

BlockHeader.propTypes = {
  className: PropTypes.string,
  title: PropTypes.node,
  renderLeft: PropTypes.func,
  renderRight: PropTypes.func,
  titlePosition: PropTypes.oneOf(["center", "left", "right"]),
  testid: PropTypes.string
};

export default BlockHeader;
