import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import uiActions from "~/store/ui/uiActions";
import NewConsultationButton from "../components/NewConsultationButton";
import { NEW_CONSULTATION_VIEW } from "./NewConsultationModal";

const NewConsultationButtonContainer = props => {
  const { user, ...otherProps } = props;
  const dispatch = useDispatch();

  const showModalMemo = useCallback(() => {
    dispatch(uiActions.showModal(NEW_CONSULTATION_VIEW, { user }));
  }, [user]);

  return <NewConsultationButton onClick={showModalMemo} {...otherProps} />;
};

export default NewConsultationButtonContainer;
