import Immutable from "seamless-immutable";
import {
  GET_CONSULTATION_RATING,
  DELETE_CONSULTATION_RATING,
  PATCH_CONSULTATION_RATING,
  CONSULTATION_RATING_FAIL,
  REPLY_CONSULTATION_RATING,
  orders,
  RESET_CONSULTATION_RATING
} from "./types";

const initialState = Immutable({
  consultationRating: [],
  error: "",
  isLoading: false,
  page: 1,
  order_by: orders.id_desc
});

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CONSULTATION_RATING.CALL:
    case DELETE_CONSULTATION_RATING.CALL:
    case REPLY_CONSULTATION_RATING.CALL:
      return state.merge({
        error: "",
        isLoading: true,
        page: action.payload.order_by !== state.order_by ? 1 : state.page,
        order_by: action.payload.order_by
      });
    case DELETE_CONSULTATION_RATING.SUCCESS:
      return state.merge({
        error: "",
        isLoading: false,
        // eslint-disable-next-line no-confusing-arrow
        consultationRating: state.consultationRating.filter(feedback =>
          feedback.id === action.payload.id ? false : feedback
        )
      });
    case PATCH_CONSULTATION_RATING.CALL:
      return state.merge({
        error: "",
        isLoading: false,
        // eslint-disable-next-line no-confusing-arrow
        consultationRating: state.consultationRating.map(feedback =>
          feedback.id === action.payload.id ? action.payload : feedback
        )
      });
    case GET_CONSULTATION_RATING.SUCCESS:
      const { consultationRating } = action.payload;
      return state.merge({
        consultationRating:
          state.page === 1
            ? consultationRating
            : state.consultationRating.concat(consultationRating),
        error: "",
        isLoading: false,
        page: state.page + 1
      });
    case CONSULTATION_RATING_FAIL:
      return state.merge({
        error: action.payload.error,
        isLoading: false
      });
    case RESET_CONSULTATION_RATING.CALL:
      return state.merge({
        consultationRating: [],
        error: "",
        isLoading: false,
        page: 1
      });

    default:
      return state;
  }
};
