import React, { forwardRef } from "react";
import noop from "lodash/noop";
import { useRouteMatch } from "react-router-dom";
import Link from "~/components/Link";
import Loading from "~/components/Loading/Loading";
import ChatsListItem from "./ChatsListItem";
import EmptyStub from "~/components/EmptyStub";
import { DATA_LOAD_ERROR } from "~/consts/texts";
import styles from "./ChatsList.scss";
import { CHAT_MODE_ALL } from "~/store/chats/chatModel";
import InfiniteList from "~/components/InfiniteList";

const ChatsList = (
  {
    items,
    currentUserId,
    isFetching,
    initialFetched,
    canLoadMore,
    mode,
    hasErrors,
    isEmpty,
    incrementPage
  },
  ref
) => {
  const match = useRouteMatch();
  const loadingProps = isEmpty ? {} : { variant: "row", height: 80 };
  return (
    <InfiniteList
      ref={ref}
      className={styles.list}
      canLoadMore={canLoadMore}
      loadMore={incrementPage}
    >
      {items.map(item => (
        <Link
          key={item.id}
          to={`${match.url}/${item.id}`}
          activeClassName={styles.selected}
        >
          <ChatsListItem
            chat={item}
            isSelected={match.params.chatId === item.id}
            currentUserId={currentUserId}
            onClick={noop}
          />
        </Link>
      ))}
      {isFetching && <Loading {...loadingProps} />}
      {!initialFetched && !hasErrors && (
        <EmptyStub text="Загружаем список чатов" centered />
      )}
      {hasErrors && <EmptyStub text={DATA_LOAD_ERROR} centered />}
      {isEmpty && <EmptyStub centered text={"Чаты не найдены"} />}
    </InfiniteList>
  );
};

export default forwardRef(ChatsList);
