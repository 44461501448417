import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { Tooltip, IconWithText, Icon, Text } from "ibolit-ui";
import PartnerInfo from "./PartnerInfo";
import { getLowerTooltipRoot } from "~/utils/tooltip";
import styles from "./ChatHeader.scss";
import { passMoreThanHours } from "~/utils/dateUtils";
import { LAST_ASK_HOURS_DURATION } from "~/consts/chats";

const ChatHeader = ({ partner, showUserInfo, infoShown }) => {
  const shouldAddWarning =
    partner.is_primary &&
    partner.is_primary_at &&
    !passMoreThanHours(partner.is_primary_at, LAST_ASK_HOURS_DURATION);

  return (
    <div className={styles.container}>
      <div className={styles.userInfo}>
        <PartnerInfo user={partner} />
        {shouldAddWarning && (
          <div className={styles.userIcon}>
            <Tooltip
              key={partner.id}
              offset={120}
              position="bottom"
              clickable
              variant="warning"
              header={
                <a href="#">
                  <Icon fill="#ffd230" variant="info" />
                </a>
              }
              domRoot={getLowerTooltipRoot()}
            >
              <div className={styles.tooltipContent}>
                <Text variant="h4">У вас новый пациент</Text>
                <Text variant="desc">
                  Поставить диагноз или назначить лечение можно пациентам,
                  которые были на очном приеме
                </Text>
              </div>
            </Tooltip>
          </div>
        )}
      </div>
      {partner && !partner.is_deleted && (
        <IconWithText
          className={cn(styles.button, { [styles.hidden]: infoShown })}
          iconVariant="info"
          text="O пациенте"
          onClick={showUserInfo}
          hasHoverEffect
          dataTestid={"chat-header-about-patient"}
        />
      )}
    </div>
  );
};

ChatHeader.propTypes = {
  partner: PropTypes.object
};

export default ChatHeader;
