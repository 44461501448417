import React from "react";
import PropTypes from "prop-types";
import { Modal } from "ibolit-ui";
import { createPortal } from "react-dom";
import { connect } from "react-redux";
import { getModalViews } from "~/store/ui/uiSelectors";
import uiActions from "~/store/ui/uiActions";

const Modals = ({ modals, modalsMap, idDOMRoot, onBGClick }) =>
  createPortal(
    <Modal modals={modals} modalsMap={modalsMap} onBGClick={onBGClick} />,
    document.getElementById(idDOMRoot)
  );

const ModalContainer = connect(
  state => ({
    modals: getModalViews(state)
  }),
  {
    onBGClick: uiActions.hideModal
  }
)(Modals);

ModalContainer.propTypes = {
  idDOMRoot: PropTypes.string.isRequired,
  modalsMap: PropTypes.object.isRequired
};

export default ModalContainer;
