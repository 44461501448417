import React from "react";
import PropTypes from "prop-types";
import ScrollbarAutoSized from "~/components/Scrollbar/ScrollbarAutoSized";
import Message from "../Message";
import styles from "./MessagesList.scss";
import dateStyles from "../ChatDate/ChatDate.scss";
import { CHAT } from "~/locators/chats";
import { useChatBubble } from "~/hooks/useChatBubble";

const MessagesList = ({
  onScroll,
  items,
  partner,
  onReady,
  scrollRef,
  isSending
}) => {
  useChatBubble(
    scrollRef,
    items.length,
    dateStyles.bubble_text,
    dateStyles.bubble_visible
  );

  return (
    <ScrollbarAutoSized
      ref={scrollRef}
      fixBottomOnResize
      onScrollFrame={onScroll}
      onReady={onReady}
      className={styles.scrollbar}
    >
      <div className={styles.container}>
        {items.map(message => (
          <Message
            key={message.id}
            testId={CHAT.MESSAGES_CONTAINER}
            partnerId={partner.id}
            message={message}
            isSending={isSending}
          />
        ))}
      </div>
    </ScrollbarAutoSized>
  );
};

MessagesList.propTypes = {
  onScroll: PropTypes.any,
  items: PropTypes.any,
  partner: PropTypes.any,
  onReady: PropTypes.any,
  scrollRef: PropTypes.any
};

export default MessagesList;
