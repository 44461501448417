import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useGetParamValue } from "~/hooks/useGetParamValue";
import { Icon, Separator } from "ibolit-ui";
import { pluralizeYears } from "~/utils/pluralize";
import {
  getUser,
  getConsultationRatingCount
} from "~/store/profile/profileSelectors";
import MainLayout, { Section, Aside } from "~/components/layouts/Main/Main";
import RowWithImage from "~/components/RowWithImage";
import EmptyStub from "~/components/EmptyStub";
import ConnectionCheck from "~/components/HealthCheck";
import ProfileHeader from "./ProfileHeader/ProfileHeader";
import ProfileSettings from "./ProfileSettings";
import ProfileFeedback from "./ProfileFeedback";
import ProfileInfo from "./ProfileInfo";
import ProfileIncome from "./ProfileIncome/ProfileIncome";
import ProfileOnlineRecord from "./ProfileOnlineRecord/ProfileOnlineRecord";
import styles from "./ProfileLayout.scss";

function ProfileLayout() {
  const user = useSelector(state => getUser(state));
  const consultationRatingLength = useSelector(getConsultationRatingCount);

  const defaultSection = useGetParamValue("section");
  const [openSection, setOpenSection] = useState(defaultSection || "");

  function handleActionClick(section) {
    return () => {
      setOpenSection(section);
    };
  }

  function handleCloseClick() {
    setOpenSection("");
  }

  if (!user) {
    return <EmptyStub centered />;
  }

  return (
    <MainLayout mode="wide">
      <Section>
        <div className={styles.container}>
          <ProfileHeader user={user} />
          <div>
            {user.experience && (
              <RowWithImage
                className={styles.info}
                image={<Icon variant="phonendoscope" />}
                info={`Опыт работы: ${pluralizeYears(user.experience)}`}
                testid="ProfileLayout__experience-info"
              />
            )}
            {user.category && (
              <RowWithImage
                image={<Icon variant="grade" />}
                info={user.category}
                className={styles.info}
                testid="ProfileLayout__category-info"
              />
            )}
            {user.education && (
              <RowWithImage
                key="education"
                image={<Icon variant="school" />}
                info={user.education}
                className={styles.info}
                testid="ProfileLayout__education-info"
              />
            )}
            <div className={styles.actions}>
              <RowWithImage
                className={styles.row}
                titleClass={styles.rowTitle}
                image={<Icon variant="user" />}
                title="Редактировать профиль"
                onClick={handleActionClick("editProfile")}
                hasHoverEffect
                testid="ProfileLayout__edit-profile-action"
              />
              <RowWithImage
                className={styles.row}
                titleClass={styles.rowTitle}
                image={<Icon variant="info" />}
                title="Редактировать информацию о себе"
                onClick={handleActionClick("editInfo")}
                hasHoverEffect
                testid="ProfileLayout__edit-about-profile-action"
              />
              <RowWithImage
                className={styles.row}
                titleClass={styles.rowTitle}
                image={<Icon variant="time-lock" />}
                title="Настройки онлайн-записи"
                onClick={handleActionClick("onlineRecord")}
                hasHoverEffect
                testid="ProfileLayout__edit-online-record-profile-action"
              />
              <RowWithImage
                className={styles.row}
                titleClass={styles.rowTitle}
                image={<Icon variant="percent" />}
                title="Расчет заработной платы"
                onClick={handleActionClick("income")}
                hasHoverEffect
                testid="ProfileLayout__edit-income-action"
              />
              <RowWithImage
                className={styles.row}
                titleClass={styles.rowTitle}
                image={<Icon variant="feedback" />}
                title="Отзывы пациентов"
                titleRight={` (${consultationRatingLength || 0})`}
                titleRightClassName={styles.titleRightClassName}
                onClick={handleActionClick("feedback")}
                hasHoverEffect
                testid="ProfileLayout__edit-feedback-action"
              />
            </div>
          </div>
          {user.description && (
            <>
              <Separator />
              <div
                className={styles.descriptionContainer}
                data-testid="ProfileLayout__description"
              >
                <h3>О себе</h3>
                <p>{user.description}</p>
              </div>
            </>
          )}
          <Separator />
          <RowWithImage
            className={styles.checkRow}
            image={<Icon variant="check" />}
            title="Проверка качества связи"
            onClick={handleActionClick("check")}
            hasHoverEffect
            testid="ProfileLayout__health-check-action"
          />
        </div>
      </Section>
      {openSection && (
        <Aside variant="rightSide">
          {openSection === "editProfile" && (
            <ProfileSettings onCloseClick={handleCloseClick} />
          )}
          {openSection === "feedback" && (
            <ProfileFeedback onCloseClick={handleCloseClick} />
          )}
          {openSection === "editInfo" && (
            <ProfileInfo onCloseClick={handleCloseClick} />
          )}
          {openSection === "onlineRecord" && (
            <ProfileOnlineRecord onCloseClick={handleCloseClick} />
          )}
          {openSection === "income" && (
            <ProfileIncome onCloseClick={handleCloseClick} />
          )}
          {openSection === "check" && (
            <ConnectionCheck onCloseClick={handleCloseClick} />
          )}
        </Aside>
      )}
    </MainLayout>
  );
}

export default ProfileLayout;
