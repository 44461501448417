import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "~/components/Loading/Loading";
import chatsActions from "~/store/chats/chatsActions";
import Chat from "./Chat";

const ChatContainer = ({ chatId, showUserInfo, infoShown }) => {
  const dispatch = useDispatch();

  const chatInfoRequest = useCallback(() => {
    dispatch(chatsActions.infoRequest(chatId));
  }, [chatId]);

  useEffect(() => {
    !user && chatInfoRequest();
  }, [user]);

  const { chat, user, currentUserId } = useSelector(state =>
    chatSelector(state, chatId)
  );

  return user ? (
    <Chat
      chat={chat}
      user={user}
      currentUserId={currentUserId}
      showUserInfo={showUserInfo}
      infoShown={infoShown}
    />
  ) : (
    <Loading showLoading />
  );
};

const chatSelector = (
  { auth: { currentUserId }, chats: { items: chats }, users: { items: users } },
  chatId
) => {
  const chat = chats[chatId] || {};

  return {
    chat,
    user: users[chat.user],
    currentUserId
  };
};

export default ChatContainer;
