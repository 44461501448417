import axios from "./httpService";

export default {
  userChatIdApi: ({ id }) => axios.get(`/v3/user/partner/${id}/chat`),
  usersSearchApi: params => axios.get("/v3/users/patients", { params }),
  userInfoApi: ({ id }) => axios.get(`/v3/users/patients/${id}`),
  userCheckNewApi: ({ id }) => axios.get(`/v3/user/${id}/check-new`),
  chatSendConfirmNewPatient: ({ id, is_primary }) =>
    axios.put(`/v3/user/patients/${id}/is_primary`, { is_primary })
};
