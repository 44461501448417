import React from "react";
import PropTypes from "prop-types";
import ProfileFeedback from "./components/ProfileFeedback";

const ProfileFeedbackContainer = ({ onCloseClick }) => (
  <ProfileFeedback onCloseClick={onCloseClick} />
);

ProfileFeedbackContainer.propTypes = {
  onCloseClick: PropTypes.func
};

export default ProfileFeedbackContainer;
