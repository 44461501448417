export const INVITATION_MODAL = {
  ICON_BACK: "invitation__modal-iconBack",
  ICON_CLOSE: "invitation__modal-iconClose"
};

export const PATIENT_CHECK = {
  PHONE_OR_EMAIL: "invitation__patientCheck__phone-or-email-input",
  CANCEL: "invitation__patientCheck__cancel-button",
  CONTINUE: "invitation__patientCheck__continue-button",
  MASK_CONTAINER: "invitation__patientCheck__mask-container",
  MASK_SELECT: "invitation__patientCheck__mask-select",
  MASK_SELECT_TITLE: "invitation__patientCheck__mask-select-title",
  MASK_INPUT: "invitation__patientCheck__mask-input",
  MASK_DROPDOWN: "invitation__patientCheck__mask-dropdown",
  MASK_SELECT_IMG: "invitation__patientCheck__mask-select-img",
  MASK_CONTAINER_ERROR: "invitation__patientCheck__mask-container-error",
  dropdownMenuElement: index =>
    `invitation__patientCheck__mask-dropdown-menu-element-${index}`,
  dropdownElementImg: index =>
    `invitation__patientCheck__mask-dropdown-menu-element-image-${index}`,
  dropdownElementCountryName: index =>
    `invitation__patientCheck__mask-dropdown-menu-element-country-name-${index}`,
  dropdownElementLabel: index =>
    `invitation__patientCheck__mask-dropdown-menu-element-label-${index}`
};

export const PATIENT_ADD = {
  FIO: "invitation__patientAdd__fio-input",
  FIO_LABEL: "invitation__patientAdd__fio-input-label",
  GENDER: "invitation__patientAdd__gender-select",
  GENDER_LABEL: "invitation__patientAdd__gender-select-label",
  DATE_OF_BIRTH: "invitation__patientAdd__date-of-birth-input",
  DATE_OF_BIRTH_LABEL: "invitation__patientAdd__date-of-birth-input-label",
  SEND_INVITATION: "invitation__patientAdd__send-button",
  SNILS: "invitation__patientAdd__snils-input",
  SNILS_LABEL: "invitation__patientAdd__snils-input-label"
};

export const PATIENT_FOUND = {
  USER_CARD: "patient__patientFound__user-card",
  CANCEL: "patient__patientFound__cancel-button",
  ADD: "patient__patientFound__add-button"
};
