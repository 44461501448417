import Immutable from "seamless-immutable";
import Types from "~/store/clinics/clinicsTypes";
import {
  searchRequestReducer,
  searchSuccessReducer,
  searchFailureReducer
} from "~/store/utils";

const initialState = Immutable({
  items: {},
  order: [],
  isFetching: false,
  data: null,
  error: null
});

export default (state = initialState, action) => {
  switch (action.type) {
    case Types.UPDATE_ITEMS:
      return state.merge({ items: action.items }, { deep: true });

    case Types.SEARCH_REQUEST:
      return searchRequestReducer(state, action);

    case Types.SEARCH_SUCCESS:
      return searchSuccessReducer(state, action);

    case Types.SEARCH_FAILURE:
      return searchFailureReducer(state, action);

    default:
      return state;
  }
};
