import React from "react";
import PropTypes from "prop-types";
import { Text } from "ibolit-ui";
import cn from "classnames";
import styles from "./EmptySlotsLIst.scss";

const EmptySlotsList = ({ text }) => (
  <p className={cn(styles.container)}>
    <Text tag="span" variant="h5">
      {text}
    </Text>
  </p>
);

EmptySlotsList.propTypes = {
  text: PropTypes.string.isRequired
};

export default EmptySlotsList;
