import React, { useEffect, useRef, useState } from "react";
import { Button } from "ibolit-ui";
import PrivateCommentField from "~/modals/Comment/PrivateCommentField";
import styles from "./CommentForm.scss";

const CommentForm = ({ comment, requestSubmit, error, testid }) => {
  const [errors, setErrors] = useState(error || {});
  const [isTouched, setIsTouched] = useState(false);
  useEffect(() => {
    setErrors(error || {});
  }, [error]);

  const inputRef = useRef(null);

  const handleSubmit = () => {
    inputRef.current.blur();
    requestSubmit(inputRef.current.value.trim());
  };

  const handleRemove = () => {
    requestSubmit("");
  };

  return (
    <>
      <PrivateCommentField
        initialValue={comment}
        error={errors.comment}
        ref={inputRef}
        required={false}
        autoFocus
        resize="none"
        noSpace
        testid={`${testid}__textarea-comment`}
        autosize={false}
        className={styles.commentField}
        onChange={(e) =>
          setIsTouched(
            e.target.value !== comment && e.target.value.trim() !== ""
          )
        }
      />
      <div className={styles.controls}>
        {comment && comment.length > 0 && (
          <Button
            className={styles.controlButton}
            testid={`${testid}__button-remove`}
            variant="secondary"
            colorVariant="danger"
            onClick={handleRemove}
          >
            Удалить
          </Button>
        )}
        <Button
          fullWidth={!comment}
          className={styles.controlButton}
          testid={`${testid}__button-submit`}
          onClick={handleSubmit}
          disabled={!isTouched}
        >
          Сохранить
        </Button>
      </div>
    </>
  );
};

export default CommentForm;
