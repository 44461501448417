import noop from "lodash/noop";
import { all, call, put, select, takeLatest, spawn } from "redux-saga/effects";
import { normalize, schema } from "normalizr";
import authTypes from "~/store/auth/authTypes";
import {
  changeLoginApi,
  changePasswordApi,
  confirmSettingsChange,
  editUserDataApi,
  getStatisticsApi,
  getUserDataApi,
  resendCode,
  sendAvatarApi,
  getTariffsApi,
  getDoctorsStats as getDoctorsStatsReq
} from "~/api/profileApi";
import types from "~/store/profile/profileTypes";
import actions from "./profileAction";
import { setToken } from "~/utils/localStorageHelper";
import {
  getSettingsBackScene,
  getSettingsLogin,
  getSettingsCodeId,
  getUser,
  getUpdatedLogin
} from "./profileSelectors";
import { parseResponseError } from "~/utils/parse";
import { formScenes } from "~/layouts/Profile/ProfileSettings/components/ProfleSettings/components/FormSwitcher/types";
import { STATUS } from "~/consts/status";
import logger from "~/utils/Logger";
import uiActions from "~/store/ui/uiActions";
import { PROFILE_CHANGE_SETTINGS_VIEW } from "~/consts/profile";

const UNSUPPORTED_ERROR = {
  status: STATUS.ERROR_BAD_REQUEST
};

const statisticsSchema = new schema.Entity("statistics");

function* logoutSaga() {
  yield put(actions.setProfileDefaultState());
}

function* userAvatarFetchSaga({ file }) {
  try {
    const {
      data: { temp_file_id }
    } = yield call(sendAvatarApi, file);
    const user = yield select(getUser);
    yield put(
      actions.userDataUpdateRequest({ ...user, temp_avatar_id: temp_file_id })
    );
  } catch (err) {
    yield put(actions.userDataUpdateError());
  }
}

function* userDataUpdateFetchSaga({ values }) {
  try {
    const { data } = yield call(editUserDataApi, values);
    if (!data.id) {
      logger.error(`В ответе отсутсвует id`);
      throw UNSUPPORTED_ERROR;
    }
    const user = yield select(getUser);
    yield put(actions.userDataUpdateSuccess({ ...user, ...data }));
  } catch (err) {
    yield put(actions.userDataUpdateError());
  }
}

function* passwordChangeSaga({ values, setErrors = noop }) {
  try {
    const {
      data: { token }
    } = yield call(changePasswordApi, values);
    if (!token) {
      logger.error("В ответе нет токена");
      throw UNSUPPORTED_ERROR;
    }
    setToken(token);
    yield put(actions.changePasswordSuccess());
    yield put(uiActions.hideModal(PROFILE_CHANGE_SETTINGS_VIEW));
  } catch (err) {
    const { status, data } = err.response;
    if (status === STATUS.ERROR_VALIDATION) {
      yield put(actions.changePasswordValidationError());
      setErrors(parseResponseError(data.errors));
    } else {
      yield put(actions.changePasswordError());
      yield put(uiActions.hideModal(PROFILE_CHANGE_SETTINGS_VIEW));
    }
  }
}

function* loginChangeSaga({ values, scene, setErrors = noop }) {
  try {
    const {
      data: { codeId, timeout }
    } = yield call(changeLoginApi, values);
    if (!codeId) {
      logger("В ответе нет codeId");
      throw UNSUPPORTED_ERROR;
    }
    yield put(
      actions.changeLoginSuccess({ codeId, ...values }, scene, timeout)
    );
    yield put(
      uiActions.replaceModal(
        PROFILE_CHANGE_SETTINGS_VIEW,
        PROFILE_CHANGE_SETTINGS_VIEW,
        {
          scene: formScenes.confirm
        }
      )
    );
  } catch (err) {
    const { status, data } = err.response;
    if (status === STATUS.ERROR_VALIDATION) {
      yield put(actions.changeLoginValidationError());
      setErrors(parseResponseError(data.errors));
    } else {
      yield put(actions.changeLoginError());
      yield put(actions.resetLoginSettings());
      yield put(uiActions.hideModal(PROFILE_CHANGE_SETTINGS_VIEW));
    }
  }
}

function* confirmChangeSaga({ code, setErrors = noop }) {
  try {
    const codeId = yield select(getSettingsCodeId);
    yield call(confirmSettingsChange, { code, codeId });
    const updatedValue = yield select(getUpdatedLogin);
    yield put(actions.confirmSuccess(updatedValue));
    yield put(uiActions.hideModal(PROFILE_CHANGE_SETTINGS_VIEW));
    yield put(actions.resetLoginSettings());
  } catch (err) {
    const { status, data } = err.response;
    if (status === STATUS.ERROR_VALIDATION) {
      yield put(actions.confirmValidationError());
      setErrors(parseResponseError(data.errors));
    } else {
      yield put(actions.confirmError());
      yield put(uiActions.hideModal(PROFILE_CHANGE_SETTINGS_VIEW));
      yield put(actions.resetLoginSettings());
    }
  }
}

function* codeSendSaga() {
  try {
    const login = yield select(getSettingsLogin);
    const codeId = yield select(getSettingsCodeId);
    yield call(resendCode, { login, codeId });
    yield put(actions.sendCodeSuccess());
  } catch (err) {
    yield put(actions.sendCodeError());
    yield put(actions.resetLoginSettings());
    yield put(uiActions.hideModal(PROFILE_CHANGE_SETTINGS_VIEW));
  }
}

function* getDoctorsStats() {
  try {
    const { data } = yield call(getDoctorsStatsReq);
    yield put(actions.doctorsStatsSuccess(data));
  } catch (err) {
    yield put(actions.doctorsStatsError());
  }
}

function* getUserData() {
  try {
    yield spawn(getDoctorsStats);
    const { data } = yield call(getUserDataApi);
    yield put(actions.userDataLoadSuccess(data, true));
  } catch (err) {
    yield put(actions.userDataUpdateError());
  }
}

function* resetSaga({ settingsScene }) {
  if (settingsScene === null) {
    yield put(actions.resetLoginSettings());
  }
}

function* statisticsFetchSaga({ page }) {
  try {
    const { data } = yield call(getStatisticsApi, page);

    const {
      entities: { statistics },
      result
    } = normalize(data, [statisticsSchema]);

    yield put(actions.profileStatisticsSuccess(statistics, result, page));
  } catch (err) {
    yield put(actions.profileStatisticsError());
  }
}

function* tariffsFetchSaga({ request }) {
  try {
    const { data } = yield call(getTariffsApi, request);
    yield put(actions.tariffsSuccess(data));
  } catch (err) {
    yield put(actions.tariffsError());
  }
}

function* goBackSaga() {
  const scene = yield select(getSettingsBackScene);
  yield put(actions.setProfileSettingsScene(scene));
  yield put(
    uiActions.replaceModal(
      PROFILE_CHANGE_SETTINGS_VIEW,
      PROFILE_CHANGE_SETTINGS_VIEW,
      {
        scene
      }
    )
  );
}

export default function* profileSaga() {
  yield all([
    takeLatest(types.PROFILE_USER_AVATAR_REQUEST, userAvatarFetchSaga),
    takeLatest(types.PROFILE_USER_DATA_UPDATE_REQUEST, userDataUpdateFetchSaga),
    takeLatest(types.PROFILE_PASSWORD_CHANGE_REQUEST, passwordChangeSaga),
    takeLatest(types.PROFILE_LOGIN_CHANGE_REQUEST, loginChangeSaga),
    takeLatest(types.PROFILE_SETTINGS_CODE_SEND, codeSendSaga),
    takeLatest(types.PROFILE_SETTINGS_CONFIRM_REQUEST, confirmChangeSaga),
    takeLatest(types.PROFILE_USER_DATA_LOAD_REQUEST, getUserData),
    takeLatest(types.PROFILE_SETTINGS_CHANGE_SCENE, resetSaga),
    takeLatest(types.PROFILE_STATISTICS_REQUEST, statisticsFetchSaga),
    takeLatest(types.PROFILE_TARIFFS_REQUEST, tariffsFetchSaga),
    takeLatest(types.PROFILE_SETTINGS_GO_BACK, goBackSaga),
    takeLatest(authTypes.LOGOUT, logoutSaga)
  ]);
}
