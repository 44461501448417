import { all, put, throttle, takeLatest, call } from "redux-saga/effects";
import { normalize } from "normalizr";
import api from "~/api/chatsApi";
import productApi from "~/api/productApi";
import Types from "~/store/chats/chatsTypes";
import Actions from "~/store/chats/chatsActions";
import wsTypes from "~/store/webSocket/webSocketTypes";
import uiActions from "~/store/ui/uiActions";
import messagesTypes from "~/store/messages/messagesTypes";
import commonActions from "~/store/commons/commonActions";
import toastActions from "~/store/toast/toastActions";
import { chatSchema } from "~/store/chats/chatsNormalizer";
import { END_EXPRESS_CHAT_VIEW } from "~/modals/ExpressChat/EndExpressChatModal";
import { ERROR_REQUEST_DEFAULT } from "~/consts/texts";

function* endExpressChatSaga({ request }) {
  try {
    const params = {
      service_patient_id: request.serviceId,
      conclusion: request.conclusion
    };
    yield call(productApi.endProduct, params);
    yield put(Actions.endExpressChatSuccess());
    yield put(uiActions.hideModal(END_EXPRESS_CHAT_VIEW));
  } catch (error) {
    yield put(Actions.endExpressChatFailure());
    yield put(uiActions.hideModal(END_EXPRESS_CHAT_VIEW));
  }
}

function* getChatInfoSaga({ request }) {
  try {
    const { data } = yield api.getChatApi(request);
    const {
      entities: { chats: items, ...rest }
    } = normalize(data, chatSchema);
    yield put(Actions.infoSuccess({ items }, request));
    yield put(commonActions.updateItems(rest));
  } catch (error) {
    yield put(Actions.infoFailure(error));
  }
}

function* updateChatSaga({ event }) {
  const { entities } = normalize(event, chatSchema);
  if (typeof entities === "object") {
    yield put(commonActions.updateItems(entities));
  }
}

function* addChatSaga({ event }) {
  const { id: chatId } = event;
  const { entities } = normalize(event, chatSchema);

  yield put(commonActions.updateItems(entities));
  yield put(Actions.upChat(chatId));
}

function* updateChatOrder({ payload }) {
  for (const messageId in payload) {
    if (payload.hasOwnProperty(messageId)) {
      const { chatId } = payload[messageId];
      if (chatId) {
        yield put(Actions.upChat(chatId));
      }
    }
  }
}

function* sendRequestSaga({ payload }) {
  const { page, limit, mode, value } = payload;
  try {
    const params = value
      ? { page, limit, query: value, filter: mode }
      : { page, limit, filter: mode };
    const { data } = yield call(api.chatsSearchApi, params);
    const { result: order, entities } = normalize(data, [chatSchema]);
    yield put(commonActions.updateItems(entities));
    yield put(Actions.requestSuccess({ page, limit, order, mode }));
  } catch (error) {
    yield put(Actions.requestFailed({ page, limit, mode, errors: error }));
    yield put(toastActions.showErrorToast(ERROR_REQUEST_DEFAULT));
  }
}

export default function* chatsSaga() {
  yield all([
    throttle(200, Types.REQUEST_SEND, sendRequestSaga),
    takeLatest(Types.END_EXPRESS_CHAT_REQUEST, endExpressChatSaga),
    takeLatest(Types.INFO_REQUEST, getChatInfoSaga),
    takeLatest(wsTypes.WS_CHAT_UPDATE, updateChatSaga),
    takeLatest(wsTypes.WS_CHAT_ADD, addChatSaga),
    takeLatest(messagesTypes.UPDATE_CHATS_MESSAGES, updateChatOrder)
  ]);
}
