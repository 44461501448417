import React from "react";
import { useSelector } from "react-redux";
import { getConsultationById } from "~/store/consultations/consultationSelectors";
import Consultation from "./Consultation";

const ConsultationContainer = props => {
  const consultation = useSelector(state => getConsultationById(state, props));
  return <Consultation {...props} {...consultation} />;
};

export default ConsultationContainer;
