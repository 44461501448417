import React, { Suspense } from "react";
import PropTypes from "prop-types";
import { Box, Dialog } from "ibolit-ui";
import styles from "./CallRoomModal.scss";
import Loading from "~/components/Loading/Loading";

const LazyCallRoom = React.lazy(() => import("../Room/Room"));

const CallRoomModal = props => (
  <Box testId="CallRoomModal" className={styles.callRoomModal}>
    <Suspense
      fallback={
        <Dialog boxClassName={styles.placeholder}>
          <Loading showLoading />
        </Dialog>
      }
    >
      <LazyCallRoom {...props} />
    </Suspense>
  </Box>
);

CallRoomModal.propTypes = {
  consultationId: PropTypes.number,
  preventClick: PropTypes.bool,
  withVideo: PropTypes.bool
};

export const CALL_ROOM_VIEW = "callRoom";
export default CallRoomModal;
