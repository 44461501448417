import React from "react";
import { Box, Button, Text } from "ibolit-ui";
import ErrorSvg from "~/assets/images/Error.svg";
import styles from "./Statuses.scss";
import { useReplaceModal } from "~/hooks/useModal";
import { NEW_CONSULTATION_VIEW } from "~/modals/NewConsultation/containers/NewConsultationModal";

export const NEW_CONSULTATION_ERROR_MODAL = "newConsultationErrorModal";

export default function StatusOccupied() {
  const handleClose = useReplaceModal(
    NEW_CONSULTATION_ERROR_MODAL,
    NEW_CONSULTATION_VIEW
  );

  return (
    <Box className={styles.status_box}>
      <svg viewBox={ErrorSvg.viewBox} className={styles.status_img}>
        <use xlinkHref={`#${ErrorSvg.id}`} />
      </svg>
      <Text
        tag="h3"
        variant="h3"
        maxLength={60}
        className={styles.status_header}
      >
        {"Хьюстон, у нас проблемы"}
      </Text>
      <Text
        tag="div"
        variant="desc"
        maxLength={60}
        className={styles.status_desc}
      >
        {"Повторите попытку позже"}
      </Text>
      <Button fullWidth colorVariant="doctor" onClick={handleClose}>
        Попробовать ещё раз
      </Button>
    </Box>
  );
}
