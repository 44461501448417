// For now, Google Analytics only.
import { useEffect } from "react";
// Import outside src sucks I guess.
import { GTAG_ID } from "../../config/const";

const setUserId = userId => {
  window.gtag?.("config", GTAG_ID, {
    user_id: userId
  });
};

const trackEvent = (eventName, eventProperties) => {
  // TODO: figure out non_interaction: boolean field in payload.
  window.gtag?.("event", eventName, eventProperties);
};

const useScreenAnalytics = (eventName, eventProperties) => {
  useEffect(() => {
    trackEvent(eventName, eventProperties);
  }, []);
};

export default {
  setUserId,
  trackEvent,
  useScreenAnalytics
};
