import React from "react";
import cn from "classnames";
import { Badge, Text } from "ibolit-ui";
import { END_EXPRESS_CHAT_VIEW } from "~/modals/ExpressChat/EndExpressChatModal";
import styles from "./ExpressChat.scss";
import { useShowModal } from "~/hooks/useModal";

const ExpressChatShape = ({ chatId, name, serviceId }) => {
  const showModal = useShowModal(END_EXPRESS_CHAT_VIEW, {
    chatId,
    serviceId
  });

  return (
    <div className={cn(styles.shapeContainer)}>
      <Badge variant="primary" className={styles.badge}>
        {name}
      </Badge>
      <Text
        variant="h4"
        colorVariant="pressing"
        onClick={showModal}
        className={styles.button}
        testid="express-chat-shape-end"
      >
        Завершить
      </Text>
    </div>
  );
};

export default ExpressChatShape;
