import { CONSULTATION_STATUS } from "~/consts/consultations";

export const returnButtonsData = ({
  status,
  isInProgress,
  hasConclusion,
  hasProblem,
  hConfirmConsultation,
  hCancelConsultation,
  hShowTab,
  hEnterConsultation,
  hMakeConclusion,
  hEndConsultation,
  isTariffTypeOffline,
  context
}) => {
  switch (true) {
    case status === CONSULTATION_STATUS.WAIT_PAYMENT:
      return [];
    case status === CONSULTATION_STATUS.PENDING:
      if (context === "doctor") {
        return [
          {
            label: "Подтвердить",
            type: "submit",
            onClick: hConfirmConsultation
          },
          {
            label: "Отменить консультацию",
            type: "cancel",
            onClick: hCancelConsultation,
            color: "gray"
          }
        ];
      }
      return [];
    case status === CONSULTATION_STATUS.APPROVED &&
      !isInProgress &&
      !hasProblem:
      return [];
    case status === CONSULTATION_STATUS.APPROVED &&
      (!isInProgress || isTariffTypeOffline) &&
      hasProblem:
      return [
        {
          label: "Посмотреть",
          onClick: hShowTab
        }
      ];
    case status === CONSULTATION_STATUS.APPROVED && !isTariffTypeOffline:
      return [
        {
          label: "Войти на прием",
          type: isInProgress ? "default" : "disabled",
          onClick: hEnterConsultation
        },
        {
          label: "Завершить консультацию",
          onClick: hEndConsultation,
          type: isInProgress ? "default" : "disabled",
          color: "gray"
        }
      ];
    case !hasConclusion && status === CONSULTATION_STATUS.COMPLETED:
      return [
        {
          label: "Написать заключение",
          onClick: hMakeConclusion
        }
      ];
    default:
      return [];
  }
};

export const returnConsultationStatusText = ({
  status,
  isStarted,
  hasConclusion,
  hasProblem,
  isTariffTypeOffline,
  context
}) => {
  const statusIsCanceled =
    status === CONSULTATION_STATUS.CANCELED ||
    status === CONSULTATION_STATUS.EXPIRED ||
    status === CONSULTATION_STATUS.NOT_APPEARED;

  switch (true) {
    case statusIsCanceled:
      return {
        label: "Консультация не состоялась",
        text:
          "Консультация не состоялась. Возможно позже пациент повторно запишется на новый слот доступный в вашем расписании."
      };
    case status === CONSULTATION_STATUS.PENDING:
      const text =
        context !== "doctor"
          ? "Ожидайте подтверждения администратором"
          : "У вас новая запись на консультацию. Вы можете самостоятельно подтвердить ее или отменить, указав причину отмены для администратора.";
      return {
        label: "Консультация ожидает подтверждения",
        text
      };
    case status === CONSULTATION_STATUS.WAIT_PAYMENT:
      return {
        label: "Ожидаем оплаты от пациента",
        text:
          "После оплаты консультации пациент оставит жалобу и возможно загрузит файлы, с которыми можно будет ознакомиться в разделе “Анамнез” и “Файлы”"
      };
    case status !== CONSULTATION_STATUS.COMPLETED &&
      (!isStarted || isTariffTypeOffline):
      if (!hasProblem) {
        return {
          label: "Ожидаем данные по жалобе пациента",
          text:
            "После подтверждения консультации пациент оставит жалобу и возможно загрузит файлы, с которыми можно будет ознакомиться в разделе “Анамнез” и “Файлы”"
        };
      }
      return {
        label: isTariffTypeOffline
          ? "Пациент заполнил анамнез"
          : "Пациент оплатил консультацию",
        text:
          "Вы можете ознакомиться с файлами и вводной информацией от пациента."
      };
    case isStarted && !isTariffTypeOffline:
      return {
        label: "Подошло время консультации",
        text:
          "Проверьте качество связи, войдите на видео прием и дождитесь собеседника, он скоро подключится. Вы можете написать заключение после завершения консультации."
      };
    case status === CONSULTATION_STATUS.COMPLETED && !hasConclusion:
      return {
        label: "Консультация состоялась",
        text: `Обязательно оставьте заключение к консультации, это обязательный шаг.`
      };
    case status === CONSULTATION_STATUS.COMPLETED && hasConclusion:
      return {
        label: "Консультация завершена",
        text: "Вы оставили заключение. Услуга выполнена в полном объеме."
      };
    default:
      return {
        label: "Консультация ожидает подтверждения",
        text:
          "У вас новая запись на консультацию. Вы можете самостоятельно подтвердить ее или отменить, указав причину отмены для администратора."
      };
  }
};
