import Types from "./uiTypes";

export default {
  showModal: (view, props) => ({
    type: Types.SHOW_MODAL,
    payload: {
      view,
      props
    }
  }),
  hideModal: view => ({
    type: Types.HIDE_MODAL,
    payload: {
      view
    }
  }),
  closeAllModal: () => ({
    type: Types.CLOSE_ALL_MODAL
  }),
  replaceModal: (viewOld, viewNew, props) => ({
    type: Types.REPLACE_MODAL,
    payload: {
      viewOld,
      viewNew,
      props
    }
  })
};
