import { connect } from "react-redux";
import { withFormik } from "formik";
import actions from "~/store/profile/profileAction";
import {
  getIsLoadingChangeLogin,
  getSettingsLoginPassword,
  getSettingsLogin
} from "~/store/profile/profileSelectors";
import ChangeCredentialForm from "../components/ChangeCredentialForm";
import { checkValidEmail } from "~/utils/validations";
import { formScenes } from "../types";
import {
  MAX_PASSWORD_LENGTH,
  MIN_PASSWORD_LENGTH_LEGACY
} from "~/consts/values";

const validate = values => {
  const result = {};

  if (!values.login) {
    result.login = "Это поле обязательно для заполнения";
  } else if (!checkValidEmail(values.login)) {
    result.login = "Введен невалидный email";
  }

  if (!values.password) {
    result.password = "Это поле обязательно для заполнения";
  } else if (values.password.length < MIN_PASSWORD_LENGTH_LEGACY) {
    result.password = `Пароль не должен быть менее ${MIN_PASSWORD_LENGTH_LEGACY} символов`;
  }

  return result;
};

const mapPropsToValues = ({ login = "", password = "" }) => ({
  login,
  password
});

const handleSubmit = (values, bag) => {
  bag.props.onSubmit({
    values: { login: values.login, password: values.password },
    scene: formScenes.email,
    setErrors: bag.setErrors
  });
};

const FormikEmailForm = withFormik({
  mapPropsToValues,
  validate,
  handleSubmit
})(ChangeCredentialForm);

const defaultProps = {
  title: "Изменение электронной почты",
  name: "login",
  firstInput: {
    label: "Введите новую почту",
    type: "text",
    name: "login"
  },
  secondInput: {
    label: "Введите текущий пароль",
    type: "password",
    name: "password",
    maxLength: MAX_PASSWORD_LENGTH
  }
};

const ChangeEmailFormContainer = connect(
  state => ({
    loading: getIsLoadingChangeLogin(state),
    login: getSettingsLogin(state),
    password: getSettingsLoginPassword(state),
    title: defaultProps.title,
    name: defaultProps.name,
    firstInput: defaultProps.firstInput,
    secondInput: defaultProps.secondInput,
    submitTestId: 'ChangeEmailFormContainer__submit'
  }),
  {
    onSubmit: actions.changeLoginRequest
  }
)(FormikEmailForm);

export default ChangeEmailFormContainer;
