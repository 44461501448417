import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import styles from "./SlotsList.scss";
import SlotItem from "~/components/Slots/SlotItem";
import { SLOTS } from "~/locators/consultations";

const SlotsList = ({ slots, onItemClick, selected }) => {
  const selectedId = selected ? selected.id : null;
  return (
    <ul className={cn(styles.list)} data-testid={SLOTS.CONTAINER}>
      {slots.map(slot => (
        <li key={slot.start_at} className={cn(styles.item)}>
          <SlotItem
            testid={SLOTS.SLOT}
            onClick={() => onItemClick(slot)}
            text={slot.start_at}
            active={slot.id === selectedId}
          />
        </li>
      ))}
    </ul>
  );
};

SlotsList.propTypes = {
  slots: PropTypes.array.isRequired,
  onItemClick: PropTypes.func.isRequired,
  selected: PropTypes.object
};

export default SlotsList;
