import cn from "classnames";
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Icon, Text } from "ibolit-ui";
import { getTzDate } from "~/utils/dateUtils";
import styles from "./Row.scss";

const Row = ({
  testId,
  children,
  position,
  isSending,
  isSendFailure,
  created_at
}) => {
  const time = useMemo(
    () => created_at && getTzDate(created_at).format("HH:mm"),
    [created_at]
  );
  const isOwnMsg = position === "right";
  return (
    <div data-testid={testId} className={cn(styles.row, styles[position])}>
      <div
        className={cn(styles.rowWrapper, {
          [styles.rowRight]: position === "right",
          [styles.rowLeft]: position === "left"
        })}
      >
        {children}
        {isSendFailure && (
          <Icon
            className={styles.iconFailure}
            variant="info"
            fill="var(--pink)"
          />
        )}
      </div>
      {isSendFailure && (
        <Text
          className={styles.rowFailure}
          variant="caption"
          colorVariant="error"
        >
          Не отправлено
        </Text>
      )}
      {!isSendFailure && (
        <div className={cn(styles.rowFooter, styles[position])}>
          <Text
            className={cn(styles.time, { [styles.order]: position === "left" })}
            variant="caption"
            colorVariant="tertiary"
          >
            {isSending ? "отправляется..." : time}
          </Text>
          {!isSending && isOwnMsg && <Icon variant="checks-mark" size="s" />}
        </div>
      )}
    </div>
  );
};

Row.defaultProps = {
  position: "left",
  isSendFailure: false
};

Row.propTypes = {
  position: PropTypes.oneOf(["left", "center", "right"]),
  isSendFailure: PropTypes.bool,
  created_at: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default Row;
