import axios from "./httpService";

export default {
  conclusionsSearch: ({ userId, ...params }) =>
    axios.get(`/v3/conclusions/${userId}`, { params }),
  sendConclusion: ({
    consultationId,
    isNew,
    text,
    problemTitle,
    problemStatus,
    recommendedConsultationDays
  }) =>
    axios[isNew ? "post" : "put"](
      `/v3/doctor/consultation/${consultationId}/conclusion`,
      {
        text: text || null,
        problem_title: problemTitle, // required
        problem_status: problemStatus, // required
        recommended_consultation_days: recommendedConsultationDays // nullable
      }
    ),
  getConclusion: id => axios.get(`/v3/user/conclusion/${id}`),
  getConclusionPDF: id =>
    axios.get(`/v3/user/conclusion/${id}/pdf`, {
      responseType: "blob"
    })
};
