import React, { useRef } from "react";
import { Button, CheckInput } from "ibolit-ui";
import Field from "~/components/Field/Field";
import RadioGroup from "~/components/inputs/RadioGroup";
import { FILTERS } from "~/locators/contacts";
import { INITIAL_FILTER, SORTING_VARIANTS } from "~/store/users/const";
import styles from "./Filter.scss";

const FilterForm = ({ filter, setFilter }) => {
  const sortingRef = useRef(null);
  const inactiveRef = useRef(null);

  const onResetClick = () => {
    setFilter(INITIAL_FILTER);
  };

  const onSubmitClick = () => {
    setFilter({
      order: sortingRef.current.value,
      only_active: inactiveRef.current.checked
    });
  };

  return (
    <>
      <Field
        Component={RadioGroup}
        options={SORTING_VARIANTS}
        initialValue={filter.order}
        ref={sortingRef}
        label="Сортировка"
        inputClassName={styles.radioGroup}
        noSpace
      />
      <Field
        Component={CheckInput}
        testidProps={{
          input: FILTERS.HIDE_INACTIVE
        }}
        text="Скрыть неактивированных пользователей"
        ref={inactiveRef}
        checked={filter.only_active}
        noSpace
        className={styles.lastField}
      />
      <Button
        className={styles.controlButton}
        testid={FILTERS.RESET}
        variant="secondary"
        onClick={onResetClick}
      >
        Сбросить
      </Button>
      <Button
        className={styles.controlButton}
        testid={FILTERS.SAVE}
        onClick={onSubmitClick}
      >
        Сохранить
      </Button>
    </>
  );
};
export default FilterForm;
