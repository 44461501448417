import React, { forwardRef } from "react";
import Loading from "~/components/Loading/Loading";
import EmptyStub from "~/components/EmptyStub";
import UserItem from "~/components/UserItem/UserItem";
import { CONTACTS_LIST } from "~/locators/contacts";
import InfiniteList from "~/components/InfiniteList";

const ContactsList = (
  {
    items,
    selected,
    isFetching,
    initialFetched,
    canLoadMore,
    hasErrors,
    isEmpty,
    incrementPage,
    onItemClick,
    className
  },
  ref
) => {
  const loadingProps = isEmpty ? {} : { variant: "row", height: 80 };

  return (
    <InfiniteList
      ref={ref}
      canLoadMore={canLoadMore}
      loadMore={incrementPage}
      className={className}
    >
      {items.map(item => (
        <UserItem
          key={item.id}
          user={item}
          testid={CONTACTS_LIST.USER_ITEM}
          isSelected={!!selected && selected.id === item.id}
          onClick={onItemClick}
        />
      ))}
      {isFetching && <Loading {...loadingProps} />}
      {!initialFetched && (
        <EmptyStub text={"Загружаем список\nпациентов"} centered />
      )}
      {hasErrors && <EmptyStub text={"Ошибка загрузки"} centered />}
      {isEmpty && <EmptyStub centered text={"Пациенты не найдены"} />}
    </InfiniteList>
  );
};

export default forwardRef(ContactsList);
