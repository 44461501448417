import { generateAction } from "~/utils/redux/generateAction";
import {
  orders,
  GET_CONSULTATION_RATING,
  DELETE_CONSULTATION_RATING,
  REPLY_CONSULTATION_RATING,
  DELETE_REPLY,
  PATCH_CONSULTATION_RATING,
  CONSULTATION_RATING_FAIL,
  RESET_CONSULTATION_RATING
} from "./types";

export const getConsultationRatingCallAction = ({
  page = 1,
  limit = 20,
  order_by = orders.id_desc
}) =>
  generateAction(GET_CONSULTATION_RATING.CALL, {
    page,
    limit,
    order_by
  });

export const getConsultationRatingSuccessAction = data =>
  generateAction(GET_CONSULTATION_RATING.SUCCESS, { consultationRating: data });

export const deleteConsultationRatingCallAction = id =>
  generateAction(DELETE_CONSULTATION_RATING.CALL, { id });

export const deleteConsultationRatingSuccessAction = id =>
  generateAction(DELETE_CONSULTATION_RATING.SUCCESS, { id });

export const replyConsultationRatingCallAction = (id, data) =>
  generateAction(REPLY_CONSULTATION_RATING.CALL, { id, data });

export const replyConsultationRatingSuccessAction = data =>
  generateAction(REPLY_CONSULTATION_RATING.SUCCESS, {
    consultationRating: data
  });

export const deleteReplyConsultationRatingCallAction = id =>
  generateAction(DELETE_REPLY.CALL, { id });

export const deleteReplyConsultationRatingSuccessAction = () =>
  generateAction(DELETE_REPLY.SUCCESS);

export const patchReplyConsultationData = data =>
  generateAction(PATCH_CONSULTATION_RATING.CALL, data);

export const consultationRatingFailAction = error =>
  generateAction(CONSULTATION_RATING_FAIL, { error });

export const resetConsultationRatingAction = () =>
  generateAction(RESET_CONSULTATION_RATING.CALL);
