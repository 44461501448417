import cn from "classnames";
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Spinner, Avatar, Icon } from "ibolit-ui";
import splitUserName from "~/utils/splitUserName";
import styles from "./UploadAvatar.scss";

const UploadAvatar = ({
  userId,
  full_name,
  className,
  srcAvatar,
  onClick,
  loading,
  error,
  dataTestid
}) => {
  const [firstName, lastName] = splitUserName(full_name);

  useEffect(() => {
    if (error) {
      // eslint-disable-next-line no-alert
      alert(error);
    }
  }, [error]);

  return (
    <div
      onClick={!loading ? onClick : undefined}
      className={cn(styles.uploadAvatar, className, {
        [styles.cursorActive]: onClick,
        [styles.loading]: loading
      })}
      dataTestid={dataTestid}
    >
      <Avatar
        size="large"
        id={userId}
        firstName={firstName}
        lastName={lastName}
        srcImg={srcAvatar}
      />
      {loading && (
        <Spinner fill="var(--pink)" className={styles.spinner} scale={0.5} />
      )}
      <div className={styles.avatarIcon}>
        <Icon variant="photo" fill="var(--pink)" />
      </div>
    </div>
  );
};

UploadAvatar.defaultProps = {
  loading: false
};

UploadAvatar.propTypes = {
  userId: PropTypes.number.isRequired,
  full_name: PropTypes.string,
  srcAvatar: PropTypes.string,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  error: PropTypes.string
};

export default UploadAvatar;
