import React from "react";
import TextInput from "~/components/inputs/TextInput";

const snilsValidator = value => {
  value = value.replaceAll("-", "");

  let result = true;
  let error = { code: "", message: "" };
  if (value.length === 0) {
    return true;
  } else if (/[^0-9]/.test(value)) {
    result = false;
    error.code = 2;
    error.message = "СНИЛС может состоять только из цифр";
  } else if (value.length !== 11) {
    result = false;
    error.code = 3;
    error.message = "СНИЛС может состоять только из 11 цифр";
  } else {
    /* let sum = 0;
    for (let i = 0; i < 9; i++) {
      sum += parseInt(value[i]) * (9 - i);
    }
    let checkDigit = 0;
    if (sum < 100) {
      checkDigit = sum;
    } else if (sum > 101) {
      checkDigit = parseInt(sum % 101);
      if (checkDigit === 100) {
        checkDigit = 0;
      }
    }
    if (checkDigit !== parseInt(value.slice(-2))) {
      result = false;
      error.code = 4;
      error.message = "Неправильное контрольное число";
    } */
  }
  return result || [false, error.message];
};

const SNILSInput = ({ ...props }, inputRef) => {
  return (
    <TextInput
      ref={inputRef}
      required={false}
      validator={snilsValidator}
      mask={"999-999-999-99"}
      {...props}
    />
  );
};

export default React.forwardRef(SNILSInput);
