import React from "react";
import { Dialog } from "ibolit-ui";
import FilterForm from "./FilterForm";
import { useCloseModal } from "~/hooks/useModal";
import { FILTERS } from "~/locators/contacts";

export const FILTER_VIEW = "filter";

const Filter = ({ filter, setFilter }) => {
  const closeModal = useCloseModal(FILTER_VIEW);

  const handleFilter = params => {
    setFilter(params);
    closeModal();
  };

  return (
    <Dialog
      testId="FilterUserModal"
      headerTestId={FILTERS.HEADER}
      closeTestId={FILTERS.ICON_CLOSE}
      header="Параметры"
      onCloseClick={closeModal}
    >
      <FilterForm filter={filter} setFilter={handleFilter} />
    </Dialog>
  );
};

export default Filter;
