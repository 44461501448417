import React, { useCallback } from "react";
import cn from "classnames";
import { Button, Text } from "ibolit-ui";
import { useDispatch } from "react-redux";
import styles from "./Confirm.scss";
import { useCloseModal } from "~/hooks/useModal";
import actions from "~/store/consultations/consultationsActions";
import Analytics from "~/utils/analytics";
import { Dialog } from "ibolit-ui";

export const CONFIRM_VIEW = "confirm";

const Confirm = ({ consultationId, service_patient_id }) => {
  const closeModal = useCloseModal(CONFIRM_VIEW);
  const dispatch = useDispatch();
  const confirm = useCallback(() => {
    Analytics.trackEvent("Consultation Finished", {
      consultationId,
    });
    dispatch(actions.endConsultation(service_patient_id, consultationId));
    closeModal();
  }, []);

  return (
    <Dialog
      boxClassName={styles.box}
      headerClassName={styles.header}
      bodyClassName={styles.body}
      header={"Завершить консультацию"}
      onCloseClick={null}
      testId="CancelConsultationModal"
    >
      <Text
        variant="body1"
        colorVariant={"tertiary"}
        className={styles.desc}
        testid="CancelConsultationModal__desc"
      >
        Вы действительно хотите завершить ?
      </Text>
      <div className={styles.controls}>
        <Button
          fixedWidth={false}
          className={cn(styles.button, styles.agreeButton)}
          variant="secondary"
          onClick={confirm}
          testid="CancelConsultationModal_yes"
        >
          Да
        </Button>
        <Button
          fixedWidth={false}
          className={styles.button}
          variant="secondary"
          onClick={closeModal}
          testid="CancelConsultationModal_no"
        >
          Нет
        </Button>
      </div>
    </Dialog>
  );
};

export default Confirm;
