import React from "react";
import SearchBox from "~/components/SearchBox";

const MIN_SEARCH_LENGTH = 4;

const SearchBlock = ({ className, placeholder, onSearchChange, testId }) => {
  const handleSearch = searchStr => {
    onSearchChange(searchStr);
  };

  const handleChange = value => {
    if (value === "") {
      onSearchChange("");
    }
  };

  const handleClear = () => {
    onSearchChange("");
  };

  return (
    <SearchBox
      testid={testId}
      placeholder={placeholder}
      onClear={handleClear}
      onChange={handleChange}
      className={className}
      handleSearch={handleSearch}
      minLength={MIN_SEARCH_LENGTH}
      variant="full"
    />
  );
};

export default SearchBlock;
