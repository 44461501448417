import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";
import { Button, Input, Select, Text } from "ibolit-ui";
import Field from "~/components/Field";
import TextareaInput from "~/components/inputs/TextareaInput/TextareaInput";
import styles from "./ConclusionForm.scss";
import { pluralizeDay } from "~/utils/pluralize";
import {
  DOCTOR_PROBLEM_STATUSES,
  ProblemStatus
} from "~/modals/Conclusion/ConclusionForm/ProblemStatus/ProblemStatus";
import Analytics from "~/utils/analytics";

export const recommendedDates = [
  { value: null, label: "Не требуется" },
  { value: "7", label: "Через 1 неделю" },
  { value: "14", label: "Через 2 недели" },
  { value: "30", label: "Через 1 месяц" },
  { value: "90", label: "Через 3 месяца" },
  { value: "180", label: "Через 6 месяцев" },
  { value: "365", label: "Через год" }
];

export const recommendedDatesInDays = [
  { value: null, label: "Не требуется" },
  ..._.range(1, 366).map(n => ({
    value: String(n),
    label: `Через ${pluralizeDay(n)}`
  }))
];

export function ConclusionForm({
  requestSubmit,
  problemTitle,
  setProblemTitle,
  conclusionText,
  setConclusion,
  problemStatus,
  setProblemStatus,
  nextConsultation,
  setNextConsultation,
  isFormChanged,
  isNewConclusion,
  consultationId,
  problemId
}) {
  const conclusionRef = React.useRef(null);
  const handleSubmit = () => {
    conclusionRef.current.blur();
    requestSubmit(conclusionRef.current.value.trim());
  };

  const handleRecommendedConsultationChange = item => {
    Analytics.trackEvent("Entered Days", {
      consultationId,
      problemId,
      days: item.value
    });
    setNextConsultation(item);
  };

  const isFormValid = React.useMemo(() => {
    const isProblem = DOCTOR_PROBLEM_STATUSES.some(
      s => s.value === problemStatus
    );

    return problemTitle.length >= 5 && isProblem;
  }, [problemTitle, conclusionText, problemStatus]);

  return (
    <div className={styles.row}>
      <div className={styles.column}>
        <Text variant="h4" className={styles.label} testid="ConclusionForm__complaint-label">
          Жалоба
        </Text>
        <Field
          className={styles.field}
          value={problemTitle}
          onChange={value => setProblemTitle(value)}
          Component={Input}
          fullWidth
          placeholder={"Болит голова"}
          required
          autoFocus
          noSpace
          testidProps={{
            input: `conclusion__input-problem`
          }}
        />
        <Text variant="h4" className={styles.label} testid="ConclusionForm__conclusion-label">
          Заключение
        </Text>
        <Field
          inputClassName={styles.textarea}
          initialValue={conclusionText}
          onChange={e => setConclusion(e.target.value)}
          Component={TextareaInput}
          fullWidth
          placeholder={"Напишите заключение для пациента"}
          ref={conclusionRef}
          noSpace
          rows={20}
          maxRows={20}
          resize="none"
          testidProps={{
            textarea: `conclusion__textarea-conclusion`
          }}
        />
      </div>

      <div className={styles.column}>
        <ProblemStatus
          problemId={problemId}
          consultationId={consultationId}
          problemStatus={problemStatus}
          setProblemStatus={setProblemStatus}
        />

        <Text variant="h4" className={styles.label} testid="ConclusionForm__next-appintment-label">
          Рекомендовать следующий прием через
        </Text>
        <Select
          className={styles.select}
          defaultValue={nextConsultation.value}
          disabled={!isNewConclusion}
          inputClassName={styles.inputClassName}
          items={isNewConclusion ? recommendedDates : recommendedDatesInDays}
          testId={"conclusion__select-next-consultation-date"}
          onChange={handleRecommendedConsultationChange}
        />

        <Button
          className={styles.button}
          testid={`conclusion__button-submit`}
          disabled={!(isFormChanged && isFormValid)}
          onClick={handleSubmit}
        >
          Отправить
        </Button>
      </div>
    </div>
  );
}

ConclusionForm.propTypes = {
  problemId: PropTypes.number,
  consultationId: PropTypes.number,
  conclusionText: PropTypes.string,
  isFormChanged: PropTypes.bool,
  isNewConclusion: PropTypes.bool,
  nextConsultation: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  }),
  problemStatus: PropTypes.string,
  problemTitle: PropTypes.string,
  requestSubmit: PropTypes.func.isRequired,
  setConclusion: PropTypes.func,
  setNextConsultation: PropTypes.func,
  setProblemStatus: PropTypes.func,
  setProblemTitle: PropTypes.func
};
