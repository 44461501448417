import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { getConsultationInProgress } from "~/store/consultations/consultationSelectors";
import { getConcusionPDF } from "~/store/conclusions/conclusionsActions";

import { CONSULTATION_STATUS } from "~/consts/consultations";

import {
  ConsultationInfo,
  NoConclusionPlacehoder,
  ConsultationConclusion
} from "ibolit-components";

import styles from "../ConsultationPage.scss";

export const ConsultationConslusionPage = ({
  consultationData,
  consultationStatusInfo,
  isTariffTypeOffline
}) => {
  const dispatch = useDispatch();
  const isInProgress = useSelector(state =>
    getConsultationInProgress(state, consultationData)
  );

  if (!consultationData.conclusion) {
    const withPadding =
      (consultationData.status === CONSULTATION_STATUS.COMPLETED &&
        !consultationData.conclusion) ||
      (consultationData.status === CONSULTATION_STATUS.APPROVED &&
        !isTariffTypeOffline &&
        isInProgress);

    return (
      <NoConclusionPlacehoder
        fill="#00B5CE"
        isDoctor
        withPadding={withPadding}
      />
    );
  }

  const handleDownloadPDF = () =>
    dispatch(getConcusionPDF(consultationData.conclusion.id));

  return (
    <div className={styles.conclusionContainer}>
      <ConsultationInfo {...consultationStatusInfo} iconFill={"#00B5CE"} />

      <ConsultationConclusion
        consultationData={consultationData}
        onDownloadPDF={handleDownloadPDF}
        color="var(--blue)"
        isDoctor
      />
    </div>
  );
};
