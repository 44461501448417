import { connect } from "react-redux";
import { withFormik } from "formik";
import actions from "~/store/profile/profileAction";
import {
  getIsLoadingChangeLogin,
  getSettingsLogin,
  getSettingsLoginPassword
} from "~/store/profile/profileSelectors";
import ChangeCredentialForm from "../components/ChangeCredentialForm";
import { checkValidPhone } from "~/utils/validations";
import formatPhone, { clearPhoneFormat } from "~/utils/formatPhone";
import { formScenes } from "../types";
import {
  MAX_PASSWORD_LENGTH,
  MIN_PASSWORD_LENGTH_LEGACY
} from "~/consts/values";

const validate = values => {
  const result = {};

  if (!values.login) {
    result.login = "Это поле обязательно для заполнения";
  } else if (!checkValidPhone(clearPhoneFormat(values.login))) {
    result.login = "Введен невалидный телефон";
  }

  if (!values.password) {
    result.password = "Это поле обязательно для заполнения";
  } else if (values.password.length < MIN_PASSWORD_LENGTH_LEGACY) {
    result.password = `Пароль не должен быть менее ${MIN_PASSWORD_LENGTH_LEGACY} символов`;
  }

  return result;
};

const mapPropsToValues = ({ login = "", password = "" }) => ({
  login,
  password
});

const handleSubmit = (values, bag) => {
  bag.props.onSubmit({
    values: { ...values, login: clearPhoneFormat(values.login) },
    scene: formScenes.phone,
    setErrors: bag.setErrors
  });
};

const FormikPhoneForm = withFormik({
  mapPropsToValues,
  validate,
  handleSubmit
})(ChangeCredentialForm);

const defaultProps = {
  title: "Изменение номера телефона",
  name: "login",
  firstInput: {
    label: "Введите новый номер телефона",
    type: "text",
    name: "login"
  },
  secondInput: {
    label: "Введите текущий пароль",
    type: "password",
    name: "password",
    maxLength: MAX_PASSWORD_LENGTH
  },
  mask: value => formatPhone(value),
  submitTestId: "ChangePhoneFormContainer__submit_button"
};

const ChangePhoneFormContainer = connect(
  state => ({
    loading: getIsLoadingChangeLogin(state),
    login: getSettingsLogin(state),
    password: getSettingsLoginPassword(state),
    ...defaultProps,
  }),
  {
    onSubmit: actions.changeLoginRequest
  }
)(FormikPhoneForm);

export default ChangePhoneFormContainer;
