export default {
  SEARCH_REQUEST: "conclusions/SEARCH_REQUEST",
  SEARCH_SUCCESS: "conclusions/SEARCH_SUCCESS",
  SEARCH_FAILURE: "conclusions/SEARCH_FAILURE",

  SEND_CONCLUSION_REQUEST: "conclusions/SEND_CONCLUSION_REQUEST",
  SEND_CONCLUSION_SUCCESS: "conclusions/SEND_CONCLUSION_SUCCESS",
  SEND_CONCLUSION_FAILURE: "conclusions/SEND_CONCLUSION_FAILURE",

  GET_CONCLUSION: "conclusions/GET_CONCLUSION",
  GET_CONCLUSION_SUCCESS: "conclusions/GET_CONCLUSION_SUCCESS",
  GET_CONCLUSION_FAILURE: "conclusions/GET_CONCLUSION_FAILURE",

  GET_CONCLUSION_PDF: "conclusions/GET_CONCLUSION_PDF",
  GET_CONCLUSION_PDF_FAILURE: "conclusions/GET_CONCLUSION_PDF__FAILURE"
};
