import React from "react";
import { Text } from "ibolit-ui";
import Expandable from "~/components/Expandable";
import styles from "./PatientInfo.scss";
import { DATE_FORMAT_DMY, getTzDate } from "~/utils/dateUtils";

const ConclusionItem = ({ created_at, text }) => {
  return (
    <div className={styles.conclusionContainer}>
      <Text variant="h6">{`Заключение от ${getTzDate(created_at).format(
        DATE_FORMAT_DMY
      )}`}</Text>
      {text ? (
        <Expandable
          line={4}
          className={styles.content}
          collapsedText="Читать полностью"
          expandedText="свернуть"
          text={text}
          togglerClassName={styles.toggler}
        />
      ) : (
        <Text variant="desc" colorVariant="tertiary">
          Нет заключения
        </Text>
      )}
    </div>
  );
};

export default ConclusionItem;
