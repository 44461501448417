import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { Tabs } from "ibolit-ui";
import BlockHeader from "~/components/BlockHeader";
import ChatsList from "./ChatsList";
import { CHATS_LIST } from "~/locators/chats";
import styles from "./ChatAside.scss";
import {
  CHAT_MODE_ALL,
  CHAT_MODE_NO_ENDED,
  CHAT_MODE_NO_READ,
  chatRequestSelector
} from "~/store/chats/chatModel";
import chatsActions from "~/store/chats/chatsActions";
import { getCurrentUserId } from "~/store/auth/authSelectors";
import SearchBlock from "~/components/SearchBlock";
import { useFilteredPaginator } from "~/hooks/usePaginator";

const CHATS_SEARCH_ITEMS_LIMIT = 20;

const TABS_VALUES = {
  [CHAT_MODE_ALL]: "Все",
  [CHAT_MODE_NO_READ]: "Непрочитанные",
  [CHAT_MODE_NO_ENDED]: "Незавершенные"
};

const ChatAside = () => {
  const listRef = React.createRef();
  const dispatch = useDispatch();

  const {
    items,
    isFetching,
    initialFetched,
    canLoadMore,
    hasErrors,
    isEmpty,
    setSearch,
    mode,
    setMode,
    incrementPage
  } = useFilteredPaginator({
    initialFetched,
    selector: chatRequestSelector,
    initialMode: CHAT_MODE_ALL,
    ref: listRef,
    limit: CHATS_SEARCH_ITEMS_LIMIT,
    sendAction: chatsActions.requestSend
  });
  const currentUserId = useSelector(getCurrentUserId);

  const onTabChange = useCallback(newMode => {
    dispatch(chatsActions.changeMode(newMode));
    setMode(newMode);
  }, []);
  const [isTabsHide, toggleTabs] = useState(false);

  const onSearchChange = useCallback(value => {
    if (isTabsHide && value === "") {
      toggleTabs(false);
    }
    if (!isTabsHide && value && value.length > 0) {
      toggleTabs(true);
    }
    setSearch(value);
  });

  return (
    <div className={classNames(styles.container)}>
      <BlockHeader title="Сообщения" testid={CHATS_LIST.HEADER} />
      <div
        className={classNames(styles.block, { [styles.block.hidden]: isEmpty })}
      >
        <SearchBlock
          testId={CHATS_LIST.INPUT_SEARCH}
          placeholder="Поиск по чатам"
          onSearchChange={onSearchChange}
          className={styles.search}
        />
      </div>
      <Tabs
        values={TABS_VALUES}
        value={mode}
        hideTabs={isTabsHide}
        onChange={onTabChange}
        className={classNames(styles.block, { [styles.block.hidden]: isEmpty })}
      />
      <ChatsList
        ref={listRef}
        items={items}
        isFetching={isFetching}
        initialFetched={initialFetched}
        canLoadMore={canLoadMore}
        mode={mode}
        hasErrors={hasErrors}
        isEmpty={isEmpty}
        incrementPage={incrementPage}
        currentUserId={currentUserId}
      />
    </div>
  );
};

export default ChatAside;
