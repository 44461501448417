import React from "react";
import cn from "classnames";

import { CONSULTATION_STATUS } from "~/consts/consultations";
import { CONCLUSION_VIEW } from "~/modals/Conclusion/ConclusionModal";
import { useShowModal } from "~/hooks/useModal";
import { getConsultationInProgress } from "~/store/consultations/consultationSelectors";

import { ADDING_FILES } from "~/layouts/Consultations/containers/Consultation/components/FileManager/AddingFilesModal/AddingFilesModal";

import { useDispatch, useSelector } from "react-redux";

import { enumTabsNames } from "ibolit-components";

import { Icon } from "ibolit-ui";

import consultationsActions from "~/store/consultations/consultationsActions";
import callActions from "~/store/call/callActions";

import CheckHealthButton from "~/components/HealthCheck/CheckHealthButton";

import styles from "./ConsultationPage.scss";

const WriteConclusion = ({ onClick }) => (
  <button
    className={styles.downloadButton}
    onClick={onClick}
    data-testid="BottomButton-WriteConclusion"
  >
    Написать заключение
  </button>
);

const DownloadFilesButton = ({ onClick }) => (
  <button
    className={styles.button}
    onClick={(e) => {
      onClick(e);
    }}
    data-testid="BottomButton-DownloadFilesButton"
  >
    <Icon variant="plus-icon" className={styles.downloadButtonIcon} />
    <span className={styles.downloadButtonText}>Загрузить файлы</span>
  </button>
);

const StartCall = ({ onClick }) => (
  <button
    className={styles.button}
    onClick={onClick}
    data-testid="BottomButton-StartCall"
  >
    Войти на прием
  </button>
);

const AcceptConsultation = ({ onClick }) => (
  <button
    className={styles.completeButton}
    onClick={onClick}
    data-testid="BottomButton-AcceptConsultation"
  >
    Подтвердить консультацию
  </button>
);

const WaitForConsultation = () => (
  <button
    className={cn(styles.button, styles.opacityButton)}
    data-testid="BottomButton-WaitForConsultation"
  >
    Ожидайте начала консультации
  </button>
);

const WaitForPaid = () => (
  <button
    className={cn(styles.button, styles.opacityButton)}
    data-testid="BottomButton-WaitForPaid"
  >
    Консультация ожидает оплаты
  </button>
);

const EditConclusion = ({ onClick }) => (
  <button
    className={cn(styles.button, styles.editButton)}
    onClick={onClick}
    data-testid="BottomButton-EditConclusion"
  >
    <Icon variant="edit" className={styles.downloadButtonIcon} />
    <span>Редактировать заключение</span>
  </button>
);

const EndConsultation = ({ onClick }) => (
  <button
    className={cn(styles.button, styles.endConsultationButton)}
    onClick={onClick}
    data-testid="BottomButton-EndConsultation"
  >
    Завершить консультацию
  </button>
);

export const BottomButton = ({
  activeTab,
  consultationData,
  onEndConsultation,
  isTariffTypeOffline,
}) => {
  const dispatch = useDispatch();

  const consultationId = consultationData.id;

  const handleShowConclusionModal = useShowModal(CONCLUSION_VIEW, {
    consultationId,
    conclusion: consultationData.conclusion,
    problem: consultationData?.problem,
    testid: consultationId,
    recommendedConsultationDate:
      consultationData?.recommended_consultation?.date_time,
  });

  const isInProgress = useSelector((state) =>
    getConsultationInProgress(state, consultationData)
  );

  const handleUploadFile = useShowModal(ADDING_FILES, { consultationId });

  const handleStartCall = (consultationId) => () =>
    dispatch(callActions.callStart({ consultationId }));

  const handleSubmitConfirmation = () =>
    dispatch(
      consultationsActions.confirmConsultation({
        consultationId,
      })
    );

  let InnerComponent = () => null;

  const status = consultationData.status;
  const problemStatus = consultationData.problem?.status;

  switch (true) {
    case problemStatus === CONSULTATION_STATUS.COMPLETED &&
      problemStatus === CONSULTATION_STATUS.CANCELED:
      return () => null;
    case activeTab === enumTabsNames.FILES:
      InnerComponent = () => <DownloadFilesButton onClick={handleUploadFile} />;
      break;
    case status === CONSULTATION_STATUS.APPROVED &&
      activeTab !== enumTabsNames.INFO &&
      !isTariffTypeOffline:
      if (!isInProgress) {
        if (activeTab === enumTabsNames.CONCLUSION) {
          break;
        }
        InnerComponent = () => <WaitForConsultation />;
        break;
      }
      if (activeTab === enumTabsNames.CONCLUSION) {
        InnerComponent = () => <EndConsultation onClick={onEndConsultation} />;
        break;
      }
      InnerComponent = () => (
        <StartCall onClick={handleStartCall(consultationData.id)} />
      );
      break;
    case status === CONSULTATION_STATUS.PENDING &&
      activeTab !== enumTabsNames.INFO &&
      activeTab !== enumTabsNames.CONCLUSION:
      InnerComponent = () => (
        <AcceptConsultation onClick={handleSubmitConfirmation} />
      );
      break;
    case status === CONSULTATION_STATUS.WAIT_PAYMENT &&
      activeTab !== enumTabsNames.INFO:
      if (activeTab === enumTabsNames.CONCLUSION) {
        break;
      }
      InnerComponent = () => <WaitForPaid />;
      break;
    case status === CONSULTATION_STATUS.COMPLETED &&
      !consultationData.conclusion:
      if (activeTab === enumTabsNames.INFO) {
        break;
      }
      InnerComponent = () => (
        <WriteConclusion onClick={handleShowConclusionModal} />
      );
      break;
    case activeTab === enumTabsNames.CONCLUSION &&
      !!consultationData.conclusion:
      InnerComponent = () => (
        <EditConclusion onClick={handleShowConclusionModal} />
      );
      break;
    default:
      if (isTariffTypeOffline) {
        return () => null;
      }
      if (
        activeTab === enumTabsNames.ANAMNESIS ||
        activeTab === enumTabsNames.CONCLUSION
      ) {
        return () => null;
      }
      if (
        status === CONSULTATION_STATUS.COMPLETED ||
        status === CONSULTATION_STATUS.CANCELED ||
        status === CONSULTATION_STATUS.EXPIRED ||
        status === CONSULTATION_STATUS.NOT_APPEARED
      ) {
        return () => null;
      }
      InnerComponent = () => (
        <CheckHealthButton
          className={styles.checkHealthButton}
          modalProps={{ hasNoBackStep: true }}
          testid="BottomButton__checkHealthButton"
        />
      );
  }

  return (
    <div className={styles.bottomButtonContainer}>
      <InnerComponent />
    </div>
  );
};
