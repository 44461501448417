import React, { forwardRef, useRef } from "react";
import noop from "lodash/noop";
import { useRouteMatch } from "react-router-dom";
import Link from "~/components/Link";
import Loading from "~/components/Loading/Loading";
import EmptyStub from "~/components/EmptyStub";
import UserItem from "~/components/UserItem/UserItem";
import { CONTACTS_LIST } from "~/locators/contacts";
import InfiniteList from "~/components/InfiniteList";
import styles from "./ContactList.scss";
import { getDayOrDateText } from "~/utils/dateUtils";
import DateItem from "./DateItem";
import { FILTER_BY_DATE } from "~/store/users/const";
import { DATA_LOAD_ERROR } from "~/consts/texts";

const ContactsList = (
  {
    items,
    isFetching,
    initialFetched,
    canLoadMore,
    filter,
    hasErrors,
    isEmpty,
    incrementPage
  },
  ref
) => {
  const dateComparator = useRef(null);
  const match = useRouteMatch();

  const loadingProps = isEmpty ? {} : { variant: "row", height: 80 };
  const showDate = filter.order === FILTER_BY_DATE;

  return (
    <InfiniteList
      ref={ref}
      className={styles.list}
      canLoadMore={canLoadMore}
      loadMore={incrementPage}
    >
      {items.map((item, index) => {
        let shouldRenderDate = false;
        const textAddedDate = getDayOrDateText(item.added_at);
        if (dateComparator.current !== textAddedDate) {
          shouldRenderDate = true;
          dateComparator.current = textAddedDate;
        }
        const isLast = index === items.length - 1;
        if (isLast) {
          dateComparator.current = null;
        }
        return (
          <React.Fragment key={item.id}>
            {showDate && shouldRenderDate && (
              <DateItem className={styles.date}>{textAddedDate}</DateItem>
            )}
            <Link
              key={item.id}
              to={`${match.url}/${item.id}`}
              className={styles.link}
              activeClassName={styles.selected}
            >
              <UserItem
                user={item}
                testid={CONTACTS_LIST.USER_ITEM}
                isSelected={match.params.chatId === item.id}
                contentClass={isLast ? "" : styles.bordered}
                onClick={noop}
              />
            </Link>
          </React.Fragment>
        );
      })}
      {isFetching && <Loading {...loadingProps} />}
      {!initialFetched && !hasErrors && (
        <EmptyStub text={"Загружаем список\nпациентов"} centered />
      )}
      {hasErrors && <EmptyStub text={DATA_LOAD_ERROR} centered />}
      {isEmpty && (
        <EmptyStub
          centered
          text={
            filter.only_active
              ? "У вас нет активированных\n пациентов"
              : "Пациенты не найдены"
          }
        />
      )}
    </InfiniteList>
  );
};

export default forwardRef(ContactsList);
