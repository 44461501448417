import React, { useEffect, useMemo } from "react";
import getTariffText from "~/utils/getTariffText";
import ShapeButton from "~/components/ShapeButton";
import Scrollbar from "~/components/Scrollbar";
import { useSelectorModal } from "./SelectorModal";
import TariffsList from "~/modals/NewConsultation/components/TariffsList";
import TariffsListHeader from "~/modals/NewConsultation/components/TariffsListHeader";
import { TARIFF_SELECTOR } from "~/locators/consultations";

const getButton = (tariff, tariffs, onClick) => {
  let text = "Выбрать услугу";
  let textColorVariant = "pale";
  if (tariff) {
    text = getTariffText(tariff);
    textColorVariant = "primary";
  } else if (tariffs && tariffs.length === 0) {
    text = "Нет доступных услуг";
    textColorVariant = "error";
  }

  return (
    <ShapeButton
      testid={TARIFF_SELECTOR.PLACEHOLDER}
      textColorVariant={textColorVariant}
      onClick={onClick}
      disabled={!tariff && tariffs && tariffs.length < 2}
      className={!tariff && tariffs && tariffs.length === 0 ? "errorFrame" : ""}
      text={text}
    />
  );
};

const useTariffs = tariffs =>
  useMemo(
    () => ({
      allTariffs: [...tariffs.online, ...tariffs.offline],
      multiList: tariffs.online.length && tariffs.offline.length
    }),
    [tariffs]
  );

export default ({ tariff, tariffs, getTariffs, setTariff }) => {
  const [SelectorModal, toggleSelector] = useSelectorModal();

  const handleSelect = selectedTariff => {
    setTariff(selectedTariff);
    toggleSelector(false);
  };

  useEffect(() => {
    getTariffs();
  }, []);

  const { allTariffs, multiList } = useTariffs(tariffs);

  useEffect(() => {
    if (allTariffs.length && allTariffs.length < 2) {
      setTariff(allTariffs[0]);
    }
  }, [tariffs]);

  return (
    <>
      {getButton(tariff, tariffs, toggleSelector)}
      {allTariffs.length > 1 && (
        <SelectorModal style={{ marginTop: tariffs.length > 1 ? 8 : 31 }}>
          <Scrollbar
            showTrack
            autoHeight
            autoHeightMin={48}
            autoHeightMax={48 * 5}
          >
            {multiList ? (
              <>
                <TariffsListHeader title="Онлайн консультации" />
                <TariffsList
                  onItemClick={handleSelect}
                  tariffs={tariffs.online}
                  selected={tariff}
                />
                <TariffsListHeader title="Прием в клинике" />
                <TariffsList
                  onItemClick={handleSelect}
                  tariffs={tariffs.offline}
                  selected={tariff}
                />
              </>
            ) : (
              <TariffsList
                onItemClick={handleSelect}
                tariffs={allTariffs}
                selected={tariff}
              />
            )}
          </Scrollbar>
        </SelectorModal>
      )}
    </>
  );
};
