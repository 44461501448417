import cn from "classnames";
import React from "react";
import { Tabs } from "ibolit-ui";
import { CONSULTATIONS_LIST } from "~/locators/consultations";
import styles from "./TopBlock.scss";
import NewConsultationButtonContainer from "~/modals/NewConsultation/containers/NewConsultationButton";

const TopBlock = ({ className, filters, filter, setFilter }) => (
  <div className={cn(styles.container)}>
    <NewConsultationButtonContainer
      variant="button"
      className={styles.button}
      testid={CONSULTATIONS_LIST.NEW_CONSULTATION}
    />
    <Tabs
      testid={CONSULTATIONS_LIST.TABS}
      className={cn(className, styles.tabs, { [styles.hidden]: !filter })}
      onChange={setFilter}
      values={filters}
      value={filter}
      shift
    />
  </div>
);

export default TopBlock;
