import React, { useCallback, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "ibolit-ui";
import AUTH_LOCATORS from "~/locators/auth";
import LoginInput from "~/components/inputs/LoginInput";
import Field from "~/components/Field";
import styles from "../../Authorization.scss";
import { getAuthError, getLogin } from "~/store/auth/authSelectors";
import actions from "~/store/auth/authActions";
import Analytics from "~/utils/analytics";

const testidFieldProps = {
  input: AUTH_LOCATORS.AUTH_LOGIN_INPUT_USER,
  label: AUTH_LOCATORS.AUTH_LOGIN_LABEL_INPUT_USER,
  error: AUTH_LOCATORS.AUTH_LOGIN_INPUT_USER_ERROR_MESSAGE
};

const Login = () => {
  Analytics.useScreenAnalytics("Login Start");

  const login = useSelector(getLogin);
  const error = useSelector(getAuthError);
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const handleSubmit = useCallback(() => {
    dispatch(actions.checkLoginRequest(inputRef.current.value.trim()));
  }, []);
  const errors = typeof error === "string" ? { login: error } : error;
  const [canSubmit, setCanSubmit] = useState(false);

  return (
    <div className={styles.form}>
      <Field
        Component={LoginInput}
        initialValue={login}
        errors={errors}
        errorKey="login"
        ref={inputRef}
        label="Ваш телефон или email"
        autoFocus
        submitHandler={handleSubmit}
        validHandler={setCanSubmit}
        testidProps={testidFieldProps}
      />
      <Button
        disabled={!canSubmit}
        onClick={handleSubmit}
        fullWidth
        type="submit"
        testid={AUTH_LOCATORS.AUTH_LOGIN_BUTTON_SUBMIT_USER}
      >
        Продолжить
      </Button>
    </div>
  );
};

export default Login;
