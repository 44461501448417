import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Tooltip } from "ibolit-ui";
import RoundButton from "~/components/CallRoom/components/RoundButton/RoundButton";
import styles from "./RoomControls.scss";

function RoomControls(props) {
  const [tooltipsOpen, setTooltipsOpen] = useState({
    camera: false,
    microphone: false
  });
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    const showCameraTooltip = props.permissions.video === "denied";
    const showMicrophoneTooltip = props.permissions.audio === "denied";

    // When both permissions are denied, both tooltips should be hidden
    setTooltipsOpen({
      camera: showCameraTooltip && !showMicrophoneTooltip,
      microphone: showMicrophoneTooltip && !showCameraTooltip
    });
  }, [props.permissions]);

  useEffect(() => {
    setTooltipsOpen({ camera: false, microphone: false });
  }, [props.callStatus, props.isExitModalVisible]);

  useEffect(() => {
    if (props.connected === null) return;

    setDisabled(!props.connected);
  }, [props.connected]);

  const handleCameraToggle = () => {
    setTooltipsOpen(prevState => ({ ...prevState, camera: !prevState.camera }));
    props.onCameraToggle();
  };

  const handleMicrophoneToggle = () => {
    setTooltipsOpen(prevState => ({
      ...prevState,
      microphone: !prevState.microphone
    }));
    props.onMicrophoneToggle();
  };

  return (
    <div className={styles.container}>
      {props.permissions.video === "denied" ? (
        <Tooltip
          position="top"
          clickable
          opened={tooltipsOpen.camera}
          header={
            <RoundButton
              disabled={disabled}
              variant={[
                props.isCameraEnabled ? "video-on" : "video-off",
                "medium"
              ]}
              isDenied={tooltipsOpen.camera}
              onClick={handleCameraToggle}
            />
          }
          className={styles.tooltip}
          portalClassName={styles.tooltipPortal}
        >
          Предоставьте iBolit разрешение, чтобы использовать камеру для
          видеозвонков
        </Tooltip>
      ) : (
        <RoundButton
          disabled={disabled}
          onClick={handleCameraToggle}
          variant={[props.isCameraEnabled ? "video-on" : "video-off", "medium"]}
        />
      )}
      {props.permissions.audio === "denied" ? (
        <Tooltip
          position="top"
          clickable
          opened={tooltipsOpen.microphone}
          header={
            <RoundButton
              disabled={disabled}
              variant={[
                props.isMicrophoneEnabled ? "mic-on" : "mic-off",
                "medium"
              ]}
              isDenied={tooltipsOpen.microphone}
              onClick={handleMicrophoneToggle}
            />
          }
          className={styles.tooltip}
          portalClassName={styles.tooltipPortal}
        >
          Предоставьте iBolit разрешение, чтобы использовать микрофон для
          видеозвонков
        </Tooltip>
      ) : (
        <RoundButton
          disabled={disabled}
          onClick={handleMicrophoneToggle}
          variant={[props.isMicrophoneEnabled ? "mic-on" : "mic-off", "medium"]}
        />
      )}
      <RoundButton
        disabled={disabled}
        variant={["screen", "medium"]}
        onClick={props.onScreenShare}
      />
      <RoundButton
        variant={["call-end", "medium", "red"]}
        onClick={props.onRoomExit}
      />
    </div>
  );
}

RoomControls.propTypes = {
  callStatus: PropTypes.oneOf(["IDLE", "START", "ACTIVE", "END"]).isRequired,
  permissions: PropTypes.shape({
    video: PropTypes.string,
    audio: PropTypes.string
  }),
  isExitModalVisible: PropTypes.bool,
  isCameraEnabled: PropTypes.bool,
  isMicrophoneEnabled: PropTypes.bool,
  onCameraToggle: PropTypes.func,
  onMicrophoneToggle: PropTypes.func,
  onRoomExit: PropTypes.func,
  onScreenShare: PropTypes.func,
  connected: PropTypes.oneOfType([null, PropTypes.bool])
};

export default RoomControls;
