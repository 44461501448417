import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import actions from "~/store/feedback/feedbackAction";
import ProfileSupport from "./components/ProfileSupport";
import {
  getFeedbackRequestError,
  getFeedbackRequestLoading
} from "~/store/feedback/feedbackSelectors";

export const PROFILE_SUPPORT_VIEW = "profileSupport";

export default function ProfileSupportContainer() {
  const error = useSelector(getFeedbackRequestError);
  const loading = useSelector(getFeedbackRequestLoading);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.setDefaultState());
  });

  const onFormSubmit = useCallback(values => {
    dispatch(actions.sendRequest(values));
  }, []);

  return (
    <ProfileSupport
      error={error}
      loading={loading}
      onFormSubmit={onFormSubmit}
    />
  );
}
