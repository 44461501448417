import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import consultations from "./consultations/consultationsReducer";
import consultationRatings from "./consultationRatings/reducer";
import appStarted from "./appStarted/appStartedReducer";
import auth from "./auth/authReducer";
import chats from "./chats/chatsReducer";
import clinics from "./clinics/clinicsReducer";
import contacts from "./contacts/contactsReducer";
import error from "./error/errorReducer";
import faq from "./faq/faqReducer";
import feedback from "./feedback/feedbackReducer";
import messages from "./messages/messagesReducer";
import profile from "./profile/profileReducer";
import users from "./users/usersReducer";
import conclusions from "./conclusions/conclusionsReducer";
import toolbar from "./toolbar/toolbarReducer";
import slots from "./slots/slotsReducer";
import call from "./call/callReducer";
import ui from "./ui/uiReducer";

export default history =>
  combineReducers({
    consultations,
    appStarted,
    auth,
    chats,
    clinics,
    contacts,
    error,
    faq,
    feedback,
    messages,
    profile,
    router: connectRouter(history),
    users,
    conclusions,
    toolbar,
    slots,
    call,
    ui,
    consultationRatings
  });
